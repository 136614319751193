import React from "react";
import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

export function ToastMsg(props) {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export function toastsMsg(title, msg, type) {
  if (typeof title === "object") {
    if (title.icon === "success") {
      return toast.success(`${title.title} 
${title.text}`);
    } else if (title.icon === "error") {
      return toast.error(`${title.title} 
${title.text}`);
    } else if (title.icon === "warning") {
      return toast.warning(`${title.title} 
${title.text}`);
    }
  } else {
    if (type === "success") {
      return toast.success(`${title}
${msg}`);
    } else if (type === "error") {
      return toast.error(`${title}
${msg}`);
    } else if (type === "warning") {
      return toast.warning(`${title}
${msg}`);
    }
  }
}
