import React, { useState } from 'react';
import { Link } from "react-router-dom";
import LoginImg from "../../../assets/img/login.svg";
import projectprogressLogin from "../../../assets/img/projectprogressLogin.svg";
import api from "../../../config/api";
import { decodeToken } from '../../../config/auth';

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [load, setLoad] = useState(false);
    const [message, setMessage] = useState("");

    const fazerLogin = async (event) => {
        event.preventDefault();
        setLoad(true);
        setMessage("");
        await api.post(`/login`, { email, password })
            .then((response) => {
                const { token } = response.data;
                localStorage.setItem("TOKEN_KEY", token);
                setLoad(false);
                const { user = {} } = decodeToken();
                if(user.role === 'category'){
                    window.location.href = "/projetos-da-categoria";
                }else if(user.role === 'client'){
                    window.location.href = "/meus-projetos";
                }else{
                    window.location.href = "/dashboard";
                }
                
            })
            .catch((error) => {
                setMessage("Login e/ou senha inválidos!");
                if (error.response !== undefined) {
                    const { error: message } = error.response.data;
                    if (message === "User Not Found") {
                        setMessage("Usuário não encontrado!");
                    }
                    else if (message === "Invalid Password") {
                        setMessage("Senha inválida!");
                    }
                }
                setLoad(false);
            })
    }

    return (
        <div className="bg-white h-100 px-3 px-lg-0">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-12 col-lg-4 my-auto">
                        <div className="row">
                            <div className="col-12 py-5 text-center text-lg-start">
                                <img src={projectprogressLogin} alt="Project Progress" className="img-fluid" />
                            </div>
                            <div className="col-12 mb-3">
                                <h1 className="mb-0 font-montserrat fz-29" style={{ fontWeight: 500, color: "#3F4254" }}>Sua plataforma de gestão de projetos</h1>
                            </div>
                            <div className="col-12 mb-4">
                                <p className="mb-0 font-montserrat fz-18" style={{ color: "#3F4254" }}>Gerencie todos os seus projetos de forma simples e rápida!</p>
                            </div>
                            <div className="col-12">
                                <form onSubmit={fazerLogin}>
                                    <div className="mb-3">
                                        <label className="form-label text-uppercase font-poppins fz-16 mb-1 text-prop-grey-light">E-mail</label>
                                        <input type="email" className="form-control font-poppins rounded" style={{ borderColor: "#F2F2F2" }} onChange={(event) => setEmail(event.target.value)} required />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label text-uppercase font-poppins fz-16 mb-1 text-prop-grey-light">Senha</label>
                                        <input type="password" className="form-control font-poppins rounded" style={{ borderColor: "#F2F2F2" }} onChange={(event) => setPassword(event.target.value)} required />
                                    </div>
                                    <div className="mb-3 d-flex">
                                        <Link to={"/esqueci-minha-senha"} className="d-flex ms-auto font-poppins fz-12 text-prop-grey-light" style={{ textDecoration: "none" }}>Esqueci minha senha</Link>
                                    </div>
                                    {(!load) ?
                                        <button type="submit" className="btn btn-prop-blue d-flex w-100 text-white justify-content-center text-uppercase font-poppins fw-bold py-3 fz-16">Login</button>
                                        :
                                        <button type="submit" className="btn btn-prop-blue d-flex w-100 text-white justify-content-center text-uppercase font-poppins fw-bold py-3 fz-16" disabled>
                                            <div className="spinner-border spinner-border-sm text-light" role="status">
                                                <span className="visually-hidden">Carregando...</span>
                                            </div>
                                        </button>
                                    }
                                    <div className="my-3 d-flex justify-content-center">
                                        <span className="font-rubik text-prop-danger fz-12">{message}</span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 offset-lg-1 d-none d-lg-flex">
                        <img src={LoginImg} alt="Login" className="img-fluid" />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Login;