import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import {
  List,
  ListBody,
  ListBodyItem,
  ListBodyItemAction,
  ListHeader,
  ListHeaderItemSortAble,
} from "../../../components/List";
import ModalCategory from "../../../components/_modals/_agency/Category";
import EmptyList from "../../../components/_empty/list";
import Loading from "../../../components/Load";
import AddButton from "../../../components/AddButton";
import api from "../../../config/api";
import io from "socket.io-client";
import swal from "sweetalert";
import { toastsMsg } from "../../../components/ToasterMsg";
require("dotenv").config();

function Category() {
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [id, setId] = useState("");
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(false);
  const [nameFilter, setNameFilter] = useState("");

  useEffect(() => {
    getCategories(page);
    async function registerToSocket() {
      try {
        const socket = io(`${process.env.REACT_APP_API_URL}`, {
          withCredentials: false,
        });
        socket.on("category", () => {
          getCategories(page);
        });
      } catch (error) {
        console.log("socket error category agency", error);
      }
    }
    registerToSocket();
  }, [page]);

  const getCategories = async (mypage = 1, filter = "", sortBy = "createdAt", order = "desc") => {
    setLoading(true);
    const response = await api.get(
      `/category?paginate=true&page=${mypage}&filter=${filter}&order=${order}&sortBy=${sortBy}`
    );
    const { docs = [], ...arr } = response.data;
    setCategories(docs);
    setPages(parseInt(arr.pages));
    setLoading(false);
  };

  const nextPage = () => {
    getCategories(page + 1);
    setPage(page === pages ? page : page + 1);
  };

  const backPage = () => {
    getCategories(page - 1);
    setPage(page === 1 ? page : page - 1);
  };

  const destroy = async (item) => {
    swal({
      title: "Tem certeza que deseja excluir essa categoria?",
      text: `Essa categoria será apagada e não terá mais volta`,
      // icon: "warning",
      buttons: ["Cancelar", "EXCLUIR"],
      dangerMode: true,
    }).then(async (res) => {
      if (res) {
        await api
          .delete(`/category/${item._id}`)
          .then(async () => {
            await getCategories(page);
            toastsMsg({
              icon: "success",
              title: "Sucesso!",
              text: "Categoria removida com sucesso.",
            });
          })
          .catch(() => {
            toastsMsg({
              icon: "error",
              title: "Erro!",
              text: "Erro ao remover a categoria, tente novamente mais tarde.",
            });
          });
      }
    });
  };

  const edit = (item) => {
    setType("edit");
    setName(item.name);
    setId(item._id);
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    await api
      .put(`/category/${id}`, { name })
      .then(() => {
        setLoad(false);
        toastsMsg("Sucesso!", "Categoria editada com sucesso!", "success");
        document.getElementById("btnCloseSingle").click();
        getCategories(page);
      })
      .catch(() => {
        setLoad(false);
        toastsMsg("Erro!", "Erro ao editar a categoria, tente novamente mais tarde.", "error");
      });
  };

  const sortByNameChangeHandler = () => {
    if (!order) {
      getCategories(page, nameFilter, "name", "asc");
      setOrder(order ? false : true);
    } else {
      getCategories(page, nameFilter);
      setOrder(order ? false : true);
    }
  };

  const filterChangeHandlerName = (event) => {
    setNameFilter(event.target.value);
    getCategories(page, event.target.value);
  };

  return (
    <>
      <Layout defaultMenu={21} defaultSubMenu={203} title={"Categorias de projeto"}>
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-12 mb-4">
                            <h1 className="mb-0 fz-24 fw-bold font-rubik">Categorias de projeto</h1>
                        </div> */}
            <div className="col-12 col-lg-4 mt-2 me-auto">
              <div className="input-group input-search">
                <input
                  type="text"
                  className="form-control border-0 fz-14 box-shadow-none text-prop-grey-light font-rubik"
                  onChange={(event) => filterChangeHandlerName(event)}
                  placeholder="O que você está buscando?"
                />
                <span className="input-group-text bg-white border-0">
                  <i className="bi bi-search text-prop-grey-light"></i>
                </span>
              </div>
            </div>
            <AddButton
              sizeLg={6}
              onClick={() => setType("add")}
              modalId={"#myModalSingle"}
              text={"Adicionar Categoria"}
            />
            <div className="col-12 mb-4 table-border-default">
              <List paginate={true} page={page} pages={pages} backPage={() => backPage()} nextPage={() => nextPage()}>
                <ListHeader>
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler()} name="Nome" sizeLg={12} />
                </ListHeader>
                {categories.map((item, index) => (
                  <ListBody key={index} specialClass="d-flex flex-wrap">
                    <ListBodyItem title="Nome" value={item.name} sizeLg={11} />
                    <ListBodyItemAction sizeLg={1}>
                      <li>
                        <span
                          className="dropdown-item fz-14 font-rubik c-pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#myModalSingle"
                          onClick={() => edit(item)}
                        >
                          Editar
                        </span>
                      </li>
                      <li>
                        <span
                          className="dropdown-item fz-14 font-rubik text-prop-danger c-pointer"
                          onClick={() => destroy(item)}
                        >
                          Excluir
                        </span>
                      </li>
                    </ListBodyItemAction>
                  </ListBody>
                ))}
                {categories.length === 0 ? (
                  <EmptyList icon="indiferente" message="Não foi encontrada nenhuma categoria" />
                ) : (
                  <></>
                )}
              </List>
            </div>
          </div>
        </div>
      </Layout>
      <div
        className="modal fade border-0"
        id="myModalSingle"
        tabIndex="-1"
        aria-labelledby="myModalSingleLabel"
        aria-hidden="true"
        style={{ borderRadius: "10px" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {type === "add" ? (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalLabel">
                    Adicionar categoria de projeto
                  </h5>
                  <button
                    type="button"
                    id="btnCloseCategory"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <ModalCategory close={() => document.getElementById("btnCloseCategory").click()} />
                </div>
              </>
            ) : (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                    Editar categoria de projeto
                  </h5>
                  <button
                    type="button"
                    id="btnCloseSingle"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={save}>
                    <div className="row">
                      <div className="col-12 col-lg-12 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Nome</label>
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          onChange={(event) => setName(event.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-12 d-flex align-items-center justify-content-end">
                        <button
                          type="button"
                          className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-28"
                          data-bs-dismiss="modal"
                        >
                          Cancelar
                        </button>
                        {load ? (
                          <button
                            type="button"
                            className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                            disabled
                          >
                            <div className="spinner-border spinner-border-sm text-light" role="status">
                              <span className="visually-hidden">Carregando...</span>
                            </div>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                          >
                            Salvar
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Loading show={loading} />
    </>
  );
}

export default Category;
