import React from 'react';

function WidgetClient(props) {

    return (
        <div className={`${(props.specialClass === undefined) ? '' : props.specialClass} col-12 col-lg-${(props.sizeLg === undefined) ? "auto" : props.sizeLg} `}>
            <div className={`col-12 col-lg-auto d-flex align-items-center justify-content-start`}>
                <div>
                    <img src={(props.img !== undefined) ? props.img : ""} alt="" className="img-fluid rounded border" width={71} height={71} />
                </div>
                <div className="d-flex flex-column ms-3">
                    <label className="font-rubik fw-bold fz-24 text-prop-grey-dark mb-0">{props.clientName}</label>
                    <label className="font-rubik fz-16 text-prop-grey-light">{props.projectCategory}</label>
                </div>
            </div>
        </div>
    );
}

export default WidgetClient;