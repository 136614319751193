import axios from "axios";
import { getToken } from "./auth";
require('dotenv').config();

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// api.interceptors.response.use((response) => {
//     return response;
// }, async (error) => {
//     window.alert("Sessão expirada!");
//     localStorage.clear();
//     window.location.reload();
//     await Promise.reject(error.message);
//     return ;
// });

export default api;