import React from "react";

function WidgetTitle(props) {
  return (
    <div className={`d-flex row-cols-auto justify-content-between align-items-center mb-3 ${props.className}`}>
      <div className="col">
        <h4 className="mb-0 text-prop-dark-blue fw-bold fz-20 font-rubik">{props.title}</h4>
      </div>
      {props.children}
    </div>
  );
}

export default WidgetTitle;
