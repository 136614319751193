import React from "react";
import { Link } from "react-router-dom";

export default function SidebarProject(props) {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-white mb-4"
      style={{ borderRadius: "10px" }}
    >
      <div className="container-fluid px-0">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse w-100" id="navbarNav">
          <ul className="navbar-nav d-flex justify-content-between w-100">
            <li className="nav-item col ">
              <Link
                className={`nav-link d-flex justify-content-center  text-uppercase fw-bold fz-14 ${
                  props.active === "overview"
                    ? "text-prop-blue border-bottom-celestial-blue"
                    : "text-prop-grey-light border-bottom-bright-gray"
                }`}
                to={`/projeto/${props.project}/overview`}
              >
                Overview
              </Link>
            </li>
            <li className="nav-item col ">
              <Link
                className={`nav-link d-flex justify-content-center  text-uppercase fw-bold fz-14 ${
                  props.active === "sprint"
                    ? "text-prop-blue border-bottom-celestial-blue"
                    : "text-prop-grey-light border-bottom-bright-gray"
                }`}
                to={`/projeto/${props.project}/sprints`}
              >
                Sprints
              </Link>
            </li>
            {/* <li className="nav-item col ">
                            <Link className={`nav-link d-flex justify-content-center  text-uppercase fw-bold fz-14 ${(props.active === "task") ? "text-prop-blue border-bottom-celestial-blue" : "text-prop-grey-light border-bottom-bright-gray"}`} to={`/projeto/${props.project}/tarefas`}>Tarefas</Link>
                        </li> */}
            <li className="nav-item col ">
              <Link
                className={`nav-link d-flex justify-content-center  text-uppercase fw-bold fz-14 ${
                  props.active === "reunion"
                    ? "text-prop-blue border-bottom-celestial-blue"
                    : "text-prop-grey-light border-bottom-bright-gray"
                }`}
                to={`/projeto/${props.project}/reunioes`}
              >
                Reuniões
              </Link>
            </li>
            <li className="nav-item col ">
              <Link
                className={`nav-link d-flex justify-content-center  text-uppercase fw-bold fz-14 ${
                  props.active === "report"
                    ? "text-prop-blue border-bottom-celestial-blue"
                    : "text-prop-grey-light border-bottom-bright-gray"
                }`}
                to={`/projeto/${props.project}/reports`}
              >
                Reports
              </Link>
            </li>
            <li className="nav-item col ">
              <Link
                className={`nav-link d-flex justify-content-center  text-uppercase fw-bold fz-14 ${
                  props.active === "stakeholder"
                    ? "text-prop-blue border-bottom-celestial-blue"
                    : "text-prop-grey-light border-bottom-bright-gray"
                }`}
                to={`/projeto/${props.project}/stakeholders`}
              >
                Stakeholders
              </Link>
            </li>
            <li className="nav-item col-3 ">
              <Link
                className={`nav-link d-flex justify-content-center  text-uppercase fw-bold fz-14 text-aling-center ${
                  props.active === "history"
                    ? "text-prop-blue border-bottom-celestial-blue"
                    : "text-prop-grey-light border-bottom-bright-gray"
                }`}
                to={`/projeto/${props.project}/historico-de-alteracoes`}
              >
                Histórico de Alterações
              </Link>
            </li>
            <li className="nav-item col-2 ">
              <Link
                className={`nav-link d-flex justify-content-center  text-uppercase fw-bold fz-14 ${
                  props.active === "settings"
                    ? "text-prop-blue border-bottom-celestial-blue"
                    : "text-prop-grey-light border-bottom-bright-gray"
                }`}
                to={`/projeto/${props.project}/configuracoes-do-projeto`}
              >
                Configurações
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
