import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
// import { faCheckCircle, faCircleXmark} from "@fortawesome/free-regular-svg-icons"
import ReactTooltip from "react-tooltip";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export function WidgetProjectInfo(props) {
  return (
    <div
      className={"card border-0 shadow-sm h-100 p-3 " + props.background}
      style={{ borderRadius: "7px", border: " 2px solid #D9E2EC", boxShadow: "0px 4px 8px #CBCBCB17" }}
    >
      {props.qtPeople ? (
        <>
          <div className="d-flex align-items-start jutify-content-center justify-content-lg-between py-0">
            <span
              onClick={props.onClickAndamento}
              className="d-block d-lg-flex font-rubik text-prop-dark-blue fz-16 c-pointer"
            >
              <span className="fw-bold me-1">{props.qtProjects}</span>
              {props.title}
            </span>
            <span className="d-block d-lg-flex font-rubik text-prop-dark-blue fz-16">
              <span className="fw-bold me-1">{props.qtPeople}</span>
              {props.text}
            </span>
          </div>
          <div className="d-flex justify-content-end align-items-start w-100 mb-3">
            <a
              href={props.link}
              className="font-rubik fz-14 text-prop-celestial-blue"
              style={{ textDecoration: "none" }}
            >
              {props.linkName}
            </a>
          </div>
          <div className="w-100 d-flex flex-column flex-lg-row justify-content-between mt-2 mt-lg-0">
            {props.children}
          </div>
        </>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div>
              <Skeleton width={230} count={1} height={16} />
            </div>
            <div>
              <Skeleton width={280} count={1} height={16} />
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-start w-100 mb-3">
            <Skeleton width={80} count={1} height={16} />
          </div>
          <div className="w-100 d-flex flex-column flex-lg-row justify-content-between mt-2 mt-lg-0">
            {props.children}
          </div>
        </>
      )}
    </div>
  );
}

export function WidgetNumberWithClick(props) {
  return (
    <div className={" border-0 p-3 col h-100 " + props.background} style={{ borderRadius: "5px" }}>
      <div className="row m-0">
        <div className="d-flex align-items-start justify-content-between w-100 px-0">
          <span onClick={props.onClick} className={`c-pointer d-flex font-rubik text-${props.textColor} fw-bold fz-12`}>
            {props.title}
          </span>
          {props.children}
        </div>
        <span
          onClick={props.onClick}
          className={`c-pointer d-flex font-rubik text-prop-dark-blue fw-bold px-0 font-number-widget ${
            props.fontSizeValue !== undefined ? props.fontSizeValue : "fz-40"
          }`}
        >
          {props.value}
        </span>
      </div>
    </div>
  );
}

export function WidgetNumberWithClickAdmin(props) {
  return (
    <div className={" border-0 p-3 col h-100 " + props.background} style={{ borderRadius: "5px" }}>
      <div className="row m-0">
        <div className="d-flex align-items-start justify-content-between w-100 px-0 mb-3">
          <span onClick={props.onClick} className={`c-pointer d-flex font-rubik text-${props.textColor} fw-bold fz-12`}>
            {props.title}
          </span>
          {props.children}
        </div>
        <span
          onClick={props.onClick}
          className={`c-pointer d-flex font-rubik text-prop-dark-blue fw-bold px-0 font-number-widget ${
            props.fontSizeValue !== undefined ? props.fontSizeValue : "fz-24"
          }`}
        >
          {props.value}
        </span>
      </div>
    </div>
  );
}

export function WidgetNumberWithClickAdminList(props) {
  return (
    <div className={" border-0 p-4 col h-100 " + props.background} style={{ borderRadius: "5px" }}>
      <div className="row m-0">
        <div className="d-flex align-items-center px-0 mb-2">
          <FontAwesomeIcon icon={props.icon} size={props.iconSize} color={props.iconColor} />
          <span
            onClick={props.onClick}
            className={`ms-2 c-pointer d-flex font-rubik text-uppercase text-${props.textColor} fw-bold fz-10`}
          >
            {" "}
            {props.title}
          </span>
          {props.children}
        </div>
        <span
          onClick={props.onClick}
          className={`c-pointer d-flex font-rubik text-prop-dark-blue fw-bold px-0 font-number-widget ${
            props.fontSizeValue !== undefined ? props.fontSizeValue : "fz-24"
          }`}
        >
          {props.value}
        </span>
        <span onClick={props.onClick} className={`c-pointer d-flex font-montserrat text-prop-dark-blue px-0 fz-12`}>
          {props.lastPeriod}
        </span>
      </div>
    </div>
  );
}

export function WidgetDropdownList(props) {
  return (
    <>
      <button
        type="button"
        style={{ height: "16px" }}
        className="d-flex justify-content-center btn btn-secondary dropdown-toggle dropdown-toggle-split p-0 box-shadow-none"
        id={props.id}
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-reference="parent"
      >
        <FontAwesomeIcon color={props.iconColor} size="sm" icon={faQuestionCircle} />
      </button>
      <ul
        className="dropdown-menu"
        id={props.id}
        aria-labelledby={props.WidgetProjectInfo}
        style={{ borderRadius: "5px", right: "auto", left: "20%", top: "40%" }}
      >
        {props.children}
      </ul>
    </>
  );
}

export function WidgetDropdownItem(props) {
  return (
    <div
      onClick={() => (window.location.href = `/projeto/${props.projectID}/overview`)}
      className="dropdown-item c-pointer"
      style={{ padding: "16px 24px", width: "278px", borderBottom: "2px solid #EAEFF4" }}
    >
      <span className="font-rubik fw-bold fz-14 text-prop-dark-blue">{props.projetName}</span>
      <div className="d-flex justify-content-between">
        <span className="font-rubik fz-12 text-prop-dark-eletric-blue">{props.days}</span>
        <span className="font-rubik fz-12 text-prop-dark-eletric-blue">{props.responsible}</span>
      </div>
    </div>
  );
}

export function WidgetTooltip(props) {
  return (
    <>
      <ReactTooltip id={props.id} place="top" type="dark" effect="float">
        <span>{props.text}</span>
      </ReactTooltip>
    </>
  );
}
