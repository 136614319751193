import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import AddButton from "../../../components/AddButton";
import {
  List,
  ListBody,
  ListBodyItem,
  ListBodyItemImage,
  ListHeaderItemSortAble,
  ListHeader,
  ListHeaderItem,
  ListBodyItemStatus,
  ListBodyItemAction,
} from "../../../components/List";
import api from "../../../config/api";
// import io from "socket.io-client";
import Loading from "../../../components/Load";
import ModalUserCompany from "../../../components/_modals/_company/UserCompany";
import swal from "sweetalert";
import EmptyList from "../../../components/_empty/list";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { Drawer } from "../../../components/Drawer";
import ModalDepartament from "../../../components/_modals/_agency/Departament";
import { toastsMsg } from "../../../components/ToasterMsg";

require("dotenv").config();

function UserCompany() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [statusFilter, setStatusFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [id, setId] = useState("");
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileUpload, setFileUpload] = useState("");
  const [newImage, setNewImage] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  let [status, setStatus] = useState("");
  const [sort, setSort] = useState("");
  const [departaments, setDepartaments] = useState([]);
  const [departament, setDepartament] = useState("");
  const [departamentFilter, setDepartamentFilter] = useState("");
  const [departamentsInUser, setDepartamentsInUser] = useState([]);
  const [drawerOpenAddUserCompany, setDrawerOpenAddUserCompany] = useState(false);
  const [drawerOpenAddDepartment, setDrawerOpenAddDepartment] = useState(false);

  useEffect(() => {
    getUsers(page);
    getDepartaments();
    getDepartamentsInUser();
    // async function registerToSocket() {
    //     const socket = io(`${process.env.REACT_APP_API_URL}`);
    //     socket.on("user", () => {
    //         getUsers(page);
    //     });
    // }
    // registerToSocket();
  }, [page]);

  const getUsers = async (
    mypage = 1,
    filterName = "",
    filterStatus = "",
    filterDepartament = "",
    sortBy = "createdAt",
    order = "desc"
  ) => {
    setLoading(true);
    const response = await api.get(
      `/user/company?paginate=true&page=${mypage}&filterName=${filterName}&filterStatus=${filterStatus}&filterDepartament=${filterDepartament}&order=${order}&sortBy=${sortBy}`
    );
    const { docs = [], ...arr } = response.data;
    setUsers(docs);
    setPages(parseInt(arr.pages));
    setLoading(false);
  };

  const getDepartaments = async () => {
    const response = await api.get(`/departament?limit=9999&sortBy=name&order=ASC`);
    setDepartaments(response.data);
  };
  const getDepartamentsInUser = async () => {
    const response = await api.get(`/user-in-departament?filter=${"admin"}&limit=9999&sortBy=name&order=ASC`);
    setDepartamentsInUser(response.data);
  };

  const nextPage = () => {
    getUsers(page + 1);
    setPage(page === pages ? page : page + 1);
  };

  const backPage = () => {
    getUsers(page - 1);
    setPage(page === 1 ? page : page - 1);
  };

  const destroy = async (item) => {
    swal({
      title: "Tem certeza que deseja excluir esse administrador?",
      text: `Esse administrado será apagado e não terá mais volta`,
      // icon: "warning",
      buttons: ["Cancelar", "EXCLUIR"],
      dangerMode: true,
    }).then(async (res) => {
      if (res) {
        await api
          .delete(`/user/${item._id}`)
          .then(async () => {
            await getUsers(page);
            toastsMsg({
              icon: "success",
              title: "Sucesso!",
              text: "Colaborador removido com sucesso.",
            });
          })
          .catch(() => {
            toastsMsg({
              icon: "error",
              title: "Erro!",
              text: "Erro ao remover o administrador, tente novamente mais tarde.",
            });
          });
      }
    });
  };

  const edit = async (item) => {
    setType("edit");
    setDrawerOpenAddUserCompany(true);
    setFirstName(item.firstName);
    setLastName(item.lastName);
    setStatus(item.status);
    setId(item._id);
    setFileUpload("");
    setNewImage("");
    setFile(item.avatar);
    setEmail(item.email);
    setDepartament(item.departament === undefined || item.departament === null ? "" : item.departament._id);
  };

  const handleImageChange = async (e) => {
    setFileUpload(e.target.files[0]);
    setNewImage(URL.createObjectURL(e.target.files[0]));
    e.target.value = "";
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    if (password === confirm_password) {
      const dataup = new FormData();
      if (fileUpload !== "") {
        dataup.append("file", fileUpload);
      }

      dataup.append("firstName", firstName);
      dataup.append("lastName", lastName);
      dataup.append("email", email);
      if (status === "") {
        status = "active";
      }
      dataup.append("departament", departament);
      dataup.append("status", status);
      dataup.append("password", password);
      dataup.append("role", "company");
      //console.log(dataup.get("firstName"));

      await api
        .put(`/user/${id}`, dataup)
        .then(() => {
          setLoad(false);
          toastsMsg("Sucesso!", "Colaborador editado com sucesso!", "success");
          setFileUpload("");
          setNewImage("");
          getUsers(page);
          setDrawerOpenAddUserCompany(false);
        })
        .catch((error) => {
          setLoad(false);
          if (error.response.data.error === "User Already Exists") {
            toastsMsg("Erro!", "Esse e-mail já está sendo usado, por favor utilize outro", "error");
          } else {
            toastsMsg("Erro!", "Erro ao editar o administrador, tente novamente mais tarde.", "error");
          }
        });
    } else {
      setLoad(false);
      toastsMsg(
        "Erro!",
        "Senha e Confirmar Senha não estão iguais, por favor insira informações corretamente.",
        "error"
      );
    }
  };

  const filterChangeHandlerName = (event) => {
    setNameFilter(event.target.value);
    getUsers(page, event.target.value, statusFilter, departamentFilter);
  };

  // const filterChangeHandlerStatus = (event) => {
  //   setStatusFilter(event.target.value);
  //   getUsers(page, nameFilter, event.target.value, departamentFilter);
  // };

  // const filterChangeHandlerDepartament = (event) => {
  //   setDepartamentFilter(event.target.value);
  //   getUsers(page, nameFilter, statusFilter, event.target.value);
  // };

  const sortByNameChangeHandler = (sortBy) => {
    if (sort !== sortBy) {
      getUsers(page, nameFilter, statusFilter, sortBy, "asc");
      setSort(sortBy);
    } else {
      getUsers(page, nameFilter, statusFilter);
      setSort("");
    }
  };

  const addUserAgency = () => {
    setType("add");
    setDrawerOpenAddUserCompany(true);
  };

  const openAddDepartment = () => {
    setDrawerOpenAddDepartment(true);
  };

  const verifyDepartament = (event) => {
    if (event === "addDepartment") {
      openAddDepartment();
    } else {
      setDepartament(event);
      console.log(event);
    }
  };

  return (
    <>
      <Layout defaultMenu={20} defaultSubMenu={208} title={"Administradores"}>
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-12 mb-4">
                            <h1 className="mb-0 fz-24 fw-bold font-rubik">Administradores</h1>
                        </div> */}
            <div className="col-12 col-lg-4 mt-2 me-auto">
              <div className="input-group input-search">
                <input
                  type="text"
                  className="form-control border-0 fz-14 box-shadow-none text-prop-grey-light font-rubik"
                  onChange={(event) => filterChangeHandlerName(event)}
                  placeholder="O que você está buscando?"
                />
                <span className="input-group-text bg-white border-0">
                  <i className="bi bi-search text-prop-grey-light"></i>
                </span>
              </div>
            </div>
            {/* <div className="col-12 col-lg-3 mt-2">
                            <select className="filter-select fz-12 text-prop-grey-light font-rubik" onChange={(event) => filterChangeHandlerStatus(event)} required>
                                <option value="" key={0}>Selecione o status</option>
                                <option value="active" key={1}>Ativo</option>
                                <option value="inactive" key={2}>Inativo</option>
                            </select>
                        </div>
                        <div className="col-12 col-lg-3 mt-2">
                            <select className="filter-select fz-12 text-prop-grey-light font-rubik" onChange={(event) => filterChangeHandlerDepartament(event)} required>
                                <option value="" key={0}>Selecione o departamento</option>
                                {departamentsInUser.map((item, index) => (
                                    <option value={item._id} key={index}>{item.name}</option>
                                ))}
                            </select>
                        </div> */}
            <AddButton
              size={6}
              sizeLg={3}
              text={"Adicionar Administrador"}
              onClick={() => addUserAgency()}
              modalId={"#myModalSingle"}
            />
            <div className="col-12 mb-4 table-border-default">
              <List
                title="administradores"
                paginate={true}
                page={page}
                pages={pages}
                backPage={() => backPage()}
                nextPage={() => nextPage()}
              >
                <ListHeader>
                  <ListHeaderItem name="" sizeLg={1} />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("name")} name="Nome" sizeLg={3} />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("status")} name="Status" sizeLg={2} />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("departament")}
                    name="Departamento"
                    sizeLg={3}
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("createdAt")}
                    name="Criado em"
                    sizeLg={3}
                  />
                </ListHeader>

                {users.map((item, index) => (
                  <ListBody key={index} specialClass="d-flex flex-wrap">
                    <ListBodyItemImage img={item.avatar} sizeLg={1} />
                    <ListBodyItem title="Nome" value={`${item.firstName} ${item.lastName}`} sizeLg={3} />
                    <ListBodyItemStatus
                      title="Status"
                      value={item.status === "active" ? "Ativo" : "Inativo"}
                      sizeLg={2}
                    />
                    <ListBodyItem
                      title="Departamento"
                      value={
                        item.departament === null || item.departament === undefined
                          ? "Não informado"
                          : item.departament.name
                      }
                      sizeLg={3}
                    />
                    <ListBodyItem title="Criado em" value={new Date(item.createdAt).toLocaleDateString()} sizeLg={2} />
                    <ListBodyItemAction sizeLg={1}>
                      <li>
                        <span
                          className="dropdown-item fz-14 font-rubik c-pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#myModalSingle"
                          onClick={() => edit(item)}
                        >
                          Editar
                        </span>
                      </li>
                      <li>
                        <span
                          className="dropdown-item fz-14 font-rubik text-prop-danger c-pointer"
                          onClick={() => destroy(item)}
                        >
                          Excluir
                        </span>
                      </li>
                    </ListBodyItemAction>
                  </ListBody>
                ))}
                {users.length === 0 ? (
                  <EmptyList icon="indiferente" message="Não foi encontrado nenhum administrador" />
                ) : (
                  <></>
                )}
              </List>
            </div>
          </div>
        </div>
      </Layout>
      <Drawer
        show={drawerOpenAddUserCompany}
        onClose={() => setDrawerOpenAddUserCompany(false)}
        onOpen={() => setDrawerOpenAddUserCompany(true)}
      >
        <div className="p-3 modal-drawer">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              {type === "add" ? (
                <>
                  <div className="modal-header border-0">
                    <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalLabel">
                      Adicionar administrador
                    </h5>
                    <button
                      type="button"
                      id="btnClose"
                      className="btn-close"
                      onClick={() => setDrawerOpenAddUserCompany(false)}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <ModalUserCompany
                      close={() => setDrawerOpenAddUserCompany(false)}
                      openAddDepartment={openAddDepartment}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="modal-header border-0">
                    <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                      Editar administrador
                    </h5>
                    <button
                      type="button"
                      id="btnCloseSingleCompany"
                      className="btn-close"
                      onClick={() => setDrawerOpenAddUserCompany(false)}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <form name="formmodal" novalidate>
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                          <div
                            className="d-flex align-items-center justify-content-center position-relative rounded-circle"
                            style={{ width: "115px", height: "115px" }}
                          >
                            <img
                              alt=""
                              src={fileUpload !== "" ? newImage : file}
                              className="img-fluid rounded-circle"
                              style={{ width: "115px", height: "115px" }}
                            />
                            <label
                              htmlFor="file"
                              className="p-2 rounded-circle d-flex align-items-center justify-content-center"
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                right: 0,
                                bottom: "10px",
                                backgroundColor: "#22A6AC",
                              }}
                            >
                              <FontAwesomeIcon color="white" icon={faPencilAlt} />
                              <input type="file" id="file" className="d-none" onChange={handleImageChange} />
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Nome</label>
                          <input
                            type="text"
                            className="form-control"
                            value={firstName}
                            onChange={(event) => setFirstName(event.target.value)}
                            required
                          />
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                            Sobrenome
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={lastName}
                            onChange={(event) => setLastName(event.target.value)}
                            required
                          />
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                            Status
                          </label>
                          <select
                            className="form-select"
                            value={status}
                            onChange={(event) => setStatus(event.target.value)}
                            required
                          >
                            <option></option>
                            <option value="active">Ativo</option>
                            <option value="inactive">Inativo</option>
                          </select>
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                            Departamento
                          </label>
                          <select
                            className="form-select"
                            value={departament}
                            onChange={(event) => verifyDepartament(event.target.value)}
                            onClick={() => getDepartaments()}
                            required
                          >
                            <option></option>
                            {departaments.map((item, index) => (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            ))}
                            <option value={"addDepartment"}>Criar Departamento</option>
                          </select>
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                            E-mail
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            required
                          />
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Senha</label>
                          <input
                            type="password"
                            className="form-control"
                            onChange={(event) => setPassword(event.target.value)}
                            required
                          />
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                            Confirmar Senha
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            onChange={(event) => setConfirmPassword(event.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-12 d-flex align-items-center justify-content-end">
                          <button
                            type="button"
                            className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-28"
                            onClick={() => setDrawerOpenAddUserCompany(false)}
                          >
                            Cancelar
                          </button>
                          {load ? (
                            <button
                              type="button"
                              className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                              disabled
                            >
                              <div className="spinner-border spinner-border-sm text-light" role="status">
                                <span className="visually-hidden">Carregando...</span>
                              </div>
                            </button>
                          ) : (
                            <button
                              type="submit"
                              onClick={save}
                              className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                            >
                              Salvar
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Drawer>
      <Drawer
        show={drawerOpenAddDepartment}
        onClose={() => setDrawerOpenAddDepartment(false)}
        onOpen={() => setDrawerOpenAddDepartment(true)}
      >
        <div className="p-3 modal-drawer">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalLabel">
                  Adicionar departamento
                </h5>
                <button
                  type="button"
                  id="btnCloseDepartament"
                  className="btn-close"
                  onClick={() => setDrawerOpenAddDepartment(false)}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <ModalDepartament close={() => setDrawerOpenAddDepartment(false)} getDepartaments={getDepartaments} />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <Loading show={loading} />
    </>
  );
}

export default UserCompany;
