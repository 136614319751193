import jwt_decode from 'jwt-decode';

export const isAuthenticated = () => localStorage.getItem("TOKEN_KEY") !== null;

export const getToken = () => localStorage.getItem("TOKEN_KEY");

export const isCompany = () => {
    if (isAuthenticated()) {
        const token = jwt_decode(getToken());
        if (token.user.role === "company") {
            return true;
        }
    }
    return false;
}

export const isAgency = () => {
    if (isAuthenticated()) {
        const token = jwt_decode(getToken());
        if (token.user.role === "agency") {
            return true;
        }
    }
    return false;
}

export const isClient = () => {
    if (isAuthenticated()) {
        const token = jwt_decode(getToken());
        if (token.user.role === "client") {
            return true;
        }
    }
    return false;
}

export const isCategory = () => {
    if (isAuthenticated()) {
        const token = jwt_decode(getToken());
        if (token.user.role === "category") {
            return true;
        }
    }
    return false;
}

export const isShared = () => {
    if (isAuthenticated()) {
        const token = jwt_decode(getToken());
        if (token.user.role === "company" || token.user.role === "agency") {
            return true;
        }
    }
    return false;
}

export const decodeToken = () => {
    if (getToken())
        return jwt_decode(localStorage.getItem("TOKEN_KEY"));
    return null;
};