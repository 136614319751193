import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { decodeToken } from "../../../config/auth";
// import MaterialIcon from 'material-icons-react';
import plusicon from "../../../assets/img/icons/plusicon.svg";

function WidgetAbstract(props) {
  const [user, setUser] = useState({});

  useEffect(() => {
    const { user = {} } = decodeToken();
    setUser(user);
  }, [props]);

  return (
    <div className="card py-2 px-0 " style={{ border: "2px solid #EAEFF4", borderRadius: "8px" }}>
      <div className="card-body">
        <div className="row mb-4">
          <div className="col-12 col-lg-12">
            <div className="row h-100 align-items-center">
              <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                <span className="font-rubik fz-17 text-prop-dark-eletric-blue fw-bold">Progresso</span>
                <span className={`font-rubik fw-bold fz-17 ${props.textAlertClass}`}>{props.projectProgress}%</span>
              </div>
              <div className="col-12">
                <div className="progress rounded" style={{ height: 6 }}>
                  <div
                    className={`progress-bar ${props.bgAlertClass}`}
                    role="progressbar"
                    style={{ width: `${props.projectProgress}%` }}
                    aria-valuenow={props.projectProgress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-cols-auto mb-0 mb-lg-4">
          <div className="col-6 col-lg-auto d-flex flex-column mb-3 mb-lg-0" style={{ marginRight: "1%" }}>
            <span className="font-rubik fw-bold text-prop-dark-eletric-blue fz-16">Empresa Responsável</span>
            <span className="font-rubik text-prop-grey-dark fz-16">{props.agencyName}</span>
          </div>
          <div className="col-6 col-lg-auto d-flex flex-column mb-3 mb-lg-0" style={{ marginRight: "1%" }}>
            <span className="font-rubik fw-bold text-prop-dark-eletric-blue fz-16">Início do projeto</span>
            <span className="font-rubik text-prop-grey-dark fz-16">{props.projectKickoffDate}</span>
          </div>
          <div className="col-6 col-lg-auto d-flex flex-column mb-3 mb-lg-0" style={{ marginRight: "1%" }}>
            <span className="font-rubik fw-bold text-prop-dark-eletric-blue fz-16">Categoria do Projeto</span>
            <span className="font-rubik text-prop-grey-dark fz-16">{props.projectType}</span>
          </div>
          <div className="col-6 col-lg-auto d-flex flex-column mb-3 mb-lg-0" style={{ marginRight: "1%" }}>
            <span className="font-rubik fw-bold text-prop-dark-eletric-blue fz-16">Responsável</span>
            <span className="font-rubik text-prop-grey-dark fz-16">{props.projectDevs}</span>
          </div>
        </div>
        {(props.contract !== null && props.contract !== undefined) ||
        (props.requirements !== null && props.requirements !== undefined) ||
        (props.layout !== null && props.layout !== undefined) ||
        (props.wireframe !== null && props.wireframe !== undefined) ||
        user.role === "agency" ? (
          <>
            <div className="row mb-2">
              <div className="col-12 d-flex flex-column">
                <span className="font-rubik fw-bold text-prop-dark-eletric-blue fz-15">Documentação</span>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-lg-2 mb-2 mb-lg-0">
                {props.contract !== null && props.contract !== undefined ? (
                  <a
                    href={props.contract}
                    target="_Blank"
                    rel="noreferrer"
                    className="btn btn-prop-celestial-blue btn-prop-disabled text-white fz-14 fw-bold font-rubik px-5 d-flex align-items-center justify-content-center w-100"
                    style={{ borderRadius: 7 }}
                  >
                    Contrato <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
                  </a>
                ) : (
                  <>
                    {(user.role !== "client") & (user.role !== "company") & (user.role !== "category") ? (
                      <button
                        onClick={props.onClickContract}
                        data-bs-toggle="modal"
                        data-bs-target="#myModalSingle"
                        type="button"
                        className="btn btn-white save-close-button btn-prop-disabled  fz-14 fw-bold font-rubik px-4 align-items-center d-flex justify-content-center w-100"
                      >
                        <img src={plusicon} width={13} height={13} className="mx-2 plusicon" alt={``} /> Contrato
                      </button>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
              <div className="col-6 col-lg-2 mb-2 mb-lg-0">
                {props.requirements !== null && props.requirements !== undefined ? (
                  <a
                    href={props.requirements}
                    target="_Blank"
                    rel="noreferrer"
                    className="btn btn-prop-celestial-blue btn-prop-disabled text-white fz-14 fw-bold font-rubik px-5 d-flex align-items-center justify-content-center w-100"
                    style={{ borderRadius: 7 }}
                    disabled
                  >
                    Requisitos <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
                  </a>
                ) : (
                  <>
                    {(user.role !== "client") & (user.role !== "company") & (user.role !== "category") ? (
                      <button
                        onClick={props.onClickRequirements}
                        data-bs-toggle="modal"
                        data-bs-target="#myModalSingle"
                        type="button"
                        className="btn btn-white save-close-button btn-prop-disabled align-items-center fz-14 fw-bold font-rubik px-4 d-flex justify-content-center w-100"
                      >
                        <img src={plusicon} width={13} height={13} className="mx-2 plusicon" alt={``} /> Requisitos
                      </button>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
              <div className="col-6 col-lg-2 mb-2 mb-lg-0">
                {props.layout !== null && props.layout !== undefined ? (
                  <a
                    href={props.layout}
                    target="_Blank"
                    rel="noreferrer"
                    className="btn btn-prop-celestial-blue btn-prop-disabled text-white fz-14 fw-bold font-rubik px-5 d-flex align-items-center justify-content-center w-100"
                    style={{ borderRadius: 7 }}
                    disabled
                  >
                    Protótipo <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
                  </a>
                ) : (
                  <>
                    {(user.role !== "client") & (user.role !== "company") & (user.role !== "category") ? (
                      <button
                        onClick={props.onClickLayout}
                        data-bs-toggle="modal"
                        data-bs-target="#myModalSingle"
                        type="button"
                        className="btn btn-white save-close-button btn-prop-disabled align-items-center fz-14 fw-bold font-rubik px-5 d-flex justify-content-center w-100"
                      >
                        <img src={plusicon} width={13} height={13} className="mx-2 plusicon" alt={``} /> Protótipo
                      </button>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
              <div className="col-6 col-lg-2 mb-2 mb-lg-0">
                {props.wireframe !== null && props.wireframe !== undefined ? (
                  <a
                    href={props.wireframe}
                    target="_Blank"
                    rel="noreferrer"
                    className="btn btn-prop-celestial-blue btn-prop-disabled text-white fz-14 fw-bold font-rubik px-4 d-flex align-items-center justify-content-center w-100"
                    style={{ borderRadius: 7 }}
                  >
                    Prévia <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
                  </a>
                ) : (
                  <>
                    {(user.role !== "client") & (user.role !== "company") & (user.role !== "category") ? (
                      <button
                        onClick={props.onClickWireframe}
                        data-bs-toggle="modal"
                        data-bs-target="#myModalSingle"
                        type="button"
                        className="btn btn-white save-close-button btn-prop-disabled align-items-center fz-14 fw-bold font-rubik px-5 d-flex justify-content-center w-100"
                      >
                        <img src={plusicon} width={13} height={13} className="mx-2 plusicon" alt={``} /> Prévia
                      </button>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default WidgetAbstract;
