import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import SidebarProject from "../../../components/SidebarProject";
import SidebarProjectCategory from "../../../components/SidebarProjectCategory";
// import swal from "sweetalert";
import WidgetAbstract from "../../../components/_widgets/WidgetAbstract";
import WidgetNumber from "../../../components/_widgets/WidgetNumber";
import confirmed from "../../../assets/img/icons/confirmed_sprint.svg";
import Loading from "../../../components/Load";
import { decodeToken } from "../../../config/auth";
import api from "../../../config/api";
import { formatDate } from "../../../config/utils";
import BurndownChart from "../../../components/_charts/BurndownChart";
import WidgetTitle from "../../../components/_widgets/WidgetTitle";
// import MaterialIcon from "material-icons-react";
import { ListCollapseItemWithPadding, ListCollapseItemStatus, ListCollapseItemResponsible } from "../../../components/List";
import Footer from "../../../components/Footer";
// import AddButton from "../../../components/AddButton";
import expand from "../../../assets/img/icons/v.svg";
import { toastsMsg } from "../../../components/ToasterMsg";

function ProjectOverview(props) {
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  let [user, setUser] = useState({});
  const [project, setProject] = useState({});
  const [client, setClient] = useState({});
  const [taskResponsible, setTaskResponsible] = useState({});
  const [totalTasks, setTotalTasks] = useState({});
  const [schedule, setSchedule] = useState([]);
  const [clientSprint, setClientSprint] = useState([]);
  const [layout, setLayout] = useState("");
  const [wireframe, setWireframe] = useState("");
  const [contract, setContract] = useState("");
  const [requirements, setRequirements] = useState("");
  const [type, setType] = useState("");
  const [collapseArr, setCollapseArr] = useState([]);
  const [burndownData, setBurndownData] = useState({});
  const [status, setStatus] = useState("");

  useEffect(() => {
    const { user = {} } = decodeToken();
    setUser(user);
    getProject();
    getTotalTasks();
    getTasksOfSprint();
    // companyFilterDisplayNone();
    getGantt();
    getBurndownChart();
  }, [props, user.role]);

  const getProject = async () => {
    const response = await api.get(`/dashboard-project/${props.match.params.id}`);
    setProject(response.data);
    setClient(response.data.client);
    setTaskResponsible(response.data.responsible);
    setLoading(false);
  };

  const getTotalTasks = async () => {
    const response = await api.get(`/dashboard-project-total-tasks/${props.match.params.id}`);
    setTotalTasks(response.data);
  };

  // const getNoSprintTask = async () => {
  //     const response = await api.get(`/no-sprint-task/${props.match.params.id}`);
  //     setNoSprintTask(response.data);
  // };

  // const getCategoriesAndHours = async () => {
  //     const response = await api.get(`/dashboard-project-categories-and-hours/${props.match.params.id}`);
  //     setCategoriesAndHours(response.data)
  // };

  const getTasksOfSprint = async () => {
    const response = await api.get(`/dashboard-project-tasks-of-sprint/${props.match.params.id}`);
    setClientSprint(response.data);
  };

  const getGantt = async () => {
    const response = await api.get(`/dashboard-project-schedule/${props.match.params.id}`);
    setSchedule(response.data);
  };

  const getBurndownChart = async () => {
    const response = await api.get(`/dashboard-project-burndown/${props.match.params.id}`);
    console.log(response.data);
    setBurndownData(response.data);
    setLoading(false);
  };

  // const companyFilterDisplayNone = () => {
  //     if (user.role === 'company') {
  //         const select = document.getElementById("formCompanySelect");
  //         if (select.classList.contains("d-lg-flex")) {
  //             select.classList.remove("d-lg-flex");
  //         }
  //     }
  // }

  const saveLayout = async (event) => {
    event.preventDefault();
    setLoad(true);
    await api
      .put(`/project-settings-layout/${project._id}`, { layout })
      .then(() => {
        setLoad(false);
        toastsMsg("Sucesso!", "Protótipo editado com sucesso!", "success");
        getProject();
        document.getElementById("btnCloseSingleOverview").click();
        setLoad(false);
      })
      .catch(() => {
        setLoad(false);
        toastsMsg("Erro!", "Erro ao editar o Protótipo, tente novamente mais tarde.", "error");
      });
  };

  const saveWireframe = async (event) => {
    event.preventDefault();
    setLoad(true);
    await api
      .put(`/project-settings-wireframe/${project._id}`, { wireframe })
      .then(() => {
        setLoad(false);
        toastsMsg("Sucesso!", "Prévia do projeto editada com sucesso!", "success");
        getProject();
        document.getElementById("btnCloseSingleOverview").click();
        setLoad(false);
      })
      .catch(() => {
        setLoad(false);
        toastsMsg("Erro!", "Erro ao editar a prévia do projeto, tente novamente mais tarde.", "error");
      });
  };

  const saveContract = async (event) => {
    event.preventDefault();
    setLoad(true);
    await api
      .put(`/project-settings-contract/${project._id}`, { contract })
      .then(() => {
        setLoad(false);
        toastsMsg("Sucesso!", "Contrato do projeto editado com sucesso!", "success");
        getProject();
        document.getElementById("btnCloseSingleOverview").click();
        setLoad(false);
      })
      .catch(() => {
        setLoad(false);
        toastsMsg("Erro!", "Erro ao editar o contrato do projeto, tente novamente mais tarde.", "error");
      });
  };

  const saveRequirements = async (event) => {
    event.preventDefault();
    setLoad(true);
    await api
      .put(`/project-settings-requirements/${project._id}`, { requirements })
      .then(() => {
        setLoad(false);
        toastsMsg("Sucesso!", "Resquisitos do projeto editado com sucesso!", "success");
        getProject();
        document.getElementById("btnCloseSingleOverview").click();
        setLoad(false);
      })
      .catch(() => {
        setLoad(false);
        toastsMsg("Erro!", "Erro ao editar os requisitos do projeto, tente novamente mais tarde.", "error");
      });
  };

  const collapseSprint = (index) => {
    let arr = collapseArr;
    const sprint = collapseArr[index].show;
    arr[index].show = !sprint;
    setCollapseArr(arr);
  };

  return (
    <>
      <Layout
        defaultMenu={0}
        isSingleProject={true}
        clientImg={client.logo}
        clientName={client.name}
        projectName={project.name}
        projectCategory={project.category !== undefined ? project.category[0] : ""}
      >
        <div className="container-fluid">
          <div className="row">
            {project.status === "close" ? (
              <div className="alert alert-prop-success text-center" role="alert">
                <span className="font-rubik fw-bold fz-20">Esse projeto já está concluído!</span>
              </div>
            ) : (
              <></>
            )}
            {project.status === "pause" ? (
              <div className="alert alert-prop-warning text-center" role="alert">
                <span className="font-rubik fw-bold fz-20">Esse projeto está pausado!</span>
              </div>
            ) : (
              <></>
            )}
            {user.role === "agency" ? (
              <div className="col-12">
                <SidebarProject active={"overview"} project={props.match.params.id} />
              </div>
            ) : (
              <></>
            )}
            {user.role === "category" ? (
              <div className="col-12">
                <SidebarProjectCategory seeReport={user.seeReport} active={"overview"} project={props.match.params.id} />
              </div>
            ) : (
              <></>
            )}
            <div className="col-12 mb-3">
              <div className="row">
                <div className="col-12 col-lg-3 mb-2 mb-lg-0">
                  <WidgetNumber
                    background="bg-prop-ghost-white"
                    title="Tarefas concluídas"
                    value={
                      <span>
                        {totalTasks.close}/<span>{totalTasks.total}</span>
                      </span>
                    }
                  />
                </div>
                <div className="col-12 col-lg-3 mb-2 mb-lg-0">
                  <WidgetNumber
                    background="bg-prop-ghost-white"
                    title="Última atualização"
                    value={
                      project.lastUpdated !== null && project.lastUpdated !== undefined ? new Date(project.lastUpdated).toLocaleDateString() : "Sem alterações"
                    }
                  />
                </div>
                <div className="col-12 col-lg-3 mb-2 mb-lg-0">
                  <WidgetNumber background="bg-prop-ghost-white" title="Deadline" value={formatDate(project.deadline)} />
                </div>
                <div className="col-12 col-lg-3 mb-2 mb-lg-0">
                  <WidgetNumber
                    background="bg-prop-ghost-white"
                    title="Previsão de entrega"
                    value={
                      project.deliveryDate === null || project.deliveryDate === undefined ? formatDate(project.deadline) : formatDate(project.deliveryDate)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-12 mb-2">
              <WidgetAbstract
                img={client.logo}
                clientName={client.name}
                projectName={project.name}
                agencyName={project.agency !== undefined ? project.agency.name : ""}
                projectProgress={parseInt(project.totalProgress)}
                bgAlertClass={"bg-prop-success"}
                textAlertClass={"text-prop-success"}
                projectKickoffDate={
                  project.startDate === undefined || project.startDate === null || project.startDate === "" ? "Nõa iniciado" : formatDate(project.startDate)
                }
                projectType={`${project.category}`}
                projectDevs={taskResponsible !== undefined ? `${taskResponsible.firstName} ${taskResponsible.lastName}` : "Nenhum"}
                contract={project.contract}
                requirements={project.requirements}
                wireframe={project.wireframe}
                layout={project.layout}
                onClickLayout={() => setType("layout")}
                onClickWireframe={() => setType("wireframe")}
                onClickContract={() => setType("contract")}
                onClickRequirements={() => setType("requirements")}
              />
            </div>
            {/* <div className='col-12'>
                            <div className='d-flex justify-content-end'>
                                <div className=''>
                                <AddButton
                                    size={4}
                                    sizeLg={12}
                                    className=""
                                    onClick={() => delivery(project)}
                                    text={'Entregar Projeto'}
                                    modalId={"#myModalSingle"}
                                />
                                </div>
                                <div className=''>
                                <AddButton
                                    size={4}
                                    sizeLg={12}
                                    className="me-3"
                                    onClick={() => pause(project)}
                                    text={'Pausar Projeto'}
                                    modalId={"#myModalSingle"}
                                />
                                </div>
                            </div>
                        </div> */}
            {/* <div className="mb-3 mb-lg-0 mt-3">
              <div className="p-3 burndown-data" style={{ borderRadius: "8px", border: "2px solid #EAEFF4" }}>
                {burndownData.error === undefined || burndownData.error === null ? (
                  <BurndownChart
                    ideal={burndownData.ideal}
                    title={burndownData.sprintName}
                    category={burndownData.category}
                    actual={burndownData.actual}
                  />
                ) : (
                  <div className="d-flex justify-content-center my-3">
                    <span className="fz-16 font-rubik"> Nenhuma Sprint iniciada!</span>
                  </div>
                )}
              </div>
            </div> */}
            <div className="d-flex pt-4 flex-row">
              <WidgetTitle title="Cronograma do projeto" />
            </div>
            <div className="col-12 mb-1">
              {schedule.map((sprint) => {
                if (collapseArr.length === 0) {
                  let arr = [];
                  for (let sprint of schedule) {
                    if (sprint.status === "open") {
                      arr.push({ id: sprint._id, show: true });
                    } else {
                      arr.push({ id: sprint._id, show: false });
                    }
                  }
                  setCollapseArr(arr);
                }
                let index = -1;
                index = collapseArr.findIndex((item) => item.id === sprint._id);
                return (
                  <>
                    <div className={`bg-prop-anti-flash-white d-flex py-3 mb-1 flex-wrap`} style={{ borderRadius: "8px" }}>
                      <a
                        onClick={() => collapseSprint(index)}
                        href={"#"}
                        style={{ textDecoration: "none" }}
                        className={`d-flex aling-items-center w-100 ${index !== -1 ? (collapseArr[index].show ? "open" : index) : "close"} sprintCollapse`}
                      >
                        <div className="col-12">
                          <div className="row m-0 align-items-center">
                            <div className="col-12 col-lg-3 d-flex align-items-center">
                              <img src={expand} className={"m-2 rotate-icon"} />
                              <span
                                style={{
                                  color: "#102A43",
                                  textTransform: "capitalize",
                                }}
                                className="font-rubik p-0 fw-bold fz-14 m-0"
                              >
                                {sprint.title}
                              </span>
                            </div>
                            <div className="col-12 col-lg-4  d-flex justify-content-end align-items-center">
                              <span
                                style={{
                                  color: "#102A43",
                                  textTransform: "capitalize",
                                }}
                                className="font-rubik fw-bold p-0 fz-14"
                              >
                                {(sprint.estimatedDate !== undefined) & (sprint.estimatedDate !== null) & (sprint.estimatedDate !== "")
                                  ? "Deadline: " + formatDate(sprint.estimatedDate)
                                  : "Deadline: Não informada"}
                              </span>
                            </div>
                            <div className="col-12 col-lg-3 d-flex align-items-center">
                              <span style={{ color: "#102A43" }} className="font-rubik fw-bold p-0 fz-14">
                                {(sprint.deliveryDate !== undefined) & (sprint.deliveryDate !== null) & (sprint.deliveryDate !== "")
                                  ? "Data de entrega: " + formatDate(sprint.deliveryDate)
                                  : "Data de entrega: Não entregue"}
                              </span>
                            </div>
                            <div className="col-12 col-lg-2 px-lg-0 d-flex align-items-center">
                              {sprint.status === "closed" ? (
                                <span
                                  style={{ padding: "6px 10px" }}
                                  className="text-prop-light-sea-green border-ligth-sea-green bg-prop-mint-cream font-rubik fz-16 d-flex align-items-center"
                                >
                                  <img
                                    src={confirmed}
                                    alt=""
                                    style={{
                                      width: 21,
                                      height: 21,
                                      marginRight: "5px",
                                    }}
                                  />{" "}
                                  {"Sprint concluída"}
                                </span>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </a>
                      <div
                        className={"p-3 w-100 collapse " + (index !== -1 ? (collapseArr[index].show === true ? "show" : "close") : "close index -1 ")}
                        id={"collapseExample_" + sprint._id}
                      >
                        {sprint.task.map((task, indexTask) => {
                          return (
                            <div
                              id={task._id}
                              className={`card-body bg-white d-flex py-2 flex-wrap ${indexTask === 0 ? "border-top-task-item" : ""}`}
                              style={{
                                borderRadius: "0px",
                                borderBottom: "1px solid #BCCCDC",
                                borderInline: "1px solid #BCCCDC",
                              }}
                            >
                              <ListCollapseItemWithPadding sizeLg="8" title="Nome da etapa" value={task.title} />
                              <ListCollapseItemResponsible sizeLg="2" title="Responsável" value={task.responsible} />
                              <ListCollapseItemStatus sizeLg="2" title="Status" value={task.status} />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            {user.role === "client" || user.role === "category" ? (
              <>
                <div className="row mt-3 mt-lg-5">
                  <div className="col-12 col-lg-3">
                    <div className="row">
                      <div className="col-12 mb-4">
                        <h2 className="mb-0 fz-22 fw-bold font-rubik">Fases do projeto</h2>
                      </div>
                      <div className="col-12 mb-4">
                        <div className="card border-0 py-2 px-0 mb-1" style={{ borderRadius: "10px" }}>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12 mb-2">
                                <p className="font-rubik text-prop-grey-light fz-18 mb-0">Seu projeto está na fase</p>
                              </div>
                              <div className="col-12 mb-3">
                                <h3 className="mb-0 font-rubik text-prop-grey-dark fw-bold fz-35">{clientSprint !== null ? clientSprint.title : "Nenhum"}</h3>
                              </div>
                              <div className="col-12 mb-2">
                                <p className="font-rubik text-prop-grey-light fz-14 mb-0">Previsão de entrega da fase</p>
                              </div>
                              <div className="col-12 mb-3">
                                <h3 className="mb-0 font-rubik text-prop-grey-dark fw-bold fz-22">
                                  {clientSprint !== null ? formatDate(clientSprint.estimatedDate) : "Nenhum"}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <Footer></Footer>
        </div>
      </Layout>
      <div
        className="modal fade border-0"
        id="myModalSingle"
        tabIndex="-1"
        aria-labelledby="myModalSingleLabel"
        aria-hidden="true"
        style={{ borderRadius: "10px" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {type === "layout" ? (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                    Adicionar protótipo
                  </h5>
                  <button type="button" id="btnCloseSingleOverview" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={saveLayout}>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Adicione o link do protótipo</label>
                        <input type="text" className="form-control" value={layout} onChange={(event) => setLayout(event.target.value)} required />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-12 d-flex align-items-center justify-content-end">
                        <button
                          type="button"
                          className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-25"
                          data-bs-dismiss="modal"
                        >
                          Cancelar
                        </button>
                        {load ? (
                          <button type="button" className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25" disabled>
                            <div className="spinner-border spinner-border-sm text-light" role="status">
                              <span className="visually-hidden">Carregando...</span>
                            </div>
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25">
                            Salvar
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </>
            ) : (
              <></>
            )}
            {type === "wireframe" ? (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                    Adicionar prévia
                  </h5>
                  <button type="button" id="btnCloseSingleOverview" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={saveWireframe}>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Adicione o link da prévia do projeto</label>
                        <input type="text" className="form-control" value={wireframe} onChange={(event) => setWireframe(event.target.value)} required />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-12 d-flex align-items-center justify-content-end">
                        <button
                          type="button"
                          className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-25"
                          data-bs-dismiss="modal"
                        >
                          Cancelar
                        </button>
                        {load ? (
                          <button type="button" className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25" disabled>
                            <div className="spinner-border spinner-border-sm text-light" role="status">
                              <span className="visually-hidden">Carregando...</span>
                            </div>
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25">
                            Salvar
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </>
            ) : (
              <></>
            )}
            {type === "contract" ? (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                    Adicionar contrato
                  </h5>
                  <button type="button" id="btnCloseSingleOverview" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={saveContract}>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Adicione o link da contrato</label>
                        <input type="text" className="form-control" value={contract} onChange={(event) => setContract(event.target.value)} required />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-12 d-flex align-items-center justify-content-end">
                        <button
                          type="button"
                          className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-25"
                          data-bs-dismiss="modal"
                        >
                          Cancelar
                        </button>
                        {load ? (
                          <button type="button" className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25" disabled>
                            <div className="spinner-border spinner-border-sm text-light" role="status">
                              <span className="visually-hidden">Carregando...</span>
                            </div>
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25">
                            Salvar
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </>
            ) : (
              <></>
            )}
            {type === "requirements" ? (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                    Adicionar requisitos
                  </h5>
                  <button type="button" id="btnCloseSingleOverview" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={saveRequirements}>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Adicione o link dos requisitos</label>
                        <input type="text" className="form-control" value={requirements} onChange={(event) => setRequirements(event.target.value)} required />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-12 d-flex align-items-center justify-content-end">
                        <button
                          type="button"
                          className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-25"
                          data-bs-dismiss="modal"
                        >
                          Cancelar
                        </button>
                        {load ? (
                          <button type="button" className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25" disabled>
                            <div className="spinner-border spinner-border-sm text-light" role="status">
                              <span className="visually-hidden">Carregando...</span>
                            </div>
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25">
                            Salvar
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Loading show={loading} />
    </>
  );
}

export default ProjectOverview;
