import React from "react";
// import plusCircle from '../../assets/img/icons/plusCircle.svg'

export default function AddButton(props) {
  return (
    <>
      <div
        className={`${props.className !== undefined ? props.className : ""} col-${
          props.size === undefined ? 12 : props.size
        } col-lg-${props.sizeLg} d-flex justify-content-end mb-4 mt-2`}
      >
        <button
          onClick={props.onClick}
          className="btn btn-prop-blue fw-bold text-white font-ruby fz-14 px-4 py-2 button-add "
          data-bs-toggle="modal"
          data-bs-target={props.modalId}
          type="button"
        >
          {props.text}
        </button>
      </div>
    </>
  );
}
