import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import {
  List,
  ListBody,
  ListBodyItemModal,
  ListBodyItemAction,
  ListHeaderItemSortAble,
  ListBodyItemStatusEmojiModal,
  ListHeader,
} from "../../../components/List";
import ModalReport from "../../../components/_modals/_agency/Report";
import EmptyList from "../../../components/_empty/list";
import AddButton from "../../../components/AddButton";
import Loading from "../../../components/Load";
import { formatDate, formatDateNumber } from "../../../config/utils";
import api from "../../../config/api";
// import io from "socket.io-client";
import swal from "sweetalert";
import { format } from "date-fns";
import { Drawer } from "../../../components/Drawer";
import { toastsMsg } from "../../../components/ToasterMsg";
require("dotenv").config();

function Report() {
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [id, setId] = useState("");
  const [subject, setSubject] = useState("");
  const [rating, setRating] = useState("");
  const [comments, setComments] = useState("");
  const [statusFilter, setStatusFilter] = useState(
    localStorage.getItem("AGENCY_REPORT_FILTER_STATUS") === null
      ? ""
      : localStorage.getItem("AGENCY_REPORT_FILTER_STATUS")
  );
  const [nameFilter, setNameFilter] = useState("");
  const [type, setType] = useState("");
  const [clientFilter, setClientFilter] = useState(
    localStorage.getItem("AGENCY_REPORT_FILTER_CLIENT") === null
      ? ""
      : localStorage.getItem("AGENCY_REPORT_FILTER_CLIENT")
  );
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("");
  const [schedule, setSchedule] = useState("");
  const [client, setClient] = useState("");
  const [date, setDate] = useState("");
  const [sort, setSort] = useState("");
  let [view, setView] = useState({});
  const [pageItensTable, setPageItensTable] = useState(1);
  const [limitItensTable, setLimitItensTable] = useState(20);
  const [drawerOpenEditReport, setDrawerOpenEditReport] = useState(false);
  const [drawerOpenViewReport, setDrawerOpenViewReport] = useState(false);

  useEffect(() => {
    getReports(page, nameFilter, clientFilter, statusFilter, limitItensTable);
    getClients();
    getProjects();
    // async function registerToSocket() {
    //     const socket = io(`${process.env.REACT_APP_API_URL}`);
    //     socket.on("report", () => {
    //         getReports(page, nameFilter, clientFilter, statusFilter);
    //     });
    // }
    // registerToSocket();
  }, [page, nameFilter]);

  const getReports = async (
    mypage = 1,
    filterName = "",
    filterClient = "",
    filterStatus = "",
    limit = limitItensTable,
    sortBy = "createdAt",
    order = "desc"
  ) => {
    setLoading(true);
    const response = await api.get(
      `/report?paginate=true&page=${mypage}&limit=${limit}&filterName=${filterName}&filterClient=${filterClient}&filterStatus=${filterStatus}&order=${order}&sortBy=${sortBy}`
    );
    const { docs = [], ...arr } = response.data;
    setReports(docs);
    setPages(parseInt(arr.pages));
    setLoading(false);
  };

  const getClients = async () => {
    const response = await api.get(`/client?limit=9999&sortBy=name&order=ASC`);
    setClients(response.data);
  };

  const getProjects = async () => {
    const response = await api.get(`/project/all?limit=9999&sortBy=name&order=ASC`);
    setProjects(response.data);
  };

  const nextPage = () => {
    getReports(page + 1);
    setPage(page === pages ? page : page + 1);
  };

  const backPage = () => {
    getReports(page - 1);
    setPage(page === 1 ? page : page - 1);
  };

  const destroy = async (item) => {
    swal({
      title: "Tem certeza que deseja excluir esse report?",
      text: `Esse report será apagado e não terá mais volta`,
      // icon: "warning",
      buttons: ["Cancelar", "EXCLUIR"],
      dangerMode: true,
    }).then(async (res) => {
      if (res) {
        await api
          .delete(`/report/${item._id}`)
          .then(async () => {
            await getReports(page);
            toastsMsg({
              icon: "success",
              title: "Sucesso!",
              text: "Report removida com sucesso.",
            });
          })
          .catch(() => {
            toastsMsg({
              icon: "error",
              title: "Erro!",
              text: "Erro ao remover o Report, tente novamente mais tarde.",
            });
          });
      }
    });
  };

  const edit = (item) => {
    setType("edit");
    setDrawerOpenEditReport(true);
    setId(item._id);
    setClient(item.client._id);
    setProject(item.project._id);
    setDate(String(item.date).substr(0, 10));
    setSubject(item.subject);
    setRating(item.rating);
    setComments(item.comments);
  };

  const visually = (item) => {
    setView({
      _id: item._id,
      client: item.client,
      project: item.project,
      date: formatDate(item.date),
      subject: item.subject,
      rating: item.rating,
      comments: item.comments,
      user: item.user,
      schedule: item.schedule,
    });
    setDrawerOpenViewReport(true);
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    const formData = new FormData();
    formData.append("file", schedule);
    schedule !== "" ? formData.append("haveFile", true) : formData.append("haveFile", false);
    formData.append("client", client);
    formData.append("project", project);
    formData.append("subject", subject);
    formData.append("date", date);
    formData.append("rating", rating);
    formData.append("comments", comments);

    await api
      .put(`/report/${id}`, formData)
      .then(() => {
        setLoad(false);
        getReports(page);
        setDrawerOpenEditReport(false);
        toastsMsg("Sucesso!", "Report editado com sucesso!", "success");
      })
      .catch((error) => {
        setLoad(false);
        if (error.response.data.error === "Invalid File") {
          toastsMsg("Erro!", "Tipo de arquivo selecionado incorreto, por favor selecione um arquivo válido.", "error");
          setSchedule("");
        } else {
          toastsMsg("Erro!", "Erro ao editar o report, tente novamente mais tarde.", "error");
        }
      });
  };

  const filterChangeHandlerName = (event) => {
    setNameFilter(event.target.value);
    getReports(page, event.target.value, clientFilter, statusFilter, limitItensTable);
  };

  // const filterChangeHandlerClient = (event) => {
  //   setClientFilter(event.target.value);
  //   getReports(page, nameFilter, event.target.value, statusFilter);
  //   localStorage.setItem("AGENCY_REPORT_FILTER_CLIENT", event.target.value);
  // };

  // const filterChangeHandlerStatus = (event) => {
  //   setStatusFilter(event.target.value);
  //   getReports(page, nameFilter, clientFilter, event.target.value);
  //   localStorage.setItem("AGENCY_REPORT_FILTER_STATUS", event.target.value);
  // };

  // const clearFilter = () => {
  //   localStorage.removeItem("AGENCY_REPORT_FILTER_CLIENT");
  //   localStorage.removeItem("AGENCY_REPORT_FILTER_STATUS");
  //   window.location.reload();
  // };

  const sortByNameChangeHandler = (sortBy) => {
    if (sort !== sortBy) {
      getReports(page, nameFilter, clientFilter, statusFilter, limitItensTable, sortBy, "asc");
      setSort(sortBy);
    } else {
      getReports(page, nameFilter, clientFilter, statusFilter, limitItensTable);
      setSort("");
    }
  };

  const changePage = (event) => {
    const changePage = event.target.id;
    setPage(changePage);
    setPageItensTable(changePage);
    getReports(changePage);
  };

  const setLimitTable = (value) => {
    setLimitItensTable(value.target.value);
    getReports(page, nameFilter, clientFilter, statusFilter, value.target.value);
  };

  const addAction = () => {
    setType("add");
    setDrawerOpenEditReport(true);
  };

  return (
    <>
      <Layout defaultMenu={10} title={"Report"}>
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-12 mb-4">
              <h1 className="mb-0 fz-24 fw-bold font-rubik">Report</h1>
            </div> */}
            <div className="col-12 col-lg-4 mt-2 me-auto">
              <div className="input-group input-search">
                <input
                  type="text"
                  className="form-control border-0 fz-14 box-shadow-none text-prop-grey-light font-rubik"
                  onChange={(event) => filterChangeHandlerName(event)}
                  placeholder="O que você está buscando?"
                />
                <span className="input-group-text bg-white border-0">
                  <i className="bi bi-search text-prop-grey-light"></i>
                </span>
              </div>
            </div>
            {/* <div className="col-12 col-lg-3 mt-2">
              <select
                value={
                  localStorage.getItem("AGENCY_REPORT_FILTER_CLIENT") === null
                    ? ""
                    : localStorage.getItem("AGENCY_REPORT_FILTER_CLIENT")
                }
                className="filter-select fz-12 text-prop-grey-light font-rubik"
                onChange={(event) => filterChangeHandlerClient(event)}
                required
              >
                <option value="" key={0}>
                  Selecione o cliente
                </option>
                {clients.map((item, index) => (
                  <option value={item._id} key={index + 1}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-lg-3 mt-2">
              <select
                value={
                  localStorage.getItem("AGENCY_REPORT_FILTER_STATUS") === null
                    ? ""
                    : localStorage.getItem("AGENCY_REPORT_FILTER_STATUS")
                }
                className="filter-select fz-12 text-prop-grey-light font-rubik"
                onChange={(event) => filterChangeHandlerStatus(event)}
                required
              >
                <option value="" key={0}>
                  Selecione o status
                </option>
                <option value="Bom" key={1}>
                  Bom
                </option>
                <option value="Regular" key={2}>
                  Regular
                </option>
                <option value="Ruim" key={3}>
                  Ruim
                </option>
              </select>
            </div>
            <ClearButton
              sizeLg={2}
              onClick={() => clearFilter()}
              text={"Limpar Filtros"}
            /> */}
            <AddButton
              size={6}
              sizeLg={3}
              onClick={() => addAction()}
              modalId={"#myModalSingle"}
              text={"Adicionar Reports"}
            />
            <List
              title="reports"
              paginate={true}
              page={parseInt(page)}
              pages={pages}
              backPage={() => backPage()}
              nextPage={() => nextPage()}
              totalItem={reports.length}
              changePage={(event) => {
                changePage(event);
              }}
              actualPage={pageItensTable}
              onChangeLimit={(event) => setLimitTable(event)}
            >
              <div className="col-12 table-border-default">
                <ListHeader>
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("subject")}
                    name="Assunto"
                    className="col-12 col-lg-2"
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("client.name")}
                    name="Cliente"
                    className="col-12 col-lg-2"
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("project.name")}
                    name="Projeto"
                    className="col-12 col-lg-2"
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("user.firstName")}
                    name="Colaborador"
                    className="col-12 col-lg-2"
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("date")}
                    name="Data"
                    className="col-12 col-lg-2"
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("rating")}
                    name="Status"
                    className="col-12 col-lg-2"
                  />
                </ListHeader>

                {reports.map((item, index) => (
                  <ListBody key={index} specialClass="card-with-hover d-flex flex-wrap">
                    <ListBodyItemModal
                      title="Assunto"
                      value={item.subject}
                      sizeLg={2}
                      modalId="#myModalView"
                      onClick={() => visually(item)}
                    />
                    <ListBodyItemModal
                      title="Cliente"
                      value={item.client.name}
                      sizeLg={2}
                      modalId="#myModalView"
                      onClick={() => visually(item)}
                    />
                    <ListBodyItemModal
                      title="Projeto"
                      value={item.project.name}
                      sizeLg={2}
                      modalId="#myModalView"
                      onClick={() => visually(item)}
                    />
                    <ListBodyItemModal
                      title="Colaborador"
                      value={`${item.user.firstName} ${item.user.lastName}`}
                      sizeLg={2}
                      modalId="#myModalView"
                      onClick={() => visually(item)}
                    />
                    <ListBodyItemModal
                      title="Data"
                      value={format(formatDateNumber(item.date), "dd/MM/yyyy")}
                      sizeLg={2}
                      modalId="#myModalView"
                      onClick={() => visually(item)}
                    />
                    <ListBodyItemStatusEmojiModal
                      title="Status"
                      value={item.rating}
                      sizeLg={1}
                      modalId="#myModalView"
                      onClick={() => visually(item)}
                    />
                    <ListBodyItemAction sizeLg={1}>
                      <li>
                        <span
                          className="dropdown-item fz-14 font-rubik c-pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#myModalSingle"
                          onClick={() => edit(item)}
                        >
                          Editar
                        </span>
                      </li>
                      <li>
                        <span
                          className="dropdown-item fz-14 font-rubik text-prop-danger c-pointer"
                          onClick={() => destroy(item)}
                        >
                          Excluir
                        </span>
                      </li>
                    </ListBodyItemAction>
                  </ListBody>
                ))}
                {reports.length === 0 ? (
                  <EmptyList icon="indiferente" message="Não foi encontrado nenhum report" />
                ) : (
                  <></>
                )}
              </div>
            </List>
          </div>
        </div>
      </Layout>
      <Drawer
        show={drawerOpenViewReport}
        onClose={() => setDrawerOpenViewReport(false)}
        onOpen={() => setDrawerOpenViewReport(true)}
      >
        <div className="p-3 notification-scroll modal-drawer">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark mb-3" id="myModalSingleLabel">
                  {view.subject}
                </h5>
                <button
                  type="button"
                  id="btnCloseSingle"
                  className="btn-close"
                  onClick={() => setDrawerOpenViewReport(false)}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  {view.user !== undefined ? (
                    <div className="col-12 d-flex align-items-center justify-content-start">
                      <div className="col-4 col-lg-2 d-flex align-items-center justify-content-start">
                        <img
                          src={view.user.avatar}
                          alt=""
                          width={100}
                          height={100}
                          style={{ border: "1px solid #626679" }}
                          className="rounded-circle"
                        />
                      </div>
                      <div className="col-8 col-lg-10 d-flex align-items-start justify-content-start flex-column">
                        <span className="fw-bold">{view.user.firstName + " " + view.user.lastName}</span>
                        <span>{view.date}</span>
                        <span className="fw-bold">
                          {"Status: "}
                          <span
                            className={`${
                              view.rating === "Bom"
                                ? "fw-normal text-prop-cyan"
                                : view.rating === "Regular"
                                ? "fw-normal text-prop-warning-light"
                                : "fw-normal text-prop-danger"
                            }`}
                          >
                            {view.rating}
                          </span>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="col-12 col-lg-12 mb-3 mt-2">
                    <label className="form-label font-rubik fw-bold fz-14 mb-2">Observações</label>
                    <div className="form-control" style={{ borderRadius: "5px" }}>
                      <div className="d-flex justify-content-start text-break scroll h-100">
                        <textarea disabled={true} rows={8} className="col-12 p-2 border-0 bg-transparent">
                          {view.comments}
                        </textarea>
                      </div>
                    </div>
                  </div>
                  {(view.schedule !== undefined) & (view.schedule !== " ") ? (
                    <>
                      <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-2">
                        Arquivo para download
                      </label>
                      <div className="col-12 d-flex align-items-center justify-content-start flex-wrap ">
                        <span className="col-12 col-lg-6">Esse relatório possui um arquivo para download</span>
                        <a className="col-12 col-lg-3" target="_blank" rel="noopener noreferrer" href={view.schedule}>
                          <button className=" btn btn-prop-blue text-white font-rubik fz-16 px-3 " type="button">
                            Baixar arquivo
                          </button>
                        </a>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <Drawer
        show={drawerOpenEditReport}
        onClose={() => setDrawerOpenEditReport(false)}
        onOpen={() => setDrawerOpenEditReport(true)}
      >
        <div className="p-3 notification-scroll modal-drawer">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              {type === "add" ? (
                <>
                  <div className="modal-header border-0">
                    <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalLabel">
                      Adicionar report
                    </h5>
                    <button
                      type="button"
                      onClick={() => setDrawerOpenEditReport(false)}
                      className="btn-close"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <ModalReport getReports={getReports} close={() => setDrawerOpenEditReport(false)} />
                  </div>
                </>
              ) : (
                <>
                  <div className="modal-header border-0">
                    <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                      Editar report
                    </h5>
                    <button
                      type="button"
                      id="btnCloseSingle"
                      className="btn-close"
                      onClick={() => setDrawerOpenEditReport(false)}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={save}>
                      <div className="row">
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                            Cliente
                          </label>
                          <select
                            className="form-select"
                            value={client}
                            onChange={(event) => setClient(event.target.value)}
                            required
                          >
                            <option></option>
                            {clients.map((item, index) => (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                            Assunto
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={subject}
                            onChange={(event) => setSubject(event.target.value)}
                            required
                          />
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                            Projeto
                          </label>
                          <select
                            className="form-select"
                            value={project}
                            onChange={(event) => setProject(event.target.value)}
                            required
                          >
                            <option></option>
                            {projects
                              .filter((item) => item.client._id === client)
                              .map((item, index) => (
                                <option value={item._id} key={index}>
                                  {`${item.name} - ${item.category}`}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Data</label>
                          <input
                            type="date"
                            className="form-control"
                            value={date}
                            onChange={(event) => setDate(event.target.value)}
                            required
                          />
                        </div>
                        <div className="col-6 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                            Status
                          </label>
                          <select
                            className="form-select"
                            value={rating}
                            onChange={(event) => setRating(event.target.value)}
                            required
                          >
                            <option></option>
                            <option value="Bom">Bom</option>
                            <option value="Regular">Regular</option>
                            <option value="Ruim">Ruim</option>
                          </select>
                        </div>
                        <div className="col-6 col-lg-6 mb-3">
                          <label
                            htmlFor="formFile"
                            className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1"
                          >
                            Anexar pauta <span className="fz-10 text-prop-danger font-rubik">Tamanho máximo 2MB</span>
                          </label>
                          <input
                            className="form-control"
                            type="file"
                            id="formFile"
                            onChange={(event) => setSchedule(event.target.files[0])}
                          />
                        </div>
                        <div className="col-12 col-lg-12 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                            Observações
                          </label>
                          <textarea
                            className="form-control"
                            rows="8"
                            value={comments}
                            onChange={(event) => setComments(event.target.value)}
                            required
                          ></textarea>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-12 d-flex align-items-center justify-content-end">
                          <button
                            type="button"
                            className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-28"
                            onClick={() => setDrawerOpenEditReport(false)}
                          >
                            Cancelar
                          </button>
                          {load ? (
                            <button
                              type="button"
                              className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                              disabled
                            >
                              <div className="spinner-border spinner-border-sm text-light" role="status">
                                <span className="visually-hidden">Carregando...</span>
                              </div>
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                            >
                              Salvar
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Drawer>
      <Loading show={loading} />
    </>
  );
}

export default Report;
