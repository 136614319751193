import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import {
  List,
  ListBody,
  ListBodyItem,
  ListBodyItemAction,
  ListHeaderItemSortAble,
  ListBodyItemImage,
  ListHeader,
  ListHeaderItem,
  ListBodyItemStatus,
} from "../../../components/List";
import api from "../../../config/api";
// import io from "socket.io-client";
import Loading from "../../../components/Load";
import AddButton from "../../../components/AddButton";
import ModalAgency from "../../../components/_modals/_company/Agency";
import EmptyList from "../../../components/_empty/list";
import { Drawer } from "../../../components/Drawer";
import swal from "sweetalert";
import { toastsMsg } from "../../../components/ToasterMsg";
require("dotenv").config();

function Agency() {
  const [type, setType] = useState("");
  const [agencies, setAgencies] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [id, setId] = useState("");
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [sort, setSort] = useState("");
  const [drawerOpenAddAgency, setDrawerOpenAddAgency] = useState(false);

  useEffect(() => {
    getAgencies(page);
    // async function registerToSocket() {
    //     const socket = io(`${process.env.REACT_APP_API_URL}`);
    //     socket.on("agency", () => {
    //         getAgencies(page);
    //     });
    // }
    // registerToSocket();
  }, [page]);

  const getAgencies = async (mypage = 1, filterName = "", filterStatus = "", sortBy = "createdAt", order = "desc") => {
    setLoading(true);
    const response = await api.get(
      `/agency?paginate=true&page=${mypage}&filterName=${filterName}&filterStatus=${filterStatus}&order=${order}&sortBy=${sortBy}`
    );
    const { docs = [], ...arr } = response.data;
    setAgencies(docs);
    setPages(parseInt(arr.pages));
    setLoading(false);
  };

  const nextPage = () => {
    getAgencies(page + 1);
    setPage(page === pages ? page : page + 1);
  };

  const backPage = () => {
    getAgencies(page - 1);
    setPage(page === 1 ? page : page - 1);
  };

  const sortByNameChangeHandler = (sortBy) => {
    if (sort !== sortBy) {
      getAgencies(page, nameFilter, statusFilter, sortBy, "asc");
      setSort(sortBy);
    } else {
      getAgencies(page, nameFilter, statusFilter);
      setSort("");
    }
  };

  const destroy = async (item) => {
    swal({
      title: "Tem certeza que deseja excluir essa empresa?",
      text: `Todos os itens relacionados a essa empresa serão apagados, todos os itens significam Colaboradores,reports,reuniões,clientes,modelos de projetos, projetos,tarefas e etc, serão apagados, essa ação não tem volta`,
      // icon: "warning",
      buttons: ["Cancelar", "EXCLUIR"],
      dangerMode: true,
    }).then(async (res) => {
      if (res) {
        await api
          .delete(`/agency/${item._id}`)
          .then(async () => {
            await getAgencies(page);
            toastsMsg({
              icon: "success",
              title: "Sucesso!",
              text: "Empresa removida com sucesso.",
            });
          })
          .catch(() => {
            toastsMsg({
              icon: "error",
              title: "Erro!",
              text: "Erro ao remover a empresa, tente novamente mais tarde.",
            });
          });
      }
    });
  };

  const edit = (name, status, id) => {
    setType("edit");
    setName(name);
    setStatus(status);
    setId(id);
  };

  const filterChangeHandlerName = (event) => {
    setNameFilter(event.target.value);
    getAgencies(page, event.target.value, statusFilter);
  };

  // const filterChangeHandlerStatus = (event) => {
  //   setStatusFilter(event.target.value);
  //   getAgencies(page, nameFilter, event.target.value);
  // };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    await api
      .put(`/agency/${id}`, { name, status })
      .then(() => {
        setLoad(false);
        toastsMsg("Sucesso!", "Empresa editada com sucesso!", "success");
        document.getElementById("btnCloseSingle").click();
      })
      .catch((error) => {
        setLoad(false);
        if (error.response.data.error === "Name already in use") {
          toastsMsg("Erro!", "Não foi possivel editar essa empresa, pois esse nome já está sendo usado.", "error");
        } else {
          toastsMsg("Erro!", "Erro ao editar a empresa, tente novamente mais tarde.", "error");
        }
      });
  };

  const addAgency = () => {
    setType("add");
    setDrawerOpenAddAgency(true);
  };

  return (
    <>
      <Layout defaultMenu={20} defaultSubMenu={206} title={"Empresas"}>
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-12 mb-4">
                            <h1 className="mb-0 fz-24 fw-bold font-rubik">Empresas</h1>
                        </div> */}
            <div className="col-12 col-lg-4 mt-2 me-auto">
              <div className="input-group input-search">
                <input
                  type="text"
                  className="form-control border-0 fz-14 box-shadow-none text-prop-grey-light font-rubik"
                  onChange={(event) => filterChangeHandlerName(event)}
                  placeholder="O que você está buscando?"
                />
                <span className="input-group-text bg-white border-0">
                  <i className="bi bi-search text-prop-grey-light"></i>
                </span>
              </div>
            </div>
            {/* <div className="col-12 col-lg-3 mt-2">
                            <select className="filter-select fz-12 text-prop-grey-light font-rubik" onChange={(event) => filterChangeHandlerStatus(event)} required>
                                <option value="" key={0}>Selecione o status</option>
                                <option value="active" key={1}>Ativo</option>
                                <option value="inactive" key={2}>Inativo</option>
                            </select>
                        </div> */}
            <AddButton sizeLg={6} onClick={() => addAgency()} text={"Adicionar Empresa"} />
            <div className="col-12 mb-4 table-border-default">
              <List
                title={"empresas"}
                paginate={true}
                page={page}
                pages={pages}
                backPage={() => backPage()}
                nextPage={() => nextPage()}
              >
                <ListHeader>
                  <ListHeaderItem name="" sizeLg={1} />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("name")} name="Nome" sizeLg={3} />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("status")} name="Status" sizeLg={3} />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("createdAt")}
                    name="Criado em"
                    sizeLg={5}
                  />
                </ListHeader>

                {agencies.map((item, index) => (
                  <ListBody key={index} specialClass="d-flex flex-wrap">
                    <ListBodyItemImage img={item.logo} sizeLg={1} />
                    <ListBodyItem title="Nome" value={item.name} sizeLg={3} />
                    <ListBodyItemStatus
                      title="Status"
                      value={item.status === "active" ? "Ativo" : "Inativo"}
                      sizeLg={3}
                    />
                    <ListBodyItem title="Criado em" value={new Date(item.createdAt).toLocaleDateString()} sizeLg={4} />
                    <ListBodyItemAction sizeLg={1}>
                      <li>
                        <span
                          className="dropdown-item fz-14 font-rubik c-pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#myModalSingle"
                          onClick={() => edit(item.name, item.status, item._id)}
                        >
                          Editar
                        </span>
                      </li>
                      <li>
                        <span
                          className="dropdown-item fz-14 font-rubik text-prop-danger c-pointer"
                          onClick={() => destroy(item)}
                        >
                          Excluir
                        </span>
                      </li>
                    </ListBodyItemAction>
                  </ListBody>
                ))}
                {agencies.length === 0 ? (
                  <EmptyList icon="indiferente" message="Não foi encontrada nenhuma empresa" />
                ) : (
                  <></>
                )}
              </List>
            </div>
          </div>
        </div>
      </Layout>
      <Drawer
        show={drawerOpenAddAgency}
        onClose={() => setDrawerOpenAddAgency(false)}
        onOpen={() => setDrawerOpenAddAgency(true)}
      >
        <div className="p-3 modal-drawer">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              {type === "add" ? (
                <>
                  <div className="modal-header border-0">
                    <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                      Adicionar empresa
                    </h5>
                    <button
                      type="button"
                      id="btnCloseSingle"
                      className="btn-close"
                      onClick={() => setDrawerOpenAddAgency(false)}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <ModalAgency close={() => setDrawerOpenAddAgency(false)} />
                  </div>
                </>
              ) : (
                <>
                  <div className="modal-header border-0">
                    <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                      Editar empresa
                    </h5>
                    <button
                      type="button"
                      id="btnCloseSingle"
                      className="btn-close"
                      onClick={() => setDrawerOpenAddAgency(false)}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={save}>
                      <div className="row">
                        <div className="col-12 col-lg-12 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Nome</label>
                          <input
                            type="text"
                            className="form-control"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            required
                          />
                        </div>
                        <div className="col-12 col-lg-12 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                            Status
                          </label>
                          <select
                            className="form-select"
                            value={status}
                            onChange={(event) => setStatus(event.target.value)}
                            required
                          >
                            <option></option>
                            <option value="active">Ativo</option>
                            <option value="inactive">Inativo</option>
                          </select>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-12 d-flex align-items-center justify-content-end">
                          <button
                            type="button"
                            className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-28"
                            onClick={() => setDrawerOpenAddAgency(false)}
                          >
                            Cancelar
                          </button>
                          {load ? (
                            <button
                              type="button"
                              className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                              disabled
                            >
                              <div className="spinner-border spinner-border-sm text-light" role="status">
                                <span className="visually-hidden">Carregando...</span>
                              </div>
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                            >
                              Salvar
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Drawer>

      <Loading show={loading} />
    </>
  );
}

export default Agency;
