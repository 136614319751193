import React from "react";
import Routes from "./routes";
import "../src/assets/scss/styles.scss";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";

window.process = {
  env: {
    NODE_ENV: "production",
  },
};

const App = () => <Routes />;

export default App;
