import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import Img404 from "../../../assets/img/not-found.svg";

function NotFound() {

    useEffect(() => {
        document.body.style.backgroundColor = "#fff";
        document.getElementById("root").style.backgroundColor = "#fff";
    }, []);

    return (
        <div className="container h-100">
            <div className="row justify-content-center h-100">
                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center flex-column h-100">
                    <img src={Img404} className="img-fluid mb-5" width={"80%"} alt="404 - Not Found"/>
                    <Link to={"/dashboard"} className="btn btn-prop-blue text-white text-uppercase font-rubik fw-bold py-3 fz-16 d-flex justify-content-center w-50">VOLTAR PARA O DASHBOARD</Link>
                </div>
            </div>
        </div>
    );
}

export default NotFound;