import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import {
  List,
  ListBody,
  ListBodyItem,
  ListBodyItemAction,
  ListHeaderItemSortAble,
  ListBodyItemImageAndName,
  ListBodyItemStatus,
  ListHeader,
} from "../../../components/List";
import ModalCategoryAccess from "../../../components/_modals/_agency/CategoryAccess";
import api from "../../../config/api";
import AddButton from "../../../components/AddButton";
import EmptyList from "../../../components/_empty/list";
import io from "socket.io-client";
import swal from "sweetalert";
import Loading from "../../../components/Load";
import { toastsMsg } from "../../../components/ToasterMsg";
require("dotenv").config();

function CategoryAccess() {
  const [categories, setCategories] = useState([]);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [category, setCategory] = useState("");
  let [status, setStatus] = useState("");
  const [id, setId] = useState("");
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [sort, setSort] = useState("");
  const [seeReport, setSeeReport] = useState(false);

  useEffect(() => {
    getUsers(page);
    getCategories();
    async function registerToSocket() {
      try {
        const socket = io(`${process.env.REACT_APP_API_URL}`);
        socket.on("access", () => {
          getUsers(page);
          getCategories();
        });
      } catch (error) {
        console.log("Error socket category access agency");
      }
    }
    registerToSocket();
  }, [page]);

  const getUsers = async (mypage = 1, filterName = "", sortBy = "createdAt", order = "desc") => {
    setLoading(true);
    const response = await api.get(
      `/category-access?paginate=true&page=${mypage}&filterName=${filterName}&order=${order}&sortBy=${sortBy}`
    );
    const { docs = [], ...arr } = response.data;
    setUsers(docs);
    setPages(parseInt(arr.pages));
    setLoading(false);
  };

  const getCategories = async () => {
    const response = await api.get(`/category?limit=9999&sortBy=name&order=ASC`);
    setCategories(response.data);
  };

  const nextPage = () => {
    getUsers(page + 1);
    setPage(page === pages ? page : page + 1);
  };

  const backPage = () => {
    getUsers(page - 1);
    setPage(page === 1 ? page : page - 1);
  };

  const destroy = async (item) => {
    swal({
      title: "Tem certeza que deseja excluir esse acesso por categoria?",
      text: `O acesso por categoria será apagado, essa ação não tem volta`,
      // icon: "warning",
      buttons: ["Cancelar", "EXCLUIR"],
      dangerMode: true,
    }).then(async (res) => {
      if (res) {
        await api
          .delete(`/category-access/${item._id}`)
          .then(async () => {
            await getUsers(page);
            toastsMsg({
              icon: "success",
              title: "Sucesso!",
              text: "Acesso por Categoria removido com sucesso.",
            });
          })
          .catch(() => {
            toastsMsg({
              icon: "error",
              title: "Erro!",
              text: "Erro ao remover o acesso por categoria, tente novamente mais tarde.",
            });
          });
      }
    });
  };

  const edit = (item) => {
    setStatus(item.status);
    setType("edit");
    setCategory(item.category._id);
    setEmail(item.email);
    setId(item._id);
    setSeeReport(item.seeReport);
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    if (password === confirm_password) {
      let object = {
        email,
        password,
        seeReport,
      };

      object.id_category = category;

      status === "" ? (object.status = "active") : (object.status = status);

      await api
        .put(`/category-access/${id}`, object)
        .then(() => {
          setLoad(false);
          toastsMsg("Sucesso!", "Acesso por Categoria editado com sucesso!", "success");
          document.getElementById("btnCloseSingleAccessCategory").click();
          setPassword("");
          setConfirmPassword("");
          getUsers(page);
        })
        .catch((error) => {
          setLoad(false);
          if (error.response.data.error === "Access Already Exists") {
            toastsMsg("Erro!", "Esse e-mail já está sendo usado, por favor utilize outro", "error");
          } else {
            toastsMsg("Erro!", "Erro ao editar o acesso por categoria, tente novamente mais tarde.", "error");
          }
        });
    } else {
      setLoad(false);
      toastsMsg(
        "Erro!",
        "Senha e Confirmar Senha não estão iguais, por favor insira informações corretamente.",
        "error"
      );
    }
  };

  const filterChangeHandlerName = (event) => {
    setNameFilter(event.target.value);
    getUsers(page, event.target.value);
  };

  const sortByNameChangeHandler = (sortBy) => {
    if (sort !== sortBy) {
      getUsers(page, nameFilter, sortBy, "asc");
      setSort(sortBy);
    } else {
      getUsers(page, nameFilter);
      setSort("");
    }
  };

  return (
    <>
      <Layout defaultMenu={21} defaultSubMenu={207} title={"Acesso por Categoria"}>
        <div className="container-fluid new-page">
          <div className="row">
            {/* <div className="col-12 mb-4">
                            <h1 className="mb-0 fz-24 fw-bold font-rubik">Acesso por Categoria</h1>
                        </div> */}
            <div className="col-12 col-lg-4 mt-2 me-auto">
              <div className="input-group input-search">
                <input
                  type="text"
                  className="form-control border-0 fz-14 box-shadow-none text-prop-grey-light font-rubik"
                  onChange={(event) => filterChangeHandlerName(event)}
                  placeholder="O que você está procurando?"
                />
                <span className="input-group-text bg-white border-0">
                  <i className="bi bi-search text-prop-grey-light"></i>
                </span>
              </div>
            </div>
            <AddButton sizeLg={6} onClick={() => setType("add")} modalId={"#myModalSingle"} text={"Adicionar Acesso"} />
            <div className="col-12 mb-4 table-border-default">
              <List paginate={true} page={page} pages={pages} backPage={() => backPage()} nextPage={() => nextPage()}>
                <ListHeader>
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("firstName")}
                    name="Categoria"
                    sizeLg={5}
                  />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("status")} name="Status" sizeLg={3} />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("createdAt")}
                    name="Adicionado em"
                    sizeLg={4}
                  />
                </ListHeader>
                {users.map((item, index) => (
                  <ListBody key={index} specialClass="d-flex flex-wrap">
                    <ListBodyItemImageAndName
                      value={`${item.firstName} ${item.lastName}`}
                      sizeLg={5}
                      img={item.avatar}
                    />
                    <ListBodyItemStatus
                      title="Status"
                      value={item.status === "active" ? "Ativo" : "Inativo"}
                      sizeLg={3}
                    />
                    <ListBodyItem
                      title="Adicionado em"
                      value={new Date(item.createdAt).toLocaleDateString()}
                      sizeLg={3}
                    />
                    <ListBodyItemAction sizeLg={1}>
                      <li>
                        <span
                          className="dropdown-item fz-14 font-rubik c-pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#myModalSingle"
                          onClick={() => edit(item)}
                        >
                          Editar
                        </span>
                      </li>
                      <li>
                        <span
                          className="dropdown-item fz-14 font-rubik text-prop-danger c-pointer"
                          onClick={() => destroy(item)}
                        >
                          Excluir
                        </span>
                      </li>
                    </ListBodyItemAction>
                  </ListBody>
                ))}
                {users.length === 0 ? (
                  <EmptyList icon="indiferente" message="Não foi encontrado nenhum acesso" />
                ) : (
                  <></>
                )}
              </List>
            </div>
          </div>
        </div>
      </Layout>
      <div
        className="modal fade border-0"
        id="myModalSingle"
        tabIndex="-1"
        aria-labelledby="myModalSingleLabel"
        aria-hidden="true"
        style={{ borderRadius: "10px" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {type === "add" ? (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalLabel">
                    Adicionar acesso por categoria
                  </h5>
                  <button
                    type="button"
                    id="btnCloseSingleAccessCategory"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <ModalCategoryAccess close={() => document.getElementById("btnCloseSingleAccessCategory").click()} />
                </div>
              </>
            ) : (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                    Editar acesso por categoria
                  </h5>
                  <button
                    type="button"
                    id="btnCloseSingleAccessCategory"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={save}>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                          Categoria
                        </label>
                        <select
                          className="form-select"
                          value={category}
                          onChange={(event) => setCategory(event.target.value)}
                          required
                        >
                          <option className="text-prop-grey-light">Selecione a categoria</option>
                          {categories.map((item, index) => (
                            <option value={item._id} key={index}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Status</label>
                        <select
                          className="form-select"
                          value={status}
                          onChange={(event) => setStatus(event.target.value)}
                          required
                        >
                          <option value="active" defaultValue>
                            Ativo
                          </option>
                          <option value="inactive">Inativo</option>
                        </select>
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">E-mail</label>
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                          required
                        />
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Senha</label>
                        <input
                          type="password"
                          className="form-control"
                          value={password}
                          onChange={(event) => setPassword(event.target.value)}
                        />
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                          Confirmar Senha
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          value={confirm_password}
                          onChange={(event) => setConfirmPassword(event.target.value)}
                        />
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                          Visualizar reports
                        </label>
                        <select
                          className="form-select"
                          value={seeReport}
                          onChange={(event) => setSeeReport(event.target.value)}
                        >
                          <option value={true}>Sim</option>
                          <option value={false}>Não</option>
                        </select>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-12 d-flex align-items-center justify-content-end">
                        <button
                          type="button"
                          className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-28"
                          data-bs-dismiss="modal"
                        >
                          Cancelar
                        </button>
                        {load ? (
                          <button
                            type="button"
                            className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                            disabled
                          >
                            <div className="spinner-border spinner-border-sm text-light" role="status">
                              <span className="visually-hidden">Carregando...</span>
                            </div>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                          >
                            Salvar
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Loading show={loading} />
    </>
  );
}

export default CategoryAccess;
