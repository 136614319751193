import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import { ListBody, ListBodyItemModal, ListBodyItemStatusEmojiModal } from "../../../components/List";
import api from "../../../config/api";
// import io from "socket.io-client";
// import swal from "sweetalert";
import Loading from "../../../components/Load";
import SidebarProject from "../../../components/SidebarProject";
import SidebarProjectCategory from "../../../components/SidebarProjectCategory";
import { formatDate } from "../../../config/utils";
import AddButton from "../../../components/AddButton";
import EmptyList from "../../../components/_empty/list";
// import WidgetClient from '../../../components/_widgets/WidgetClient';
import ModalReport from "../../../components/_modals/_agency/Report";
// import ClearButton from '../../../components/ClearButton';
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableHeaderItemDropdown,
  TableBody,
  TableBodyItemAction,
  TableBodyItemActionReports,
} from "../../../components/Table";
import { decodeToken } from "../../../config/auth";
import { Drawer } from "../../../components/Drawer";
import { ConfirmDelete } from "../../../components/Alert";
import { toastsMsg } from "../../../components/ToasterMsg";

require("dotenv").config();

function Report(props) {
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [limit, setLimit] = useState(6);

  const [project, setProject] = useState("");
  const [client, setClient] = useState("");
  const [responsibles, setResponsibles] = useState([]);
  let [user, setUser] = useState({});

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [subjectEdit, setSubjectEdit] = useState(false);
  const [scheduleEdit, setSheduleEdit] = useState(false);
  const [reportIdDelete, setReportIdDelete] = useState("");

  const [report, setReport] = useState({});
  const [drawerOpenEditReport, setDrawerOpenEditReport] = useState(false);
  const [drawerOpenViewReport, setDrawerOpenViewReport] = useState(false);
  const [subject, setSubject] = useState("");
  const [rating, setRating] = useState("");
  const [comments, setComments] = useState("");
  const [date, setDate] = useState("");
  const [schedule, setSchedule] = useState("");
  const [id, setId] = useState("");
  const [type, setType] = useState("");

  // Filter
  const [filterDropdownName, setFilterDropdownName] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [responsibleFilter, setResponsibleFilter] = useState("");
  const [sort, setSort] = useState("");
  let [view, setView] = useState({});

  useEffect(() => {
    const { user = {} } = decodeToken();
    setUser(user);
    getReports(page, limit, nameFilter, statusFilter, responsibleFilter);
    getProject();
    getResponsibles();
    // async function registerToSocket() {
    //     const socket = io(`${process.env.REACT_APP_API_URL}`);
    //     socket.on("report", () => {
    //         getReports(page, nameFilter, statusFilter, responsibleFilter);
    //     });
    // }
    // registerToSocket();
  }, [page, props, user.role]);

  const getReports = async (
    mypage = 1,
    limit = 6,
    filterName = "",
    filterStatus = "",
    filterResponsible = "",
    sortBy = "createdAt",
    order = "desc"
  ) => {
    setLoading(true);
    const response = await api.get(
      `/report/${props.match.params.id}?paginate=true&page=${mypage}&limit=${limit}&filterName=${filterName}&filterStatus=${filterStatus}&filterResponsible=${filterResponsible}&order=${order}&sortBy=${sortBy}`
    );
    const { ...arr } = response.data;
    setReports(response.data);
    setPages(parseInt(arr.pages));
    setLoading(false);
  };

  const getProject = async () => {
    const response = await api.get(`/dashboard-project/${props.match.params.id}`);
    setProject(response.data);
    setClient(response.data.client);
  };

  const getResponsibles = async () => {
    const response = await api.get(`/user-in-report/${props.match.params.id}`);
    setResponsibles(response.data);
  };

  const nextPage = () => {
    getReports(page === pages ? page : page + 1, limit, nameFilter, statusFilter, responsibleFilter);
    setPage(page === pages ? page : page + 1);
  };

  const backPage = () => {
    getReports(page === 1 ? page : page - 1, limit, nameFilter, statusFilter, responsibleFilter);
    setPage(page === 1 ? page : page - 1);
  };

  const destroy = async () => {
    await api
      .delete(`/report/${reportIdDelete}`)
      .then(async () => {
        getReports(page, limit, nameFilter, statusFilter, responsibleFilter);
        document.getElementById("cancelButtonDeleteModel").click();
        toastsMsg({
          icon: "success",
          title: "Sucesso!",
          text: "Report removido com sucesso.",
        });
      })
      .catch(() => {
        toastsMsg({
          icon: "error",
          title: "Erro!",
          text: "Erro ao remover o report, tente novamente mais tarde.",
        });
      });
  };

  const visually = (item) => {
    setView({
      _id: item._id,
      client: item.client,
      project: item.project,
      date: formatDate(item.date),
      subject: item.subject,
      rating: item.rating,
      comments: item.comments,
      user: item.user,
      schedule: item.schedule,
    });
    setDrawerOpenViewReport(true);
  };

  const edit = (item) => {
    setReport(item);
    setType("edit");
    setId(item._id);
    setDate(String(item.date).substr(0, 10));
    setSubject(item.subject);
    setRating(item.rating);
    setComments(item.comments);
    setDrawerOpenEditReport(true);
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    const formData = new FormData();
    formData.append("file", schedule);
    schedule !== "" ? formData.append("haveFile", true) : formData.append("haveFile", false);
    (subject !== "") & (subject !== undefined)
      ? formData.append("subject", subject)
      : formData.append("subject", report.subject);
    formData.append("client", client._id);
    formData.append("project", project._id);
    formData.append("date", date);
    formData.append("rating", rating);
    formData.append("comments", comments);
    try {
      await api
        .put(`/report/${report._id}`, formData)
        .then(() => {
          setLoad(false);
          toastsMsg("Sucesso!", "Report editado com sucesso!", "success");
          getReports(page, limit, nameFilter, statusFilter, responsibleFilter);
          setDrawerOpenEditReport(false);
        })
        .catch((error) => {
          setLoad(false);
          if (error.response.data.error === "Invalid File") {
            toastsMsg(
              "Erro!",
              "Tipo de arquivo selecionado incorreto, por favor selecione um arquivo válido.",
              "error"
            );
          } else {
            toastsMsg("Erro!", "Erro ao editar o report, tente novamente mais tarde.", "error");
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const reunionHandleChange = (e) => {
    console.log(e);
    //setReport((values) => ({ ...values, [e.target.name]: e.target.value }));
  };

  const reunionSheduleHandleChange = (e) => {
    console.log(e);
    //setReport((values) => ({ ...values, [e.target.name]: e.target.files[0] }));
  };

  const closeDrawer = () => {
    setSheduleEdit(false);
    setSubjectEdit(false);
    setDrawerOpen(false);
  };

  const subjectNewEdit = (value) => {
    setSubjectEdit(!subjectEdit);
    setReport((values) => ({ ...values, newSubject: value }));
  };

  const filterChangeHandlerName = (event) => {
    setNameFilter(event.target.value);
    getReports(page, limit, event.target.value, statusFilter, responsibleFilter);
  };

  const filterChangeHandlerResponsible = (value) => {
    setResponsibleFilter(value);
    getReports(page, limit, nameFilter, statusFilter, value);
  };

  const filterChangeHandlerStatus = (value) => {
    setStatusFilter(value);
    getReports(page, limit, nameFilter, value, responsibleFilter);
  };

  const changeLimit = (event) => {
    setLimit(event.target.value);
    setPage(1);
    getReports(1, event.target.value, nameFilter, statusFilter, responsibleFilter);
  };

  const changePage = (event) => {
    setLoad(true);
    setPage(event.target.id);
    getReports(event.target.id, limit, nameFilter, statusFilter, responsibleFilter);
    setLoad(false);
  };

  const sortByNameChangeHandler = (sortBy, order) => {
    getReports(page, limit, nameFilter, statusFilter, "", sortBy, order);
    setResponsibleFilter("");
    setSort(sortBy);
  };

  const addAction = () => {
    setReport({});
    setDrawerOpenEditReport(true);
  };

  return (
    <>
      <Layout
        defaultMenu={0}
        isSingleProject={true}
        clientImg={client.logo}
        clientName={client.name}
        projectName={project.name}
        projectCategory={project.category !== undefined ? project.category[0] : ""}
      >
        <div className="container-fluid singleProject">
          {user.role === "agency" ? <SidebarProject active={"report"} project={props.match.params.id} /> : <></>}
          {user.role === "category" ? (
            <div className="col-12">
              <SidebarProjectCategory seeReport={user.seeReport} active={"report"} project={props.match.params.id} />
            </div>
          ) : (
            <></>
          )}
          <div className="row">
            <div className="col-12 col-lg-4 mt-2 me-auto">
              <div className="input-group input-search">
                <input
                  type="text"
                  className="form-control border-0 fz-14 box-shadow-none text-prop-grey-light font-rubik"
                  onChange={(event) => filterChangeHandlerName(event)}
                  placeholder="O que você está buscando?"
                />
                <span className="input-group-text bg-white border-0">
                  <i className="bi bi-search text-prop-grey-light"></i>
                </span>
              </div>
            </div>
            {user.role !== "category" ? (
              <AddButton
                className={"m-0"}
                sizeLg={"6"}
                onClick={() => addAction()}
                modalId={"#myModalSingle"}
                text="Adicionar Report"
              />
            ) : (
              <></>
            )}
            <div className="col-12 mb-4">
              <Table
                title={"reuniões"}
                totalItem={reports.total}
                value={limit}
                onChangeLimit={(e) => changeLimit(e)}
                prevButton={() => backPage()}
                nextButton={() => nextPage()}
                page={page}
                changePage={(e) => changePage(e)}
              >
                <TableHeader>
                  <TableHeaderItem sizeLg={3} title="Assunto" />
                  <TableHeaderItem sizeLg={3} title="Colaborador">
                    <TableHeaderItemDropdown>
                      <li
                        className="dropdown-header d-flex justify-content-end flex-column pt-0 pb-1 px-2"
                        style={{ borderBottom: "1px solid #D9D9D9" }}
                      >
                        <div className="input-group input-search">
                          <input
                            onChange={({ target }) => setFilterDropdownName(target.value)}
                            type="text"
                            style={{ width: "173px" }}
                            className="input-dropdown form-control fz-14 border-0 box-shadow-none text-cl-auroMetal ff-roboto px-1"
                            placeholder={"Buscar"}
                          />
                          <span className="input-group-text bg-white border-0">
                            <i className="bi bi-search " style={{ color: "#000000" }}></i>
                          </span>
                        </div>
                        <div
                          className="c-pointer mt-1"
                          onClick={() => sortByNameChangeHandler("user.firstName", "asc")}
                        >
                          <span className="font-rubik fz-14 text-prop-dark-blue">Ordenar de A a Z</span>
                        </div>
                        <div
                          className="c-pointer mt-1"
                          onClick={() => sortByNameChangeHandler("user.firstName", "desc")}
                        >
                          <span className="font-rubik fz-14 text-prop-dark-blue">Ordenar de Z para A</span>
                        </div>
                      </li>
                      <li
                        onClick={() => filterChangeHandlerResponsible("")}
                        className="dropdown-header d-flex justify-content-start py-0 mt-2 px-2 c-pointer"
                      >
                        <span className="font-rubik fz-14 text-prop-dark-blue">Todos</span>
                      </li>
                      {responsibles
                        .filter(
                          (element) =>
                            filterDropdownName === "" ||
                            String(element.firstName + " " + element.lastName)
                              .toLowerCase()
                              .includes(filterDropdownName.toLocaleLowerCase())
                        )
                        .map((item, index) => (
                          <li
                            onClick={() => filterChangeHandlerResponsible(item._id)}
                            className="dropdown-header d-flex justify-content-start py-0 mt-2 px-2 c-pointer"
                          >
                            <span className="font-rubik fz-14 text-prop-dark-blue" key={index + 1}>
                              {item.firstName + " " + item.lastName}
                            </span>
                          </li>
                        ))}
                    </TableHeaderItemDropdown>
                  </TableHeaderItem>
                  <TableHeaderItem sizeLg={3} title="Data" />
                  <TableHeaderItem sizeLg={3} title="Status">
                    <TableHeaderItemDropdown>
                      <li
                        onClick={() => filterChangeHandlerStatus("")}
                        className="dropdown-header d-flex justify-content-start py-0 mt-2 px-2 c-pointer"
                      >
                        <span className="font-rubik fz-14 text-prop-dark-blue">Todos</span>
                      </li>
                      <li
                        onClick={() => filterChangeHandlerStatus("Bom")}
                        className="dropdown-header d-flex justify-content-start py-0 mt-2 px-2 c-pointer"
                      >
                        <span className="font-rubik fz-14 text-prop-dark-blue">Bom</span>
                      </li>
                      <li
                        onClick={() => filterChangeHandlerStatus("Regular")}
                        className="dropdown-header d-flex justify-content-start py-0 mt-2 px-2 c-pointer"
                      >
                        <span className="font-rubik fz-14 text-prop-dark-blue">Regular</span>
                      </li>
                      <li
                        onClick={() => filterChangeHandlerStatus("Ruim")}
                        className="dropdown-header d-flex justify-content-start py-0 mt-2 px-2 c-pointer"
                      >
                        <span className="font-rubik fz-14 text-prop-dark-blue">Ruim</span>
                      </li>
                    </TableHeaderItemDropdown>
                  </TableHeaderItem>
                </TableHeader>
                <TableBody>
                  {reports.docs !== undefined ? (
                    reports.docs.map((item, index) => (
                      <ListBody key={index} specialClass="card-with-hover d-flex flex-wrap">
                        <ListBodyItemModal
                          sizeLg={3}
                          title="Assunto"
                          value={item.subject}
                          modalId="#myModalView"
                          onClick={() => visually(item)}
                        />
                        <ListBodyItemModal
                          sizeLg={3}
                          title="Colaborador"
                          value={`${item.user.firstName} ${item.user.lastName}`}
                          modalId="#myModalView"
                          onClick={() => visually(item)}
                        />
                        <ListBodyItemModal
                          sizeLg={3}
                          title="Data"
                          value={formatDate(item.date)}
                          modalId="#myModalView"
                          onClick={() => visually(item)}
                        />
                        <ListBodyItemStatusEmojiModal
                          sizeLg={2}
                          title="Status"
                          value={item.rating}
                          modalId="#myModalView"
                          onClick={() => visually(item)}
                        />
                        {user.role !== "category" ? (
                          <TableBodyItemActionReports sizeLg={1}>
                            <li>
                              <span
                                className="dropdown-item fz-14 font-rubik c-pointer"
                                data-bs-toggle="modal"
                                data-bs-target="#myModalSingle"
                                onClick={() => edit(item)}
                              >
                                Editar
                              </span>
                            </li>
                            <li>
                              <span
                                onClick={() => setReportIdDelete(item._id)}
                                className="dropdown-item fz-14 font-rubik text-prop-danger c-pointer"
                                data-bs-toggle="modal"
                                data-bs-target={"#confirmDeleteReunion"}
                              >
                                Excluir
                              </span>
                            </li>
                          </TableBodyItemActionReports>
                        ) : (
                          <></>
                        )}
                      </ListBody>
                    ))
                  ) : (
                    <></>
                  )}
                  {reports.length === 0 ? (
                    <EmptyList icon="indiferente" message="Não foi encontrada nenhuma report" />
                  ) : reports.docs.length === 0 ? (
                    <EmptyList icon="indiferente" message="Não foi encontrada nenhuma report" />
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </Layout>

      <Drawer
        show={drawerOpenEditReport}
        onClose={() => setDrawerOpenEditReport(false)}
        onOpen={() => setDrawerOpenEditReport(true)}
      >
        <div className="p-3 modal-drawer">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              {report._id === undefined ? (
                <>
                  <div className="modal-header border-0">
                    <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalLabel">
                      Adicionar report
                    </h5>
                    <button
                      type="button"
                      id="btnClose"
                      className="btn-close"
                      onClick={() => setDrawerOpenEditReport(false)}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <ModalReport
                      getReports={getReports}
                      client={client}
                      project={project}
                      close={() => setDrawerOpenEditReport(false)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="modal-header border-0">
                    <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                      Editar report
                    </h5>
                    <button
                      type="button"
                      id="btnCloseSingle"
                      className="btn-close"
                      onClick={() => setDrawerOpenEditReport(false)}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={save}>
                      <div className="row">
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                            Assunto
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={subject}
                            onChange={(event) => setSubject(event.target.value)}
                            required
                          />
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Data</label>
                          <input
                            type="date"
                            className="form-control"
                            value={date}
                            onChange={(event) => setDate(event.target.value)}
                            required
                          />
                        </div>
                        <div className="col-6 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                            Status
                          </label>
                          <select
                            className="form-select"
                            value={rating}
                            onChange={(event) => setRating(event.target.value)}
                            required
                          >
                            <option></option>
                            <option value="Bom">Bom</option>
                            <option value="Regular">Regular</option>
                            <option value="Ruim">Ruim</option>
                          </select>
                        </div>
                        <div className="col-6 col-lg-6 mb-3">
                          <label
                            htmlFor="formFile"
                            className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1"
                          >
                            Anexar pauta <span className="fz-10 text-prop-danger font-rubik">Tamanho máximo 2MB</span>
                          </label>
                          <input
                            className="form-control"
                            type="file"
                            id="formFile"
                            onChange={(event) => setSchedule(event.target.files[0])}
                          />
                        </div>
                        <div className="col-12 col-lg-12 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                            Observações
                          </label>
                          <textarea
                            className="form-control"
                            rows="8"
                            value={comments}
                            onChange={(event) => setComments(event.target.value)}
                            required
                          ></textarea>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-12 d-flex align-items-center justify-content-end">
                          <button
                            type="button"
                            className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-28"
                            onClick={() => setDrawerOpenEditReport(false)}
                          >
                            Cancelar
                          </button>
                          {load ? (
                            <button
                              type="button"
                              className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                              disabled
                            >
                              <div className="spinner-border spinner-border-sm text-light" role="status">
                                <span className="visually-hidden">Carregando...</span>
                              </div>
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                            >
                              Salvar
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Drawer>
      <Drawer
        show={drawerOpenViewReport}
        onClose={() => setDrawerOpenViewReport(false)}
        onOpen={() => setDrawerOpenViewReport(true)}
      >
        <div className="p-3 modal-drawer">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark mb-3" id="myModalSingleLabel">
                  {view.subject}
                </h5>
                <button
                  type="button"
                  id="btnCloseSingle"
                  className="btn-close"
                  onClick={() => setDrawerOpenViewReport(false)}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  {view.user !== undefined ? (
                    <div className="col-12 d-flex align-items-center justify-content-start">
                      <div className="col-4 col-lg-2 d-flex align-items-center justify-content-start">
                        <img
                          src={view.user.avatar}
                          alt=""
                          width={100}
                          height={100}
                          style={{ border: "1px solid #626679" }}
                          className="rounded-circle"
                        />
                      </div>
                      <div className="col-8 col-lg-10 d-flex align-items-start justify-content-start flex-column">
                        <span className="fw-bold">{view.user.firstName + " " + view.user.lastName}</span>
                        <span>{view.date}</span>
                        <span className="fw-bold">
                          {"Status: "}
                          <span
                            className={`${
                              view.rating === "Bom"
                                ? "fw-normal text-prop-cyan"
                                : view.rating === "Regular"
                                ? "fw-normal text-prop-warning-light"
                                : "fw-normal text-prop-danger"
                            }`}
                          >
                            {view.rating}
                          </span>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="col-12 col-lg-12 mb-3 mt-2">
                    <label className="form-label font-rubik fw-bold fz-14 mb-2">Observações</label>
                    <div className="form-control" style={{ borderRadius: "5px" }}>
                      <div className="d-flex justify-content-start text-break scroll h-100">
                        <textarea disabled={true} rows={8} className="col-12 p-2 border-0 bg-transparent">
                          {view.comments}
                        </textarea>
                      </div>
                    </div>
                  </div>
                  {(view.schedule !== undefined) & (view.schedule !== " ") ? (
                    <>
                      <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-2">
                        Arquivo para download
                      </label>
                      <div className="col-12 d-flex align-items-center justify-content-start flex-wrap ">
                        <span className="col-12 col-lg-6">Esse relatório possui um arquivo para download</span>
                        <a className="col-12 col-lg-3" target="_blank" rel="noopener noreferrer" href={view.schedule}>
                          <button className=" btn btn-prop-blue text-white font-rubik fz-16 px-3 " type="button">
                            Baixar arquivo
                          </button>
                        </a>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <ConfirmDelete
        modalId="confirmDeleteReunion"
        title="Você tem certeza?"
        description="Esse report será apagado e não terá mais volta"
        deleteText="Excluir"
        confirmDeleteOnClick={() => destroy()}
      />

      <Loading show={loading} />
    </>
  );
}

export default Report;
