import React, { useEffect, useState } from "react";
import api from "../../../../config/api";
import { toastsMsg } from "../../../ToasterMsg";
// import swal from "sweetalert";

function ModalStakeholder(props) {
  const [load, setLoad] = useState(false);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("xxxxx-xxxx");
  const [isManager, setIsManager] = useState(false);

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = async () => {
    const response = await api.get(`/project/all?limit=9999&sortBy=name&order=ASC`);
    setProjects(response.data);
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    let newStakeholder = {
      name: name,
      email: email,
      phone: phone,
      isManager: isManager,
    };
    if (props.project === undefined) {
      newStakeholder.project = project;
    } else {
      newStakeholder.project = props.project;
    }
    await api
      .post(`/stakeholder`, newStakeholder)
      .then(() => {
        setLoad(false);
        toastsMsg("Sucesso!", "Stakeholder cadastrado com sucesso!", "success");
        setName("");
        setEmail("");
        props.getStakeholders();
        props.close();
      })
      .catch((error) => {
        setLoad(false);
        toastsMsg("Erro!", "Erro ao cadastrar o stakeholder, tente novamente mais tarde.", "error");
        props.close();
      });
  };

  return (
    <>
      <form onSubmit={save}>
        <div className="row">
          {props.project === undefined ? (
            <div className="col-12 col-lg-6 mb-3">
              <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Projeto</label>
              <select className="form-select" onChange={(event) => setProject(event.target.value)} required>
                <option></option>
                {projects.map((item, index) => (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <></>
          )}
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Nome</label>
            <input type="text" className="form-control" onChange={(event) => setName(event.target.value)} required />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">E-mail</label>
            <input type="email" className="form-control" onChange={(event) => setEmail(event.target.value)} required />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Telefone</label>
            <input type="text" className="form-control" onChange={(event) => setPhone(event.target.value)} />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Responsável</label>
            <select className="form-select" value={isManager} onChange={(event) => setIsManager(event.target.value)} required>
              <option></option>
              <option value={true}>Sim</option>
              <option value={false}>Não</option>
            </select>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 d-flex align-items-center justify-content-end">
            <button type="reset" className="d-none" id="btnClearStakeholder">
              Reset
            </button>
            <button type="button" className="btn text-danger px-lg-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-25" onClick={() => props.close()}>
              Cancelar
            </button>
            {load ? (
              <button
                type="button"
                className="btn btn-prop-blue px-lg-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                disabled
              >
                <div className="spinner-border spinner-border-sm text-light" role="status">
                  <span className="visually-hidden">Carregando...</span>
                </div>
              </button>
            ) : (
              <button type="submit" className="btn btn-prop-blue px-lg-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center">
                Cadastrar
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

export default ModalStakeholder;
