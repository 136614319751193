import React, { useEffect, useState } from "react";
import api from "../../../../config/api";
// import swal from "sweetalert";
import Select from "react-select";
// import Loading from "../../../Load";
import { toastsMsg } from "../../../ToasterMsg";

function ModalSprint(props) {
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("");
  const [title, setTitle] = useState("");
  const [estimatedDate, setEstimatedDate] = useState("");
  const [tasks, setTasks] = useState([]);
  const [project_tasks, setProjectTasks] = useState([]);
  // const [startDate, setStartDate] = useState(false);

  useEffect(() => {
    getProjects();
    getProjectsTasks();
  }, [project_tasks]);

  const getProjects = async () => {
    const response = await api.get(`/project/all?limit=9999&sortBy=name&order=ASC`);
    setProjects(response.data);
  };

  const getProjectsTasks = async (project_id = "") => {
    if (props.project._id !== undefined) {
      project_id = props.project._id;
    } else {
      project_id = "";
    }
    try {
      setLoading(true);
      const response = await api.get(`/task/all?project=${project_id}&limit=9999&sortBy=name&order=ASC`);
      const tasks_arr = [];
      response.data.forEach((u) => tasks_arr.push({ value: u._id, label: `${u.title}` }));
      setProjectTasks(tasks_arr);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    let task = [];
    if ((tasks !== undefined) & (tasks !== []) & (tasks !== null)) {
      tasks.forEach((u) => task.push(u.value));
    }
    let newSprint = {
      title: title,
      estimatedDate: estimatedDate,
      task: task,
    };
    if (props.project === undefined) {
      newSprint.project = project;
    } else {
      newSprint.project = props.project;
    }
    try {
      setLoading(true);
      await api
        .post(`/sprint`, newSprint)
        .then(() => {
          toastsMsg("Sucesso!", "Sprint cadastrada com sucesso!", "success");
          setTitle("");
          setEstimatedDate(null);
          setTasks(null);
          getProjectsTasks(project);
          props.getSprints();
          props.getTasks();

          props.close();
          setLoad(false);
          setLoading(false);
        })
        .catch(() => {
          setLoad(false);
          setLoading(false);
          toastsMsg("Erro!", "Erro ao cadastrar a sprint, tente novamente mais tarde.", "error");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleprojectChange = async (event) => {
    setProject(event.target.value);
    getProjectsTasks(event.target.value);
  };

  return (
    <>
      <form onSubmit={save}>
        <div className="row">
          {props.project === undefined ? (
            <div className="col-12 col-lg-12 mb-3">
              <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Projeto</label>
              <select className="form-select" onChange={(event) => handleprojectChange(event)} required>
                <option></option>
                {projects.map((item, index) => (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <></>
          )}
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Nome</label>
            <input type="text" className="form-control" onChange={(event) => setTitle(event.target.value)} required />
          </div>
          {/* <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Início da sprint</label>
                        <input type="date" className="form-control" onChange={(event) => setStartDate(event.target.value)} required />
                    </div>*/}
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
              Estimativa de entrega
            </label>
            <input
              type="date"
              className="form-control"
              onChange={(event) => setEstimatedDate(event.target.value + "T03:00:00.000Z")}
              required
            />
          </div>
          <div className="col-12 col-lg-12 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Tarefas</label>
            {project_tasks.length ? (
              <Select
                name="tasks"
                value={tasks}
                placeholder=""
                options={project_tasks}
                onChange={(event) => setTasks(event)}
                isMulti
              />
            ) : (
              <>
                <select className="form-select" onChange={() => props.addTask()}>
                  <option></option>
                  <option>Criar Task</option>
                </select>
              </>
            )}
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 d-flex align-items-center justify-content-end">
            <button type="reset" className="d-none" id="btnClearSprint">
              Reset
            </button>

            <button
              type="button"
              className="btn text-danger px-lg-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-25"
              onClick={() => props.setDrawerOpenAddSprint(false)}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn btn-prop-blue px-lg-4 py-2 text-white me-3 fz-13 text-uppercase font-rubik d-flex justify-content-center"
              onClick={() => props.addTask()}
            >
              Adicionar Tarefa
            </button>
            {load ? (
              <button
                type="button"
                className="btn btn-prop-blue px-lg-4 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                disabled
              >
                <div className="spinner-border spinner-border-sm text-light" role="status">
                  <span className="visually-hidden">Carregando...</span>
                </div>
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-prop-blue px-lg-4 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
              >
                Cadastrar
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

export default ModalSprint;
