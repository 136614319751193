import React, { useEffect, useState } from "react";
import api from "../../../../config/api";
// import swal from "sweetalert";
import { toastsMsg } from "../../../ToasterMsg";

import Select from "react-select";

function ModalTask(props) {
  const [load, setLoad] = useState(false);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [estimatedDate, setEstimatedDate] = useState("");
  const [typeTasks, setTypeTasks] = useState([]);
  const [priority, setPriority] = useState(false);
  const [responsible, setResponsible] = useState([]);
  const [users, setUsers] = useState(1);
  const [saveType, setSaveType] = useState("");

  useEffect(() => {
    getProjects();
    getTypeTasks();
    getUsers();
  }, []);

  const getProjects = async () => {
    const response = await api.get(`/project/all?limit=9999&sortBy=name&order=ASC`);
    setProjects(response.data);
  };

  const getUsers = async () => {
    const response = await api.get(`/user/agency?limit=99999&sortBy=firstName&order=ASC`);
    const users_arr = [];
    let items = response.data;
    items = items.filter((item) => item.status === "active" && item.status !== "inactive");
    items.forEach((item) =>
      users_arr.push({
        value: item._id,
        label: `${item.firstName} ${item.lastName}`,
      })
    );
    setUsers(users_arr);
  };

  const getTypeTasks = async (limit = 100) => {
    const response = await api.get(`/typeTask?paginate=true&limit=${limit}`);
    const { docs = [] } = response.data;
    setTypeTasks(docs);
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);

    let user_arr = [];
    if (responsible !== null) {
      user_arr = responsible.length > 0 ? responsible.map((item) => item.value) : [];
    } else {
      user_arr = [];
    }
    await api
      .post(`/task`, {
        project: props.project !== undefined ? props.project : project,
        title,
        estimatedDate,
        category,
        priority,
        responsible: user_arr,
      })
      .then(() => {
        setLoad(false);
        toastsMsg("Sucesso!", "Tarefa cadastrada com sucesso!", "success");
        document.getElementById("btnClearTask").click();
        setResponsible(null);
        if (saveType === "save") {
          props.close();
        }
      })
      .catch(() => {
        setLoad(false);
        toastsMsg("Erro!", "Erro ao cadastrar a tarefa, tente novamente mais tarde.", "error");
      });
  };

  return (
    <>
      <form onSubmit={save}>
        <div className="row">
          {props.project === undefined ? (
            <div className="col-12 col-lg-12 mb-3">
              <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Projeto</label>
              <select className="form-select" onChange={(event) => setProject(event.target.value)} required>
                <option></option>
                {projects.map((item, index) => (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <></>
          )}

          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Título</label>
            <input type="text" className="form-control" onChange={(event) => setTitle(event.target.value)} required />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Categoria</label>
            <select className="form-select" onChange={(event) => setCategory(event.target.value)} required>
              <option></option>
              {typeTasks.map((item, index) => (
                <option value={item.name} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Deadline</label>
            <input
              type="date"
              className="form-control"
              onChange={(event) => setEstimatedDate(event.target.value)}
              required
            />
          </div>

          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Prioridade</label>
            <select className="form-select" onChange={(event) => setPriority(event.target.value)} required>
              <option></option>
              <option value={true}>Sim</option>
              <option value={false}>Não</option>
            </select>
          </div>
          <div className="col-12 col-lg-12 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Responsável</label>
            <Select
              name="responsible"
              placeholder=""
              value={responsible}
              onChange={(item) => setResponsible(item)}
              options={users}
              isMulti
            />
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 d-flex align-items-center justify-content-end">
            <button type="reset" className="d-none" id="btnClearTask">
              Reset
            </button>
            <button
              type="button"
              className="btn text-danger px-lg-3 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-15"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
            {load ? (
              <button
                type="button"
                className="btn btn-prop-blue px-lg-5 py-2 text-white fz-13 text-uppercase font-rubik w-28"
                disabled
              >
                <div className="spinner-border spinner-border-sm text-light" role="status">
                  <span className="visually-hidden">Carregando...</span>
                </div>
              </button>
            ) : (
              <>
                <button
                  type="submit"
                  onClick={() => setSaveType("save")}
                  className="px-lg-2 py-2 save-close-button fz-13 text-uppercase font-rubik w-25 mx-2"
                >
                  SALVAR
                </button>
                <button
                  type="submit"
                  onClick={() => setSaveType("saveMore")}
                  style={{ borderRadius: "10px" }}
                  className="btn btn-prop-blue px-lg-2 py-2 text-white fz-13 text-uppercase font-rubik w-28"
                >
                  SALVAR E CRIAR OUTRA
                </button>
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

export default ModalTask;
