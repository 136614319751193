import React from "react";
import confirmed from "../../assets/img/icons/confirmed.svg";
import MaterialIcon from "material-icons-react";

export function CardBody(props) {
  return (
    <>
      <div
        className={`sprintCard ${
          props !== undefined ? (props.status === "closed" ? "bg-prop-cyan" : "bg-prop-blue") : "bg-prop-blue"
        } d-flex justify-content-start col-12 mb-3`}
        style={{ borderRadius: "10px 10px 10px 10px", paddingLeft: "10px" }}
      >
        <div className="col-8 col-lg-10">{props.children}</div>
        {props.isSprint ? (
          <CardItemSprintAction
            isTaskOpen={props.isTaskOpen}
            openTask={props.openTask}
            status={props.status}
            role={props.role}
            task={props.task}
            openSprint={props.openSprint}
            closeSprint={props.closeSprint}
            edit={props.edit}
            destroy={props.destroy}
          />
        ) : (
          <CardItemModelAction edit={props.edit} destroy={props.destroy} />
        )}
      </div>
    </>
  );
}

export function CardItemBody(props) {
  return (
    <>
      <div
        className={`${props.status === "closed" ? "bg-prop-green-light" : "bg-white"}  row ${
          props.className !== undefined ? props.className : ""
        }`}
      >
        {props.children}
      </div>
    </>
  );
}

export function CardHeaderItem(props) {
  return (
    <>
      <div
        className={`py-2 d-none d-lg-flex col-lg-${props.sizeLg} d-flex align-items-center justify-content-start  ${
          props.className !== undefined ? props.className : ""
        } divider`}
      >
        <span className="fw-bold fz-14 text-prop-grey-light">{props.text}</span>
      </div>
    </>
  );
}

export function CardItem(props) {
  return (
    <>
      <div
        className={`py-2 col-12 ${props.title === "" ? "disaper" : ""} col-lg-${
          props.sizeLg
        } d-flex align-items-center justify-content-start  ${props.className !== undefined ? props.className : ""}`}
      >
        <span className={`fw-bold fz-14 text-prop-grey-light d-flex d-lg-none px-2`}>{props.title}:</span>
        <span className="fw-bold fz-14 text-prop-grey-light">{props.text}</span>
      </div>
    </>
  );
}

export function CardItemModelAction(props) {
  return (
    <>
      <div
        className="bg-white d-flex justify-content-center align-items-center col-4 col-lg-2"
        style={{ borderRadius: "0px 10px 10px 0px", borderLeft: "1px solid #D1D5E6" }}
      >
        <span
          className="p-2 fz-14 font-rubik c-pointer"
          data-bs-toggle="modal"
          data-bs-target="#myModalSingle"
          onClick={props.edit}
        >
          <MaterialIcon icon="edit" />
        </span>
        <span className="p-2 fz-14 font-rubik text-prop-danger c-pointer" onClick={props.destroy}>
          Excluir
        </span>
      </div>
    </>
  );
}

export function CardItemSprintAction(props) {
  return (
    <>
      <div
        className={`${
          props.status === "closed" ? "bg-prop-green-light" : "bg-white"
        } d-flex justify-content-center align-items-center col-4 col-lg-2`}
        style={{ borderRadius: "0px 9px 9px 0px", borderLeft: "1px solid #e0e0e0" }}
      >
        {(props.status === "pending") & (props.role !== "category") ? (
          <>
            {props.task.length > 0 ? (
              <span
                className="fz-14 font-rubik fw-bold text-uppercase text-prop-cyan c-pointer"
                onClick={props.openSprint}
                style={{ marginLeft: "14px" }}
              >
                Iniciar sprint
              </span>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        {(props.status === "open") & (props.role !== "category") ? (
          <span
            className="d-flex aling-items-center justify-content-center fz-14 font-rubik fw-bold text-uppercase text-prop-cyan c-pointer px-2"
            onClick={props.closeSprint}
            style={{ textAlign: "center" }}
          >
            Entregar sprint
          </span>
        ) : (
          <></>
        )}
        {(props.status === "closed") & (props.role !== "category") ? (
          <>
            {props.task.length > 0 ? (
              <>
                <a
                  onClick={props.openTask}
                  href={"#"}
                  style={{ textDecoration: "none" }}
                  className={`disaper d-flex aling-items-center sprintTaskArrow ${props.isTaskOpen}`}
                >
                  <MaterialIcon icon="expand_more" />
                  <span className="fz-16 font-rubik fw-bold text-uppercase text-prop-cyan m-0 ">Exibir tarefas</span>
                </a>
                <a
                  onClick={props.openTask}
                  href={"#"}
                  style={{ textDecoration: "none" }}
                  className={`d-flex d-lg-none aling-items-center sprintTaskArrow ${props.isTaskOpen}`}
                >
                  <span
                    className="fz-16 font-rubik fw-bold text-uppercase text-prop-cyan "
                    style={{ marginLeft: "14px", textAlign: "center" }}
                  >
                    {props.isTaskOpen === "open" ? "Ocultar tarefas" : "Exibir tarefas"}
                  </span>
                </a>
              </>
            ) : (
              <>
                <span
                  className="fz-14 font-rubik fw-bold text-uppercase text-prop-cyan disaper"
                  style={{ marginLeft: "14px" }}
                >
                  Sprint entregue
                </span>
                <img
                  onClick={props.openTask}
                  src={confirmed}
                  className="d-flex aling-items-center justify-content-center d-lg-none"
                  alt=""
                  style={{ width: 45, height: 45 }}
                />
              </>
            )}
          </>
        ) : (
          <></>
        )}
        {(props.status !== "open") & (props.status !== "closed") & (props.role !== "category") ? (
          <div className="btn-group">
            <button type="button" className="btn dropdown-toggle py-0" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="bi bi-three-dots-vertical"></i>
            </button>

            <ul className="dropdown-menu" style={{ borderRadius: "10px" }}>
              <li>
                <span
                  className="dropdown-item fz-14 font-rubik c-pointer"
                  data-bs-toggle="modal"
                  data-bs-target="#myModalSingle"
                  onClick={props.edit}
                >
                  Editar
                </span>
              </li>
              <li>
                <span className="dropdown-item fz-14 font-rubik text-prop-danger c-pointer" onClick={props.destroy}>
                  Excluir
                </span>
              </li>
            </ul>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export function CardItemSprintActionNew(props) {
  return (
    <>
      <div className={`d-flex justify-content-center align-items-center col-lg-auto `}>
        {(props.status === "pending") & (props.role !== "category") ? (
          <>
            {props.task.length > 0 ? (
              <span
                className="btn btn-prop-blue text-white font-ruby fz-14 px-4 py-1 button-add"
                onClick={props.openSprint}
                style={{ marginLeft: "14px" }}
              >
                Iniciar sprint
              </span>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        {(props.status === "open") & (props.role !== "category") ? (
          <span
            className="btn btn-prop-light-sea-green  text-white font-ruby fz-14 px-4 py-1"
            onClick={props.closeSprint}
            style={{ textAlign: "center" }}
          >
            Completar sprint
          </span>
        ) : (
          <></>
        )}
        {(props.status !== "closed") & (props.role !== "category") ? (
          <div className="btn-group">
            <button type="button" className="btn dropdown-toggle py-0" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="bi bi-three-dots-vertical"></i>
            </button>

            <ul className="dropdown-menu" style={{ borderRadius: "10px" }}>
              <li>
                <span
                  className="dropdown-item fz-14 font-rubik c-pointer"
                  data-bs-toggle="modal"
                  data-bs-target="#myModalSingle"
                  onClick={props.edit}
                >
                  Editar
                </span>
              </li>
              <li>
                <span className="dropdown-item fz-14 font-rubik text-prop-danger c-pointer" onClick={props.destroy}>
                  Excluir
                </span>
              </li>
            </ul>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export function CardItemModelActionNew(props) {
  return (
    <>
      <div
        className="bg-white d-flex justify-content-center align-items-center col-4 col-lg-2"
        style={{ borderRadius: "0px 10px 10px 0px", borderLeft: "1px solid #D1D5E6" }}
      >
        <span
          className="p-2 fz-14 font-rubik c-pointer"
          data-bs-toggle="modal"
          data-bs-target="#myModalSingle"
          onClick={props.edit}
        >
          <MaterialIcon icon="edit" />
        </span>
        <span className="p-2 fz-14 font-rubik text-prop-danger c-pointer" onClick={props.destroy}>
          Excluir
        </span>
      </div>
    </>
  );
}
