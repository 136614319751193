import React from 'react';
import { Drawer as MUIDrawer } from '@material-ui/core';


export function Drawer(props) {
    return (
        <div>
            <MUIDrawer
                anchor='right'
                open={props.show}
                onOpen={props.onOpen}
                onClose={props.onClose}
            >
                {props.children}
            </MUIDrawer>
        </div>
    );
}
