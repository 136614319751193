import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import {
  List,
  ListBody,
  ListBodyItem,
  ListBodyItemAction,
  ListHeaderItemSortAble,
  ListHeader,
  ListBodyItemImageAndName,
  ListBodyItemStatus,
} from "../../../components/List";
import ModalUserAgency from "../../../components/_modals/_agency/UserAgency";
import AddButton from "../../../components/AddButton";
import api from "../../../config/api";
// import io from "socket.io-client";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import EmptyList from "../../../components/_empty/list";
import Loading from "../../../components/Load";
import { Drawer } from "../../../components/Drawer";
// import ModalDepartament from "../../../components/_modals/_agency/Departament";
import { toastsMsg } from "../../../components/ToasterMsg";
require("dotenv").config();

function UserAgency() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [file, setFile] = useState("");
  const [fileUpload, setFileUpload] = useState("");
  const [newImage, setNewImage] = useState("");
  const [id, setId] = useState("");
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("active");
  const [departamentFilter, setDepartamentFilter] = useState("");
  const [departamentsInUser, setDepartamentsInUser] = useState([]);
  const [sort, setSort] = useState("");
  const [departaments, setDepartaments] = useState([]);
  const [departament, setDepartament] = useState("");
  const [drawerOpenAddDepartment, setDrawerOpenAddDepartment] = useState(false);
  const [drawerOpenAddUserAgency, setDrawerOpenAddUserAgency] = useState(false);

  useEffect(() => {
    getUsers(page);
    getDepartaments();
    getDepartamentsInUser();
  }, [page]);

  const getUsers = async (mypage = page, filterName = "", filterStatus = statusFilter, filterDepartament = "", sortBy = "createdAt", order = "desc") => {
    setLoading(true);
    const response = await api.get(
      `/user/agency?paginate=true&page=${mypage}&filterName=${filterName}&filterStatus=${filterStatus}&filterDepartament=${filterDepartament}&order=${order}&sortBy=${sortBy}`
    );
    const { docs = [], ...arr } = response.data;
    setUsers(docs);
    setPages(parseInt(arr.pages));
    setLoading(false);
  };

  const getDepartaments = async () => {
    const response = await api.get(`/departament?limit=9999&sortBy=name&order=ASC`);
    setDepartaments(response.data);
  };
  const getDepartamentsInUser = async () => {
    const response = await api.get(`/user-in-departament?limit=9999&sortBy=name&order=ASC`);
    setDepartamentsInUser(response.data);
  };

  const nextPage = () => {
    getUsers(page + 1);
    setPage(page === pages ? page : page + 1);
  };

  const backPage = () => {
    getUsers(page - 1);
    setPage(page === 1 ? page : page - 1);
  };

  const destroy = async (item) => {
    swal({
      title: "Tem certeza que deseja excluir esse usuário?",
      text: `Esse usuário será apagado e não terá mais volta`,
      // icon: "warning",
      buttons: ["Cancelar", "EXCLUIR"],
      dangerMode: true,
    }).then(async (res) => {
      if (res) {
        await api
          .delete(`/user/${item._id}`)
          .then(async () => {
            await getUsers(page);
            toastsMsg({
              icon: "success",
              title: "Sucesso!",
              text: "Usuário removido com sucesso.",
            });
          })
          .catch(() => {
            toastsMsg({
              icon: "error",
              title: "Erro!",
              text: "Erro ao remover o usuário, tente novamente mais tarde.",
            });
          });
      }
    });
  };

  const edit = async (item) => {
    setType("edit");
    setDrawerOpenAddUserAgency(true);
    setFirstName(item.firstName);
    setLastName(item.lastName);
    setStatus(item.status);
    setId(item._id);
    setFileUpload("");
    setNewImage("");
    setFile(item.avatar);
    setEmail(item.email);
    setDepartament(item.departament === undefined || item.departament === null ? "" : item.departament._id);
  };

  const handleImageChange = async (e) => {
    setFileUpload(e.target.files[0]);
    setNewImage(URL.createObjectURL(e.target.files[0]));
    e.target.value = "";
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    if (password === confirm_password) {
      const dataup = new FormData();
      if (fileUpload !== "") {
        dataup.append("file", fileUpload);
      }

      dataup.append("firstName", firstName);
      dataup.append("lastName", lastName);
      dataup.append("email", email);
      if (status === "") {
        status = "active";
      }
      dataup.append("departament", departament);
      dataup.append("status", status);
      dataup.append("password", password);
      dataup.append("role", "agency");
      //console.log(dataup.get("firstName"));

      await api
        .put(`/user/${id}`, dataup)
        .then(() => {
          setLoad(false);
          toastsMsg("Sucesso!", "Usuário editado com sucesso!", "success");
          setDrawerOpenAddUserAgency(false);
          setFileUpload("");
          setNewImage("");
          getUsers(page);
        })
        .catch((error) => {
          setLoad(false);
          if (error.response.data.error === "User Already Exists") {
            toastsMsg("Erro!", "Esse e-mail já está sendo usado, por favor utilize outro", "error");
          } else {
            toastsMsg("Erro!", "Erro ao cadastrar o colaborador, tente novamente mais tarde.", "error");
          }
        });
    } else {
      setLoad(false);
      toastsMsg("Erro!", "Senha e Confirmar Senha não estão iguais, por favor insira informações corretamente.", "error");
    }
  };

  const filterChangeHandlerName = (event) => {
    setNameFilter(event.target.value);
    getUsers(page, event.target.value, statusFilter, departamentFilter);
  };

  const filterChangeHandlerStatus = (event) => {
    setStatusFilter(event.target.value);
    getUsers(1, nameFilter, event.target.value, departamentFilter);
  };

  // const filterChangeHandlerDepartament = (event) => {
  //   setDepartamentFilter(event.target.value);
  //   getUsers(page, nameFilter, statusFilter, event.target.value);
  // };

  const sortByNameChangeHandler = (sortBy) => {
    if (sort !== sortBy) {
      getUsers(page, nameFilter, statusFilter, sortBy, "asc");
      setSort(sortBy);
    } else {
      getUsers(page, nameFilter, statusFilter);
      setSort("");
    }
  };

  const addUserAgency = () => {
    setType("add");
    setDrawerOpenAddUserAgency(true);
  };
  const changePage = (event) => {
    const changePage = event.target.id;
    setPage(parseInt(changePage));
    getUsers(changePage, nameFilter, statusFilter);
  };

  return (
    <>
      <Layout defaultMenu={21} defaultSubMenu={202} title={"Colaboradores"}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-4 mt-2 me-auto">
              <div className="input-group input-search">
                <input
                  type="text"
                  className="form-control border-0 fz-14 box-shadow-none text-prop-grey-light font-rubik"
                  onChange={(event) => filterChangeHandlerName(event)}
                  placeholder="O que você está buscando?"
                />
                <span className="input-group-text bg-white border-0">
                  <i className="bi bi-search text-prop-grey-light"></i>
                </span>
              </div>
            </div>
            <AddButton size={6} sizeLg={3} onClick={() => addUserAgency()} modalId={"#myModalSingle"} text={"Adicionar Colaborador"} />
            <div className="col-12 col-lg-2 mt-2">
              <select className="filter-select fz-12 text-prop-grey-light font-rubik" onChange={(event) => filterChangeHandlerStatus(event)} required>
                <option value="active" key={1}>
                  Ativo
                </option>
                <option value="inactive" key={2}>
                  Inativo
                </option>
              </select>
            </div>
            <div className="col-12 mb-4 table-border-default">
              <List
                title="colaboradores"
                paginate={true}
                page={page}
                pages={pages}
                backPage={() => backPage()}
                nextPage={() => nextPage()}
                changePage={changePage}
              >
                <ListHeader>
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("firstName")} name="Colaboradores" sizeLg={3} />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("status")} name="Status" sizeLg={3} />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("departament")} name="Departamento" sizeLg={3} />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("createdAt")} name="Adicionado em" sizeLg={3} />
                </ListHeader>
                {users.map((item, index) => (
                  <ListBody key={index + item.firstName} specialClass="d-flex flex-wrap">
                    <ListBodyItemImageAndName value={`${item.firstName} ${item.lastName}`} sizeLg={3} img={item.avatar} />
                    <ListBodyItemStatus title="Status" value={item.status === "active" ? "Ativo" : "Inativo"} sizeLg={3} />
                    <ListBodyItem
                      title="Departamento"
                      value={item.departament === null || item.departament === undefined ? "Não informado" : item.departament.name}
                      sizeLg={3}
                    />
                    <ListBodyItem title="Adicionado em" value={new Date(item.createdAt).toLocaleDateString()} sizeLg={2} />
                    <ListBodyItemAction sizeLg={1}>
                      <li>
                        <span
                          className="dropdown-item fz-14 font-rubik c-pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#myModalSingle"
                          onClick={() => edit(item)}
                        >
                          Editar
                        </span>
                      </li>
                      <li>
                        <span className="dropdown-item fz-14 font-rubik text-prop-danger c-pointer" onClick={() => destroy(item)}>
                          Excluir
                        </span>
                      </li>
                    </ListBodyItemAction>
                  </ListBody>
                ))}
                {users.length === 0 ? <EmptyList icon="indiferente" message="Não foi encontrado nenhum colaborador" /> : <></>}
              </List>
            </div>
          </div>
        </div>
      </Layout>
      <Drawer show={drawerOpenAddUserAgency} onClose={() => setDrawerOpenAddUserAgency(false)} onOpen={() => setDrawerOpenAddUserAgency(true)}>
        <div className="p-3 modal-drawer">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              {type === "add" ? (
                <>
                  <div className="modal-header border-0">
                    <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalLabel">
                      Adicionar colaborador
                    </h5>
                    <button type="button" id="btnClose" className="btn-close" onClick={() => setDrawerOpenAddUserAgency(false)} aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <ModalUserAgency close={() => setDrawerOpenAddUserAgency(false)} />
                  </div>
                </>
              ) : (
                <>
                  <div className="modal-header border-0">
                    <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                      Editar colaborador
                    </h5>
                    <button
                      type="button"
                      id="btnCloseSingle"
                      className="btn-close"
                      onClick={() => setDrawerOpenAddUserAgency(false)}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <form name="formmodal" novalidate>
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                          <div
                            className="d-flex align-items-center justify-content-center position-relative rounded-circle"
                            style={{
                              width: "150px",
                              height: "150px",
                              border: "1px solid #626679",
                            }}
                          >
                            <img
                              alt=""
                              src={fileUpload !== "" ? newImage : file}
                              className="img-fluid rounded-circle"
                              style={{
                                height: "150px",
                                width: "150px",
                                border: "1px solid #626679",
                              }}
                            />
                            <label
                              htmlFor="file"
                              className="p-2 rounded-circle d-flex align-items-center justify-content-center"
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                right: 0,
                                bottom: "10px",
                                backgroundColor: "#22A6AC",
                              }}
                            >
                              <FontAwesomeIcon color="white" icon={faPencilAlt} />
                              <input type="file" id="file" className="d-none" onChange={handleImageChange} />
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Nome</label>
                          <input type="text" className="form-control" value={firstName} onChange={(event) => setFirstName(event.target.value)} required />
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Sobrenome</label>
                          <input type="text" className="form-control" value={lastName} onChange={(event) => setLastName(event.target.value)} required />
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Status</label>
                          <select className="form-select" value={status} onChange={(event) => setStatus(event.target.value)} required>
                            <option></option>
                            <option value="active">Ativo</option>
                            <option value="inactive">Inativo</option>
                          </select>
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Departamento</label>
                          <select className="form-select" value={departament} onChange={(event) => setDepartament(event.target.value)} required>
                            <option></option>
                            {departaments.map((item, index) => (
                              <option value={item._id} key={index + item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">E-mail</label>
                          <input type="email" className="form-control" value={email} onChange={(event) => setEmail(event.target.value)} required />
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Senha</label>
                          <input type="password" className="form-control" onChange={(event) => setPassword(event.target.value)} required />
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Confirmar Senha</label>
                          <input type="password" className="form-control" onChange={(event) => setConfirmPassword(event.target.value)} required />
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-12 d-flex align-items-center justify-content-end">
                          <button
                            type="button"
                            className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-28"
                            onClick={() => setDrawerOpenAddUserAgency(false)}
                          >
                            Cancelar
                          </button>
                          {load ? (
                            <button
                              type="button"
                              className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                              disabled
                            >
                              <div className="spinner-border spinner-border-sm text-light" role="status">
                                <span className="visually-hidden">Carregando...</span>
                              </div>
                            </button>
                          ) : (
                            <button
                              type="submit"
                              onClick={save}
                              className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                            >
                              Salvar
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Drawer>

      <Loading show={loading} />
    </>
  );
}

export default UserAgency;
