import styled from 'styled-components';

export const SideLayout = styled.div`
.logo-menu{
  position: absolute;
    width: 20px;
    left: 45px;
    top: 15px;
    transition: all 0.6s ;
    transform: rotate(180deg);
}
.collapse{
  a{
    /* margin-left: 22px;
    margin-bottom: 10px; */
  }
  .active{
    span{
      color: #fff;
    }
  }
}
    #sidebar {
        min-width: 70px;
        max-width: 70px;
        height: 100vh;
        background: #102A43;
        color: #fff;
        transition: all 0.6s ;
        transform-origin: center left;
        box-shadow: 0px 0px 10px #00000019;
        overflow: hidden;
        a{
          color: #577694;
          text-decoration: none;
          display: flex;
          align-items: center;
          align-content: center;
          align-self: baseline;
          font-weight: 600;
          i{
            color:#577694;
            display: none;
          }
          span{
            margin-left: 15px;
            display: none;
            font-size: 14px;
            font-weight: normal;
            font-family: 'Rubik', sans-serif;
          }
        }
        .card-header{
          background: transparent;
          border-bottom: none;
        }

        .logo-2{
            display: none;
            transition: all 0.6s ;
        }

        .accordion{
            min-width: 100%;
            div{
              margin-inline: auto;
              .card-header{
                padding: 0;
                a{
                  padding-left: 20px;
                }
                .line{
                  width: 85%;
                  margin-top: 16px;
                  min-height: 2px;
                  height: 2px;
                  background-color: #fff;
                  border: 1px solid #577694;
                  opacity: 0.1;
                }
              }
              min-height: 45px;
            }
            #dropdown_project[aria-expanded=true]  {
              i {
                transform: rotate(180deg);
              }
            }  
            #dropdown[aria-expanded=true] {
              i {
                transform: rotate(180deg);
              }
            }
        }

        &.active {
            min-width: 280px;
            max-width: 280px;
            overflow: hidden;
            transition: all 0.6s;

            .logo-2{
                display: block;
            }

            .accordion{
                min-width: auto;
            }
            .card-body{
              a.active{
                background-color: rgba(87,118,148,0.3);
                border-radius: 10px;
                padding: 10px 0 10px 0;
              }
              span{
                margin-left: 44px;
              }
            }
            span{
              display: block;
            }
            .logo-menu{
              left: 255px;
              transform: rotate(0deg);
            }
            i{
              display: block;
            }
            #sidebar:hover{
              .logo-menu{
                position: absolute;
                  width: 20px;
                  left: 45px;
                  top: 15px;
                  display: none
              }
            }
        }

    .active {
        .logo-menu{
          transform: rotate(0deg);
        }
        .card-header{
          a, i{
          color: #fff;
          margin-left: -2px;
        }
        }
        
    }

    .submenu{
      padding: 15px;
      .headside{
        /* border-bottom: solid 2px #F2F2F2; */
        a{
          color: #BBBBBB;
          text-decoration: none;
          
        }
      }
      .side{
        a{
          border-bottom: solid 2px #F2F2F2;
          text-decoration: none;
          padding-top: 20px;
          padding-bottom: 20px;
          p{
            padding-left: 30px;
            margin-bottom: 0;
          }
        }
      }
    }
    .active{.card-header{ a{border-left: solid 4px #fff !important;background: transparent !important;border-radius: 0;}}}
    

    .card {
        background: transparent;
        border-color: transparent;
        border-bottom: solid 1px #adadad59;
        border-radius: 0;
        border-bottom: none;
        color: inherit;

        .card-header {
            background: transparent;
            padding: 0;
            border-bottom: none;
            border-color: transparent;
            transition: 0.7s;

            &:hover {
            background: #fff !important;
                a, i {
                        /*color: inherit !important;*/
                        transition: 0.6s;
                }
            }

        a {
          user-select: none;
          font-size: 14px;
          color: #333;
          padding: 0.75rem 1.25rem;
          display: flex;
          align-items: center;
          text-decoration: none;

          i {
            color: #333;
            font-size: 24px;
          }
        }
      }

      .card-body {
        padding: 0;

        a {
          user-select: none;
          font-size: 14px;
          font-weight: normal;
          font-family: 'Rubik', sans-serif;
          color: rgba(0, 0, 0, 0.5);
          padding: 0.25rem 1.25rem;
          display: flex;
          text-decoration: none;
          i {
            color: rgba(0, 0, 0, 0.5);
            font-size: 24px;
          }

          &:hover {
            color: rgba(10, 141, 211, 1) !important;
            transition: 0.6s;

            i {
              color: rgba(10, 141, 211, 1) !important;
              transition: 0.6s;
            }
          }
        }
      }
    }
    .card-drop{
        margin-left: 20px;
      }
  }

  @media (max-width: 997px) {
    #sidebar {
      &.active {
        .logo-menu{
          display:none;
        }
      }
    }
    .logo-menu{
      display:none;
    }
  }

  @media (max-width: 768px) {
    #sidebar {
      margin-left: -250px;
      transform: rotateY(100deg);

      &.active {
        margin-left: 0;
        transform: none;
        min-width: 80vw;
        max-width: 80vw;
        .logo-menu{
          display:none;
        }
      }
    }
    .card-drop{
      max-height: 103px;
      overflow: scroll;
      overflow-x: hidden;
    }
    .logo-menu{
      display:none;
    }
  }

  /* Navbar */

  .navbar {
    background: rgb(41, 43, 48);
    padding: 0.5rem 1.25rem;
    border-bottom: solid rgb(41, 43, 48);

    .navbar-text {
      color: rgba(255, 255, 255, 0.5) !important;
      padding-top: 0;
      padding-bottom: 0.3rem;
    }
    .navbar-brand {
      padding: 0;
      span {
        cursor: pointer;
        user-select: none;
      }
      i {
        color: rgba(255, 255, 255, 0.5) !important;
      }
    }
  }
`;
