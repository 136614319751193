import React from 'react';
import { Link } from 'react-router-dom';
import MaterialIcon from 'material-icons-react';

function WidgetClient(props) {

    return (
        <div className="row mb-4">
            <div className="d-flex flex-column col-12">
                <div className="text-prop-grey-dark">
                    <Link to={props.link} className="d-flex align-items-center justify-content-start flex-row fz-14" style={{ color: 'inherit', textDecoration: 'none' }}>
                        <MaterialIcon icon="chevron_left" color="#3F4254" />
                        <span>Voltar</span>
                    </Link>
                </div>
                <span className="px-2 font-rubik fw-bold fz-28 text-prop-grey-dark mb-0">{props.title}</span>
            </div>
        </div>
    );
}

export default WidgetClient;