import React, { useState } from "react";
import api from "../../../../config/api";
// import swal from "sweetalert";
import { toastsMsg } from "../../../ToasterMsg";

function ModalPlatform(props) {
  const [name, setName] = useState("");
  const [load, setLoad] = useState(false);
  const [saveType, setSaveType] = useState("");

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    await api
      .post(`/platform`, { name })
      .then(() => {
        setLoad(false);
        toastsMsg("Sucesso!", "Plataforma cadastrada com sucesso!", "success");
        document.getElementById("btnClearPlatform").click();
        if (saveType === "save") {
          props.close();
        }
      })
      .catch(() => {
        setLoad(false);
        toastsMsg("Erro!", "Erro ao cadastrar a plataforma, tente novamente mais tarde.", "error");
      });
  };

  return (
    <>
      <form onSubmit={save}>
        <div className="row">
          <div className="col-12 col-lg-12 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Nome</label>
            <input type="text" className="form-control" onChange={(event) => setName(event.target.value)} required />
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 d-flex align-items-center justify-content-end">
            <button type="reset" className="d-none" id="btnClearPlatform">
              Reset
            </button>
            <button
              type="button"
              className="btn text-danger px-lg-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-25"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
            {load ? (
              <button
                type="button"
                className="btn btn-prop-blue px-lg-5 py-2 text-white fz-13 text-uppercase font-rubik w-25"
                disabled
              >
                <div className="spinner-border spinner-border-sm text-light" role="status">
                  <span className="visually-hidden">Carregando...</span>
                </div>
              </button>
            ) : (
              <>
                <button
                  type="submit"
                  onClick={() => setSaveType("saveMore")}
                  className="px-lg-2 py-2 save-close-button fz-13 text-uppercase font-rubik w-28 mx-2"
                >
                  SALVAR E CRIAR OUTRA
                </button>
                <button
                  type="submit"
                  onClick={() => setSaveType("save")}
                  style={{ borderRadius: "10px" }}
                  className="btn btn-prop-blue px-lg-2 py-2 text-white fz-13 text-uppercase font-rubik w-25"
                >
                  SALVAR
                </button>
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

export default ModalPlatform;
