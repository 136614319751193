import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { decodeToken } from "../../config/auth";
import otherApps from "../../assets/img/icons/other-apps.svg";
import bell from "../../assets/img/icons/bell.svg";
import infinity from "../../assets/img/infinity-img.png";
import egate from "../../assets/img/egate-img.png";
import qbr from "../../assets/img/qbr-img.png";
import avatar from "../../assets/img/avatar.png";
import logoutImg from "../../assets/img/icons/logout.png";
import profile from "../../assets/img/icons/profile-icon.png";
import WidgetClient from "../../components/_widgets/WidgetClient";
import WidgetPath from "../../components/_widgets/WidgetPath";
import expand from "../../assets/img/icons/v.svg";
import api from "../../config/api";
import WidgetTitleDefault from "../_widgets/WidgetTitleDefault";
import { ToastMsg } from "../ToasterMsg";

function Layout(props) {
  const [user, setUser] = useState({});
  const [realUser, setRealUser] = useState({});
  const [agency, setAgency] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  const [loading, setLoading] = useState(true);

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  useEffect(() => {
    const { user: session = {} } = decodeToken();
    setUser(session);

    getRealUser(session);
    getAgencies();
  }, []);

  const getAgencies = async () => {
    try {
      const response = await api.get(`/agency/`);
      const dataArray = [];
      response.data.map((agency) => {
        dataArray.push({ key: agency._id, label: agency.name });
      });
      setAgency(dataArray);
    } catch (error) {
      console.log(error);
    }
  };

  const getValue = async (agencyId) => {
    await api
      .get(`/dashboard/${agencyId}`)
      .then((response) => {
        const token = response.data.token;
        localStorage.setItem("TOKEN_KEY", token);
      })
      .then(() => {
        window.location.reload(false);
      });
  };

  const getAdm = async (userId) => {
    await api
      .get(`/dashboard-rollback/${userId}`)
      .then((response) => {
        const token = response.data.token;
        localStorage.setItem("TOKEN_KEY", token);
      })
      .then(() => {
        window.location.reload(false);
      });
  };

  const getRealUser = async (user) => {
    try {
      const response = await api.get(`/profile/${user._id}`);
      setRealUser(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  if (loading !== true) {
    return (
      <>
        <ToastMsg />
        <div className="wrapper d-flex h-100 overflow-hidden">
          <div className="d-flex flex-row w-100">
            <Sidebar sidebar={sidebar} defaultMenu={props.defaultMenu} defaultSubMenu={props.defaultSubMenu} />
            <div className="d-flex align-items-center flex-column w-100 page-container mt-3">
              <div className="w-100 mx-auto overflow-auto h-100 pe-4">
                {(props.isSingleProject !== undefined) & (props.isSingleProject === true) ? (
                  <WidgetPath
                    className="mt-2 ps-3"
                    projectName={props.projectName}
                    path={[
                      {
                        name: "Projetos",
                        link: "",
                      },
                      {
                        name: "Projetos em andamento",
                        link: "/projetos-em-andamento",
                      },
                    ]}
                  />
                ) : (
                  <></>
                )}
                <nav
                  className={`navbar navbar-expand-lg navbar-light bg-light  mb-1 mt-0 
                            ${
                              (props.isSingleProject !== undefined) & (props.isSingleProject === true)
                                ? "mt-lg-0"
                                : "mt-lg-2"
                            } `}
                >
                  <div className="container-fluid d-flex bd-highlight">
                    <span className="navbar-brand d-flex d-lg-none" onClick={() => toggleSidebar()}>
                      <FontAwesomeIcon icon={faBars} className="text-prop-grey-light ms-1 mt-2" />
                    </span>

                    {props.isSingleProject === undefined || props.isSingleProject === false ? (
                      <>
                        {window.location.pathname === "/dashboard" ? (
                          <span className="navbar-brand mt-3 mt-lg-0 me-0 me-auto p-2 bd-highlight">
                            <h1 className="fz-24 font-rubik text-prop-dark-blue fw-bold ">
                              Olá {user.firstName},{" "}
                              <span className="fz-16 d-lg-block fw-normal text-prop-wild-blue">
                                que bom ver você aqui novamente!
                              </span>
                            </h1>
                          </span>
                        ) : (
                          <WidgetTitleDefault title={props.title} subTitle={props.subTitle} />
                        )}
                      </>
                    ) : (
                      <WidgetClient
                        specialClass="navbar-brand mt-3 mt-lg-0 me-0 me-auto p-2 bd-highlight"
                        sizeLg={user.role === "client" ? 12 : "auto"}
                        link={
                          user.role === "company"
                            ? "/empresa-projetos-em-andamento"
                            : user.role === "agency"
                            ? "/projetos-em-andamento"
                            : user.role === "category"
                            ? "/projetos-da-categoria"
                            : "/meus-projetos"
                        }
                        img={props.clientImg}
                        clientName={props.clientName}
                        projectCategory={props.projectCategory}
                      />
                    )}
                    {user.role !== "client" ? (
                      <>
                        {realUser.role === "company" &&
                        (window.location.pathname === "/dashboard" ||
                          window.location.pathname === "/todas-empresas") ? (
                          <>
                            {user.role === "company" ? (
                              <>
                                <div class="d-flex align-items-center w-auto p-3 div-select-agency">
                                  {/* <img src={store} width={24} height={24} className="img-fluid icon-not-rotate" alt={``} class="store-icon"/> */}
                                  <select
                                    class="form-select p-2 bd-highlight select-agency"
                                    aria-label="Default select example"
                                    onChange={(e) => getValue(e.target.value)}
                                  >
                                    <option value="">Todas as empresas</option>
                                    {agency.map((each) => (
                                      <option value={each.key}>{each.label}</option>
                                    ))}
                                  </select>
                                </div>
                              </>
                            ) : (
                              <>
                                {/* <img src={store} width={24} height={24} className="img-fluid icon-not-rotate" alt={``} class="store-icon"/> */}
                                <select
                                  class="form-select p-2 bd-highlight select-agency"
                                  aria-label="Default select example"
                                  onChange={(e) => getAdm(e.target.value)}
                                >
                                  <option value="">Todas as empresas</option>
                                  <option value={user._id}>Visão Geral</option>
                                </select>
                              </>
                            )}{" "}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {user.role !== "client" ? (
                      <>
                        <div className="icon-button btn-group ms-0 ms-lg-4 bg-prop-azureish d-flex align-items-center order-1 order-md-1 order-lg-2 order-xl-2">
                          <button
                            type="button"
                            className="btn btn-secondary dropdown-toggle dropdown-toggle-split p-0 box-shadow-none"
                            id="dropdownOtherApps"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-bs-reference="parent"
                          >
                            <img
                              src={otherApps}
                              width={24}
                              height={24}
                              className="img-fluid icon-not-rotate"
                              alt={``}
                            />
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuReference"
                            style={{ right: "0px", left: "auto" }}
                          >
                            <li className="dropdown-header" style={{ borderBottom: "1px solid #EAEFF4" }}>
                              <label className=" fz-16 font-rubik fw-bold text-prop-dark-eletric-blue">
                                Outros aplicativos
                              </label>
                            </li>
                            <li>
                              <a
                                target="blank"
                                className="dropdown-item fz-14 font-rubik text-prop-grey-light c-pointer d-flex align-items-center me-5"
                                href={"https://infinity.app.br/dashboard"}
                              >
                                <img src={infinity} alt="" />
                                <div className="ms-2 c-pointer">
                                  <label className="d-block fz-14 font-rubik text-prop-dark-blue fw-bold">
                                    Infinity
                                  </label>
                                  <label className="fz-12 font-rubik text-prop-dark-eletric-blue">
                                    Sua empresa em um único acesso
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                target="blank"
                                className="dropdown-item fz-14 font-rubik text-prop-grey-light c-pointer d-flex align-items-center"
                                href={"https://infinity.app.br/egate"}
                              >
                                <img src={egate} alt="" />
                                <div className="ms-2 c-pointer">
                                  <label className="d-block fz-14 font-rubik text-prop-dark-blue fw-bold">E-Gate</label>
                                  <label className="fz-12 font-rubik text-prop-dark-eletric-blue">
                                    Revisão mídias sociais e SEO
                                  </label>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                target="blank"
                                className="dropdown-item fz-14 font-rubik text-prop-grey-light c-pointer d-flex align-items-center"
                                href={"https://infinity.app.br/qbr"}
                              >
                                <img src={qbr} alt="" />
                                <div className="ms-2 c-pointer">
                                  <label className="d-block fz-14 font-rubik text-prop-dark-blue fw-bold">QBR</label>
                                  <label className="fz-12 font-rubik text-prop-dark-eletric-blue">
                                    Revisão de qualidade
                                  </label>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="icon-button btn-group ms-3 bg-prop-azureish d-flex align-items-center order-2 order-md-2 order-lg-3 order-xl-3">
                          <button
                            type="button"
                            className="btn btn-secondary dropdown-toggle dropdown-toggle-split p-0 box-shadow-none"
                            id="dropdownOtherApps"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-bs-reference="parent"
                          >
                            <img src={bell} width={24} height={24} className="img-fluid icon-not-rotate" alt={``} />
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuReference"
                            style={{ right: "0px", left: "auto" }}
                          >
                            <li className="dropdown-header" style={{ borderBottom: "1px solid #EAEFF4" }}>
                              <label className=" fz-16 font-rubik fw-bold text-prop-dark-eletric-blue">
                                Notificações
                              </label>
                            </li>
                            {/* <div className='notification-scroll'>
                                                        <NotificationItem
                                                            user="Bruna Garibaldi"
                                                            action="respondeu seu comentário na tarefa: Revisão front end"
                                                            time="26 JAN 2022 11:01"
                                                        />
                                                        <NotificationItem
                                                            user="Bruna Garibaldi"
                                                            action="respondeu seu comentário na tarefa: Revisão front end"
                                                            time="26 JAN 2022 11:01"
                                                        />
                                                        <NotificationItem
                                                            user="Bruna Garibaldi"
                                                            action="respondeu seu comentário na tarefa: Revisão front end"
                                                            time="26 JAN 2022 11:01"
                                                        />
                                                        <NotificationItem
                                                            user="Bruna Garibaldi"
                                                            action="respondeu seu comentário na tarefa: Revisão front end"
                                                            time="26 JAN 2022 11:01"
                                                        />
                                                    </div> */}
                            <li
                              className="dropdown-header d-flex justify-content-end pt-3"
                              style={{ borderTop: "1px solid #EAEFF4" }}
                            >
                              <button className="fz-14 white-outline-button font-rubik me-2">Marcar como lidas</button>
                              <button className="fz-14 red-button font-rubik ">Limpar tudo</button>
                            </li>
                          </ul>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="btn-group ms-0 ms-lg-2 ms-auto order-3 order-md-3 order-lg-4 order-xl-4">
                      <a className="btn" href={"/perfil"}>
                        <div className="d-flex align-items-center">
                          <img
                            src={user.avatar}
                            width={40}
                            height={40}
                            style={{ borderRadius: "4px" }}
                            className="img-fluid me-2 avatar"
                            alt={`${user.firstName} ${user.lastName}`}
                          />
                          <div className="d-flex align-items-start flex-column">
                            <span className="font-rubik fz-14 text-prop-grey-dark fw-bold">{`${user.firstName} ${user.lastName}`}</span>
                            <span className="font-rubik fz-12 text-prop-grey-light">
                              {user.role === "company"
                                ? "Administrador"
                                : user.role === "agency"
                                ? user.agency.name
                                : user.role === "category"
                                ? user.category.name
                                : "Cliente"}
                            </span>
                          </div>
                        </div>
                      </a>
                      <button
                        type="button"
                        className="btn btn-secondary dropdown-toggle dropdown-toggle-split p-0 box-shadow-none"
                        id="dropdownMenuReference"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-reference="parent"
                      >
                        <span className="material-icons text-prop-grey-dark icon-rotate">
                          <img src={expand} />
                        </span>
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuReference"
                        style={{
                          borderRadius: "10px",
                          right: "0",
                          left: "43px",
                        }}
                      >
                        <li>
                          <a className="dropdown-item fz-14 font-rubik text-prop-dark-blue c-pointer" href={"/perfil"}>
                            <img className="me-2" src={profile} alt="" />
                            Meu Perfil
                          </a>
                        </li>
                        <li>
                          <span
                            className="dropdown-item fz-14 font-rubik text-prop-firebrick c-pointer"
                            onClick={() => logout()}
                          >
                            <img className="me-2" src={logoutImg} alt="" />
                            Sair
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
}

function NotificationItem(props) {
  return (
    <a
      target="blank"
      style={{ paddingTop: "8px", paddingBottom: "8px" }}
      className="dropdown-item fz-14 font-rubik text-prop-grey-light c-pointer d-flex align-items-start"
      href={"https://infinity.app.br/dashboard"}
    >
      <img src={avatar} width={40} height={40} alt="" />
      <div className="ms-2 d-flex flex-column c-pointer" style={{ width: "307px", whiteSpace: "normal" }}>
        <label className=" fz-14 font-rubik text-prop-dark-jungle-green fw-bold">
          {props.user}
          <span className="ms-1 fw-normal">{props.action}</span>
        </label>
        <label className="d-block mt-1 fz-12 font-rubik text-prop-weldon-blue">{props.time}</label>
      </div>
    </a>
  );
}

export default Layout;
