import React from 'react';


export default function WidgetLateSprint(props) {

    return (

        <div className={"card shadow-sm p-3 card-sprints font-rubik "} style={{ borderRadius: "5px", border: " 2px solid #D9E2EC", boxShadow: "0px 4px 8px #CBCBCB17" }}>
            <span className='font-rubik fw-bold fz-16 text-prop-dark-eletric-blue d-block mb-1' >{props.projectName}</span>
            <span className='font-rubik fz-12 text-prop-dark-eletric-blue d-block mb-3' >{props.sprintName}</span>
            <div className='d-flex justify-content-between mb-3'>
                <span className={`font-rubik fw-bold fz-14 ${(80 > 0) ? "text-prop-firebrick-2" : "text-prop-yellow-red"}`}>{"Faltam " + props.days + " dias"}</span>
                <span className={`font-rubik fw-bold fz-14 text-prop-dark-eletric-blue`}>{props.responsible}</span>
            </div>
            <div className='d-flex justify-content-between mb-1'>
                <span className={`font-rubik fz-12 text-prop-dark-blue`}>{`${props.taskClosed}/${props.totalTask} tarefas`}</span>
                <span className={`font-rubik fz-12 text-prop-dark-blue`}>{`${props.projectProgress}%`}</span>
            </div>
            <div className="col-12">
                <div className="progress rounded" style={{ height: 6 }}>
                    <div className={`progress-bar ${props.bgAlertClass}`} role="progressbar" style={{ width: `${props.projectProgress}%`, borderRadius: "8px" }} aria-valuenow={props.projectProgress} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
        </div>
    );
}
