import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import {
  List,
  ListBody,
  ListBodyItem,
  ListHeaderItemSortAble,
  ListBodyItemStatusEmoji,
  ListHeader,
} from "../../../components/List";
import api from "../../../config/api";
// import io from "socket.io-client";
import Loading from "../../../components/Load";
import EmptyList from "../../../components/_empty/list";
import { formatDate } from "../../../config/utils";
require("dotenv").config();

function Report() {
  const [reports, setReports] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [statusFilter, setStatusFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  let [view, setView] = useState({});
  const [sort, setSort] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getReports(page);
    // async function registerToSocket() {
    //     const socket = io(`${process.env.REACT_APP_API_URL}`);
    //     socket.on("report", () => {
    //         getReports(page);
    //     });
    // }
    // registerToSocket();
  }, [page]);

  const getReports = async (
    mypage = 1,
    filterName = "",
    filterStatus = "",
    sortBy = "createdAt",
    order = "desc"
  ) => {
    setLoading(true);
    const response = await api.get(
      `/report-category?paginate=true&page=${mypage}&filterName=${filterName}&filterStatus=${filterStatus}&order=${order}&sortBy=${sortBy}`
    );
    const { docs = [], ...arr } = response.data;
    setReports(docs);
    setPages(parseInt(arr.pages));
    setLoading(false);
  };

  const nextPage = () => {
    getReports(page + 1);
    setPage(page === pages ? page : page + 1);
  };

  const backPage = () => {
    getReports(page - 1);
    setPage(page === 1 ? page : page - 1);
  };

  const visually = (item) => {
    setView({
      _id: item._id,
      client: item.client,
      project: item.project,
      date: formatDate(item.date),
      subject: item.subject,
      rating: item.rating,
      comments: item.comments,
      user: item.user,
      schedule: item.schedule,
    });
  };

  const filterChangeHandlerName = (event) => {
    setNameFilter(event.target.value);
    getReports(page, event.target.value, statusFilter);
  };

  const filterChangeHandlerStatus = (event) => {
    setStatusFilter(event.target.value);
    getReports(page, nameFilter, event.target.value);
  };

  const sortByNameChangeHandler = (sortBy) => {
    if (sort !== sortBy) {
      getReports(page, nameFilter, statusFilter, sortBy, "asc");
      setSort(sortBy);
    } else {
      getReports(page, nameFilter, statusFilter);
      setSort("");
    }
  };

  return (
    <>
      <Layout defaultMenu={213}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 mb-4">
              <h1 className="mb-0 fz-35 fw-bold font-rubik">Report</h1>
            </div>
            <div className="col-12 col-lg-3 mt-2">
              <div className="input-group input-search">
                <input
                  type="text"
                  className="form-control border-0 fz-12 box-shadow-none text-prop-grey-light font-rubik"
                  onChange={(event) => filterChangeHandlerName(event)}
                  placeholder="Busca Rápida"
                />
                <span className="input-group-text bg-white border-0">
                  <i className="bi bi-search text-prop-grey-light"></i>
                </span>
              </div>
            </div>
            <div className="col-12 col-lg-3 mt-2 mb-4">
              <select
                className="filter-select fz-12 text-prop-grey-light font-rubik"
                onChange={(event) => filterChangeHandlerStatus(event)}
                required
              >
                <option value="" key={0}>
                  Selecione o status
                </option>
                <option value="Bom" key={1}>
                  Bom
                </option>
                <option value="Regular" key={2}>
                  Regular
                </option>
                <option value="Ruim" key={3}>
                  Ruim
                </option>
              </select>
            </div>
            <div className="col-12 mb-4">
              <List
                paginate={true}
                page={page}
                pages={pages}
                backPage={() => backPage()}
                nextPage={() => nextPage()}
              >
                <ListHeader>
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("subject")}
                    name="Assunto"
                    sizeLg={2}
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("project.name")}
                    name="Projeto"
                    sizeLg={2}
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("client.name")}
                    name="Cliente"
                    sizeLg={2}
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("user.firstName")}
                    name="Colaborador"
                    sizeLg={2}
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("date")}
                    name="Data"
                    sizeLg={2}
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("rating")}
                    name="Status"
                    sizeLg={2}
                  />
                </ListHeader>

                {reports.map((item, index) => (
                  <ListBody
                    key={index}
                    specialClass="card-with-hover d-flex flex-wrap"
                  >
                    <span
                      className="d-flex col-12 flex-wrap"
                      data-bs-toggle="modal"
                      data-bs-target="#myModalView"
                      onClick={() => visually(item)}
                    >
                      <ListBodyItem
                        title="Assunto"
                        value={item.subject}
                        sizeLg={2}
                      />
                      <ListBodyItem
                        title="Projeto"
                        value={item.project.name}
                        sizeLg={2}
                      />
                      <ListBodyItem
                        title="Cliente"
                        value={item.client.name}
                        sizeLg={2}
                      />
                      <ListBodyItem
                        title="Colaborador"
                        value={`${item.user.firstName} ${item.user.lastName}`}
                        sizeLg={2}
                      />
                      <ListBodyItem
                        title="Data"
                        value={formatDate(item.date)}
                        sizeLg={2}
                      />
                      <ListBodyItemStatusEmoji
                        title="Status"
                        value={item.rating}
                        sizeLg={2}
                      />
                    </span>
                  </ListBody>
                ))}
                {reports.length === 0 ? (
                  <EmptyList
                    icon="indiferente"
                    message="Não foi encontrado nenhum report"
                  />
                ) : (
                  <></>
                )}
              </List>
            </div>
          </div>
        </div>
      </Layout>
      <div
        className="modal fade border-0"
        id="myModalView"
        tabIndex="-1"
        aria-labelledby="myModalView"
        aria-hidden="true"
        style={{ borderRadius: "10px" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5
                className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark"
                id="myModalSingleLabel"
              >
                Visualizar report
              </h5>
              <button
                type="button"
                id="btnCloseSingle"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                {view.user !== undefined ? (
                  <div className="col-12 d-flex align-items-center justify-content-start">
                    <div className="col-4 col-lg-2 d-flex align-items-center justify-content-start">
                      <img
                        src={view.user.avatar}
                        alt=""
                        width={100}
                        height={100}
                        style={{ border: "1px solid #626679" }}
                        className="rounded-circle"
                      />
                    </div>
                    <div className="col-8 col-lg-10 d-flex align-items-start justify-content-start flex-column">
                      <span className="fw-bold">
                        {view.user.firstName + " " + view.user.lastName}
                      </span>
                      <span>{view.date}</span>
                      <span className="fw-bold">
                        {"Status da reunião: "}
                        <span
                          className={`${
                            view.rating === "Bom"
                              ? "fw-normal text-prop-cyan"
                              : view.rating === "Regular"
                              ? "fw-normal text-prop-warning-light"
                              : "fw-normal text-prop-danger"
                          }`}
                        >
                          {view.rating}
                        </span>
                      </span>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="col-12 col-lg-12 mb-3 mt-2">
                  <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-2">
                    Observações
                  </label>
                  <div
                    className=" card border-0 shadow p-2"
                    style={{ height: "198px", borderRadius: "10px" }}
                  >
                    <div
                      className="d-flex justify-content-start text-break scroll h-100"
                      style={{ overflowY: "auto", overflowX: "hidden" }}
                    >
                      <span className="col-12 p-2">{view.comments}</span>
                    </div>
                  </div>
                </div>
                {(view.schedule !== undefined) & (view.schedule !== " ") ? (
                  <>
                    <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-2">
                      Arquivo para download
                    </label>
                    <div className="col-12 d-flex align-items-center justify-content-start flex-wrap ">
                      <span className="col-12 col-lg-6">
                        Esse relatório possui um arquivo para download
                      </span>
                      <a
                        className="col-12 col-lg-3"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={view.schedule}
                      >
                        <button
                          className=" btn btn-prop-blue text-white font-rubik fz-16 px-3 "
                          type="button"
                        >
                          Baixar arquivo
                        </button>
                      </a>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loading show={loading} />
    </>
  );
}

export default Report;
