import React, { useEffect, useState } from "react";
import api from "../../../../config/api";
// import swal from "sweetalert";
import { toastsMsg } from "../../../ToasterMsg";

import defaultUser from "../../../../assets/img/default_avatar.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

function ModalUserAgency(props) {
  const [load, setLoad] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [departaments, setDepartaments] = useState([]);
  const [departament, setDepartament] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  let [status, setStatus] = useState("");
  const [file, setFile] = useState("");

  useEffect(() => {
    getDepartaments();
  }, []);

  const getDepartaments = async () => {
    const response = await api.get(`/departament?limit=9999&sortBy=name&order=ASC`);
    setDepartaments(response.data);
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    if (password === confirm_password) {
      const formData = new FormData();
      if (file === "") {
        formData.append(
          "avatar",
          "https://domatech-tests.s3.amazonaws.com/users/84cccb55672b0755b02ddb3568387000-default_avatar.png"
        );
        formData.append("avatarKey", "users/84cccb55672b0755b02ddb3568387000-default_avatar.png");
      } else {
        formData.append("file", file);
      }
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("email", email);
      if (status === "") {
        status = "active";
      }
      formData.append("departament", departament);
      formData.append("status", status);
      formData.append("password", password);
      formData.append("role", "agency");

      await api
        .post(`/user`, formData)
        .then(() => {
          setLoad(false);
          toastsMsg("Sucesso!", "Usuário cadastrado com sucesso!", "success");
          setFile("");
          props.close();
        })
        .catch((error) => {
          setLoad(false);
          if (error.response.data.error === "User Already Exists") {
            toastsMsg("Erro!", "Esse e-mail já está sendo usado, por favor utilize outro", "error");
          } else {
            toastsMsg("Erro!", "Erro ao cadastrar o colaborador, tente novamente mais tarde.", "error");
          }
        });
    } else {
      setLoad(false);
      toastsMsg(
        "Erro!",
        "Senha e Confirmar Senha não estão iguais, por favor insira informações corretamente.",
        "error"
      );
    }
  };

  const clean = () => {
    setFile("");
  };

  return (
    <>
      <form onSubmit={save}>
        <div className="row">
          <div className="col-12 d-flex align-items-center justify-content-center mb-4">
            <div
              className={`d-flex align-items-center justify-content-center position-relative rounded-circle ${
                file !== "" ? "avatar_user_selected" : ""
              }`}
              style={{ width: "115px", height: "115px" }}
            >
              <img
                alt=""
                src={file === "" ? defaultUser : URL.createObjectURL(file)}
                className={`img-fluid rounded-circle ${file !== "" ? "avatar_user_selected" : ""}`}
                style={{ width: "115px", height: "115px" }}
              />
              <label
                htmlFor="file"
                className="p-2 rounded-circle d-flex align-items-center justify-content-center"
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: 0,
                  bottom: "10px",
                  backgroundColor: "#22A6AC",
                }}
              >
                <FontAwesomeIcon color="white" size="sm" icon={faPencilAlt} />
                <input
                  type="file"
                  name="file"
                  id="file"
                  className="d-none"
                  onChange={(event) => setFile(event.target.files[0])}
                />
              </label>
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Nome</label>
            <input
              type="text"
              className="form-control"
              onChange={(event) => setFirstName(event.target.value)}
              required
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Sobrenome</label>
            <input
              type="text"
              className="form-control"
              onChange={(event) => setLastName(event.target.value)}
              required
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Status</label>
            <select className="form-select" onChange={(event) => setStatus(event.target.value)} required>
              <option value="active" defaultValue>
                Ativo
              </option>
              <option value="inactive">Inativo</option>
            </select>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Departamento</label>
            <select className="form-select" onChange={(event) => setDepartament(event.target.value)} required>
              <option></option>
              {departaments.map((item, index) => (
                <option value={item._id} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">E-mail</label>
            <input type="email" className="form-control" onChange={(event) => setEmail(event.target.value)} required />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Senha</label>
            <input
              type="password"
              className="form-control"
              onChange={(event) => setPassword(event.target.value)}
              required
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Confirmar Senha</label>
            <input
              type="password"
              className="form-control"
              onChange={(event) => setConfirmPassword(event.target.value)}
              required
            />
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 d-flex align-items-center justify-content-end">
            <button type="reset" className="d-none" id="btnClearUserAgency">
              Reset
            </button>
            <button
              onClick={() => props.close()}
              type="button"
              className="btn text-danger px-lg-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-25"
            >
              Cancelar
            </button>
            {load ? (
              <button
                type="button"
                className="btn btn-prop-blue px-lg-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                disabled
              >
                <div className="spinner-border spinner-border-sm text-light" role="status">
                  <span className="visually-hidden">Carregando...</span>
                </div>
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-prop-blue px-lg-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
              >
                Salvar
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

export default ModalUserAgency;
