import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import api from "../../../config/api";
// import io from "socket.io-client";
import Loading from "../../../components/Load";
import AddButton from "../../../components/AddButton";
import EmptyList from "../../../components/_empty/list";
// import swal from "sweetalert";
import ModalStakeholder from "../../../components/_modals/_agency/Stakeholder";
import SidebarProject from "../../../components/SidebarProject";
import SidebarProjectCategory from "../../../components/SidebarProjectCategory";
import { Table, TableHeader, TableHeaderItem, TableBody, TableBodyCell, TableBodyItem, TableBodyItemAction } from "../../../components/Table";
import { decodeToken } from "../../../config/auth";
import { Drawer } from "../../../components/Drawer";
import { toastsMsg } from "../../../components/ToasterMsg";
import swal from "sweetalert";
require("dotenv").config();

function Stakeholder(props) {
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stakeholders, setStakeholders] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [limit, setLimit] = useState(6);

  const [stakeholder, setStakeholder] = useState({});
  const [user, setUser] = useState({});
  const [project, setProject] = useState("");
  const [client, setClient] = useState("");

  const [drawerOpenAddStakeHolders, setDrawerOpenAddStakeHolders] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  // Filter
  const [nameFilter, setNameFilter] = useState("");

  useEffect(() => {
    const { user = {} } = decodeToken();
    setUser(user);
    getStakeholders(page, limit);
    getProject();
  }, [page, props, user.role]);

  const getStakeholders = async (mypage = 1, limit = 6, filter = "", sortBy = "createdAt", order = "desc") => {
    setLoading(true);
    const response = await api.get(
      `/stakeholder/${props.match.params.id}?paginate=true&limit=${limit}&page=${mypage}&filter=${filter}&order=${order}&sortBy=${sortBy}`
    );
    const { ...arr } = response.data;
    setStakeholders(response.data);
    setPages(parseInt(arr.pages));
    setLoading(false);
  };

  const getProject = async () => {
    const response = await api.get(`/dashboard-project/${props.match.params.id}`);
    setProject(response.data);
    setClient(response.data.client);
  };

  const nextPage = () => {
    getStakeholders(page + 1, limit);
    setPage(page === pages ? page : page + 1);
  };

  const backPage = () => {
    getStakeholders(page - 1, limit);
    setPage(page === 1 ? page : page - 1);
  };

  const destroy = async (item) => {
    swal({
      title: "Atenção",
      text: `Deseja excluir o Stakeholder ${item.name}`,
      icon: "warning",
      buttons: ["Cancelar", "OK"],
      dangerMode: false,
    }).then(async (res) => {
      if (res) {
        await api
          .delete(`/stakeholder/${item._id}`)
          .then(async () => {
            await getStakeholders(page, limit);
            toastsMsg({
              icon: "success",
              title: "Sucesso!",
              text: "Stakeholder removido com sucesso.",
            });
          })
          .catch(() => {
            toastsMsg({
              icon: "error",
              title: "Erro!",
              text: "Erro ao remover o stakeholder, tente novamente mais tarde.",
            });
          });
      }
    });
  };

  const edit = (item) => {
    setStakeholder(item);
    setName(item.name);
    setEmail(item.email);
    setPhone(item.phone);
    setDrawerOpenAddStakeHolders(true);
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    await api
      .put(`/stakeholder/${stakeholder._id}`, {
        name: name,
        email: email,
        phone: phone,
        isManager: isManager,
      })
      .then(() => {
        setLoad(false);
        getStakeholders(page, limit);
        setDrawerOpenAddStakeHolders(false);
        toastsMsg("Sucesso!", "Stakeholder editado com sucesso!", "success");
      })
      .catch(() => {
        setLoad(false);
        setDrawerOpenAddStakeHolders(false);
        toastsMsg("Erro!", "Erro ao editar o stakeholder, tente novamente mais tarde.", "error");
      });
  };

  const changeLimit = (event) => {
    setLimit(event.target.value);
    setPage(1);
    getStakeholders(1, event.target.value);
  };

  const changePage = (event) => {
    setLoad(true);
    setPage(event.target.id);
    getStakeholders(event.target.id, limit);
    setLoad(false);
  };

  const filterChangeHandlerName = (event) => {
    setNameFilter(event.target.value);
    getStakeholders(page, limit, event.target.value);
  };

  const sortByNameChangeHandler = (sortBy, order) => {
    getStakeholders(page, limit, nameFilter, sortBy, "asc");
  };

  const addStakeHolder = () => {
    setStakeholder({});
    setDrawerOpenAddStakeHolders(true);
  };

  return (
    <>
      <Layout
        defaultMenu={0}
        isSingleProject={true}
        clientImg={client.logo}
        clientName={client.name}
        projectName={project.name}
        projectCategory={project.category !== undefined ? project.category[0] : ""}
      >
        <div className="container-fluid singleProject">
          {user.role === "agency" ? <SidebarProject active={"stakeholder"} project={props.match.params.id} /> : <></>}
          {user.role === "category" && (
            <div className="col-12">
              <SidebarProjectCategory seeReport={user.seeReport} active={"stakeholder"} project={props.match.params.id} />
            </div>
          )}
          <div className="row">
            <div className="col-12 col-lg-4 mb-4 mt-2 me-auto">
              <div className="input-group input-search">
                <input
                  type="text"
                  className="form-control border-0 fz-14 box-shadow-none text-prop-grey-light font-rubik"
                  onChange={(event) => filterChangeHandlerName(event)}
                  placeholder="O que você está buscando?"
                />
                <span className="input-group-text bg-white border-0">
                  <i className="bi bi-search text-prop-grey-light"></i>
                </span>
              </div>
            </div>
            {user.role !== "category" ? <AddButton className={"m-0"} sizeLg={"6"} onClick={() => addStakeHolder()} text="Adicionar Stakeholder" /> : <></>}
            <div className="col-12 mb-4">
              <Table
                title={"stakeholders"}
                totalItem={stakeholders.total}
                value={limit}
                onChangeLimit={(e) => changeLimit(e)}
                prevButton={() => backPage()}
                nextButton={() => nextPage()}
                page={page}
                changePage={(e) => changePage(e)}
              >
                <TableHeader>
                  <TableHeaderItem sizeLg={3} title="Nome" />
                  <TableHeaderItem sizeLg={3} title="Email"></TableHeaderItem>
                  <TableHeaderItem sizeLg={3} title="Telefone" />
                  <TableHeaderItem sizeLg={3} title="Responsável"></TableHeaderItem>
                </TableHeader>
                <TableBody>
                  {stakeholders.docs !== undefined &&
                    stakeholders.docs.map((item) => (
                      <TableBodyCell>
                        <TableBodyItem sizeLg={3} title="Nome" value={item.name} />
                        <TableBodyItem sizeLg={3} title="E-mail" value={item.email} />
                        <TableBodyItem sizeLg={3} title="Telefone" value={item.phone} />
                        <TableBodyItem sizeLg={2} title="Responsável" value={item.isManager === true ? "Sim" : "Não"} />
                        {user.role !== "category" ? (
                          <TableBodyItemAction sizeLg={1}>
                            <li>
                              <span className="dropdown-item fz-14 font-rubik c-pointer" onClick={() => edit(item)}>
                                Editar
                              </span>
                            </li>
                            <li>
                              <span
                                onClick={() => destroy(item)}
                                className="dropdown-item fz-14 font-rubik text-prop-danger c-pointer"
                                data-bs-toggle="modal"
                                data-bs-target={"#confirmDeleteReunion"}
                              >
                                Excluir
                              </span>
                            </li>
                          </TableBodyItemAction>
                        ) : (
                          <></>
                        )}
                      </TableBodyCell>
                    ))}
                  {stakeholders.length === 0 ? <EmptyList icon="indiferente" message="Não foi encontrado nenhum stakeholder" /> : <></>}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </Layout>
      <Drawer show={drawerOpenAddStakeHolders} onClose={() => setDrawerOpenAddStakeHolders(false)} onOpen={() => setDrawerOpenAddStakeHolders(true)}>
        <div className="p-3 modal-drawer">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              {stakeholder._id === undefined ? (
                <>
                  <div className="modal-header border-0">
                    <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalLabel">
                      Adicionar stakeholder
                    </h5>
                    <button type="button" id="btnClose" className="btn-close" onClick={() => setDrawerOpenAddStakeHolders(false)}></button>
                  </div>
                  <div className="modal-body">
                    <ModalStakeholder getStakeholders={getStakeholders} project={project} close={() => setDrawerOpenAddStakeHolders(false)} />
                  </div>
                </>
              ) : (
                <>
                  <div className="modal-header border-0">
                    <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalLabel">
                      Editar stakeholder
                    </h5>
                    <button type="button" id="btnClose" className="btn-close" onClick={() => setDrawerOpenAddStakeHolders(false)}></button>
                  </div>
                  <form onSubmit={save}>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Nome</label>
                        <input value={name} type="text" className="form-control" onChange={(event) => setName(event.target.value)} required />
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">E-mail</label>
                        <input value={email} type="email" className="form-control" onChange={(event) => setEmail(event.target.value)} required />
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Telefone</label>
                        <input value={phone} type="text" className="form-control" onChange={(event) => setPhone(event.target.value)} required />
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Responsável</label>
                        <select className="form-select" value={isManager} onChange={(event) => setIsManager(event.target.value)} required>
                          <option></option>
                          <option value={true}>Sim</option>
                          <option value={false}>Não</option>
                        </select>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-12 d-flex align-items-center justify-content-end">
                        <button type="reset" className="d-none" id="btnClearStakeholder">
                          Reset
                        </button>
                        <button
                          type="button"
                          className="btn text-danger px-lg-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-25"
                          onClick={() => setDrawerOpenAddStakeHolders(false)}
                        >
                          Cancelar
                        </button>
                        {load ? (
                          <button
                            type="button"
                            className="btn btn-prop-blue px-lg-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                            disabled
                          >
                            <div className="spinner-border spinner-border-sm text-light" role="status">
                              <span className="visually-hidden">Carregando...</span>
                            </div>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-prop-blue px-lg-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                          >
                            Atualizar
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </Drawer>
      {/* <div
        className="modal fade border-0"
        id="myModalSingle"
        tabIndex="-1"
        aria-labelledby="myModalSingleLabel"
        aria-hidden="true"
        style={{ borderRadius: "10px" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {stakeholder._id === undefined ? (
              <>
                <div className="modal-header border-0">
                  <h5
                    className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark"
                    id="myModalLabel"
                  >
                    Adicionar stakeholder
                  </h5>
                  <button
                    type="button"
                    id="btnClose"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <ModalStakeholder
                    project={project}
                    close={() => document.getElementById("btnClose").click()}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div> */}
      <Loading show={loading} />
    </>
  );
}

export default Stakeholder;
