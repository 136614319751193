import React from 'react';
import errorIcon from '../../assets/img/icons/error-red.svg'

export function ConfirmDelete(props) {

    return (
        <>
            <div className="modal fade border-0" id={props.modalId} tabIndex="-1" aria-labelledby={props.modalId} aria-hidden="true" style={{ borderRadius: "10px" }}>
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button type="button" id="btnClose" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body row p-0">
                            <img className='' width={80} height={80} src={errorIcon} alt="" />
                            <span className='font-rubik fz-24 fw-bold text-center mt-3'>{props.title}</span>
                            <span className='font-rubik fz-14 text-center mt-2'>{props.description}</span>
                        </div>
                        <div className="col-12 d-flex align-items-center mt-2">
                            <div className='col-6 d-flex justify-content-end my-3 pe-4'>
                                <button type="button" className="btn py-2 text-prop-celestial-blue fz-14 font-rubik fw-500" id="cancelButtonDeleteModel" data-bs-dismiss="modal">Cancelar</button>
                            </div>
                            <div className='col-6 d-flex justify-content-start my-3 ps-4'>
                                <button type="button" onClick={props.confirmDeleteOnClick} class="px-4 red-button">{props.deleteText}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
