import React from 'react';
import { Link } from 'react-router-dom';

export default function SidebarProject(props) {

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-white mb-4" style={{ borderRadius: "10px" }}>
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item mx-4">
                            <Link className={`nav-link font-rubik text-uppercase fw-bold fz-14 ${(props.active === "overview") ? "text-prop-blue" : "text-prop-grey-light"}`} to={`/projeto/${props.project}/overview`}>Overview</Link>
                        </li>
                        <li className="nav-item mx-4">
                            <Link className={`nav-link font-rubik text-uppercase fw-bold fz-14 ${(props.active === "sprint") ? "text-prop-blue" : "text-prop-grey-light"}`} to={`/projeto-da-categoria/${props.project}/sprints`}>Sprints</Link>
                        </li>
                        <li className="nav-item mx-4">
                            <Link className={`nav-link font-rubik text-uppercase fw-bold fz-14 ${(props.active === "task") ? "text-prop-blue" : "text-prop-grey-light"}`} to={`/projeto-da-categoria/${props.project}/tarefas`}>Tarefas</Link>
                        </li>
                        <li className="nav-item mx-4">
                            <Link className={`nav-link font-rubik text-uppercase fw-bold fz-14 ${(props.active === "reunion") ? "text-prop-blue" : "text-prop-grey-light"}`} to={`/projeto-da-categoria/${props.project}/reunioes`}>Reuniões</Link>
                        </li>
                        {(props.seeReport) ?
                            <li className="nav-item mx-4">
                                <Link className={`nav-link font-rubik text-uppercase fw-bold fz-14 ${(props.active === "report") ? "text-prop-blue" : "text-prop-grey-light"}`} to={`/projeto-da-categoria/${props.project}/reports`}>Reports</Link>
                            </li>
                            :
                            <></>
                        }
                        <li className="nav-item mx-4">
                            <Link className={`nav-link font-rubik text-uppercase fw-bold fz-14 ${(props.active === "stakeholder") ? "text-prop-blue" : "text-prop-grey-light"}`} to={`/projeto-da-categoria/${props.project}/stakeholders`}>Stakeholders</Link>
                        </li>
                        <li className="nav-item mx-4">
                            <Link className={`centerText nav-link font-rubik text-uppercase fw-bold fz-14  ${(props.active === "history") ? "text-prop-blue" : "text-prop-grey-light"}`} to={`/projeto-da-categoria/${props.project}/historico-de-alteracoes`}>Log</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

