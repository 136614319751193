import React /*, { useEffect, useState }*/ from 'react';
import pasta_indiferente from '../../../assets/img/pasta_indiferente.svg';
import pasta_triste from '../../../assets/img/pasta_triste.svg';
import pasta_piscando from '../../../assets/img/pasta_piscando.svg';

function List(props) {
    // const [user, setUser] = useState({});

    // useEffect(() => {
    //     const { user = {} } = decodeToken();
    //     setUser(user);
    // }, [props]);

    return (
        <div className="empty">
            <div className="col-12 d-flex justify-content-center pt-0 pt-lg-4">
                <img src={(props.icon === 'indiferente') ? pasta_indiferente : (props.icon === 'triste') ? pasta_triste : pasta_piscando} alt="" className="img-fluid " width={(props.width !== undefined) ? props.width : 205} height={(props.height !== undefined) ? props.height : 175} />
            </div>
            <div className="col-12 d-flex justify-content-center pt-1 pt-lg-3">
                <h1 className='message'>{props.message}</h1>
            </div>
            {(props.hasButton === true & props.userRole !== 'company' & props.userRole !== 'client' & props.userRole !== 'category') ?
                <div className="col-12 d-flex justify-content-center pt-1 pt-lg-3">
                        <button onClick={props.onClick} className="btn btn-prop-blue text-white font-rubik fz-16 px-4 button-add "  type="button">{props.buttonText}</button>
                </div>
                :
                <></>
            }
        </div>
    );
}

export default List;