import React from 'react';

function WidgetPath(props) {

    return (
        <div className={`${(props.className === undefined) ? '' : props.className} col-12 col-lg-${(props.sizeLg === undefined) ? "auto" : props.sizeLg} `}>
            <span className='font-rubik'>
                {props.path.map((item, index) => (
                    <>
                        {(item.link !== "") ?
                            <a href="/projetos-em-andamento" className='fz-14 text-prop-weldon-blue underline-hover c-pointer'>{`${item.name} > `}</a>
                            :
                            <span className='fz-14 text-prop-weldon-blue'>{`${item.name} > `}</span>
                        }
                    </>
                ))}
                <span className='fz-14 text-prop-celestial-blue'>{props.projectName}</span>
            </span>
        </div>
    );
}

export default WidgetPath;