import React, { useEffect, useState } from "react";
import api from "../../../../config/api";
// import swal from "sweetalert";
import { toastsMsg } from "../../../ToasterMsg";

function ModalReport(props) {
  const [load, setLoad] = useState(false);
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [client, setClient] = useState("");
  const [project, setProject] = useState("");
  const [subject, setSubject] = useState("");
  const [date, setDate] = useState("");
  const [schedule, setSchedule] = useState("");
  const [rating, setRating] = useState("");
  const [comments, setComments] = useState("");

  useEffect(() => {
    getClients();
    getProjects();
  }, []);

  const getClients = async () => {
    const response = await api.get(`/client?limit=9999&sortBy=name&order=ASC&filterStatus=active`);
    setClients(response.data);
  };

  const getProjects = async () => {
    const response = await api.get(`/project/all?limit=9999&sortBy=name&order=ASC`);
    setProjects(response.data);
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    const formData = new FormData();
    formData.append("file", schedule);
    schedule !== "" ? formData.append("haveFile", true) : formData.append("haveFile", false);
    if (props.client === undefined) {
      formData.append("client", client);
    } else {
      formData.append("client", props.client._id);
    }
    if (props.project === undefined) {
      formData.append("project", project);
    } else {
      formData.append("project", props.project._id);
    }
    formData.append("subject", subject);
    formData.append("date", date);
    formData.append("rating", rating);
    formData.append("comments", comments);

    await api
      .post(`/report`, formData)
      .then(() => {
        setLoad(false);
        props.getReports();
        props.close();
        toastsMsg("Sucesso!", "Report cadastrado com sucesso!", "success");
      })
      .catch((error) => {
        setLoad(false);
        if (error.response.data.error === "Invalid File") {
          toastsMsg("Erro!", "Tipo de arquivo selecionado incorreto, por favor selecione um arquivo válido.", "error");
          setSchedule("");
        } else {
          toastsMsg("Erro!", "Erro ao cadastrar o report, tente novamente mais tarde.", "error");
        }
      });
  };

  return (
    <>
      <form onSubmit={save}>
        <div className="row">
          {props.client === undefined ? (
            <div className="col-12 col-lg-6 mb-3">
              <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Cliente</label>
              <select className="form-select" onChange={(event) => setClient(event.target.value)} required>
                <option></option>
                {clients.map((item, index) => (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <></>
          )}
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Assunto</label>
            <input type="text" className="form-control" onChange={(event) => setSubject(event.target.value)} required />
          </div>
          {props.project === undefined ? (
            <div className="col-12 col-lg-6 mb-3">
              <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Projeto</label>
              <select className="form-select" onChange={(event) => setProject(event.target.value)} required>
                <option></option>
                {projects
                  .filter((item) => item.client._id === client)
                  .map((item, index) => (
                    <option value={item._id} key={index}>{`${item.name} - ${item.category}`}</option>
                  ))}
              </select>
            </div>
          ) : (
            <></>
          )}
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Data</label>
            <input type="date" className="form-control" onChange={(event) => setDate(event.target.value)} required />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Status</label>
            <select className="form-select" onChange={(event) => setRating(event.target.value)} required>
              <option></option>
              <option value="Bom">Bom</option>
              <option value="Regular">Regular</option>
              <option value="Ruim">Ruim</option>
            </select>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label htmlFor="formFile" className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
              Anexar pauta <span className="fz-10 text-prop-danger font-rubik">Tamanho máximo 2MB</span>
            </label>
            <input
              className="form-control"
              type="file"
              id="formFile"
              onChangeCapture={(event) => setSchedule(event.target.files[0])}
            />
          </div>
          <div className="col-12 col-lg-12 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Observações</label>
            <textarea
              className="form-control"
              rows="8"
              onChange={(event) => setComments(event.target.value)}
              required
            ></textarea>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 d-flex align-items-center justify-content-end">
            <button type="reset" className="d-none" id="btnClearReport">
              Reset
            </button>
            <button
              type="button"
              className="btn text-danger px-lg-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-25"
              onClick={() => props.close()}
            >
              Cancelar
            </button>
            {load ? (
              <button
                type="button"
                className="btn btn-prop-blue px-lg-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                disabled
              >
                <div className="spinner-border spinner-border-sm text-light" role="status">
                  <span className="visually-hidden">Carregando...</span>
                </div>
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-prop-blue px-lg-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
              >
                Cadastrar
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

export default ModalReport;
