import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import {
  List,
  ListBody,
  ListBodyItem,
  ListBodyItemAction,
  ListHeader,
  ListHeaderItem,
  ListHeaderItemSortAble,
} from "../../../components/List";
import api from "../../../config/api";
// import io from "socket.io-client";
import Loading from "../../../components/Load";
import AddButton from "../../../components/AddButton";
import flag from "../../../assets/img/icons/flag.svg";
import swal from "sweetalert";
import ModalTask from "../../../components/_modals/_agency/Task";
import SidebarProject from "../../../components/SidebarProject";
import SidebarProjectCategory from "../../../components/SidebarProjectCategory";
import { decodeToken } from "../../../config/auth";
import EmptyList from "../../../components/_empty/list";
import { formatDate, convertTime } from "../../../config/utils";
import Select from "react-select";
import { toastsMsg } from "../../../components/ToasterMsg";
require("dotenv").config();

function Task(props) {
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [users, setUsers] = useState(1);
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [estimatedDate, setEstimatedDate] = useState("");
  const [priority, setPriority] = useState(false);
  const [responsible, setResponsible] = useState([]);
  const [typeTasks, setTypeTasks] = useState([]);
  const [status, setStatus] = useState("");
  const [category, setCategory] = useState("");
  const [hours, setHours] = useState("");
  const [project, setProject] = useState("");
  const [client, setClient] = useState("");
  const [type, setType] = useState("");
  const [responsibles, setResponsibles] = useState([]);
  const [responsibleFilter, setResponsibleFilter] = useState(
    localStorage.getItem("TASK_OVERVIEW_FILTER_RESPONSIBLE") === null
      ? ""
      : localStorage.getItem("TASK_OVERVIEW_FILTER_RESPONSIBLE")
  );
  const [statusFilter, setStatusFilter] = useState(
    localStorage.getItem("TASK_OVERVIEW_FILTER_STATUS") === null
      ? ""
      : localStorage.getItem("TASK_OVERVIEW_FILTER_STATUS")
  );
  const [nameFilter, setNameFilter] = useState("");
  const [sort, setSort] = useState("");
  let [user, setUser] = useState({});

  useEffect(() => {
    const { user = {} } = decodeToken();
    setUser(user);
    getTasks(page, nameFilter, responsibleFilter, statusFilter);
    getUsers();
    getProject();
    getTypeTasks();
    getResponsibles();
    // async function registerToSocket() {
    //     const socket = io(`${process.env.REACT_APP_API_URL}`);
    //     socket.on("task", () => {
    //         getTasks(page, nameFilter, responsibleFilter, statusFilter);
    //     });
    // }
    // registerToSocket();
  }, [page, props, user.role]);

  const getTasks = async (
    mypage = 1,
    filterName = "",
    filterResponsible = "",
    filterStatus = "",
    sortBy = "createdAt",
    order = "desc"
  ) => {
    setLoading(true);
    const response = await api.get(
      `/task/${props.match.params.id}?paginate=true&page=${mypage}&filterName=${filterName}&filterStatus=${filterStatus}&filterResponsible=${filterResponsible}&order=${order}&sortBy=${sortBy}`
    );
    const { docs = [], ...arr } = response.data;
    setTasks(docs);
    setPages(parseInt(arr.pages));
    setLoading(false);
  };

  const getProject = async () => {
    const response = await api.get(`/dashboard-project/${props.match.params.id}`);
    setProject(response.data);
    setClient(response.data.client);
  };

  const getTypeTasks = async (limit = 100) => {
    const response = await api.get(`/typeTask?paginate=true&limit=${limit}`);
    const { docs = [] } = response.data;
    setTypeTasks(docs);
  };

  const nextPage = () => {
    getTasks(page + 1);
    setPage(page === pages ? page : page + 1);
  };

  const backPage = () => {
    getTasks(page - 1);
    setPage(page === 1 ? page : page - 1);
  };

  const destroy = async (item) => {
    swal({
      title: "Atenção",
      text: `Deseja excluir a tarefa ${item.title}`,
      icon: "warning",
      buttons: ["Cancelar", "OK"],
      dangerMode: false,
    }).then(async (res) => {
      if (res) {
        await api
          .delete(`/task/${item._id}`)
          .then(async () => {
            await getTasks(page);
            toastsMsg({
              icon: "success",
              title: "Sucesso!",
              text: "Tarefa removida com sucesso.",
            });
          })
          .catch(() => {
            toastsMsg({
              icon: "error",
              title: "Erro!",
              text: "Erro ao remover a tarefa, tente novamente mais tarde.",
            });
          });
      }
    });
  };

  const edit = (item) => {
    setType("edit");
    setId(item._id);
    setTitle(item.title);
    setEstimatedDate(String(item.estimatedDate).substr(0, 10));
    setPriority(item.priority);
    setStatus(item.status);
    setCategory(item.category);
    if (item.hours !== undefined) {
      if (Array.isArray(item.hours)) {
        let taskHours = item.hours.find((element) => element.user === user._id);
        if (taskHours !== undefined) {
          setHours(convertTime(taskHours.hour));
        } else {
          setHours(convertTime(0));
        }
      } else {
        setHours(convertTime(0));
      }
    } else {
      setHours(convertTime(0));
    }

    const users_arr = [];
    item.responsible.forEach((u) => users_arr.push({ value: u._id, label: `${u.firstName} ${u.lastName}` }));
    setResponsible(users_arr);
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);

    let responsible_arr = [];
    if (responsible !== null) {
      responsible_arr = responsible.length > 0 ? responsible.map((item) => item.value) : [];
    } else {
      responsible_arr = [];
    }

    await api
      .put(`/task/${id}`, {
        title,
        estimatedDate,
        priority,
        responsible: responsible_arr,
        status,
        hours,
        category,
      })
      .then(() => {
        setLoad(false);
        toastsMsg("Sucesso!", "Tarefa editada com sucesso!", "success");
        document.getElementById("btnCloseSingle").click();
        getTasks(page);
      })
      .catch(() => {
        setLoad(false);
        toastsMsg("Erro!", "Erro ao editar a tarefa, tente novamente mais tarde.", "error");
      });
  };

  const getUsers = async () => {
    const response = await api.get(`/user/agency?limit=99999&sortBy=firstName&order=ASC`);
    const users_arr = [];
    let items = response.data;
    items = items.filter((item) => item.status === "active" && item.status !== "inactive");
    items.forEach((item) =>
      users_arr.push({
        value: item._id,
        label: `${item.firstName} ${item.lastName}`,
      })
    );
    setUsers(users_arr);
  };

  const getResponsibles = async () => {
    const response = await api.get(`/user-in-task?project=${props.match.params.id}`);
    setResponsibles(response.data);
  };

  const filterChangeHandlerName = (event) => {
    setNameFilter(event.target.value);
    getTasks(page, event.target.value, responsibleFilter, statusFilter);
  };

  const filterChangeHandlerResponsible = (event) => {
    setResponsibleFilter(event.target.value);
    getTasks(page, nameFilter, event.target.value, statusFilter);
    localStorage.setItem("TASK_OVERVIEW_FILTER_RESPONSIBLE", event.target.value);
  };

  const filterChangeHandlerStatus = (event) => {
    setStatusFilter(event.target.value);
    getTasks(page, nameFilter, responsibleFilter, event.target.value);
    localStorage.setItem("TASK_OVERVIEW_FILTER_STATUS", event.target.value);
  };

  const clearFilter = () => {
    localStorage.removeItem("TASK_OVERVIEW_FILTER_RESPONSIBLE");
    localStorage.removeItem("TASK_OVERVIEW_FILTER_STATUS");
    window.location.reload();
  };

  const sortByNameChangeHandler = (sortBy) => {
    if (sort !== sortBy) {
      getTasks(page, nameFilter, responsibleFilter, statusFilter, sortBy, "asc");
      setSort(sortBy);
    } else {
      getTasks(page, nameFilter, responsibleFilter, statusFilter);
      setSort("");
    }
  };

  return (
    <>
      <Layout
        defaultMenu={0}
        isSingleProject={true}
        clientImg={client.logo}
        clientName={client.name}
        projectName={project.name}
        projectCategory={project.category !== undefined ? project.category[0] : ""}
      >
        <div className="container-fluid singleProject">
          {/* <div className={`row col-12 d-flex justify-content-between align-items-end`}>
                        <WidgetClient
                            specialClass={"p-0 m-0"}
                            link={(user.role === 'company') ? "/empresa-projetos-em-andamento" : (user.role === 'agency') ? "/projetos-em-andamento" : (user.role === 'category') ? "/projetos-da-categoria" : "/meus-projetos"}
                            img={client.logo}
                            clientName={client.name}
                            projectName={project.name}
                        />
   
                    </div> */}
          {user.role === "agency" ? <SidebarProject active={"task"} project={props.match.params.id} /> : <></>}
          {user.role === "category" ? (
            <div className="col-12">
              <SidebarProjectCategory seeReport={user.seeReport} active={"task"} project={props.match.params.id} />
            </div>
          ) : (
            <></>
          )}
          <div className="row">
            {/* <div className="col-12 mb-4">
                            <h1 className="mb-0 fz-24 fw-bold font-rubik">Tarefas</h1>
                        </div> */}
            <div className="col-12 col-lg-4 mt-2 me-auto">
              <div className="input-group input-search">
                <input
                  type="text"
                  className="form-control border-0 fz-14 box-shadow-none text-prop-grey-light font-rubik"
                  onChange={(event) => filterChangeHandlerName(event)}
                  placeholder="O que você está buscando?"
                />
                <span className="input-group-text bg-white border-0">
                  <i className="bi bi-search text-prop-grey-light"></i>
                </span>
              </div>
            </div>
            {/* <div className="col-12 col-lg-3 mt-2">
                            <select value={(localStorage.getItem("TASK_OVERVIEW_FILTER_RESPONSIBLE") === null) ? "" : localStorage.getItem("TASK_OVERVIEW_FILTER_RESPONSIBLE")} className="filter-select fz-12 text-prop-grey-light font-rubik" onChange={(event) => filterChangeHandlerResponsible(event)} required>
                                <option value="" key={0}>Selecione o responsável</option>
                                <option value="none" key={44}>Nenhum</option>
                                {responsibles.map((item, index) => (
                                    <option value={item._id} key={index + 1}>{item.firstName + " " + item.lastName}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-12 col-lg-3 mt-2 mb-4">
                            <select value={(localStorage.getItem("TASK_OVERVIEW_FILTER_STATUS") === null) ? "" : localStorage.getItem("TASK_OVERVIEW_FILTER_STATUS")} className="filter-select fz-12 text-prop-grey-light font-rubik" onChange={(event) => filterChangeHandlerStatus(event)} required>
                                <option value="" key={0}>Selecione o status</option>
                                <option value="open" key={1}>Não entregue</option>
                                <option value="closed" key={2}>Entregue</option>
                            </select>
                        </div>
                        <ClearButton
                            size={12}
                            sizeLg={2}
                            onClick={() => clearFilter()}
                            text={'Limpar Filtros'}
                        /> */}
            {user.role !== "category" ? (
              <AddButton
                className={"p-0 m-0"}
                sizeLg={"auto"}
                onClick={() => setType("add")}
                modalId={"#myModalSingle"}
                text="Adicionar Tarefa"
              />
            ) : (
              <></>
            )}
            <div className="col-12 mb-4">
              <List paginate={true} page={page} pages={pages} backPage={() => backPage()} nextPage={() => nextPage()}>
                <ListHeader>
                  <ListHeaderItem name="" sizeLg={1} />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("title")}
                    name="Nome da tarefa"
                    sizeLg={2}
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("responsible")}
                    name="Responsável"
                    sizeLg={2}
                  />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("category")} name="Tipo" sizeLg={2} />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("estimatedDate")}
                    name="Deadline"
                    sizeLg={2}
                  />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("status")} name="Status" sizeLg={1} />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("hours")}
                    name="Tempo gasto"
                    sizeLg={2}
                  />
                </ListHeader>

                {tasks.map((item, index) => {
                  const { responsible: taskResponsibles = [] } = item;
                  let taskResponsible = "Nenhum";
                  if (taskResponsibles) {
                    if (taskResponsibles.length > 0) {
                      taskResponsible = "";
                      for (let i = 0; i < taskResponsibles.length; i++) {
                        taskResponsible +=
                          i === 0
                            ? `${taskResponsibles[i].firstName} ${taskResponsibles[i].lastName}`
                            : `, ${taskResponsibles[i].firstName} ${taskResponsibles[i].lastName}`;
                      }
                    }
                  }
                  let total = 0;
                  item.hours.forEach((element) =>
                    (element.hour !== undefined) & (element.hour !== null) ? (total += element.hour) : (total += 0)
                  );

                  return (
                    <ListBody status={item.status} key={index} specialClass="d-flex flex-wrap">
                      <ListBodyItem
                        title="Status"
                        value={
                          <>{item.priority ? <img src={flag} alt="" style={{ width: 22, height: 22 }} /> : <></>}</>
                        }
                        sizeLg={1}
                      />
                      <ListBodyItem title="Título" value={item.title} sizeLg={2} />
                      <ListBodyItem title="Responsável" value={taskResponsible} sizeLg={2} />
                      <ListBodyItem title="Tipo" value={item.category} sizeLg={2} />
                      <ListBodyItem
                        title="Deadline"
                        value={
                          (item.estimatedDate !== undefined) &
                          (item.estimatedDate !== null) &
                          (item.estimatedDate !== "")
                            ? formatDate(new Date(item.estimatedDate))
                            : "-"
                        }
                        sizeLg={2}
                      />
                      <ListBodyItem
                        title="Status"
                        value={
                          item.status === "closed" ? "Entregue" : item.status === "open" ? "Em andamento" : "Pendente"
                        }
                        sizeLg={1}
                      />
                      <ListBodyItem title="Tempo gasto" value={convertTime(total)} sizeLg={1} />
                      {user.role !== "category" ? (
                        <ListBodyItemAction sizeLg={1}>
                          <li>
                            <span
                              className="dropdown-item fz-14 font-rubik c-pointer"
                              data-bs-toggle="modal"
                              data-bs-target="#myModalSingle"
                              onClick={() => edit(item)}
                            >
                              Editar
                            </span>
                          </li>
                          <li>
                            <span
                              className="dropdown-item fz-14 font-rubik text-prop-danger c-pointer"
                              onClick={() => destroy(item)}
                            >
                              Excluir
                            </span>
                          </li>
                        </ListBodyItemAction>
                      ) : (
                        <ListBodyItem title="" specialClass="" value={""} sizeLg={1} />
                      )}
                    </ListBody>
                  );
                })}
                {tasks.length === 0 ? (
                  <EmptyList icon="indiferente" message="Não foi encontrada nenhuma tarefa" />
                ) : (
                  <></>
                )}
              </List>
            </div>
          </div>
        </div>
      </Layout>
      <div
        className="modal fade border-0"
        id="myModalSingle"
        tabIndex="-1"
        aria-labelledby="myModalSingleLabel"
        aria-hidden="true"
        style={{ borderRadius: "10px" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {type === "add" ? (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalLabel">
                    Adicionar nova tarefa
                  </h5>
                  <button
                    type="button"
                    id="btnCloseTask"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <ModalTask
                    project={project}
                    idModal={"myModalSingle"}
                    close={() => document.getElementById("btnCloseTask").click()}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                    Editar tarefa
                  </h5>
                  <button
                    type="button"
                    id="btnCloseSingle"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={save}>
                    <div className="row">
                      <div className="col-12 col-lg-12 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Título</label>
                        <input
                          type="text"
                          className="form-control"
                          value={title}
                          onChange={(event) => setTitle(event.target.value)}
                          required
                        />
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                          Deadline
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          value={estimatedDate}
                          onChange={(event) => setEstimatedDate(event.target.value)}
                          required
                        />
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                          Prioridade
                        </label>
                        <select
                          className="form-select"
                          value={priority}
                          onChange={(event) => setPriority(event.target.value)}
                          required
                        >
                          <option></option>
                          <option value={true}>Sim</option>
                          <option value={false}>Não</option>
                        </select>
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                          Categoria
                        </label>
                        <select
                          className="form-select"
                          onChange={(event) => setCategory(event.target.value)}
                          value={category}
                          required
                        >
                          <option></option>
                          {typeTasks.map((item, index) => (
                            <option value={item.name} key={index}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {responsible.find((item) => item.value === user._id) !== undefined ? (
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                            Horas trabalhadas
                          </label>
                          <input
                            type="time"
                            step="1"
                            value={hours}
                            className="form-control"
                            placeholder="Time"
                            onChange={(event) => setHours(event.target.value)}
                          />
                          {/* <input type="number" className="form-control" value={hours} onChange={(event) => setHours(event.target.value)} required /> */}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="col-12 col-lg-12 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                          Responsável
                        </label>
                        <Select
                          name="responsible"
                          placeholder=""
                          value={responsible}
                          onChange={(item) => setResponsible(item)}
                          options={users}
                          isMulti
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-12 d-flex align-items-center justify-content-end">
                        <button
                          type="button"
                          className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-20"
                          data-bs-dismiss="modal"
                        >
                          Cancelar
                        </button>
                        {load ? (
                          <button
                            type="button"
                            className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                            disabled
                          >
                            <div className="spinner-border spinner-border-sm text-light" role="status">
                              <span className="visually-hidden">Carregando...</span>
                            </div>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                          >
                            Salvar
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Loading show={loading} />
    </>
  );
}

export default Task;
