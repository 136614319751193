import React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { extendMoment } from "moment-range";
import Moment from "moment-timezone";
import "moment/locale/pt-br";
const moment = extendMoment(Moment);

function WidgetChart({ chartList }) {
  const getMonth = (month) => {
    return month === "Jan"
      ? 1
      : month === "Feb"
      ? 2
      : month === "Mar"
      ? 3
      : month === "Apr"
      ? 4
      : month === "May"
      ? 5
      : month === "Jun"
      ? 6
      : month === "Jul"
      ? 7
      : month === "Aug"
      ? 8
      : month === "Sep"
      ? 9
      : month === "Oct"
      ? 10
      : month === "Nov"
      ? 11
      : 12;
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        chart: {
          type: "spline",
          height: 210,
        },
        title: {
          text: "",
        },
        colors: ["#0090FF", "#DB5AEE"],
        xAxis: {
          type: "datetime",
          labels: {
            format: "{value:%b}",
          },
          title: {
            text: "",
          },
        },
        yAxis: {
          title: {
            text: "",
          },
          plotLines: [
            {
              value: -1,
              width: 1,
            },
          ],
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          borderRadius: 15,
          style: {
            color: "#FFFFFFDE",
            whiteSpace: "",
          },
          backgroundColor: "#06152B",
          borderColor: "#06152B",
          formatter: function () {
            return `<span class="fz-12 ff-univanova" >${moment(this.x).format(
              "DD [de] MMMM"
            )}</span><br><label class='fz-16 ff-univanova mt-2 d-flex'>${this.y} reservas</label>`;
          },
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Reservas",
            data: chartList?.map((data) => [
              Date.UTC(
                Number(data[0].split("/")[2]),
                Number(getMonth(data[0].split("/")[0])),
                Number(data[0].split("/")[1])
              ),
              data[1].reservas,
            ]),
            marker: {
              enabled: false,
            },
          },
          {
            name: "Vendas",
            data: chartList?.map((data) => [
              Date.UTC(
                Number(data[0].split("/")[2]),
                Number(getMonth(data[0].split("/")[0])),
                Number(data[0].split("/")[1])
              ),
              data[1].vendas,
            ]),
            marker: {
              enabled: false,
            },
          },
        ],
      }}
    />
  );
}

export default WidgetChart;
