import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import WidgetTitle from "../../../components/_widgets/WidgetTitle";
import api from "../../../config/api";
import { decodeToken } from "../../../config/auth";
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableHeaderItemDropdown,
  TableBody,
  TableBodyCell,
  TableBodyItem,
  TableBodyItemStatus,
} from "../../../components/Table";
import { WidgetProjectInfo, WidgetNumberWithClick, WidgetDropdownList, WidgetDropdownItem } from "../../../components/_widgets/WidgetProjectsInfo";
import WidgetLateSprint from "../../../components/_widgets/WidgetLateSprints";
import WidgetLateSprintSkeleton from "../../../components/_widgets/WidgetLateSprintsSkeleton";

import { WidgetCardListWithImage, WidgetCardListProjectWithImage } from "../../../components/_widgets/WidgetCardList";
import { Drawer } from "../../../components/Drawer";
import EmptyList from "../../../components/_empty/list";
import BurndownChart from "../../../components/_charts/BurndownChart";
import Footer from "../../../components/Footer";
import { formatDate, daysDiferance } from "../../../config/utils";
import "react-loading-skeleton/dist/skeleton.css";

function Dashboard() {
  const [user, setUser] = useState({});
  const [widgets, setWidgets] = useState([]);
  const [loadSprints, setLoadSprints] = useState(false);
  const [load, setLoad] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [totalProjectNotUpdate, setTotalProjectsNotUpdate] = useState(0);
  const [listProjectNotUpdate, setListProjectsNotUpdate] = useState([]);
  const [totalProjectsUTI, setTotalProjectsUTI] = useState(0);
  const [listProjectsUti, setListProjectsUti] = useState([]);
  const [totalProgressLate, setTotalProjectsLate] = useState(0);
  const [listProjectsLate, setListProjectsLate] = useState([]);
  const [totalProjectPorcent, setTotalPorcent] = useState(0);

  const [projectsClose, setProjectsClose] = useState([]);
  const [projectsLastDelivery, setProjectsLastDelivery] = useState([]);
  const [lateSprints, setLateSprints] = useState([]);
  const [projectsByResponsible, setProjectsByResponsible] = useState([]);
  const [newProjects, setNewProjects] = useState([]);
  const [hoursAndCategories, setHoursAndCategories] = useState([]);

  const [limitItensTable, setLimitItensTable] = useState(6);
  const [showResponsibles, setShowResponsibles] = useState(3);
  const [pageItensTable, setPageItensTable] = useState(1);
  const [burndownData, setBurndownData] = useState({});
  const [viewSprint, setViewSprint] = useState({});
  const [openProjects, setOpenProjects] = useState(0);
  const [userAgency, setUserAgency] = useState(0);

  useEffect(() => {
    const { user = {} } = decodeToken();
    setUser(user);
    getWidgets();
    getTotalPorcent();
    getLateSprints();

    getTotalProjectsNotUpdated();
    getTotalProjectsUTI();
    getTotalProjectsLate();

    getProjectsClose();
    getProjectsLastDelivery();
    getProjectsByResponsible();
    getCategoriesAndHours();
    getNewProjects();
    getTotalUserAgency();
    getTotalCurrent();
  }, []);

  const getWidgets = async () => {
    setLoad(true);
    try {
      const response = await api.get(`/dashboard-widgets`);
      setWidgets(response.data);
      setLoad(false);
    } catch (error) {
      console.log("getWidgets error: " + error);
      setLoad(false);
    }
  };

  const getTotalProjectsNotUpdated = async () => {
    try {
      setLoad(true);
      const response = await api.get(`/dashboard-not-updated-project?paginate=true&limit=9999`);
      setListProjectsNotUpdate(response.data.docs);
      setTotalProjectsNotUpdate(response.data.total);
      setLoad(false);
    } catch (error) {
      console.log("getTotalProjectsNotUpdated error: " + error);
      setLoad(false);
    }
  };

  const getTotalProjectsUTI = async () => {
    setLoad(true);
    try {
      const response = await api.get(`/project/attention?limit=9999`);
      setListProjectsUti(response.data);
      setTotalProjectsUTI(response.data.length);
      setLoad(false);
    } catch (error) {
      console.log("getTotalProjectsUTI error: " + error);
      setLoad(false);
    }
  };

  const getTotalProjectsLate = async () => {
    setLoad(true);
    try {
      const response = await api.get(`/project/late?limit=9999`);
      setListProjectsLate(response.data);
      setTotalProjectsLate(response.data.length);
      setLoad(false);
    } catch (error) {
      console.log("getTotalProjectsLate error: " + error);
      setLoad(false);
    }
  };

  const getTotalCurrent = async () => {
    setLoad(true);
    try {
      const response = await api.get(`/project/open?limit=9999`);
      setOpenProjects(response.data.length);
      setLoad(false);
    } catch (error) {
      console.log("getTotalCurrent error: " + error);
      setLoad(false);
    }
  };
  const getTotalUserAgency = async () => {
    setLoad(true);
    try {
      const response = await api.get(`/user/agency?limit=9999&filterStatus=active`);
      setUserAgency(response.data.length);
      setLoad(false);
    } catch (error) {
      console.log("getTotalCurrent error: " + error);
      setLoad(false);
    }
  };

  const getProjectsLastDelivery = async (limit = limitItensTable, page = pageItensTable) => {
    try {
      setLoad(true);
      const response = await api.get(`/dashboard-last-delivery?paginate=true&limit=${limit}&page=${page}`);
      setProjectsLastDelivery(response.data);
      setLoad(false);
    } catch (error) {
      console.log("getProjectsLastDelivery error: " + error);
      setLoad(false);
    }
  };

  const getProjectsClose = async () => {
    setLoad(true);
    try {
      const response = await api.get(`/project/close?limit=9999`);
      setProjectsClose(response.data);
      setLoad(false);
    } catch (error) {
      console.log("getProjectsClose error: " + error);
      setLoad(false);
    }
  };

  const getLateSprints = async () => {
    try {
      setLoad(true);
      setLoadSprints(true);
      const response = await api.get(`/dashboard-late-sprints?limit=${3}`);
      setLateSprints(response.data);
      setLoad(false);
      setLoadSprints(false);
    } catch (error) {
      console.log("getLateSprints error: " + error);
      setLoad(false);
      setLoadSprints(false);
    }
  };

  const getProjectsByResponsible = async () => {
    setLoad(true);
    try {
      const response = await api.get(`/dashboard-project-by-responsible`);
      setProjectsByResponsible(response.data);
      setLoad(false);
    } catch (error) {
      console.log("getProjectsByResponsible error: " + error);
      setLoad(false);
    }
  };

  const getNewProjects = async () => {
    setLoad(true);
    try {
      const response = await api.get(`/dashboard-new-projects`);
      const dataFilter = [];
      const hoje = new Date();
      response.data.map((projeto) => {
        let dataProjeto = new Date(projeto.startDate);
        dataProjeto.setDate(dataProjeto.getDate() + 30);
        if (hoje <= dataProjeto) dataFilter.push(projeto);
      });
      setNewProjects(dataFilter);
      setLoad(false);
    } catch (error) {
      console.log("getNewProjects error: " + error);
      setLoad(false);
    }
  };

  const getCategoriesAndHours = async () => {
    setLoad(true);
    const response = await api.get(`/total-hours`);
    setHoursAndCategories(response.data);
    setLoad(false);
  };

  const getBurndownChart = async (sprint_id) => {
    setLoad(true);
    try {
      const response = await api.get(`/dashboard-project-burndown/${sprint_id}?type=sprint`);
      setBurndownData(response.data);
      setLoad(false);
    } catch (error) {
      console.log("getBurndownChart error: " + error);
      setLoad(false);
    }
  };

  const getTotalPorcent = async () => {
    setLoad(true);
    try {
      const widgetsResponse = await api.get(`/dashboard-widgets`);
      const totalProgressLateResponse = await api.get(`/project/late?limit=9999`);
      let response = parseInt(100 - (totalProgressLateResponse.data.length * 100) / widgetsResponse.data.projects);
      if (isNaN(response)) response = 0;
      setTotalPorcent(response);
      setLoad(false);
    } catch (error) {
      console.log("getTotalPorcent error: " + error);
      setLoad(false);
    }
  };

  const setLimitTable = (value) => {
    setLimitItensTable(value);
    getProjectsLastDelivery(value, setPageItensTable);
  };

  const changePage = (event) => {
    setPageItensTable(event.target.id);
    getProjectsLastDelivery(limitItensTable, event.target.id);
  };

  const prevButton = () => {
    if (pageItensTable !== 1) {
      setPageItensTable(pageItensTable - 1);
      getProjectsLastDelivery(limitItensTable, pageItensTable - 1);
    }
  };

  const nextButton = () => {
    if (pageItensTable.toString() !== projectsLastDelivery.pages.toString()) {
      setPageItensTable(pageItensTable + 1);
      getProjectsLastDelivery(limitItensTable, pageItensTable + 1);
    }
  };

  const openSprintDrawer = (sprint) => {
    setDrawerOpen(true);
    setViewSprint({ ...sprint });
    getBurndownChart(sprint.sprintId);
  };

  return (
    <>
      <Layout defaultMenu={3}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 align-items-center mb-4">
              <div className="row">
                <div className="col-12 col-lg-12 mb-2">
                  <WidgetProjectInfo
                    onClickAndamento={() =>
                      user.role === "company" ? (window.location.href = "/empresa-projetos-em-andamento") : (window.location.href = "/projetos-em-andamento")
                    }
                    qtProjects={openProjects}
                    title={" projetos em andamento"}
                    qtPeople={userAgency}
                    text={" pessoas trabalhando em projetos"}
                    linkName={"Ver pessoas"}
                    link={user.role === "agency" ? "/colaboradores" : user.role === "company" ? "/parceiros-colaboradores" : ""}
                  >
                    <WidgetNumberWithClick
                      onClick={() => (window.location.href = "/projetos-desatualizados")}
                      background={totalProjectNotUpdate > 0 ? "bg-prop-seashell" : "bg-prop-anti-flash-white"}
                      value={totalProjectNotUpdate}
                      textColor={totalProjectNotUpdate > 0 ? "prop-firebrick-2" : "prop-weldon-blue"}
                      title="Projetos desatualizados"
                      for={"NotUpdated"}
                    >
                      <WidgetDropdownList id={"NotUpdated"} iconColor={totalProjectNotUpdate > 0 ? "#BA2525" : "#829AB1"}>
                        <div className="notification-scroll">
                          {listProjectNotUpdate.map((projectNotUpdate, index) => (
                            <WidgetDropdownItem
                              key={index}
                              projectID={projectNotUpdate.projectId}
                              projetName={projectNotUpdate.name}
                              days={`${daysDiferance(projectNotUpdate.lastUpdated, new Date())} dias desatualizado`}
                              responsible={`${projectNotUpdate.responsible.firstName} ${projectNotUpdate.responsible.lastName}`}
                            />
                          ))}
                        </div>
                      </WidgetDropdownList>
                    </WidgetNumberWithClick>
                    <WidgetNumberWithClick
                      onClick={() => (window.location.href = "/projetos-atrasados")}
                      background={totalProgressLate > 0 ? "bg-prop-seashell my-2 mt-lg-0 mx-lg-3" : "bg-prop-anti-flash-white my-2 mt-lg-0 mx-lg-3"}
                      value={totalProgressLate}
                      textColor={totalProgressLate > 0 ? "prop-firebrick-2" : "prop-weldon-blue"}
                      title="Projetos atrasados"
                      for={"Late"}
                    >
                      <WidgetDropdownList id={"Late"} iconColor={totalProgressLate > 0 ? "#BA2525" : "#829AB1"}>
                        <div className="notification-scroll">
                          {listProjectsLate.map((projectLate, index) => (
                            <WidgetDropdownItem
                              key={index}
                              projectID={projectLate._id}
                              projetName={projectLate.name}
                              days={`${daysDiferance(projectLate.deadline, new Date())} dias atrasado`}
                              responsible={`${projectLate.responsible.firstName} ${projectLate.responsible.lastName}`}
                            />
                          ))}
                        </div>
                      </WidgetDropdownList>
                    </WidgetNumberWithClick>
                    <WidgetNumberWithClick
                      onClick={() => (window.location.href = "/projetos-na-uti")}
                      background={totalProjectsUTI > 0 ? "bg-prop-yellow my-2 mt-lg-0 mr-lg-3" : "bg-prop-anti-flash-white my-2 mt-lg-0 mr-lg-3"}
                      value={totalProjectsUTI}
                      textColor={totalProjectsUTI > 0 ? "prop-firebrick-2" : "prop-weldon-blue"}
                      title="Precisam de atenção"
                      for="UTI"
                    >
                      <WidgetDropdownList id={"projects-uti"} iconColor={totalProjectsUTI > 0 ? "#BA2525" : "#829AB1"}>
                        <div className="notification-scroll">
                          {listProjectsUti.map((projectUti, index) => (
                            <WidgetDropdownItem
                              key={index}
                              projectID={projectUti._id}
                              projetName={projectUti.name}
                              days={`${daysDiferance(projectUti.deadline, new Date())} dias atrasado`}
                              responsible={`${projectUti.responsible.firstName} ${projectUti.responsible.lastName}`}
                            />
                          ))}
                        </div>
                      </WidgetDropdownList>
                    </WidgetNumberWithClick>
                    <WidgetNumberWithClick
                      background={
                        totalProjectPorcent <= 69
                          ? "bg-prop-seashell my-2 mt-lg-0 ms-lg-3"
                          : totalProjectPorcent <= 79
                          ? "bg-prop-yellow my-2 mt-lg-0 ms-lg-3"
                          : "bg-prop-green-light my-2 mt-lg-0 ms-lg-3"
                      }
                      value={totalProjectPorcent + "%"}
                      textColor={totalProjectPorcent <= 69 ? "prop-red" : totalProjectPorcent <= 79 ? "prop-firebrick-2" : "prop-green"}
                      title="Performance"
                      for="FinalResults"
                    >
                      <WidgetDropdownList id={"Late"} iconColor={totalProjectPorcent <= 69 ? "#ba2525" : totalProjectPorcent <= 79 ? "#ba2525" : "#00a64f"}>
                        <div className="notification-scroll">
                          {listProjectsLate.map((projectLate, index) => (
                            <WidgetDropdownItem
                              key={index}
                              projectID={projectLate._id}
                              projetName={projectLate.name}
                              days={`${daysDiferance(projectLate.deadline, new Date())} dias atrasado`}
                              responsible={`${projectLate.responsible.firstName} ${projectLate.responsible.lastName}`}
                            />
                          ))}
                        </div>
                      </WidgetDropdownList>
                    </WidgetNumberWithClick>
                  </WidgetProjectInfo>
                </div>
                {/* <div className="col-12 col-lg-3 mb-2  ms-auto ">
                  <WidgetProjectInfo
                   qtPeople={widgets.projects}
                   text={" projetos"}
                   linkName={"Ver projetos"}
                   link={
                     user.role === "agency"
                       ? "/projetos-em-andamento"
                       : user.role === "company"
                       ? "/empresa-projetos-em-andamento"
                       : "/projetos-em-andamento"
                   }
                  >
                 
                  </WidgetProjectInfo>
                </div> */}
              </div>
            </div>
            <WidgetTitle title={`Sprints que podem atrasar (${lateSprints.length})`}>
              <div className="col-3 d-flex justify-content-end fz-14 ">
                <a target="_blanck" href="/sprints-podem-atrasar">
                  Ver todas as sprints que podem atrasar
                </a>
              </div>
            </WidgetTitle>
            <div className="col-12 mb-5">
              <div
                className={`d-flex w-100 
                ${lateSprints.length === 0 ? "justify-content-center" : ""}
                `}
              >
                {loadSprints ? (
                  <>
                    <WidgetLateSprintSkeleton /> <WidgetLateSprintSkeleton /> <WidgetLateSprintSkeleton />
                  </>
                ) : (
                  <>
                    {lateSprints.map((sprint, index) =>
                      index <= 2 ? (
                        <div onClick={() => openSprintDrawer(sprint)} className="col-12 col-lg-4" style={{ borderRadius: "5px" }}>
                          <WidgetLateSprint
                            key={index}
                            className={`${index > 0 ? " mt-3 mt-lg-0" : ""}`}
                            projectName={sprint.projectName}
                            sprintName={sprint.sprintName}
                            days={sprint.days}
                            responsible={sprint.responsibleName}
                            totalTask={sprint.totalTask}
                            taskClosed={sprint.qtClosedTask}
                            projectProgress={parseInt((sprint.qtClosedTask * 100) / sprint.totalTask)}
                            bgAlertClass={parseInt((sprint.qtClosedTask * 100) / sprint.totalTask) < 60 ? "bg-prop-firebrick-2" : "bg-prop-yellow-red"}
                          />
                        </div>
                      ) : (
                        <></>
                      )
                    )}
                    {lateSprints.length === 0 ? <EmptyList icon="piscando" message="Todas as sprints estão em dia" /> : <></>}{" "}
                  </>
                )}
              </div>
            </div>
            <WidgetTitle title={`Últimas entregas (${projectsLastDelivery.total})`}>
              <div className="col-12 col-lg-2 ps-0">
                <form className="form-sidebar-header">
                  <select
                    aria-expanded="true"
                    className="form-select form-select-lg border-1 fz-14 box-shadow-none text-prop-grey-light font-rubik notification-scroll"
                  >
                    <option value="">Todos os projetos</option>
                    {projectsClose.map((item, index) => (
                      <option key={index + item.name} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </form>
              </div>
            </WidgetTitle>
            <div className="col-12 mb-0 mb-lg-5">
              <Table
                title="projetos"
                totalItem={projectsLastDelivery.total}
                value={limitItensTable}
                onChangeLimit={(event) => setLimitTable(event.target.value)}
                prevButton={() => prevButton()}
                nextButton={() => nextButton()}
                page={pageItensTable}
                changePage={(event) => changePage(event)}
              >
                <TableHeader>
                  <TableHeaderItem sizeLg={3} title="Nome do Projeto" />
                  <TableHeaderItem sizeLg={3} title="Responsável">
                    <TableHeaderItemDropdown>
                      <li className="dropdown-header d-flex justify-content-end pt-0 pb-1 px-2" style={{ borderBottom: "1px solid #D9D9D9" }}>
                        <div className="input-group input-search">
                          <input
                            type="text"
                            style={{ width: "173px" }}
                            className="input-dropdown form-control fz-14 border-0 box-shadow-none text-cl-auroMetal ff-roboto px-1"
                            placeholder={"Buscar"}
                          />
                          <span className="input-group-text bg-white border-0">
                            <i className="bi bi-search " style={{ color: "#000000" }}></i>
                          </span>
                        </div>
                      </li>
                    </TableHeaderItemDropdown>
                  </TableHeaderItem>
                  <TableHeaderItem sizeLg={2} title="Entrega prevista" />
                  <TableHeaderItem sizeLg={2} title="Entregue em:" />
                  <TableHeaderItem sizeLg={2} title="Entrega">
                    <TableHeaderItemDropdown>
                      <li className="dropdown-header d-flex justify-content-end pt-0 pb-1 px-2" style={{ borderBottom: "1px solid #D9D9D9" }}>
                        <div className="input-group input-search">
                          <input
                            type="text"
                            style={{ width: "173px" }}
                            className="input-dropdown form-control fz-14 border-0 box-shadow-none text-cl-auroMetal ff-roboto px-1"
                            placeholder={"Buscar"}
                          />
                          <span className="input-group-text bg-white border-0">
                            <i className="bi bi-search " style={{ color: "#000000" }}></i>
                          </span>
                        </div>
                      </li>
                    </TableHeaderItemDropdown>
                  </TableHeaderItem>
                </TableHeader>
                <TableBody>
                  {projectsLastDelivery.docs !== undefined ? (
                    projectsLastDelivery.docs.map((item, index) => (
                      <TableBodyCell key={item.name + index}>
                        <TableBodyItem sizeLg={3} title="Nome do projeto" value={item.name} />
                        <TableBodyItem sizeLg={3} title="Responsável" value={item.responsible.firstName + " " + item.responsible.lastName} />
                        <TableBodyItem sizeLg={2} title="Entrega prevista" value={new Date(item.deadline).toLocaleDateString()} />
                        <TableBodyItem sizeLg={2} title="Entregue em" value={new Date(item.statusUpdateDate).toLocaleDateString()} />
                        <TableBodyItemStatus
                          sizeLg={2}
                          title="Entrega"
                          condicionStyle={
                            item.deadline < item.deliveryDate
                              ? "bg-prop-seashell text-prop-red border-firebrick-2"
                              : "bg-prop-mint-cream text-prop-light-sea-green border-ligth-sea-green"
                          }
                          value={item.deadline < item.deliveryDate ? "Com Atraso" : "No prazo"}
                        />
                      </TableBodyCell>
                    ))
                  ) : (
                    <EmptyList icon="indiferente" message="Não foi encontrada nenhuma entrega" />
                  )}
                  {projectsLastDelivery.length === 0 ? (
                    <EmptyList icon="indiferente" message="Não foi encontrada nenhuma entrega" />
                  ) : projectsLastDelivery.docs.length === 0 ? (
                    <EmptyList icon="indiferente" message="Não foi encontrada nenhuma entrega" />
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </div>
            {/* <WidgetTitle title={`Projetos alocados por responsável`} />

            {projectsByResponsible.length > 0 ? (
              projectsByResponsible.map(
                (responsible, index) =>
                  index < showResponsibles && (
                    <WidgetCardListWithImage
                      className={index > 2 ? "mt-3 mt-lg-3" : index > 0 ? "mt-3 mt-lg-0" : ""}
                      key={index + responsible.responsibleName}
                      title={responsible.responsibleName}
                      avatar={responsible.responsibleAvatar}
                      list={responsible.list}
                    />
                  )
              )
            ) : (
              <EmptyList icon="indiferente" message="Não foi encontrado nenhum projeto alocado" />
            )}
            {
              showResponsibles < projectsByResponsible.length && (
                <>
                  <div
                    className={`d-flex ${
                      projectsByResponsible.length > 6 && showResponsibles > 3 ? "justify-content-end col-6" : "justify-content-center col-12"
                    }`}
                  >
                    <button
                      onClick={() => setShowResponsibles(showResponsibles + 3)}
                      className="mt-4 bg-prop-celestial-blue text-white font-rubik fz-16"
                      style={{
                        padding: "12px 40px",
                        borderRadius: "5px",
                        border: "0px solid transparent",
                      }}
                    >
                      Mostrar mais
                    </button>
                  </div>
                  {projectsByResponsible.length > 6 && showResponsibles > 3 ? (
                    <div
                      className={`d-flex ${
                        (projectsByResponsible.length > 6) & (showResponsibles > 3) ? "justify-content-start col-6" : "justify-content-center col-12"
                      }  `}
                    >
                      <button
                        onClick={() => setShowResponsibles(showResponsibles - 3)}
                        className="mt-4 bg-prop-celestial-blue text-white font-rubik fz-16"
                        style={{
                          padding: "12px 40px",
                          borderRadius: "5px",
                          border: "0px solid transparent",
                        }}
                      >
                        Mostrar menos
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )
              // <div className='col-12 d-flex justify-content-center'>
              //     <button onClick={() => setShowResponsibles(showResponsibles - 3)} className='mt-4 bg-prop-celestial-blue text-white font-rubik fz-16' style={{ padding: "12px 40px", borderRadius: "5px", border: "0px solid transparent" }}>Mostrar menos</button>
              // </div>
            } */}
            <WidgetTitle title={`Novos projetos`} />
            {newProjects.length !== 0 ? (
              newProjects.map((project, index) => (
                <div
                  key={index + project.name}
                  onClick={() => (window.location.href = `/projeto/${project._id}/overview`)}
                  className={`col-12 col-lg-4 px-1 ${index > 0 ? "mt-3 mt-lg-0" : ""}`}
                >
                  <WidgetCardListProjectWithImage
                    className={`card-sprints`}
                    title={project.name}
                    image={project.client.logo}
                    responsible={project.responsible.firstName + " " + project.responsible.lastName}
                    company={project.company.name}
                    category={project.category[0]}
                    deadline={formatDate(project.deadline)}
                  />
                </div>
              ))
            ) : (
              <EmptyList icon="indiferente" message="Não foi econtrado nenhum novo projeto nos ultimos 30 dias" />
            )}

            <Footer></Footer>
          </div>
        </div>
      </Layout>
      <Drawer show={drawerOpen} onClose={() => setDrawerOpen(false)} onOpen={() => setDrawerOpen(true)}>
        <div className="p-3 notification-scroll" style={{ maxHeight: "100%" }}>
          <button type="button" onClick={() => setDrawerOpen(false)} className="btn-close" aria-label="Close"></button>
          <a href={`/projeto/${viewSprint.projectId}/overview`} className="font-rubik fw-bold fz-20 text-prop-dark-blue mt-2 d-block underline-hover">
            {viewSprint.projectName}
          </a>
          <a href={`/projeto/${viewSprint.projectId}/overview`} className="font-rubik fz-16 text-prop-dark-eletric-blue d-block mt-1 underline-hover">
            {viewSprint.sprintName}
          </a>
          <div className="d-flex justify-content-around my-2">
            <span className="text-prop-dark-eletric-blue fz-12 font-rubik ms-5">
              Responsável: <span className="text-prop-dark-blue fw-bold">{viewSprint.responsibleName}</span>
            </span>
            <span className="text-prop-dark-eletric-blue fz-12 font-rubik mx-5">
              Data de início: <span className="text-prop-dark-blue fw-bold">{formatDate(viewSprint.sprintStartDate)}</span>
            </span>
            <span className="text-prop-dark-eletric-blue fz-12 font-rubik me-5">
              Data de entrega: <span className="text-prop-dark-blue fw-bold">{formatDate(viewSprint.sprintDeadline)}</span>
            </span>
          </div>
          <div className="" style={{ border: "1px solid #EAEFF4" }}>
            {burndownData.error === undefined || burndownData.error === null ? (
              <BurndownChart ideal={burndownData.ideal} title={burndownData.sprintName} category={burndownData.category} actual={burndownData.actual} />
            ) : burndownData.error === "Sprint start after the end" ? (
              <EmptyList icon="indiferente" message="Sprint foi iniciada depois da deadline" />
            ) : burndownData.error === "Not find sprint open" ? (
              <EmptyList icon="indiferente" message="Nenhuma sprint em andamento" />
            ) : (
              <></>
            )}
          </div>
          <span className="text-prop-dark-blue font-rubik fz-16 fw-bold">{`Tarefas da sprint (${
            viewSprint.sprintTasks !== undefined ? viewSprint.sprintTasks.length : "0"
          })`}</span>
          {viewSprint.sprintTasks !== undefined ? (
            viewSprint.sprintTasks.map((task, index) => (
              <div key={index + task.title} className="d-flex justify-content-between align-items-center mt-2" style={{ borderBottom: "2px solid #EAEFF4" }}>
                <span className={`text-prop-dark-eletric-blue font-rubik fz-14 ${task.status === "closed" ? "text-line-through" : ""}`}>{task.title}</span>
                <span
                  className={` font-rubik fz-14 px-2 mb-2
                                    ${
                                      task.status === "closed"
                                        ? "text-prop-light-sea-green bg-prop-mint-cream border-ligth-sea-green"
                                        : "text-prop-slate-gray bg-prop-anti-flash-white border-slate-gray"
                                    }`}
                >
                  {task.status === "closed" ? "Tarefa concluída" : "Tarefa pendente"}
                </span>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </Drawer>
    </>
  );
}

export default Dashboard;
