import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import api from '../../../config/api';
import Loading from '../../../components/Load';
import WidgetLateSprint from '../../../components/_widgets/WidgetLateSprints';
import { Drawer } from '../../../components/Drawer';
import EmptyList from '../../../components/_empty/list';
import BurndownChart from '../../../components/_charts/BurndownChart';
import { formatDate } from '../../../config/utils';

function Dashboard() {
    const [load, setLoad] = useState(false);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [lateSprints, setLateSprints] = useState([]);
    const [burndownData, setBurndownData] = useState({});
    const [viewSprint, setViewSprint] = useState({});

    useEffect(() => {
        getLateSprints();
    }, []);

    const getLateSprints = async () => {
        setLoad(true);
        const response = await api.get(`/dashboard-late-sprints`);
        setLateSprints(response.data);
        setLoad(false);
    }

    const getBurndownChart = async (sprint_id) => {
        const response = await api.get(`/dashboard-project-burndown/${sprint_id}?type=sprint`);
        setBurndownData(response.data);
    };

    const openSprintDrawer = (sprint) => {
        setDrawerOpen(true);
        setViewSprint({ ...sprint });
        getBurndownChart(sprint.sprintId);
    }

    return (
        <>
            <Layout defaultMenu={3}>
                <div className="container-fluid">
                    <div className="row mb-5">
                        <div className="col-12 mb-2">
                            <h1 className="mb-0 fz-35 fw-bold font-rubik">{`Sprints que podem atrasar`}</h1>
                        </div>
                        <div className="col-12 mb-4">
                            <h1 className="mb-0 fz-18 fw-bold font-rubik">{`Existem ${lateSprints.length} sprints que podem atrasar`}</h1>
                        </div>
                        {lateSprints.map((sprint, index) => (
                            <div onClick={() => openSprintDrawer(sprint)} className='col-12 col-lg-4 px-0 mb-4 ' style={{ borderRadius: "5px" }}>
                                <WidgetLateSprint key={index}
                                    className={`${(index > 0) ? " mt-3 mt-lg-0" : ""}`}
                                    projectName={sprint.projectName}
                                    sprintName={sprint.sprintName}
                                    days={sprint.days}
                                    responsible={sprint.responsibleName}
                                    totalTask={sprint.totalTask}
                                    taskClosed={sprint.qtClosedTask}
                                    projectProgress={parseInt(sprint.qtClosedTask * 100 / sprint.totalTask)}
                                    bgAlertClass={(parseInt(sprint.qtClosedTask * 100 / sprint.totalTask) < 60) ? "bg-prop-firebrick-2" : "bg-prop-yellow-red"}
                                />
                            </div>
                        ))}
                        {lateSprints.length === 0 ?
                            <div className='col-12 d-flex justify-content-center'>
                                <EmptyList
                                    icon="piscando"
                                    message="Todas as sprints estão em dia"
                                />
                            </div>
                            :
                            <></>
                        }
                    </div>
                </div>
            </Layout >
            <Loading show={load} />
            <Drawer
                show={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                onOpen={() => setDrawerOpen(true)}
            >
                <div className='p-3 notification-scroll' style={{ maxHeight: "100%" }}>
                    <button type="button" onClick={() => setDrawerOpen(false)} className="btn-close" aria-label="Close"></button>
                    <div className='d-flex justify-content-between align-items-center'>
                        <span className='font-rubik fw-bold fz-20 text-prop-dark-blue mt-2'>{viewSprint.projectName}</span>
                        <a href={`/projeto/${viewSprint.projectId}/overview`} className='font-rubik fz-16'>abrir projeto</a>
                    </div>
                    <span className='font-rubik fz-16 text-prop-dark-eletric-blue d-block mt-1'>{viewSprint.sprintName}</span>
                    <div className='d-flex justify-content-around my-2'>
                        <span className='text-prop-dark-eletric-blue fz-12 font-rubik ms-5'>Responsável: <span className='text-prop-dark-blue fw-bold'>{viewSprint.responsibleName}</span></span>
                        <span className='text-prop-dark-eletric-blue fz-12 font-rubik mx-5'>Data de início: <span className='text-prop-dark-blue fw-bold'>{formatDate(viewSprint.sprintStartDate)}</span></span>
                        <span className='text-prop-dark-eletric-blue fz-12 font-rubik me-5'>Data de entrega: <span className='text-prop-dark-blue fw-bold'>{formatDate(viewSprint.sprintDeadline)}</span></span>
                    </div>
                    <div className='' style={{ border: "1px solid #EAEFF4" }}>
                        {(burndownData.error === undefined || burndownData.error === null) ?

                            <BurndownChart ideal={burndownData.ideal} title={burndownData.sprintName} category={burndownData.category} actual={burndownData.actual} />
                            :
                            (burndownData.error === "Sprint start after the end") ?
                                <EmptyList
                                    icon="indiferente"
                                    message="Sprint foi iniciada depois da deadline"
                                />
                                :
                                (burndownData.error === "Not find sprint open") ?
                                    <EmptyList
                                        icon="indiferente"
                                        message="Nenhuma sprint em andamento"
                                    />
                                    :
                                    <></>
                        }
                    </div>
                    <span className='text-prop-dark-blue font-rubik fz-16 fw-bold'>{`Tarefas da sprint (${(viewSprint.sprintTasks !== undefined) ? viewSprint.sprintTasks.length : "0"})`}</span>
                    {(viewSprint.sprintTasks !== undefined) ?
                        viewSprint.sprintTasks.map((task, index) => (
                            <div className='d-flex justify-content-between align-items-center mt-2' style={{ borderBottom: "2px solid #EAEFF4" }}>
                                <span className={`text-prop-dark-eletric-blue font-rubik fz-14 ${(task.status === 'closed') ? "text-line-through" : ""}`}>{task.title}</span>
                                <span className={` font-rubik fz-14 px-2 mb-2
                                    ${(task.status === 'closed') ? "text-prop-light-sea-green bg-prop-mint-cream border-ligth-sea-green" :
                                        "text-prop-slate-gray bg-prop-anti-flash-white border-slate-gray"}`}
                                >
                                    {(task.status === "closed") ? "Tarefa concluída" : "Tarefa pendente"}
                                </span>
                            </div>
                        ))
                        :
                        <></>
                    }
                </div>
            </Drawer>
        </>
    );
}

export default Dashboard;