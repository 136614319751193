import React, { useLayoutEffect, useState } from "react";
// import swal from "sweetalert";
import Layout from "../../../components/Layout";
import { ListBody } from "../../../components/List";
import Loading from "../../../components/Load";
import SidebarProject from "../../../components/SidebarProject";
import { toastsMsg } from "../../../components/ToasterMsg";
// import WidgetClient from "../../../components/_widgets/WidgetClient";
import api from "../../../config/api";
import { decodeToken } from "../../../config/auth";
require("dotenv").config();

function Settings(props) {
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deadlineChanged, setDeadlineChanged] = useState(false);
  const [responsibles, setResponsibles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  let [project, setProject] = useState({
    name: "",
    category: "",
    platform: "",
    startDate: "",
    responsible: "",
    contract: "",
    requirements: "",
    layout: "",
    wireframe: "",
    deadline: "",
  });
  let [user, setUser] = useState({});

  useLayoutEffect(() => {
    getProject(props.match.params.id);
    const { user = {} } = decodeToken();
    setUser(user);
    getCategories();
    getPlatforms();
    getResponsibles();
  }, [props, user.role]);

  const getProject = async (myprojectId = props.match.params.id) => {
    setLoading(true);
    const response = await api.get(`/project-settings/${myprojectId}`);
    // console.log("response.data", response.data);
    setProject(response.data);
    setLoading(false);
  };

  const getCategories = async () => {
    const response = await api.get(`/category?limit=9999&sortBy=name&order=ASC`);
    setCategories(response.data);
  };

  const getPlatforms = async () => {
    const response = await api.get(`/platform?limit=9999&sortBy=name&order=ASC`);
    setPlatforms(response.data);
  };

  const getResponsibles = async () => {
    const response = await api.get(`/user/agency?limit=9999&sortBy=firstName&order=ASC`);
    setResponsibles(response.data);
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);

    await api
      .put(`/project-settings/${project._id}`, project)
      .then(() => {
        setLoad(false);
        toastsMsg("Sucesso!", "Configurações editadas com sucesso!", "success");
        let initialValue = {
          name: "",
          category: "",
          platform: "",
          startDate: "",
          responsible: "",
          contract: "",
          requirements: "",
          layout: "",
          wireframe: "",
          deadline: "",
          comments: "",
        };
        setProject({ ...initialValue });
        getProject(props.match.params.id);
      })
      .catch(() => {
        setLoad(false);
        toastsMsg("Erro!", "Erro ao editar as configurações, tente novamente mais tarde.", "error");
      });
  };

  // const deleteProject = async () => {
  //     swal({
  //         title: "Tem certeza que deseja excluir o projeto?",
  //         text: `Todos os itens dentro do projeto serão apagados e não terão mais volta`,
  //         // icon: "warning",
  //         buttons: ["Cancelar", "EXCLUIR"],
  //         dangerMode: true,
  //     })
  //         .then(async (res) => {
  //             if (res) {
  //                 await api.delete(`/project/${project._id}`)
  //                     .then(async () => {
  //                         swal({ icon: "success", title: "Sucesso!", text: "Projeto removido com sucesso." })
  //                             .then(() => {
  //                                 window.location.href = "/projetos"
  //                             })
  //                     }).catch(() => {
  //                         swal({ icon: "error", title: "Erro!", text: "Erro ao remover o projeto, tente novamente mais tarde." });
  //                     });
  //             }
  //         });
  // }

  const handleChange = (event) => {
    setProject((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const changedEstimatedDate = (event) => {
    setProject((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
    setProject((values) => ({ ...values, comments: "" }));
    setDeadlineChanged(true);
  };

  return (
    <>
      <Layout
        defaultMenu={0}
        isSingleProject={true}
        clientImg={project.client !== undefined ? project.client.logo : ""}
        clientName={project.client !== undefined ? project.client.name : ""}
        projectName={project.name}
      >
        <div className="container-fluid">
          {/* <WidgetClient
                        link={(user.role === 'company') ? "/empresa-projetos-em-andamento" : (user.role === 'agency') ? "/projetos-em-andamento" : (user.role === 'category') ? "/projetos-da-categoria" : "/meus-projetos"}
                        img={(project.client !== undefined) ? project.client.logo : ""}
                        clientName={(project.client !== undefined) ? project.client.name : ""}
                        projectName={project.name}
                    /> */}
          <SidebarProject active={"settings"} project={props.match.params.id} />
          <div className="row">
            <div className="col-12 mb-4">
              <h1 className="mb-0 fz-24 fw-bold font-rubik">Configurações do projeto</h1>
            </div>
            <div className="col-12 mb-4">
              <form onSubmit={save} className="row">
                <div className=" col-12 col-lg-6 d-flex justify-content-start">
                  <ListBody specialClass="shadow-sm p-3 d-flex flex-wrap">
                    <div className="col-12">
                      <span className="font-rubik fw-bold text-prop-grey-light fz-18">Nome do Projeto</span>
                    </div>
                    <div className="col-12 my-3">
                      <input
                        type="text"
                        className="form-control shadow-sm "
                        name="name"
                        value={project.name}
                        onChange={(event) => handleChange(event)}
                        required
                      />
                    </div>
                  </ListBody>
                </div>
                <div className="col-12 col-lg-6  d-flex justify-content-start">
                  <ListBody specialClass="shadow-sm p-3 d-flex flex-wrap">
                    <div className="col-12">
                      <span className="font-rubik fw-bold text-prop-grey-light fz-18">Categoria</span>
                    </div>
                    <div className="col-12 my-3">
                      <select
                        className="form-select shadow-sm "
                        multiple={false}
                        name="category"
                        onChange={(event) => handleChange(event)}
                        value={project.category}
                        required
                      >
                        <option></option>
                        {categories.map((c, index) => (
                          <option value={c.name} key={index}>
                            {c.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </ListBody>
                </div>
                <div className="col-12 col-lg-6  d-flex justify-content-start">
                  <ListBody specialClass="shadow-sm p-3 d-flex flex-wrap">
                    <div className="col-12">
                      <span className="font-rubik fw-bold text-prop-grey-light fz-18">Plataforma do Projeto</span>
                    </div>
                    <div className="col-12 my-3">
                      <select
                        className="form-select shadow-sm "
                        name="platform"
                        onChange={(event) => handleChange(event)}
                        value={project.platform}
                        required
                      >
                        <option></option>
                        {platforms.map((p, index) => (
                          <option value={p.name} key={index}>
                            {p.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </ListBody>
                </div>
                <div className="col-12 col-lg-6  d-flex justify-content-start">
                  <ListBody specialClass="shadow-sm p-3 d-flex flex-wrap">
                    <div className="col-12">
                      <span className="font-rubik fw-bold text-prop-grey-light fz-18">Início do Projeto</span>
                    </div>
                    <div className="col-12 my-3">
                      <input
                        type="date"
                        className="form-control shadow-sm "
                        name="startDate"
                        value={
                          (project.startDate !== undefined) & (project.startDate !== null)
                            ? project.startDate.substr(0, 10)
                            : ""
                        }
                        onChange={(event) => handleChange(event)}
                        required
                      />
                    </div>
                  </ListBody>
                </div>
                <div className="col-12 col-lg-6  d-flex justify-content-start">
                  <ListBody specialClass="shadow-sm p-3 d-flex flex-wrap">
                    <div className="col-12">
                      <span className="font-rubik fw-bold text-prop-grey-light fz-18">Responsável</span>
                    </div>
                    <div className="col-12 my-3">
                      <select
                        className="form-select shadow-sm "
                        name="responsible"
                        onChange={(event) => handleChange(event)}
                        value={project.responsible}
                        required
                      >
                        <option></option>
                        {responsibles.map((r, index) => (
                          <option value={r._id} key={index}>
                            {r.firstName + " " + r.lastName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </ListBody>
                </div>
                <div className="col-12 col-lg-6 d-flex justify-content-start">
                  <ListBody specialClass="shadow-sm p-3 d-flex flex-wrap">
                    <div className="col-12">
                      <span className="font-rubik fw-bold text-prop-grey-light fz-18">Contrato</span>
                    </div>
                    <div className="col-12 my-3">
                      <input
                        type="text"
                        className="form-control shadow-sm "
                        name="contract"
                        value={project.contract !== undefined ? project.contract : ""}
                        onChange={(event) => handleChange(event)}
                      />
                    </div>
                  </ListBody>
                </div>
                <div className="col-12 col-lg-6 d-flex justify-content-start">
                  <ListBody specialClass="shadow-sm p-3 d-flex flex-wrap">
                    <div className="col-12">
                      <span className="font-rubik fw-bold text-prop-grey-light fz-18">Requisitos</span>
                    </div>
                    <div className="col-12 my-3">
                      <input
                        type="text"
                        className="form-control shadow-sm "
                        name="requirements"
                        value={project.requirements}
                        onChange={(event) => handleChange(event)}
                      />
                    </div>
                  </ListBody>
                </div>
                <div className="col-12 col-lg-6 d-flex justify-content-start">
                  <ListBody specialClass="shadow-sm p-3 d-flex flex-wrap">
                    <div className="col-12">
                      <span className="font-rubik fw-bold text-prop-grey-light fz-18">Protótipo</span>
                    </div>
                    <div className="col-12 my-3">
                      <input
                        type="text"
                        className="form-control shadow-sm "
                        name="layout"
                        value={project.layout}
                        onChange={(event) => handleChange(event)}
                      />
                    </div>
                  </ListBody>
                </div>
                <div className="col-12 col-lg-6 d-flex justify-content-start">
                  <ListBody specialClass="shadow-sm p-3 d-flex flex-wrap">
                    <div className="col-12">
                      <span className="font-rubik fw-bold text-prop-grey-light fz-18">Prévia do projeto</span>
                    </div>
                    <div className="col-12 my-3">
                      <input
                        type="text"
                        className="form-control shadow-sm "
                        name="wireframe"
                        value={project.wireframe}
                        onChange={(event) => handleChange(event)}
                      />
                    </div>
                  </ListBody>
                </div>
                <div className="col-12 col-lg-6 d-flex justify-content-start">
                  <ListBody specialClass="shadow-sm p-3 d-flex flex-wrap">
                    <div className="col-12">
                      <span className="font-rubik fw-bold text-prop-grey-light fz-18">Prévisão de entrega</span>
                    </div>
                    <div className="col-12 my-3">
                      <input
                        type="date"
                        className="form-control shadow-sm "
                        name="deliveryDate"
                        value={
                          (project.deliveryDate !== undefined) & (project.deliveryDate !== null)
                            ? project.deliveryDate.substr(0, 10)
                            : (project.deadline !== undefined) & (project.deadline !== null)
                            ? project.deadline.substr(0, 10)
                            : ""
                        }
                        onChange={(event) => handleChange(event)}
                      />
                    </div>
                  </ListBody>
                </div>
                <div className="col-12 col-lg-12  d-flex justify-content-start mb-3">
                  <ListBody specialClass="shadow-sm p-3 d-flex flex-wrap">
                    <div className="col-12">
                      <span className="font-rubik fw-bold text-prop-grey-light fz-18">
                        Previsão do prazo de contrato (Deadline)
                      </span>
                    </div>
                    <div className="col-12 mt-3">
                      <input
                        type="date"
                        className="form-control shadow-sm "
                        name="newDeadline"
                        value={
                          (project.deadline !== undefined) & (project.deadline !== null)
                            ? (project.newDeadline !== undefined) & (project.newDeadline !== null)
                              ? project.newDeadline
                              : project.deadline.substr(0, 10)
                            : (project.newDeadline !== undefined) & (project.newDeadline !== null)
                            ? project.newDeadline
                            : ""
                        }
                        onChange={(event) => changedEstimatedDate(event)}
                        required
                      />
                    </div>
                    <div className="col-12 my-3">
                      <textarea
                        rows="3"
                        className="form-control shadow-sm "
                        name="comments"
                        onChange={(event) => handleChange(event)}
                        placeholder="Motivo:"
                        required={deadlineChanged}
                      />
                    </div>
                  </ListBody>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-end">
                  <div className="col-6 col-lg-2 d-flex align-items-center justify-content-end">
                    {load ? (
                      <button
                        type="button"
                        style={{
                          border: "1px solid #BB2026",
                          borderRadius: "10px",
                        }}
                        className=" btn btn-light d-flex align-items-center justify-content-center px-5 fw-bold py-2 text-white fz-13 text-uppercase font-rubik w-70"
                        disabled
                      >
                        <div className="spinner-border spinner-border-sm text-light" role="status">
                          <span className="visually-hidden">Carregando...</span>
                        </div>
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-4 col-lg-2 d-flex align-items-center justify-content-end">
                    {load ? (
                      <button
                        type="button"
                        style={{ borderRadius: "10px" }}
                        className="btn btn-prop-blue d-flex align-items-center justify-content-center px-5 fw-bold py-2 text-white fz-13 text-uppercase font-rubik w-70"
                        disabled
                      >
                        <div className="spinner-border spinner-border-sm text-light" role="status">
                          <span className="visually-hidden">Carregando...</span>
                        </div>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        style={{ borderRadius: "10px", width: "80%" }}
                        className="btn btn-prop-blue d-flex align-items-center justify-content-center px-5 fw-bold py-2 text-white fz-13 text-uppercase font-rubik"
                      >
                        Salvar
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
      <Loading show={loading} />
    </>
  );
}

export default Settings;
