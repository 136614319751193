import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import {
  List,
  ListBody,
  ListBodyItem,
  ListBodyItemAction,
  ListHeaderItemSortAble,
  ListBodyItemStatus,
  ListHeader,
  ListHeaderItem,
} from "../../../components/List";
import ModalClient from "../../../components/_modals/_agency/Client";
import api from "../../../config/api";
import AddButton from "../../../components/AddButton";
import EmptyList from "../../../components/_empty/list";
// import io from "socket.io-client";
import swal from "sweetalert";
import Loading from "../../../components/Load";
import { toastsMsg } from "../../../components/ToasterMsg";
require("dotenv").config();

function Client() {
  const [clients, setClients] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [file, setFile] = useState("");
  const [id, setId] = useState("");
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("active");
  const [sort, setSort] = useState("");

  useEffect(() => {
    getClients(page);
  }, [page]);

  const getClients = async (mypage = page, filterName = "", filterStatus = statusFilter, sortBy = "createdAt", order = "desc") => {
    setLoading(true);
    const response = await api.get(
      `/client?paginate=true&page=${mypage}&filterName=${filterName}&filterStatus=${filterStatus}&order=${order}&sortBy=${sortBy}`
    );
    const { docs = [], ...arr } = response.data;
    setClients(docs);
    setPages(parseInt(arr.pages));
    setLoading(false);
  };

  const nextPage = () => {
    getClients(page + 1);
    setPage(page === pages ? page : page + 1);
  };

  const backPage = () => {
    getClients(page - 1);
    setPage(page === 1 ? page : page - 1);
  };

  const destroy = async (item) => {
    swal({
      title: "Tem certeza que deseja excluir esse cliente?",
      text: `Não é possivel excluir clientes, esse cliente vai ser colocado como inativo`,
      // icon: "warning",
      buttons: ["Cancelar", "EXCLUIR"],
      dangerMode: true,
    }).then(async (res) => {
      if (res) {
        await api
          .put(`/client/${item._id}`, { name: item.name, status: "inactive" })
          .then(async () => {
            await getClients(page);
            toastsMsg({
              icon: "success",
              title: "Sucesso!",
              text: "Cliente alterado com sucesso.",
            });
          })
          .catch(() => {
            toastsMsg({
              icon: "error",
              title: "Erro!",
              text: "Erro ao alterar o cliente, tente novamente mais tarde.",
            });
          });
      }
    });
  };

  const edit = (item) => {
    setType("edit");
    setName(item.name);
    setStatus(item.status);
    setId(item._id);
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);
    formData.append("status", status);
    await api
      .put(`/client/${id}`, formData)
      .then(() => {
        setLoad(false);
        toastsMsg("Sucesso!", "Cliente editado com sucesso!", "success");
        document.getElementById("btnCloseSingle").click();
        setFile("");
        getClients(page);
      })
      .catch(() => {
        setLoad(false);
        swal("Erro!", "Erro ao editar o cliente, tente novamente mais tarde.", "error");
      });
  };

  const filterChangeHandlerName = (event) => {
    setNameFilter(event.target.value);
    getClients(page, event.target.value, statusFilter);
  };

  const filterChangeHandlerStatus = (event) => {
    setStatusFilter(event.target.value);
    getClients(1, nameFilter, event.target.value);
  };

  const sortByNameChangeHandler = (sortBy) => {
    if (sort !== sortBy) {
      getClients(page, nameFilter, statusFilter, sortBy, "asc");
      setSort(sortBy);
    } else {
      getClients(page, nameFilter, statusFilter);
      setSort("");
    }
  };

  const changePage = (event) => {
    const changePage = event.target.id;
    setPage(parseInt(changePage));
    getClients(changePage);
  };

  return (
    <>
      <Layout defaultMenu={21} defaultSubMenu={201} title={"Clientes"}>
        <div className="container-fluid new-page">
          <div className="row">
            {/* <div className="col-12 mb-4">
                            <h1 className="mb-0 fz-24 fw-bold font-rubik">Clientes</h1>
                        </div> */}
            <div className="col-12 col-lg-4 mt-2 me-auto">
              <div className="input-group input-search">
                <input
                  type="text"
                  className="form-control border-0 fz-14 box-shadow-none text-prop-grey-light font-rubik"
                  onChange={(event) => filterChangeHandlerName(event)}
                  placeholder="O que você está buscando?"
                />
                <span className="input-group-text bg-white border-0">
                  <i className="bi bi-search text-prop-grey-light"></i>
                </span>
              </div>
            </div>

            <AddButton sizeLg={6} onClick={() => setType("add")} text={"Adicionar Cliente"} modalId={"#myModalSingle"} />
            <div className="col-12 col-lg-2 mt-2">
              <select className="filter-select fz-12 text-prop-grey-light font-rubik" onChange={(e) => filterChangeHandlerStatus(e)} required>
                <option value="active" key={1}>
                  Ativo
                </option>
                <option value="inactive" key={2}>
                  Inativo
                </option>
              </select>
            </div>
            <div className="col-12 mb-4 table-border-default">
              <List paginate={true} page={page} pages={pages} backPage={() => backPage()} nextPage={() => nextPage()} changePage={changePage}>
                <ListHeader>
                  <ListHeaderItemSortAble name="Nome do cliente" onClick={() => sortByNameChangeHandler("name")} sizeLg={3} />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("status")} name="Status" sizeLg={3} />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("createdAt")} name="Criado em" sizeLg={2} />
                  <ListHeaderItem name="" sizeLg={4} />
                </ListHeader>
                {clients.map((item, index) => (
                  <ListBody key={index} specialClass="d-flex flex-wrap">
                    <ListBodyItem title="Nome do cliente" value={item.name} sizeLg={3} />
                    <ListBodyItemStatus title="Status" value={item.status === "active" ? "Ativo" : "Inativo"} sizeLg={3} />
                    <ListBodyItem title="Criado em" value={new Date(item.createdAt).toLocaleDateString()} sizeLg={4} />
                    <ListBodyItemAction sizeLg={2}>
                      <li>
                        <span
                          className="dropdown-item fz-14 font-rubik c-pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#myModalSingle"
                          onClick={() => edit(item)}
                        >
                          Editar
                        </span>
                      </li>
                      <li>
                        <span className="dropdown-item fz-14 font-rubik text-prop-danger c-pointer" onClick={() => destroy(item)}>
                          Excluir
                        </span>
                      </li>
                    </ListBodyItemAction>
                  </ListBody>
                ))}
                {clients.length === 0 ? <EmptyList icon="indiferente" message="Não foi encontrado nenhum cliente" /> : <></>}
              </List>
            </div>
          </div>
        </div>
      </Layout>
      <div
        className="modal fade border-0"
        id="myModalSingle"
        tabIndex="-1"
        aria-labelledby="myModalSingleLabel"
        aria-hidden="true"
        style={{ borderRadius: "10px" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {type === "add" ? (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalLabel">
                    Adicionar cliente
                  </h5>
                  <button type="button" id="btnClose" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <ModalClient close={() => document.getElementById("btnClose").click()} />
                </div>
              </>
            ) : (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                    Editar cliente
                  </h5>
                  <button type="button" id="btnCloseSingle" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={save}>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Nome</label>
                        <input type="text" className="form-control" value={name} onChange={(event) => setName(event.target.value)} required />
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Status</label>
                        <select className="form-select" value={status} onChange={(event) => setStatus(event.target.value)} required>
                          <option></option>
                          <option value="active">Ativo</option>
                          <option value="inactive">Inativo</option>
                        </select>
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label htmlFor="formFile" className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                          Logo <span className="fz-10 text-prop-danger font-rubik">Tamanho máximo 2MB</span>
                        </label>
                        <input className="form-control" type="file" id="formFile" onChange={(event) => setFile(event.target.files[0])} />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-12 d-flex align-items-center justify-content-end">
                        <button
                          type="button"
                          className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-28"
                          data-bs-dismiss="modal"
                        >
                          Cancelar
                        </button>
                        {load ? (
                          <button
                            type="button"
                            className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                            disabled
                          >
                            <div className="spinner-border spinner-border-sm text-light" role="status">
                              <span className="visually-hidden">Carregando...</span>
                            </div>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                          >
                            Salvar
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Loading show={loading} />
    </>
  );
}

export default Client;
