import React from "react";
import { daysDiferance } from "../../../config/utils";

export function WidgetCardList(props) {
  return (
    <div
      className={"card border-0 shadow-sm h-100 ps-3 pt-3 "}
      style={{ borderRadius: "7px", border: " 2px solid #D9E2EC", boxShadow: "0px 4px 8px #CBCBCB17" }}
    >
      <div className="d-flex flex-column align-items-start jutify-content-center justify-content-lg-between py-0">
        <span className="d-block d-lg-flex font-rubik text-prop-dark-blue fz-16 mb-2">
          <span className="fw-bold me-1">{props.total + "h"}</span>
          {props.title}
        </span>
        <div className="list-scroll w-100" style={{ maxHeight: "140px" }}>
          {props.list.map((item, index) => (
            <span key={index} className="d-block d-lg-flex font-rubik text-prop-dark-blue fz-16">
              <span className="fw-medium me-1">{item.total_hours + "h"}</span>
              {item.name}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export function WidgetCardListWithImage(props) {
  return (
    <div className={`col-12 col-lg-4 px-2 mb-5 ${props.className !== undefined ? props.className : ""}`}>
      <div className={"card border-1 shadow-sm h-100 "} style={{ borderRadius: "7px", border: " 2px solid #D9E2EC", boxShadow: "0px 4px 8px #CBCBCB17" }}>
        <div className="pb-3 d-flex flex-column align-items-start jutify-content-center justify-content-lg-between py-0 card-with-image">
          <span className="d-flex align-items-center font-rubik text-prop-dark-eletric-blue fz-16 mb-2 fw-bold px-3 pt-3">
            <div className="avatar_container">
              <img className="avatar_user_selected" src={props.avatar} alt="" width={40} height={40} style={{ borderRadius: "5px" }} />
            </div>
            {props.title}
          </span>
          <div className=" w-100" style={{ maxHeight: "140px" }}>
            {props.list.map((item, index) => (
              <div key={item.name + index} className="border-special px-3 bg-anti-flash-white-hover">
                <button
                  type="button"
                  className="w-100 btn btn-secondary dropdown-toggle dropdown-toggle-split p-0 box-shadow-none"
                  id={"teste"}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-reference="parent"
                >
                  <span key={index} className=" d-block d-lg-flex font-rubik text-prop-dark-blue fz-14 py-2  underline-hover c-pointer ">
                    <span className="fw-bold me-1">{item.qt}</span>
                    {item.name}
                  </span>
                </button>
                <ul
                  className="dropdown-menu"
                  id={"teste"}
                  aria-labelledby={props.WidgetProjectInfo}
                  style={{ borderRadius: "5px", right: "auto", left: "20%", top: "40%" }}
                >
                  <div className="notification-scroll">
                    {item.projects !== undefined ? (
                      item.projects.map((project, index) => (
                        <div
                          key={index}
                          onClick={() => (window.location.href = `/projeto/${project._id}/overview`)}
                          className="dropdown-item c-pointer"
                          style={{ width: "278px", borderBottom: "2px solid #EAEFF4" }}
                        >
                          <span className="font-rubik fw-bold fz-14 text-prop-dark-blue">{project.name}</span>
                          <div className="d-flex justify-content-between">
                            {item.name === "Projetos desatualizados" ? (
                              <span className="font-rubik fz-12 text-prop-dark-eletric-blue">{`${daysDiferance(
                                project.lastUpdated,
                                new Date()
                              )} dias desatualizado`}</span>
                            ) : item.name === "Projetos atrasados" || item.name === "Projetos na UTI" ? (
                              <span className="font-rubik fz-12 text-prop-dark-eletric-blue">{`${daysDiferance(
                                project.deadline,
                                new Date()
                              )} dias atrasado`}</span>
                            ) : (
                              <></>
                            )}
                            <span className="font-rubik fz-12 text-prop-dark-eletric-blue">
                              {project.responsible.firstName + " " + project.responsible.lastName}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export function WidgetCardListProjectWithImage(props) {
  return (
    <div
      className={`card border-1 shadow-sm h-100 p-3 ${props.className !== undefined ? props.className : ""}`}
      style={{ borderRadius: "7px", border: " 2px solid #D9E2EC", boxShadow: "0px 4px 8px #CBCBCB17" }}
    >
      <div className="d-flex flex-column align-items-start jutify-content-center justify-content-lg-between py-0">
        <span className="d-flex align-items-center font-rubik text-prop-dark-eletric-blue fz-16 mb-2 fw-bold">
          <img className="me-2" src={props.image} alt="" width={40} height={40} style={{ borderRadius: "5px", border: "2px solid #EAEFF4" }} />
          {props.title}
        </span>
        <div className=" w-100" style={{ maxHeight: "140px" }}>
          <span className="d-block d-lg-flex font-rubik text-prop-dark-eletric-blue fz-14 py-1">
            Responsável: <span className="text-prop-dark-blue ps-1 fw-bold">{props.responsible}</span>
          </span>
          <span className="d-block d-lg-flex font-rubik text-prop-dark-eletric-blue fz-14 py-1">
            Empresa: <span className="text-prop-dark-blue ps-1 fw-bold">{props.company}</span>
          </span>
          <span className="d-block d-lg-flex font-rubik text-prop-dark-eletric-blue fz-14 py-1">
            Categoria do projeto: <span className="text-prop-dark-blue ps-1 fw-bold">{props.category}</span>
          </span>
          <span className="d-block d-lg-flex font-rubik text-prop-dark-eletric-blue fz-14 py-1">
            Deadline: <span className="text-prop-dark-blue ps-1 fw-bold">{props.deadline}</span>
          </span>
        </div>
      </div>
    </div>
  );
}
