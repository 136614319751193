import React, { useState, useEffect } from 'react';
import MaterialIcon from 'material-icons-react';
// import { Link } from 'react-router-dom';
import { SideLayout } from './styles';
import LogoIcon from '../../assets/img/logo-icon.svg';
import LogoText from '../../assets/img/logo-text.svg';
import IconDash from '../../assets/img/icons/icon-dash.svg';
import IconDashHover from '../../assets/img/icons/icon-dash-hover.svg';
import IconProjeto from '../../assets/img/icons/icon-projetos.svg';
import IconProjetoHover from '../../assets/img/icons/icon-projeto-hover.svg';
import IconReuniao from '../../assets/img/icons/icon-reuniao.svg';
import IconReuniaoHover from '../../assets/img/icons/icon-reuniao-hover.svg';
import IconReport from '../../assets/img/icons/icon-reports.svg';
import IconReportHover from '../../assets/img/icons/icon-report-hover.svg';
import IconGerencia from '../../assets/img/icons/icon-gerenciar.svg';
import IconGerenciaHover from '../../assets/img/icons/icon-gerencia-hover.svg';
import IconPasta from '../../assets/img/icons/icon-pasta.svg';
import IconPastaHover from '../../assets/img/icons/icon-pasta-hover.svg';
import IconLancamento from '../../assets/img/icons/icon-lancamento.svg';
import IconLancamentoHover from '../../assets/img/icons/icon-lancamento-hover.svg';

import Menu from '../../assets/img/icons/menu.svg';
import { decodeToken } from '../../config/auth';

export default function Sidebar(props) {
    // const [menu, setMenu] = useState(0);
    const toggleSidebar = localStorage.getItem('toggleSidebar');
    const [isOpen, setIsOpen] = useState(toggleSidebar ? false : true);
    const [user, setUser] = useState({});
    const toggleDropDownProject = localStorage.getItem('toggleDropDownProject');
    const [isDropDownProjectOpen, setDropDownProjectOpen] = useState((isOpen) ? toggleDropDownProject ? false : true : false);
    const toggleDropDown = localStorage.getItem('toggleDropDown');
    const [isDropDownOpen, setDropDownOpen] = useState((isOpen) ? toggleDropDown ? false : true : false);

    function openDropdownProject() {
        if (isOpen === false) {
            setIsOpen(true);
            setDropDownProjectOpen(false);
        }
        if (isDropDownProjectOpen) {
            setDropDownProjectOpen(false);
            localStorage.setItem('toggleDropDownProject', true);
            return;
        }
        setDropDownProjectOpen(true);
        localStorage.removeItem('toggleDropDownProject');
    }

    function openDropdown() {
        if (isOpen === false) {
            setIsOpen(true);
            setDropDownOpen(false);
        }
        if (isDropDownOpen) {
            setDropDownOpen(false);
            localStorage.setItem('toggleDropDown', true);
            return;
        }
        setDropDownOpen(true);
        localStorage.removeItem('toggleDropDown');
    }

    function handleToggleVisible() {
        if (isOpen) {
            setIsOpen(false);
            setDropDownProjectOpen(false);
            setDropDownOpen(false);
            localStorage.setItem('toggleDropDownProject', true);
            localStorage.setItem('toggleDropDown', true);
            localStorage.setItem('toggleSidebar', true);
            return;
        }
        setIsOpen(true);
        localStorage.removeItem('toggleSidebar');
    }

    useEffect(() => {
        const { user: session = {} } = decodeToken();
        setUser(session);
    }, [props.defaultMenu, props.defaultSubMenu]);

    return (
        <SideLayout >
            <nav id="sidebar" className={`d-flex flex-column justify-content-between  ${(window.innerWidth <= 760) ? (props.sidebar) ? 'active' : '' : (isOpen) ? 'active' : ''}`}>
                <img src={Menu} className="img-fluid ms-3 logo-menu" alt="" onClick={() => handleToggleVisible()} />
                <div className='scroll-sidebar'>
                    <div className="d-flex align-items-center sidebar-logo my-5 px-2">
                        <img src={LogoIcon} className="img-fluid ms-1" alt="" width={40} height={40} />
                        <img src={LogoText} className="img-fluid ms-3 logo-2" alt="" width={180} height={40} />
                    </div>
                    <div className="d-flex">
                        <div className="accordion w-100">
                            {(user.role === "agency") ?
                                <>
                                    <SidebarNavLink
                                        link="/dashboard"
                                        icon={IconDash}
                                        iconactive={IconDashHover}
                                        name="Dashboard"
                                        class={props.defaultMenu === 3 ? ' active' : ''}
                                    />
                                    <div className={props.defaultMenu === 0 ? ' active' : ''}>
                                        <div className="card-header" style={{ paddingTop: '16px' }}>
                                            <a id={`dropdown_project`} data-bs-toggle="collapse" href={"#collapseExample_dropdown_project"} role="button" aria-expanded={isDropDownProjectOpen} aria-controls={`collapseExample_dropdown_project`} onClick={() => openDropdownProject()}>
                                                <img src={props.defaultMenu === 0 ? IconProjetoHover : IconProjeto} alt="" />
                                                <span className="ml-2">{"Projetos"}</span>
                                                <div style={{ minHeight: "auto", height: "auto", width: "24%" }} className='d-flex justify-content-end'>
                                                    <MaterialIcon icon='expand_more' />
                                                </div>
                                            </a>
                                            <div className='line'>
                                            </div>
                                        </div>
                                        <div className={`collapse ${isDropDownProjectOpen ? 'show' : ''}`} id={"collapseExample_dropdown_project"}>
                                            <div className="card-body card-drop">
                                                <SidebarNavDropdownLink
                                                    link="/projetos-em-andamento"
                                                    name="Projetos em andamento"
                                                    class={props.defaultSubMenu === 211 ? ' active' : ''}
                                                />
                                                <SidebarNavDropdownLink
                                                    link="/projetos-concluidos"
                                                    name="Projetos concluidos"
                                                    class={props.defaultSubMenu === 212 ? ' active' : ''}
                                                />
                                                <SidebarNavDropdownLink
                                                    link="/projetos-pausados"
                                                    name="Projetos pausados"
                                                    class={props.defaultSubMenu === 213 ? ' active' : ''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <SidebarNavLink
                                        link="/reunioes"
                                        icon={IconReuniao}
                                        iconactive={IconReuniaoHover}
                                        name="Reuniões"
                                        class={props.defaultMenu === 1 ? ' active' : ''}
                                    />
                                    <SidebarNavLink
                                        link="/report"
                                        icon={IconReport}
                                        iconactive={IconReportHover}
                                        name="Reports"
                                        class={props.defaultMenu === 10 ? ' active' : ''}
                                    />
                                    <div className={props.defaultMenu === 21 ? ' active' : ''}>
                                        <div className="card-header" style={{ paddingTop: '16px' }}>
                                            <a id={`dropdown`} data-bs-toggle="collapse" href={"#collapseExample_dropdown"} role="button" aria-expanded={isDropDownOpen} aria-controls={`collapseExample_dropdown`} onClick={() => openDropdown()}>
                                                <img src={props.defaultMenu === 21 ? IconGerenciaHover : IconGerencia} alt="" />
                                                <span className="ml-2">{"Gerenciar"}</span>
                                                <div style={{ minHeight: "auto", height: "auto", width: "20%" }} className='d-flex justify-content-end'>
                                                    <MaterialIcon icon='expand_more' />
                                                </div>
                                            </a>
                                            <div className='line'>
                                            </div>
                                        </div>
                                        <div className={`collapse  ${isDropDownOpen ? 'show' : ''} `} id={"collapseExample_dropdown"}>
                                            <div className="card-body card-drop">
                                                <SidebarNavDropdownLink
                                                    link="/clientes"
                                                    name="Clientes"
                                                    class={props.defaultSubMenu === 201 ? ' active' : ''}
                                                />
                                                <SidebarNavDropdownLink
                                                    link="/acessos-clientes"
                                                    name="Acesso por clientes"
                                                    class={props.defaultSubMenu === 206 ? ' active' : ''}
                                                />
                                                <SidebarNavDropdownLink
                                                    link="/acessos-categoria"
                                                    name="Acesso por categorias"
                                                    class={props.defaultSubMenu === 207 ? ' active' : ''}
                                                />
                                                <SidebarNavDropdownLink
                                                    link="/colaboradores"
                                                    name="Colaboradores"
                                                    class={props.defaultSubMenu === 202 ? ' active' : ''}
                                                />
                                                <SidebarNavDropdownLink
                                                    link="/categorias"
                                                    name="Categoria de projeto"
                                                    class={props.defaultSubMenu === 203 ? ' active' : ''}
                                                />
                                                <SidebarNavDropdownLink
                                                    link="/plataformas"
                                                    name="Plataformas"
                                                    class={props.defaultSubMenu === 204 ? ' active' : ''}
                                                />
                                                <SidebarNavDropdownLink
                                                    link="/departamentos"
                                                    name="Departamentos"
                                                    class={props.defaultSubMenu === 214 ? ' active' : ''}
                                                />
                                                <SidebarNavDropdownLink
                                                    link="/tipos-tarefas"
                                                    name="Tipo de tarefa"
                                                    class={props.defaultSubMenu === 205 ? ' active' : ''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <SidebarNavLink
                                        link="/modelos-de-projeto"
                                        icon={IconPasta}
                                        iconactive={IconPastaHover}
                                        name="Modelos de projeto"
                                        class={props.defaultMenu === 6 ? ' active' : ''}
                                    />
                                </>
                                : <></>}
                            {(user.role === "company") ?
                                <>
                                    <SidebarNavLink
                                        link="/dashboard"
                                        icon={IconDash}
                                        iconactive={IconDashHover}
                                        name="Dashboard"
                                        class={props.defaultMenu === 3 ? ' active' : ''}
                                    />
                                    <div className={props.defaultMenu === 0 ? ' active' : ''}>
                                        <div className="card-header" style={{ paddingTop: '16px' }}>
                                            <a id={`dropdown_project`} data-bs-toggle="collapse" href={"#collapseExample_dropdown_project"} role="button" aria-expanded={isDropDownProjectOpen} aria-controls={`collapseExample_dropdown_project`} onClick={() => openDropdownProject()}>
                                                <img src={props.defaultMenu === 0 ? IconProjetoHover : IconProjeto} alt="" />
                                                <span className="ml-2">{"Projetos"}</span>
                                                <div style={{ minHeight: "auto", height: "auto", width: "24%" }} className='d-flex justify-content-end'>
                                                    <MaterialIcon icon='expand_more' />
                                                </div>
                                            </a>
                                            <div className='line'>
                                            </div>
                                        </div>
                                        <div className={`collapse ${isDropDownProjectOpen ? 'show' : ''}`} id={"collapseExample_dropdown_project"}>
                                            <div className="card-body card-drop">
                                                <SidebarNavDropdownLink
                                                    link="/empresa-projetos-em-andamento"
                                                    name="Projetos em andamento"
                                                    class={props.defaultSubMenu === 211 ? ' active' : ''}
                                                />
                                                <SidebarNavDropdownLink
                                                    link="/empresa-projetos-concluidos"
                                                    name="Projetos concluidos"
                                                    class={props.defaultSubMenu === 212 ? ' active' : ''}
                                                />
                                                <SidebarNavDropdownLink
                                                    link="/empresa-projetos-pausados"
                                                    name="Projetos pausados"
                                                    class={props.defaultSubMenu === 213 ? ' active' : ''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <SidebarNavLink
                                        link="/empresa-reunioes"
                                        icon={IconReuniao}
                                        iconactive={IconReuniaoHover}
                                        name="Reuniões"
                                        class={props.defaultMenu === 1 ? ' active' : ''}
                                    />
                                    <SidebarNavLink
                                        link="/empresa-reports"
                                        icon={IconReport}
                                        iconactive={IconReportHover}
                                        name="Reports"
                                        class={props.defaultMenu === 10 ? ' active' : ''}
                                    />
                                    <div className={props.defaultMenu === 20 ? ' active' : ''}>
                                        <div className="card-header" style={{ paddingTop: '16px' }}>
                                            <a id={`dropdown`} data-bs-toggle="collapse" href={"#collapseExample_dropdown"} role="button" aria-expanded={isDropDownOpen} aria-controls={`collapseExample_dropdown`} onClick={() => openDropdown()}>
                                                <img src={props.defaultMenu === 20 ? IconGerenciaHover : IconGerencia} alt="" />
                                                <span className="ml-2">{"Gerenciar"}</span>
                                                <div style={{ minHeight: "auto", height: "auto", width: "20%" }} className='d-flex justify-content-end'>
                                                    <MaterialIcon icon='expand_more' />
                                                </div>
                                            </a>
                                            <div className='line'>
                                            </div>
                                        </div>
                                        <div className={`collapse ${isDropDownOpen ? 'show' : ''}`} id={"collapseExample_dropdown"}>
                                            <div className="card-body card-drop">
                                                <SidebarNavDropdownLink
                                                    link="/parceiros-empresas"
                                                    name="Empresas"
                                                    class={props.defaultSubMenu === 206 ? ' active' : ''}
                                                />
                                                <SidebarNavDropdownLink
                                                    link="/parceiros-colaboradores"
                                                    name="Colaboradores"
                                                    class={props.defaultSubMenu === 207 ? ' active' : ''}
                                                />
                                                <SidebarNavDropdownLink
                                                    link="/empresa-colaboradores"
                                                    name="Administradores"
                                                    class={props.defaultSubMenu === 208 ? ' active' : ''}
                                                />
                                                  <SidebarNavDropdownLink
                                                    link="/departamentos"
                                                    name="Departamentos"
                                                    class={props.defaultSubMenu === 214 ? ' active' : ''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <SidebarNavLink
                                        link="/todas-empresas"
                                        icon={IconLancamento}
                                        iconactive={IconLancamentoHover}
                                        name="Projetos lançados"
                                        class={props.defaultMenu === 30 ? ' active' : ''}
                                    />
                                </>
                                : <></>}
                            {(user.role === "client") ?
                                <>
                                    <SidebarNavLink
                                        link="/meus-projetos"
                                        icon={IconProjeto}
                                        iconactive={IconProjetoHover}
                                        name="Projetos"
                                        class={props.defaultMenu === 209 ? ' active' : ''}
                                    />
                                    <SidebarNavLink
                                        link="/minhas-reunioes"
                                        icon={IconReuniao}
                                        iconactive={IconReuniaoHover}
                                        name="Minhas Reuniões"
                                        class={props.defaultMenu === 1 ? ' active' : ''}
                                    />
                                </>
                                : <></>}
                            {(user.role === "category") ?
                                <>
                                    <SidebarNavLink
                                        link="/projetos-da-categoria"
                                        icon={IconProjeto}
                                        iconactive={IconProjetoHover}
                                        name="Projetos"
                                        class={props.defaultMenu === 208 ? ' active' : ''}
                                    />
                                    <SidebarNavLink
                                        link="/reunioes-da-categoria"
                                        icon={IconReuniao}
                                        iconactive={IconReuniaoHover}
                                        name="Minhas Reuniões"
                                        class={props.defaultMenu === 210 ? ' active' : ''}
                                    />
                                    {(user.seeReport) ?
                                        <SidebarNavLink
                                            link="/reports-da-categoria"
                                            icon={IconReport}
                                            iconactive={IconReportHover}
                                            name="Meus Reports"
                                            class={props.defaultMenu === 213 ? ' active' : ''}
                                        />
                                        :
                                        <></>
                                    }
                                </>
                                : <></>}
                        </div>
                    </div>
                </div>
            </nav>
        </SideLayout >
    );
}

function SidebarNavLink(props) {
    return (
        <div className={props.class}>
            <div className="card-header" style={{ paddingTop: '16px' }}>
                <a href={props.link}>
                    <img src={props.class ? props.iconactive : props.icon} alt="" />
                    <span className="ml-2">{props.name}</span>
                </a>
                {(props.name !== "Modelos de projeto") ?
                    <div className='line'>
                    </div>
                    : <></>
                }

            </div>
        </div>
    );
}

function SidebarNavDropdownLink(props) {
    return (
        <a className={'d-flex align-items-center my-3 ' + props.class} href={props.link}>
            <span>{props.name}</span>
        </a>
    );
}