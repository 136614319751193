import React from 'react';

function WidgetNumber(props) {

    return (
        <div className={"card border-0 h-100 " + props.background} style={{ borderRadius: "7px" }}>
            <div className="card-header border-0 bg-transparent" />
            <div className="card-body d-flex align-items-start flex-column justify-content-between py-0">
                <span className="d-flex font-rubik text-prop-weldon-blue fz-16 my-auto" style={{ fontWeight: 300 }}>{props.title}</span>
                <span className={`d-flex font-rubik text-prop-dark-blue fw-bold ${(props.fontSizeValue !== undefined) ? props.fontSizeValue : "fz-25"}`} >{props.value}</span>
            </div>
            <div className="card-footer border-0 bg-transparent" />
        </div>
    );
}

export default WidgetNumber;