import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import {
  List,
  ListBody,
  ListBodyItem,
  ListHeaderItemSortAble,
  ListBodyItemStatusEmoji,
  ListHeader,
} from "../../../components/List";
import api from "../../../config/api";
import Loading from "../../../components/Load";
import { formatDate } from "../../../config/utils";
import { Drawer } from "../../../components/Drawer";
import EmptyList from "../../../components/_empty/list";
// import io from "socket.io-client";
require("dotenv").config();

function Reunion() {
  const [reunions, setReunions] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [statusFilter, setStatusFilter] = useState(
    localStorage.getItem("COMPANY_REUNION_FILTER_STATUS") === null
      ? ""
      : localStorage.getItem("COMPANY_REUNION_FILTER_STATUS")
  );
  const [nameFilter, setNameFilter] = useState("");
  const [clientFilter, setClientFilter] = useState(
    localStorage.getItem("COMPANY_REUNION_FILTER_CLIENT") === null
      ? ""
      : localStorage.getItem("COMPANY_REUNION_FILTER_CLIENT")
  );
  const [clients, setClients] = useState([]);
  let [view, setView] = useState({});
  const [sort, setSort] = useState("");
  const [loading, setLoading] = useState(false);
  const [drawerOpenViewReunion, setDrawerOpenViewReunion] = useState(false);

  useEffect(() => {
    getReunions(page, nameFilter, clientFilter, statusFilter);
    getClients();
    // async function registerToSocket() {
    //     const socket = io(`${process.env.REACT_APP_API_URL}`);
    //     socket.on("reunion", () => {
    //         getReunions(page, nameFilter, clientFilter, statusFilter);
    //     });
    // }
    // registerToSocket();
  }, [page]);

  const getReunions = async (
    mypage = 1,
    filterName = "",
    filterClient = "",
    filterStatus = "",
    sortBy = "createdAt",
    order = "desc"
  ) => {
    setLoading(true);
    const response = await api.get(
      `/reunion?paginate=true&page=${mypage}&filterName=${filterName}&filterClient=${filterClient}&filterStatus=${filterStatus}&order=${order}&sortBy=${sortBy}`
    );
    const { docs = [], ...arr } = response.data;
    setReunions(docs);
    setPages(parseInt(arr.pages));
    setLoading(false);
  };

  const getClients = async () => {
    const response = await api.get(`/client?limit=9999&sortBy=name&order=ASC&filterStatus=active`);
    setClients(response.data);
  };

  const nextPage = () => {
    getReunions(page + 1);
    setPage(page === pages ? page : page + 1);
  };

  const backPage = () => {
    getReunions(page - 1);
    setPage(page === 1 ? page : page - 1);
  };

  const visually = (item) => {
    setView({
      _id: item._id,
      client: item.client,
      project: item.project,
      date: formatDate(item.date),
      subject: item.subject,
      rating: item.rating,
      comments: item.comments,
      user: item.user,
      schedule: item.schedule,
    });
    setDrawerOpenViewReunion(true);
  };

  const filterChangeHandlerName = (event) => {
    setNameFilter(event.target.value);
    getReunions(page, event.target.value, clientFilter, statusFilter);
  };

  const filterChangeHandlerClient = (event) => {
    setClientFilter(event.target.value);
    getReunions(page, nameFilter, event.target.value, statusFilter);
    localStorage.setItem("COMPANY_REUNION_FILTER_CLIENT", event.target.value);
  };

  const filterChangeHandlerStatus = (event) => {
    setStatusFilter(event.target.value);
    getReunions(page, nameFilter, clientFilter, event.target.value);
    localStorage.setItem("COMPANY_REUNION_FILTER_STATUS", event.target.value);
  };

  const clearFilter = () => {
    localStorage.removeItem("COMPANY_REUNION_FILTER_CLIENT");
    localStorage.removeItem("COMPANY_REUNION_FILTER_STATUS");
    window.location.reload();
  };

  const sortByNameChangeHandler = (sortBy) => {
    if (sort !== sortBy) {
      getReunions(page, nameFilter, clientFilter, statusFilter, sortBy, "asc");
      setSort(sortBy);
    } else {
      getReunions(page, nameFilter, clientFilter, statusFilter);
      setSort("");
    }
  };

  return (
    <>
      <Layout defaultMenu={1} title={"Reuniões"}>
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-12 mb-4">
                            <h1 className="mb-0 fz-24 fw-bold font-rubik">Reuniões</h1>
                        </div> */}
            <div className="col-12 col-lg-4 mt-2 mb-4">
              <div className="input-group input-search">
                <input
                  type="text"
                  className="form-control border-0 fz-14 box-shadow-none text-prop-grey-light font-rubik"
                  onChange={(event) => filterChangeHandlerName(event)}
                  placeholder="O que você está procurando?"
                />
                <span className="input-group-text bg-white border-0">
                  <i className="bi bi-search text-prop-grey-light"></i>
                </span>
              </div>
            </div>
            {/* <div className="col-12 col-lg-3 mt-2">
                            <select value={(localStorage.getItem("COMPANY_REUNION_FILTER_CLIENT") === null)?"":localStorage.getItem("COMPANY_REUNION_FILTER_CLIENT")} className="filter-select fz-12 text-prop-grey-light font-rubik" onChange={(event) => filterChangeHandlerClient(event)} required>
                                <option value="" key={0}>Selecione o cliente</option>
                                {clients
                                    .map((item, index) => (
                                        <option value={item._id} key={index + 1}>{item.name}</option>
                                    ))}
                            </select>
                        </div>
                        <div className="col-12 col-lg-3 mt-2">
                            <select value={(localStorage.getItem("COMPANY_REUNION_FILTER_STATUS") === null)?"":localStorage.getItem("COMPANY_REUNION_FILTER_STATUS")} className="filter-select fz-12 text-prop-grey-light font-rubik" onChange={(event) => filterChangeHandlerStatus(event)} required>
                                <option value="" key={0}>Selecione o status</option>
                                <option value="Bom" key={1}>Bom</option>
                                <option value="Regular" key={2}>Regular</option>
                                <option value="Ruim" key={3}>Ruim</option>
                            </select>
                        </div>
                        <ClearButton
                            size={12}
                            sizeLg={3}
                            onClick={() => clearFilter()}
                            text={'Limpar Filtros'}
                        /> */}
            <div className="col-12 mb-4 table-border-default">
              <List
                title={"reuniões"}
                paginate={true}
                page={page}
                pages={pages}
                backPage={() => backPage()}
                nextPage={() => nextPage()}
              >
                <ListHeader>
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("subject")}
                    name="Assunto"
                    sizeLg={2}
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("client.name")}
                    name="Cliente"
                    sizeLg={2}
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("project.name")}
                    name="Projeto"
                    sizeLg={2}
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("user.firstName")}
                    name="Colaborador"
                    sizeLg={2}
                  />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("date")} name="Data" sizeLg={2} />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("rating")} name="Status" sizeLg={2} />
                </ListHeader>

                {reunions.map((item, index) => (
                  <ListBody key={index} specialClass="card-with-hover d-flex flex-wrap">
                    <span className="d-flex col-12 text-nowrap" onClick={() => visually(item)}>
                      <ListBodyItem title="Assunto" value={item.subject} sizeLg={2} />
                      <ListBodyItem title="Cliente" value={item.client.name} sizeLg={2} />
                      <ListBodyItem title="Projeto" value={item.project.name} sizeLg={2} />
                      <ListBodyItem
                        title="Colaborador"
                        value={`${item.user.firstName} ${item.user.lastName}`}
                        sizeLg={2}
                      />
                      <ListBodyItem title="Data" value={formatDate(item.date)} sizeLg={2} />
                      <ListBodyItemStatusEmoji title="Status" value={item.rating} sizeLg={2} />
                    </span>
                  </ListBody>
                ))}
                {reunions.length === 0 ? (
                  <EmptyList icon="indiferente" message="Não foi encontrada nenhuma reunião" />
                ) : (
                  <></>
                )}
              </List>
            </div>
          </div>
        </div>
      </Layout>
      <Drawer
        show={drawerOpenViewReunion}
        onClose={() => setDrawerOpenViewReunion(false)}
        onOpen={() => setDrawerOpenViewReunion(true)}
      >
        <div className="p-3 notification-scroll modal-drawer">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark mb-3" id="myModalSingleLabel">
                  {view.subject}
                </h5>
                <button
                  type="button"
                  id="btnCloseSingle"
                  className="btn-close"
                  onClick={() => setDrawerOpenViewReunion(false)}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  {view.user !== undefined ? (
                    <div className="col-12 d-flex align-items-center justify-content-start">
                      <div className="col-4 col-lg-2 d-flex align-items-center justify-content-start">
                        <img
                          src={view.user.avatar}
                          alt=""
                          width={100}
                          height={100}
                          style={{ border: "1px solid #626679" }}
                          className="rounded-circle"
                        />
                      </div>
                      <div className="col-8 col-lg-10 d-flex align-items-start justify-content-start flex-column">
                        <span className="fw-bold">{view.user.firstName + " " + view.user.lastName}</span>
                        <span>{view.date}</span>
                        <span className="fw-bold">
                          {"Status da reunião: "}
                          <span
                            className={`${
                              view.rating === "Bom"
                                ? "fw-normal text-prop-red"
                                : view.rating === "Regular"
                                ? "fw-normal text-prop-warning-light"
                                : "fw-normal text-prop-danger"
                            }`}
                          >
                            {view.rating}
                          </span>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="col-12 col-lg-12 mb-3 mt-2">
                    <label className="form-label font-rubik fw-bold fz-14 mb-2">Observações</label>
                    <div className="form-control" style={{ borderRadius: "5px" }}>
                      <div className="d-flex justify-content-start text-break scroll h-100">
                        <textarea disabled={true} rows={8} className="col-12 p-2 border-0 bg-transparent">
                          {view.comments}
                        </textarea>
                      </div>
                    </div>
                  </div>
                  {(view.schedule !== undefined) & (view.schedule !== " ") ? (
                    <>
                      <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-2">
                        Arquivo para download
                      </label>
                      <div className="col-12 d-flex align-items-center justify-content-start flex-wrap ">
                        <span className="col-12 col-lg-6">Esse relatório possui um arquivo para download</span>
                        <a className="col-12 col-lg-3" target="_blank" rel="noopener noreferrer" href={view.schedule}>
                          <button className=" btn btn-prop-blue text-white font-rubik fz-16 px-3 " type="button">
                            Baixar arquivo
                          </button>
                        </a>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <Loading show={loading} />
    </>
  );
}

export default Reunion;
