import React from 'react';

function WidgetTitleDefault(props) {

    return (
        <>
        <div className="navbar-brand mt-3 mt-lg-0 me-0 me-auto p-2 bd-highlight">
            <h1 className="mb-1 fz-24 fw-bold font-rubik ">{props.title}</h1>
        {props.subTitle? (
            <h1 className="mb-0 fz-14 text-prop-grey-light font-rubik ">{props.subTitle}</h1>
            ):( <></>
            )}
        </div>
        </>
    );
}

export default WidgetTitleDefault;