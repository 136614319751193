import React from "react";
import arrowDown from "../../assets/img/icons/arrow_down.svg";
import bad from "../../assets/img/icons/bad.svg";
import average from "../../assets/img/icons/average.svg";
import good from "../../assets/img/icons/good.svg";

export function Table(props) {
  let pages = [];
  let qtPages = Math.ceil(props.totalItem / props.value);
  for (let count = 1; count < qtPages + 1; count++) {
    pages.push(count);
  }

  return (
    <>
      <div className="row m-0 w-100">
        <div className={`table-body bg-white col-12 col-lg px-0 ${(props.className !== undefined) & (props.className !== null) ? props.className : ""}`}>
          {props.children}
        </div>
        <div className="col-12 px-0 d-block d-lg-flex justify-content-between mt-3 m-0 align-items-center">
          <form className="border-auro-metal w-auto br-5">
            <select
              aria-expanded="true"
              onChange={props.onChangeLimit}
              value={props.value}
              style={{ border: "2px solid #EAEFF4" }}
              className="c-pointer form-select form-select-lg fz-14 box-shadow-none text-prop-dark-eletric-blue ff-montserrat "
            >
              <option value="6">{`Mostrar 06 ${props.title}`}</option>
              <option value="8">{`Mostrar 08 ${props.title}`}</option>
              <option value="16">{`Mostrar 16 ${props.title}`}</option>
            </select>
          </form>
          {pages.length ? (
            <div className="mt-3 mt-lg-0 d-flex justify-content-around justify-content-lg-between">
              <span
                disabled={pages.length === 0}
                onClick={props.prevButton}
                className={`text-prop-dark-eletric-blue ff-univanova-bold fz-14 ${pages.length === 0 ? "" : "c-pointer"} `}
              >
                Anterior
              </span>
              {pages.map((item, index) => (
                <span
                  key={item + index}
                  onClick={props.changePage}
                  id={item}
                  className={`${
                    props.page == item ? "text-prop-celestial-blue" : "text-prop-dark-eletric-blue"
                  } c-pointer ff-univanova-bold fz-14 t-decoration-none ${index % 2 === 0 ? "mx-2" : ""}`}
                >
                  {item}
                </span>
              ))}
              <span
                disabled={pages.length === 0}
                onClick={props.nextButton}
                className={`text-prop-dark-eletric-blue ff-univanova-bold fz-14 ms-2 ${pages.length === 0 ? "" : "c-pointer"}`}
              >
                Próximo
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export function TableHeader(props) {
  return (
    <>
      <div
        className={`row m-0 px-2 py-2 d-none d-lg-flex ${(props.className !== undefined) & (props.className !== null) ? props.className : ""}`}
        style={{ borderBottom: "2px solid #EAEFF4" }}
      >
        {props.children}
      </div>
    </>
  );
}

export function TableHeaderItem(props) {
  return (
    <>
      <div
        className={`d-flex align-items-center col-12 col-lg-${props.sizeLg} ${
          (props.className !== undefined) & (props.className !== null) ? props.className : ""
        }`}
      >
        <span className=" font-rubik fz-16 text-prop-dark-eletric-blue fw-bold">{props.title}</span>
        {props.children}
      </div>
    </>
  );
}

export function TableHeaderItemDropdown(props) {
  return (
    <>
      <button
        type="button"
        className="btn btn-secondary dropdown-toggle dropdown-toggle-split p-0 box-shadow-none"
        id={props.id}
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-reference="parent"
      >
        <img src={arrowDown} width={24} height={24} alt="" className="icon-rotate" />
      </button>
      <ul className="dropdown-menu" aria-labelledby={props.id} style={{ borderRadius: "0px" }}>
        {props.children}
      </ul>
    </>
  );
}

export function TableBody(props) {
  return (
    <div className={` row m-0 px-2 cell-list ${(props.className !== undefined) & (props.className !== null) ? props.className : ""}`}>{props.children}</div>
  );
}

export function TableBodyCell(props) {
  return (
    <>
      <div className={`row m-0 px-1 py-2 d-flex align-items-center ${(props.className !== undefined) & (props.className !== null) ? props.className : ""}`}>
        {props.children}
      </div>
      <div className="d-flex justify-content-center">
        <div style={{ backgroundColor: "#EAEFF4", height: "2px", width: "100%" }}></div>
      </div>
    </>
  );
}

export function TableBodyItem(props) {
  return (
    <>
      <div
        className={`d-flex align-items-center col-12 col-lg-${props.sizeLg} ${
          (props.className !== undefined) & (props.className !== null) ? props.className : ""
        }`}
      >
        {props.title !== "" ? <span className="d-flex d-lg-none font-rubik fz-14 text-prop-dark-eletric-blue fw-bold pe-1">{props.title}:</span> : <></>}
        <span className=" font-rubik fz-14 text-prop-dark-blue  ">{props.value}</span>
      </div>
    </>
  );
}

export function TableBodyItemStatus(props) {
  return (
    <>
      <div
        className={`d-flex align-items-center col-12 col-lg-${props.sizeLg} ${
          (props.className !== undefined) & (props.className !== null) ? props.className : ""
        }`}
      >
        {props.title !== "" ? <span className="d-flex d-lg-none font-rubik fz-14 text-prop-dark-eletric-blue fw-bold pe-1">{props.title}:</span> : <></>}
        <span className={` font-rubik fz-14 p-1 ${props.condicionStyle}`}>{props.value}</span>
      </div>
    </>
  );
}

export function TableBodyItemStatusEmoji(props) {
  return (
    <>
      <div
        className={`d-flex align-items-center col-12 col-lg-${props.sizeLg} ${
          (props.className !== undefined) & (props.className !== null) ? props.className : ""
        }`}
      >
        {props.title !== "" ? <span className="d-flex d-lg-none font-rubik fz-14 text-prop-dark-eletric-blue fw-bold pe-1">{props.title}:</span> : <></>}
        <img src={props.value === "Bom" ? good : props.value === "Regular" ? average : bad} alt="" className="img-fluid " width={24} height={24} />
      </div>
    </>
  );
}

export function TableBodyItemAction(props) {
  return (
    <>
      <div
        className={`col-12 col-lg-${props.sizeLg} d-flex align-items-center justify-content-end ${
          (props.className !== undefined) & (props.className !== null) ? props.className : ""
        }`}
      >
        <div className="btn-group">
          <button type="button" className="btn dropdown-toggle py-0" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="bi bi-three-dots-vertical"></i>
          </button>
          <ul className="dropdown-menu" style={{ borderRadius: "10px" }}>
            {props.children}
          </ul>
        </div>
      </div>
    </>
  );
}
export function TableBodyItemActionReports(props) {
  return (
    <>
      <div
        className={`col-12 col-lg-${props.sizeLg} d-flex align-items-center justify-content-end ${
          (props.className !== undefined) & (props.className !== null) ? props.className : ""
        }`}
        style={{ borderBottom: "1px solid #D9D9D9" }}
      >
        <div className="btn-group">
          <button type="button" className="btn dropdown-toggle py-0" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="bi bi-three-dots-vertical"></i>
          </button>
          <ul className="dropdown-menu" style={{ borderRadius: "10px" }}>
            {props.children}
          </ul>
        </div>
      </div>
    </>
  );
}
