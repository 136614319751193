import React, { useState } from "react";
import bad from "../../assets/img/icons/bad.svg";
import average from "../../assets/img/icons/average.svg";
import good from "../../assets/img/icons/good.svg";
import play from "../../assets/img/icons/play.svg";
import confirmed from "../../assets/img/icons/confirmed.svg";
import sort from "../../assets/img/icons/arrow_down.svg";
import pause from "../../assets/img/icons/pause.svg";
import MaterialIcon from "material-icons-react";
import expand from "../../assets/img/icons/v.svg";
import editIcon from "../../assets/img/icons/edit-icon.svg";
import { CardItemSprintActionNew, CardItemModelActionNew } from "../ColorCard";
import Skeleton from "react-loading-skeleton";
import { useRef, useEffect } from "react";
import { format, formatDistanceStrict } from "date-fns";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

export function List(props) {
  let pages = [];
  let qtPages = Math.ceil(props.page + 5);
  let pageMin = 1;
  if (props.page > 5) {
    pageMin = props.page - 5;
  }
  if (qtPages > props.pages) {
    qtPages = props.pages;
  }
  for (let count = pageMin; count < qtPages + 1; count++) {
    pages.push(count);
  }
  return (
    <>
      {props.children}
      {props.paginate && (
        <div className="card-footer py-3 border-0">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <form className="border-auro-metal w-auto br-5">
                <select
                  aria-expanded="true"
                  onChange={props.onChangeLimit}
                  value={props.value}
                  style={{ border: "2px solid #EAEFF4" }}
                  className="c-pointer form-select form-select-lg fz-14 box-shadow-none text-prop-dark-eletric-blue ff-montserrat "
                >
                  <option value="20">{`Mostrar 20 ${props.title || ""}`}</option>
                  <option value="35">{`Mostrar 35 ${props.title || ""}`}</option>
                  <option value="50">{`Mostrar 50 ${props.title || ""}`}</option>
                </select>
              </form>
              {/* <span className="font-rubik fw-bold text-prop-grey-light fz-14">Página {props.page} de {props.pages}</span> */}
              {/* <div className="btn-group" role="group" aria-label="Basic example">
                                <button type="button" className="btn btn-prop-blue text-white text-uppercase fz-14 font-rubik fw-bold" onClick={props.backPage} disabled={props.page === 1}>Anterior</button>
                                <button type="button" className="btn btn-prop-blue text-white text-uppercase fz-14 font-rubik fw-bold" onClick={props.nextPage} disabled={props.page === props.pages}>Próxima</button>
                                </div> */}

              {props.pages > 1 ? (
                <div className="mt-3 mt-lg-0 d-flex justify-content-around justify-content-lg-between">
                  <span
                    disabled={props.page >= 1}
                    onClick={props.backPage}
                    className={`text-prop-dark-eletric-blue ff-univanova-bold fz-14 ${props.page <= 1 ? "" : "c-pointer"} `}
                  >
                    Anterior
                  </span>
                  {pages.map((item, index) => (
                    <span
                      key={item + "List"}
                      onClick={props.changePage}
                      id={item}
                      className={`${
                        props.page === item ? "text-prop-celestial-blue" : "text-prop-dark-eletric-blue"
                      } c-pointer ff-univanova-bold fz-14 t-decoration-none ${index % 2 === 0 ? "mx-2" : ""}`}
                    >
                      {item}
                    </span>
                  ))}
                  <span
                    disabled={props.page <= pages.length}
                    onClick={props.nextPage}
                    className={`text-prop-dark-eletric-blue ff-univanova-bold fz-14 ms-2 ${pages === 0 ? "" : "c-pointer"}`}
                  >
                    {" "}
                    Próximo
                  </span>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export function ListHeader(props) {
  return (
    <div
      className={`card-header bg-white ${props.specialClass !== undefined ? props.specialClass : ""} d-none d-lg-flex py-2 `}
      style={{ borderBottom: "1px solid #D9D9D9" }}
    >
      {props.children}
    </div>
  );
}

export function ListHeaderItem(props) {
  return (
    <div className={`col-12 col-lg-${props.sizeLg} ${props.className !== undefined ? props.className : ""}`}>
      <span className="font-rubik py-2 fw-bold text-prop-grey-light fz-14">{props.name}</span>
    </div>
  );
}

export function ListHeaderItemSortAble(props) {
  return (
    <div className={`col-12 col-lg-${props.sizeLg} ${props.className !== undefined ? props.className : ""}`}>
      <span className="font-rubik py-2 fw-bold text-prop-grey-light fz-16 mx-1">{props.name}</span>
      <img src={sort} alt="" className="img-fluid  c-pointer" onClick={props.onClick} width={18} height={18} />
    </div>
  );
}

export function ListHeaderItemAction(props) {
  return (
    <div className={props.className}>
      <div className="btn-group">
        <button type="button" className="btn dropdown-toggle py-0 " data-bs-toggle="dropdown" aria-expanded="false">
          <i className="bi bi-three-dots-vertical"></i>
        </button>
        <ul className="dropdown-menu" style={{ borderRadius: "10px" }}>
          {props.children}
        </ul>
      </div>
    </div>
  );
}

export function ListBody(props) {
  return (
    <div
      className={`card-body ${props.status !== undefined ? (props.status !== "closed" ? "bg-white" : "bg-prop-green-light") : "bg-white"}  ${
        props.specialClass === undefined ? "" : props.specialClass
      } `}
    >
      {props.children}
    </div>
  );
}

export function ListBodyItem(props) {
  return (
    <div
      className={`col-12 col-lg-${props.sizeLg} d-flex align-items-center justify-content-start ${
        props.specialClass !== undefined ? props.specialClass : ""
      } mb-2 mb-lg-0 ${props.link !== undefined ? "c-pointer" : ""}`}
      onClick={() => (window.location.href = `${props.link !== undefined ? props.link : "#"}`)}
      style={{ borderBottom: "1px solid #D9D9D9" }}
    >
      {/* {props.title !== "" ? (
        <span
          className="font-rubik fw-bold text-prop-grey-light fz-12 d-flex d-lg-none me-1 overflow-dots"
          title={`${props.title}`}
        >
          {props.title}:
        </span>
      ) : (
        <></>
      )} */}
      <span
        className={`font-rubik mx-2 text-prop-grey-light fz-14 my-2 py-1 ${props.title === "Deadline" || props.title === "Data" ? "" : "overflow-dots"}`}
        title={`${props.value}`}
      >
        {props.value}
      </span>
    </div>
  );
}

export function ListBodyItemModal(props) {
  return (
    <span
      data-bs-toggle="modal"
      data-bs-target={props.modalId}
      className={`col-12 col-lg-${props.sizeLg} d-flex align-items-center justify-content-start ${
        props.specialClass !== undefined ? props.specialClass : ""
      } mb-2 mb-lg-0 ${props.link !== undefined ? "c-pointer" : ""}`}
      onClick={props.onClick}
      style={{ borderBottom: "1px solid #D9D9D9" }}
    >
      {props.title !== "" ? <span className="font-rubik fw-bold text-prop-grey-light fz-12 d-flex d-lg-none me-1">{props.title}:</span> : <></>}
      <span className="font-rubik mx-2 text-prop-grey-light fz-14  my-2 py-1 overflow-dots" title={`${props.value}`}>
        {props.value}
      </span>
    </span>
  );
}

export function ListBodyItemStatusEmojiModal(props) {
  return (
    <span
      data-bs-toggle="modal"
      data-bs-target={props.modalId}
      className={`col-12 col-lg-${props.sizeLg} ${props.specialClass !== undefined ? props.specialClass : ""} d-flex align-items-center mb-2 mb-lg-0`}
      onClick={props.onClick}
      style={{ borderBottom: "1px solid #D9D9D9" }}
    >
      <span className="font-rubik fw-bold text-prop-grey-light fz-12 d-flex d-lg-none me-1">{props.title}:</span>
      <img src={props.value === "Bom" ? good : props.value === "Regular" ? average : bad} alt="" className="img-fluid ms-4" width={24} height={24} />
    </span>
  );
}

export function ListBodyItemColorful(props) {
  return (
    <div
      className={`col-12 col-lg-${props.sizeLg} d-flex align-items-center justify-content-start ${
        props.specialClass !== undefined ? props.specialClass : ""
      } mb-2 mb-lg-0 ${props.link !== undefined ? "c-pointer" : ""}`}
      onClick={() => (window.location.href = `${props.link !== undefined ? props.link : "#"}`)}
    >
      {props.title !== "" ? <span className={`font-rubik fw-bold ${props.color} fz-12 d-flex d-lg-none me-1`}>{props.title}:</span> : <></>}
      <span className={`font-rubik ${props.color} fz-14 d-flex`}>{props.value}</span>
    </div>
  );
}

export function ListBodyItemProgressBar(props) {
  return (
    <div
      className={`col-12 col-lg-${props.sizeLg} d-flex align-items-center justify-content-start ${
        props.specialClass !== undefined ? props.specialClass : ""
      } mb-2 mb-lg-0 ${props.link !== undefined ? "c-pointer" : ""}`}
      onClick={() => (window.location.href = `${props.link !== undefined ? props.link : "#"}`)}
      style={{ borderBottom: "1px solid #D9D9D9" }}
    >
      <span className="font-rubik fw-bold text-prop-grey-light fz-12 d-flex d-lg-none me-1">{props.title}:</span>
      <div className="progress rounded col-7 col-lg-9 " style={{ height: 6 }}>
        <div
          className={`progress-bar ${props.bgAlertClass}`}
          role="progressbar"
          style={{ width: `${props.projectProgress}%` }}
          aria-valuenow={props.projectProgress}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <span className={`font-rubik fw-bold fz-14 px-2 col-1 col-lg-3 ${props.textAlertClass}`}>{props.projectProgress}%</span>
    </div>
  );
}

export function ListBodyItemStatus(props) {
  return (
    <div
      className={`col-12 col-lg-${props.sizeLg} d-flex align-items-center ${props.specialClass !== undefined ? props.specialClass : ""} mb-2 mb-lg-0 ${
        props.link !== undefined ? "c-pointer" : ""
      }`}
      style={{ borderBottom: "1px solid #D9D9D9" }}
    >
      <span className="font-rubik fw-bold text-prop-grey-light fz-12 d-flex d-lg-none me-1">{props.title}:</span>
      <span className={`font-rubik ${props.value === "Ativo" ? "text-prop-cyan" : "text-prop-danger"} fz-14 d-flex`}>{props.value}</span>
    </div>
  );
}

export function ListBodyItemStatusEmoji(props) {
  return (
    <div
      className={`col-12 col-lg-${props.sizeLg} ${props.specialClass !== undefined ? props.specialClass : ""} d-flex align-items-center mb-2 mb-lg-0 ps-4`}
      style={{ borderBottom: "1px solid #D9D9D9" }}
    >
      <span className="font-rubik fw-bold text-prop-grey-light fz-12 d-flex d-lg-none me-1">{props.title}:</span>
      <img src={props.value === "Bom" ? good : props.value === "Regular" ? average : bad} alt="" className="img-fluid " width={24} height={24} />
    </div>
  );
}

export function ListBodyItemAction(props) {
  return (
    <div className={`col-12 col-lg-${props.sizeLg} d-flex align-items-center justify-content-end`} style={{ borderBottom: "1px solid #D9D9D9" }}>
      <div className="btn-group">
        <button type="button" className="btn dropdown-toggle py-0 " data-bs-toggle="dropdown" aria-expanded="false">
          <i className="bi bi-three-dots-vertical"></i>
        </button>
        <ul className="dropdown-menu" style={{ borderRadius: "10px" }}>
          {props.children}
        </ul>
      </div>
    </div>
  );
}

export function ListBodyItemActionTask(props) {
  return (
    <div className={`col-12 col-lg-${props.sizeLg} d-flex align-items-center justify-content-end`}>
      {props.value !== undefined ? (
        <div>
          <button onClick={props.onClick} className="d-flex align-items-center justify-content-center iconButton py-0">
            {props.value === "pause" && props.userTask.find((item) => item._id === props.user._id) !== undefined ? (
              <img src={pause} alt="" style={{ width: 22, height: 22, marginRight: "5px" }} />
            ) : props.value === "entregue" ? (
              <img src={confirmed} alt="" style={{ width: 22, height: 22, marginRight: "5px" }} />
            ) : props.userTask.find((item) => item._id === props.user._id) !== undefined ? (
              <img src={play} alt="" style={{ width: 22, height: 22, marginRight: "5px" }} />
            ) : (
              ""
            )}
            {/* <img src={(props.value === 'pause')? pause: (props.value === 'entregue') ? confirmed : play} alt="" style={{ width: 22, height: 22, marginRight: '5px' }} /> */}
          </button>
        </div>
      ) : (
        <></>
      )}
      <div className="btn-group">
        <button type="button" className="btn dropdown-toggle py-0 " data-bs-toggle="dropdown" aria-expanded="false">
          <i className="bi bi-three-dots-vertical"></i>
        </button>
        <ul className="dropdown-menu" style={{ borderRadius: "10px" }}>
          {props.children}
        </ul>
      </div>
    </div>
  );
}
export function ListBodyItemActionTaskNew(props) {
  return (
    <div className={`col-12 col-lg-${props.sizeLg} d-flex align-items-center justify-content-end`}>
      <div className="btn-group">
        <button type="button" className="btn dropdown-toggle py-0 " data-bs-toggle="dropdown" aria-expanded="false">
          <i className="bi bi-three-dots-vertical"></i>
        </button>
        <ul className="dropdown-menu" style={{ borderRadius: "10px" }}>
          {props.children}
        </ul>
      </div>
    </div>
  );
}

export function ListBodyItemImage(props) {
  return (
    <div className={`col-12 col-lg-${props.sizeLg} d-flex align-items-center mb-2 mb-lg-0`} style={{ borderBottom: "1px solid #D9D9D9" }}>
      <img
        src={props.img}
        alt=""
        className="avatar_user_selected"
        width={40}
        height={40}
        style={{
          borderColor: "#f2f2f2",
          marginRight: "5px",
          borderRadius: "50%",
        }}
      />
    </div>
  );
}

export function ListBodyItemImageAndName(props) {
  return (
    <>
      <div
        className={`col-12 col-lg-${props.sizeLg} d-flex align-items-center mb-2 mb-lg-0 ${props.link !== undefined ? "c-pointer" : ""}`}
        onClick={() => (window.location.href = `${props.link !== undefined ? props.link : "#"}`)}
        style={{ borderBottom: "1px solid #D9D9D9" }}
      >
        <div className="avatar_container">
          <img src={props.img} alt="" className="avatar_user_selected" />
        </div>
        <span className="font-rubik text-prop-grey-light fz-14 d-flex">{props.value}</span>
      </div>
    </>
  );
}

export function ListCollapseHeader(props) {
  return (
    <div
      style={{ color: "#FFFFFF", borderRadius: "0px" }}
      className={`card-header bg-prop-blue mb-2 ${props.specialClass !== undefined ? props.specialClass : ""} d-none d-lg-flex py-3 `}
    >
      {props.children}
    </div>
  );
}

export function ListHeaderItemCollapse(props) {
  return (
    <div className={`col-12 col-lg-${props.sizeLg} ${props.className !== undefined ? props.className : ""}`}>
      <span className="font-rubik py-2 fw-bold text-prop-white fz-14">{props.name}</span>
    </div>
  );
}

export function ListCollapseItemHeader(props) {
  return (
    <>
      <div className={`card-body bg-white ${props.specialClass === undefined ? "" : props.specialClass} d-flex py-3 mb-1 flex-wrap`}>
        <a
          onClick={props.onClick}
          style={{ textDecoration: "none" }}
          href={"#"}
          className={`d-flex aling-items-center ${props.show === true ? "open" : "close"} ${"sprintCollapse-" + props.id}`}
          data-bs-toggle="collapse"
          role="button"
          aria-expanded="false"
          aria-controls={"collapseExample"}
        >
          <MaterialIcon icon="expand_more" />
          <span style={{ color: "#000000", textTransform: "capitalize" }} className="font-rubik p-0 fw-bold fz-16 m-0">
            {props.value}
          </span>
        </a>
      </div>
      <div className={`collapse ${props.show === true ? "show" : ""}`} id={"collapseExample_" + props.id}>
        {props.children}
      </div>
    </>
  );
}

export function ListCollapseItem(props) {
  return (
    <div
      className={`col-12 col-lg-${props.sizeLg} d-flex align-items-center justify-content-start ${
        props.specialClass !== undefined ? props.specialClass : ""
      } mb-2 mb-lg-0 `}
    >
      {props.title !== "" ? <span className="font-rubik fw-bold text-prop-grey-light fz-12 d-flex d-lg-none me-1">{props.title}:</span> : <></>}
      <span className="font-rubik text-prop-grey-light fz-14 d-flex">{props.value}</span>
    </div>
  );
}

export function ListCollapseItemWithPadding(props) {
  return (
    <div
      id={props.id}
      className={`padding-schedule collapse multi-collapse col-12 col-lg-${props.sizeLg} d-flex align-items-center justify-content-start sprint-text ${
        props.specialClass !== undefined ? props.specialClass : ""
      }  mb-2 mb-lg-0`}
    >
      {props.title !== "" ? <span className="font-rubik fw-bold fz-12 d-flex d-lg-none me-1">{props.title}:</span> : <></>}
      <span title={props.value} className="task-text font-rubik fz-14">
        {props.value}
      </span>
    </div>
  );
}

export function ListCollapseItemStatus(props) {
  return (
    <div
      id={props.id}
      className={`collapse multi-collapse col-12 col-lg-${props.sizeLg} d-flex align-items-center justify-content-center ${
        props.specialClass !== undefined ? props.specialClass : ""
      } mb-2 mb-lg-0 `}
    >
      {props.title !== "" ? <span className="font-rubik fw-bold text-prop-grey-light fz-12 d-flex d-lg-none me-1">{props.title}:</span> : <></>}
      {props.loadTaskButton ? (
        <span
          style={{ padding: "6px 10px", minWidth: "149px" }}
          className={`font-rubik  fz-14 d-flex align-items-center justify-content-center ${
            props.value === "closed"
              ? "text-prop-light-sea-green border-ligth-sea-green bg-prop-mint-cream px-4"
              : props.value === "open"
              ? "text-prop-blue-green border-blue-green bg-prop-powder-blue"
              : "text-prop-orange border-orange bg-prop-seashell-2"
          } d-flex`}
        >
          <div class="spinner-border spinner-border-sm " role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </span>
      ) : (
        <span
          style={{ padding: "6px 10px" }}
          className={`font-rubik  fz-14 ${
            props.value === "closed"
              ? "text-prop-light-sea-green border-ligth-sea-green bg-prop-mint-cream px-4"
              : props.value === "open"
              ? "text-prop-blue-green border-blue-green bg-prop-powder-blue"
              : "text-prop-orange border-orange bg-prop-seashell-2"
          } d-flex`}
        >
          {props.value === "closed" ? "Tarefa concluída" : props.value === "open" ? "Tarefa em andamento" : "Tarefa não iniciada"}
        </span>
      )}
    </div>
  );
}

export function ListCollapseItemResponsible(props) {
  return (
    <div
      id={props.id}
      className={`collapse multi-collapse col-12 col-lg-${props.sizeLg} d-flex align-items-center justify-content-end pe-2 ${
        props.specialClass !== undefined ? props.specialClass : ""
      } mb-2 mb-lg-0 `}
    >
      {props.title !== "" ? <span className="font-rubik fw-bold text-prop-grey-light fz-12 d-flex d-lg-none me-1">{props.title}:</span> : <></>}
      {props.value !== undefined ? (
        <>
          {props.value.map((item, index) => (
            <div
              key={index + item.firstName}
              className="d-flex align-items-center justify-content-center rounded-circle"
              style={{ width: "25px", height: "25px", overflow: "hidden", position: "relative" }}
            >
              <img
                index={index}
                src={item.avatar}
                alt=""
                title={`${item?.firstName} ${item?.lastName}`}
                style={{ position: "absolute", width: "25px", height: "25px" }}
              />
            </div>
          ))}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export function ListSprintTasks(props) {
  return (
    <div
      id={props.task._id}
      className={`card-body card-with-hover ${
        props.task.status === "open" && new Date(props.task.estimatedDate) < new Date() ? "bg-prop-rosa" : "bg-white"
      } d-flex py-2 ${props.indexTask === 0 ? "border-top-task-item" : ""}`}
      style={{ border: "1px solid #BCCCDC" }}
    >
      <span
        className="c-pointer d-flex w-100"
        onClick={() => props.showTask(props.task, props.sprint)}
        data-bs-target="#myModalShowTask"
        data-bs-toggle="modal"
      >
        <ListCollapseItemWithPadding specialClass="me-4 " sizeLg="2" title="Nome da etapa" value={props.task.title} />
        <ListCollapseItemWithPadding
          specialClass={`ms-4 me-auto ${props.task.status === "open" && new Date(props.task.estimatedDate) < new Date() ? "text-prop-danger fw-bold" : ""}`}
          sizeLg="2"
          title="Data entrega"
          value={new Date(props.task.estimatedDate).toLocaleDateString("pt-BR", {
            formatMatcher: "best fit",
            timeZone: "UTC",
          })}
        />
        <ListCollapseItemResponsible specialClass="" sizeLg="auto" title="Responsável" value={props.task.responsible} />
        <ListCollapseItemStatus specialClass="" sizeLg="auto" title="Status" value={props.task.status} loadTaskButton={props.loadTaskButton} />
      </span>
      {props.user.role !== "category" ? (
        <div className="col-12 col-lg-auto d-flex justify-content-end">
          <ListBodyItemActionTaskNew
            onClick={() => props.verifyTaskAction(props.task)}
            user={props.user}
            userTask={props.task.responsible}
            value={props.task.status === "closed" ? "entregue" : (props.task.action === "play") & (props.taskOnPlay === props.task._id) ? "pause" : "play"}
            sizeLg={2}
          >
            {props.task.status === "open" ? (
              <li>
                <span
                  className="dropdown-item fz-14 font-rubik c-pointer"
                  onClick={() => {
                    props.changeTask(props.task, "closed");
                    props.setLoadTaskBurron(true);
                  }}
                >
                  Entregar tarefa
                </span>
              </li>
            ) : (
              <li>
                <span
                  className="dropdown-item fz-14 font-rubik c-pointer"
                  onClick={() => {
                    props.changeTask(props.task, "open");
                    props.setLoadTaskBurron(true);
                  }}
                >
                  Abrir tarefa
                </span>
              </li>
            )}
            {(props.task.status === "open") & (props.indexTask.status !== "open") ? (
              <>
                <li>
                  <span
                    className="dropdown-item fz-14 font-rubik c-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#myModalSingleTask"
                    onClick={() => props.editTask(props.task)}
                  >
                    Editar
                  </span>
                </li>
                <li>
                  <span className="dropdown-item fz-14 font-rubik text-prop-danger c-pointer" onClick={() => props.destroyTask(props.task)}>
                    Excluir
                  </span>
                </li>
              </>
            ) : (
              <></>
            )}
          </ListBodyItemActionTaskNew>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export function ListEachSprint(props) {
  const [tituloSprint, setTituloSprint] = useState(() => props.sprint?.title);
  const [tituloBoolean, setTituloBoolean] = useState(true);
  const [dateFinal, setDateFinal] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [countDays, setCountDays] = useState("");
  const [today, setToday] = useState(() => "");
  const [open, setOpen] = useState(false);
  const [startDateState, setStartDate] = useState(() => props.sprint?.startDate);
  const [estimatedDateState, setEstimatedDate] = useState(() => props.sprint?.estimatedDate);
  const [createdAtState, setCreatedAtState] = useState(() => props.sprint?.createdAt);
  const [inputTituloClass, setInputTituloClass] = useState("overflow-hidden");

  useEffect(() => {
    dateFormat();
  }, [startDateState, estimatedDateState]);

  const inputRefTitle = useRef();

  function changeInputTitulo() {
    // if (props.sprint.status !== "pending") {
    //   return toastsMsg("Erro!", `Sprint já iniciada ou concluída`, "error");
    // } else {
    setTituloBoolean(false);
    setInputTituloClass("");
    setTimeout(() => {
      inputRefTitle.current.focus();
    }, 250);
    // }
  }

  function changeInputDate() {
    // if (props.sprint.status !== "pending" && props.sprint.startDate) {
    //   return toastsMsg("Erro!", `Sprint já iniciada ou concluída`, "error");
    // } else {
    setOpen(true);
    setTimeout(() => {
      const element = document.getElementsByClassName("react-daterange-picker__calendar--open")[0];
      element.style.visibility = "visible";
    }, 100);
    // }
  }

  function dateFormat() {
    const dateSprintFinal = new Date(estimatedDateState);
    const dateSprintStart = new Date(startDateState || props.sprint?.createdAt);
    let countDays = 0;
    if (startDateState) {
      countDays = formatDistanceStrict(new Date(startDateState), new Date(estimatedDateState), {
        unit: "day",
        addSuffix: false,
      }).slice(0, 2);
    } else {
      countDays = 0;
    }
    const formattedDateFinal = format(dateSprintFinal, "MMM dd");
    const formattedDateStart = format(dateSprintStart, "MMM dd");
    setCountDays(countDays);
    setDateFinal(formattedDateFinal);
    setDateStart(formattedDateStart);
  }

  function changeSprintNameInput(sprint, tituloSprint) {
    // if (props.sprint.status !== "pending") {
    //   return toastsMsg("Erro!", `Sprint já iniciada ou concluída`, "error");
    // } else {
    props.changeSprintName(sprint, tituloSprint);
    setTituloBoolean(true);
    setInputTituloClass("overflow-hidden");
    // }
  }

  function changeSprintDateInput(sprint, dateSprint) {
    // if (props.sprint.status !== "pending" && props.sprint.startDate) {
    //   return toastsMsg("Erro!", `Sprint já iniciada ou concluída`, "error");
    // } else {
    const startDate = format(dateSprint[0], "yyyy/MM/dd");
    const estimatedDate = format(dateSprint[1], "yyyy/MM/dd");
    setStartDate(startDate);
    setEstimatedDate(estimatedDate);
    props.changeSprintDate(sprint, startDate, estimatedDate);
    setOpen(false);
    // }
  }

  return (
    <div className={`bg-prop-anti-flash-white d-flex py-1 mb-3 flex-wrap rounded-2`}>
      <div className="col-12">
        <div className="row m-0 py-3 align-items-center">
          <div className="col-12 col-lg-2 d-flex align-items-center p-0 ms-2  me-5 ">
            <a
              onClick={() => props.collapseSprint(props.index)}
              href={"#"}
              className={`d-flex aling-items-center ${
                props.index !== -1 ? (props.collapseArr[props.index].show ? "open" : props.index) : "close"
              } sprintCollapse`}
            >
              <img src={expand} className={"m-2 rotate-icon"} />
            </a>
            <div className="d-flex align-items-center sprint-hover p-1 px-2">
              <input
                title={`${tituloSprint}`}
                ref={inputRefTitle}
                size={props.sprint.title.length}
                disabled={props.sprint.status !== "pending" ? true : tituloBoolean}
                className={`${inputTituloClass} text-capitalize text-prop-dark-blue border-0 font-rubik p-0 fw-bold fz-14 m-0 sprint-title overflow-dots-sprints`}
                value={tituloSprint}
                onChange={(event) => setTituloSprint(event.target.value)}
                onBlur={() => changeSprintNameInput(props.sprint, tituloSprint)}
              ></input>
              <a onClick={() => changeInputTitulo()} className={"edit-icon align-items-center"}>
                <img src={editIcon} className={"d-flex edit-icon align-items-center ms-1"} height={10} width={10} />
              </a>
            </div>
            {/* <span style={{ color: "#102A43", textTransform: "capitalize" }} className="font-rubik p-0 fw-bold fz-14 m-0">{props.sprint.title}</span> */}
          </div>
          <div className="col-lg-2 d-flex align-items-center c-pointer click-sprint me-auto fz-14">
            <div class="col-lg-auto d-flex ">
              <div class="d-flex align-items-center fw-bold sprint-hover px-2">
                <label>{countDays ? `${dateStart} - ${dateFinal}` : `${today} - ${dateFinal}`}</label>
                <a
                  className={"edit-icon"}
                  onClick={() => {
                    changeInputDate();
                  }}
                >
                  <img class="ms-2 edit-icon d-flex align-items-center" src={editIcon} height={10} />
                </a>
              </div>
              <div style={{ visibility: "hidden" }} className={""} id={"date"}>
                <DateRangePicker
                  locale={"pt-BR"}
                  value={[new Date(startDateState ? startDateState : createdAtState), new Date(estimatedDateState)]}
                  onCalendarClose={() => setOpen(false)}
                  isOpen={open}
                  onChange={(event) => changeSprintDateInput(props.sprint, event)}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 px-lg-2 d-flex justify-content-end align-items-center me-3">
            {props.isSprint ? (
              <CardItemSprintActionNew
                isTaskOpen={props.isTaskOpen}
                openTask={props.openTask}
                status={props.status}
                role={props.role}
                task={props.task}
                openSprint={props.openSprint}
                closeSprint={props.closeSprint}
                edit={props.edit}
                destroy={props.destroy}
              />
            ) : (
              <CardItemModelActionNew edit={props.edit} destroy={props.destroy} />
            )}
            {props.sprint.status === "closed" ? (
              <>
                <span className="text-prop-light-sea-green border-ligth-sea-green bg-prop-mint-cream font-rubik fz-16 d-flex align-items-center px-3 py-1">
                  <img src={confirmed} alt="" width={18} height={18} className="me-2" /> {"Sprint concluída"}
                </span>
                <div className="btn-group">
                  <button type="button" className="btn dropdown-toggle py-0" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bi bi-three-dots-vertical"></i>
                  </button>

                  <ul className="dropdown-menu" style={{ borderRadius: "10px" }}>
                    <li>
                      <span className="dropdown-item fz-14 font-rubik c-pointer" data-bs-toggle="modal" data-bs-target="#myModalSingle" onClick={props.edit}>
                        Editar
                      </span>
                    </li>
                    <li>
                      <span className="dropdown-item fz-14 font-rubik text-prop-danger c-pointer" onClick={props.destroy}>
                        Excluir
                      </span>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div
        className={"px-4 w-100 collapse " + (props.index !== -1 ? (props.collapseArr[props.index].show === true ? "show" : "close") : "close index -1 ")}
        id={"collapseExample_" + props.sprint._id}
      >
        {!props.sprint.task.length ? (
          <div className={"px-4 w-100"}>
            <div className={`card-body-addsprint  d-flex py-2 justify-content-center`}>
              <span className="font-rubik text-prop-grey-light fz-14 d-flex my-2">Sua tarefa aparecerá aqui</span>
            </div>
          </div>
        ) : (
          <>
            {props.sprint.task.map((task, indexTask) => {
              return (
                <ListSprintTasks
                  key={indexTask + "ListSprintTasks"}
                  changeTask={props.changeTask}
                  destroyTask={props.destroyTask}
                  task={task}
                  indexTask={indexTask}
                  user={props.user}
                  editTask={props.editTask}
                  showTask={props.showTask}
                  sprint={props.sprint}
                  loadTaskButton={props.loadTaskButton}
                  setLoadTaskBurron={props.setLoadTaskBurron}
                />
              );
            })}{" "}
          </>
        )}
        <div className="col-12 my-3 col-lg-auto d-flex justify-content-start">
          <button
            onClick={() => props.addTask(props.sprint._id)}
            className="btn fw-bold font-ruby fz-14 "
            data-bs-toggle="modal"
            data-bs-target="#myModalSingleTask"
            type="button"
          >
            + Criar Tarefa
          </button>
        </div>
      </div>
    </div>
  );
}

export function ListEachSprintSkeleton(props) {
  return (
    <>
      <div className={`bg-prop-anti-flash-white py-1 mb-3 flex-wrap`} style={{ borderRadius: "8px" }}>
        <div className="col-12 d-flex ">
          <div className="ms-2 row m-0 py-3 me-5 align-items-center">
            <Skeleton width={100} borderRadius={5} height={20} />
          </div>
          <div className="row m-0 py-3 align-items-center me-auto">
            <Skeleton width={300} borderRadius={5} height={20} />
          </div>
          <div className="row m-0 py-3 align-items-center me-3">
            <Skeleton width={200} borderRadius={5} height={20} />
          </div>
        </div>
      </div>
    </>
  );
}
