import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
// import AreaChartRevenue from '../../../components/_charts/AreaChart/Revenue';
import Loading from "../../../components/Load";
import api from "../../../config/api";
import WidgetTitle from "../../../components/_widgets/WidgetTitle";
import WidgetChart from "../../../components/_widgets/WidgetChart";
import EmptyList from "../../../components/_empty/list";
import {
  WidgetProjectInfo,
  WidgetNumberWithClickAdmin,
  WidgetNumberWithClickAdminList,
} from "../../../components/_widgets/WidgetProjectsInfo";
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableHeaderItemDropdown,
  TableBody,
  TableBodyCell,
  TableBodyItem,
  TableBodyItemStatus,
} from "../../../components/Table";
import {
  faCheckCircle,
  faCircleXmark,
} from "@fortawesome/free-regular-svg-icons";

function AllAgencies() {
  const [areaChartDeliveredProjects, setAreaChartDeliveredProjects] = useState(
    []
  );
  const [haveInformation, setHaveInformation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [widgets, setWidgets] = useState([]);
  const [totalProgressLate, setTotalProjectsLate] = useState(0);
  const [listProjectsLate, setListProjectsLate] = useState([]);
  const [totalProjectPorcent, setTotalPorcent] = useState(0);
  const [projectsLastDelivery, setProjectsLastDelivery] = useState([]);
  const [projectsClose, setProjectsClose] = useState([]);
  const [projectsCloseLastMonth, setProjectsCloseLastMonth] = useState([]);
  const [limitItensTable, setLimitItensTable] = useState(6);
  const [pageItensTable, setPageItensTable] = useState(1);
  const [clients, setClients] = useState(0);

  useEffect(() => {
    getAreaChartDeliveredProjects();
    getWidgets();
    getTotalProjectsLate();
    getTotalPorcent();
    getProjectsClose();
    getProjectsLastDelivery();
    getClients();
    getProjectsCloseLastMonth();
  }, []);

  const getWidgets = async () => {
    setLoading(true);
    const response = await api.get(`/dashboard-widgets`);
    setWidgets(response.data);
    setLoading(false);
  };

  const setLimitTable = (value) => {
    setLoading(true);
    setLimitItensTable(value);
    getProjectsLastDelivery(value, setPageItensTable);
    setLoading(false);
  };

  const changePage = (event) => {
    setLoading(true);
    setPageItensTable(event.target.id);
    getProjectsLastDelivery(limitItensTable, event.target.id);
    setLoading(false);
  };

  const getProjectsClose = async () => {
    const response = await api.get(`/project/close?limit=9999`);
    setProjectsClose(response.data);
  };

  const getProjectsCloseLastMonth = async () => {
    const response = await api.get(`/dashboard-projects/close?limit=9999`);
    setProjectsCloseLastMonth(response.data);
  };

  const getClients = async (mypage = 1, filterName = '', filterStatus = '', sortBy = 'createdAt', order = 'desc') => {
    setLoading(true);
    const response = await api.get(`/client?paginate=true&page=${mypage}&filterName=${filterName}&filterStatus=${filterStatus}&order=${order}&sortBy=${sortBy}`);
    const { docs = [], ...arr } = response.data;
    setClients(docs.length);
    setLoading(false);
}

  const getTotalProjectsLate = async () => {
    setLoading(true);
    const response = await api.get(`/project/late?limit=9999`);
    setListProjectsLate(response.data);
    setTotalProjectsLate(response.data.length);
    setLoading(false);
  };

  const prevButton = () => {
    setLoading(true);
    if (pageItensTable !== 1) {
      setPageItensTable(pageItensTable - 1);
      getProjectsLastDelivery(limitItensTable, pageItensTable - 1);
    }
    setLoading(false);
  };

  const nextButton = () => {
    setLoading(true);
    if (pageItensTable.toString() !== projectsLastDelivery.pages.toString()) {
      setPageItensTable(pageItensTable + 1);
      getProjectsLastDelivery(limitItensTable, pageItensTable + 1);
    }
    setLoading(false);
  };

  const getProjectsLastDelivery = async (
    limit = limitItensTable,
    page = pageItensTable
  ) => {
    const response = await api.get(
      `/dashboard-last-delivery?paginate=true&limit=${limit}&page=${page}`
    );
    setProjectsLastDelivery(response.data);
  };

  const getTotalPorcent = async () => {
    setLoading(true);
    const widgetsResponse = await api.get(`/dashboard-widgets`);
    const totalProgressLateResponse = await api.get(`/project/late?limit=9999`);
    let response = parseInt(
      100 -
        (totalProgressLateResponse.data.length * 100) /
          widgetsResponse.data.projects
    );
    if (isNaN(response)) response = 0;
    setTotalPorcent(response);
    setLoading(false);
  };

  const getAreaChartDeliveredProjects = async (
    year = new Date().getFullYear()
  ) => {
    setLoading(true);
    const response = await api.get(
      `/dashboard-area-chart-delivered-project?year=${year}`
    );
    setAreaChartDeliveredProjects(response.data);

    for (let count = 0; count < response.data.length; count++) {
      if (response.data[count].projectName.length > 0) {
        setHaveInformation(true);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Layout defaultMenu={30} title={"Dashboard"}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 align-items-center mb-4">
              <div className="row">
                <div className="col-12 col-lg-12 mb-2">
                  <WidgetProjectInfo>
                    <WidgetNumberWithClickAdmin
                      background="bg-prop-ghost-white my-2 mt-lg-0 mr-lg-3 me-2"
                      textColor={"prop-weldon-blue"}
                      title="Projetos em andamento"
                      value={widgets.projects}
                    />
                    <WidgetNumberWithClickAdmin
                      background="bg-prop-ghost-white my-2 mt-lg-0 mr-lg-3 me-2"
                      textColor={"prop-weldon-blue"}
                      title="Projetos atrasados"
                      value={totalProgressLate}
                    />
                    <WidgetNumberWithClickAdmin
                      background="bg-prop-ghost-white my-2 mt-lg-0 mr-lg-3 me-2"
                      textColor={"prop-weldon-blue"}
                      title="Empresas parceiras"
                      value={clients}
                    />
                    <WidgetNumberWithClickAdmin
                      background="bg-prop-ghost-white my-2 mt-lg-0 mr-lg-3 me-2"
                      textColor={"prop-weldon-blue"}
                      title="Faturamento médio"
                      value={"dinheiros"}
                    />
                    <WidgetNumberWithClickAdmin
                      background={
                        totalProjectPorcent <= 69
                          ? "bg-prop-seashell my-2 mt-lg-0 mr-lg-3 me-2"
                          : totalProjectPorcent <= 79
                          ? "bg-prop-yellow my-2 mt-lg-0 mr-lg-3 me-23"
                          : "bg-prop-green-light my-2 mt-lg-0 mr-lg-3 me-2"
                      }
                      value={totalProjectPorcent + "%"}
                      textColor={
                        totalProjectPorcent <= 69
                          ? "prop-red"
                          : totalProjectPorcent <= 79
                          ? "prop-firebrick-2"
                          : "prop-green"
                      }
                      title="Performance"
                      for="FinalResults"
                    />
                  </WidgetProjectInfo>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 d-flex align-items-center mb-4">
            <WidgetChart />
            <div className="ms-auto col-4 row d-flex">
              <div className="mb-2">
                <WidgetNumberWithClickAdminList
                  icon={faCheckCircle}
                  iconSize={"xl"}
                  iconColor={"#17B897"}
                  background="bg-prop-green-light-2"
                  title="Entregas no prazo"
                  value={`${widgets.projects} projetos`}
                  lastPeriod={"(periodo anterior XXX)"}
                />
              </div>
              <div className="mb-2">
                <WidgetNumberWithClickAdminList
                  icon={faCircleXmark}
                  iconSize={"xl"}
                  iconColor={"#BA2525"}
                  background="bg-prop-red-light-2"
                  title="Entregas com atraso"
                  value={`${projectsCloseLastMonth.length} projetos`}
                  lastPeriod={"(periodo anterior XXX)"}
                />
              </div>
            </div>
          </div>
        </div>

        <WidgetTitle title={`Últimas entregas (${projectsLastDelivery.total})`}>
          <div className="col-12 col-lg-2 ps-0">
            <form className="form-sidebar-header">
              <select
                aria-expanded="true"
                className="form-select form-select-lg border-1 fz-14 box-shadow-none text-prop-grey-light font-rubik notification-scroll"
              >
                <option value="">Todos os projetos</option>
                {projectsClose.map((item, index) => (
                  <option key={index} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </form>
          </div>
        </WidgetTitle>
        <div className="col-12 mb-0 mb-lg-5">
          <Table
            title="projetos"
            totalItem={projectsLastDelivery.total}
            value={limitItensTable}
            onChangeLimit={(event) => setLimitTable(event.target.value)}
            prevButton={() => prevButton()}
            nextButton={() => nextButton()}
            page={pageItensTable}
            changePage={(event) => changePage(event)}
          >
            <TableHeader>
              <TableHeaderItem sizeLg={3} title="Nome do Projeto" />
              <TableHeaderItem sizeLg={3} title="Responsável">
                <TableHeaderItemDropdown>
                  <li
                    className="dropdown-header d-flex justify-content-end pt-0 pb-1 px-2"
                    style={{ borderBottom: "1px solid #D9D9D9" }}
                  >
                    <div className="input-group input-search">
                      <input
                        type="text"
                        style={{ width: "173px" }}
                        className="input-dropdown form-control fz-14 border-0 box-shadow-none text-cl-auroMetal ff-roboto px-1"
                        placeholder={"Buscar"}
                      />
                      <span className="input-group-text bg-white border-0">
                        <i
                          className="bi bi-search "
                          style={{ color: "#000000" }}
                        ></i>
                      </span>
                    </div>
                  </li>
                </TableHeaderItemDropdown>
              </TableHeaderItem>
              <TableHeaderItem sizeLg={2} title="Entrega prevista" />
              <TableHeaderItem sizeLg={2} title="Entregue em:" />
              <TableHeaderItem sizeLg={2} title="Entrega">
                <TableHeaderItemDropdown>
                  <li
                    className="dropdown-header d-flex justify-content-end pt-0 pb-1 px-2"
                    style={{ borderBottom: "1px solid #D9D9D9" }}
                  >
                    <div className="input-group input-search">
                      <input
                        type="text"
                        style={{ width: "173px" }}
                        className="input-dropdown form-control fz-14 border-0 box-shadow-none text-cl-auroMetal ff-roboto px-1"
                        placeholder={"Buscar"}
                      />
                      <span className="input-group-text bg-white border-0">
                        <i
                          className="bi bi-search "
                          style={{ color: "#000000" }}
                        ></i>
                      </span>
                    </div>
                  </li>
                </TableHeaderItemDropdown>
              </TableHeaderItem>
            </TableHeader>
            <TableBody>
              {projectsLastDelivery.docs !== undefined ? (
                projectsLastDelivery.docs.map((item) => (
                  <TableBodyCell>
                    <TableBodyItem
                      sizeLg={3}
                      title="Nome do projeto"
                      value={item.name}
                    />
                    <TableBodyItem
                      sizeLg={3}
                      title="Responsável"
                      value={
                        item.responsible.firstName +
                        " " +
                        item.responsible.lastName
                      }
                    />
                    <TableBodyItem
                      sizeLg={2}
                      title="Entrega prevista"
                      value={new Date(item.deadline).toLocaleDateString()}
                    />
                    <TableBodyItem
                      sizeLg={2}
                      title="Entregue em"
                      value={new Date(item.deliveryDate).toLocaleDateString()}
                    />
                    <TableBodyItemStatus
                      sizeLg={2}
                      title="Entrega"
                      condicionStyle={
                        item.deadline < item.deliveryDate
                          ? "bg-prop-seashell text-prop-red border-firebrick-2"
                          : "bg-prop-mint-cream text-prop-light-sea-green border-ligth-sea-green"
                      }
                      value={
                        item.deadline < item.deliveryDate
                          ? "Com Atraso"
                          : " No prazo "
                      }
                    />
                  </TableBodyCell>
                ))
              ) : (
                <EmptyList
                  icon="indiferente"
                  message="Não foi encontrada nenhuma entrega"
                />
              )}
              {projectsLastDelivery.length === 0 ? (
                <EmptyList
                  icon="indiferente"
                  message="Não foi encontrada nenhuma entrega"
                />
              ) : projectsLastDelivery.docs.length === 0 ? (
                <EmptyList
                  icon="indiferente"
                  message="Não foi encontrada nenhuma entrega"
                />
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </div>
      </Layout>
      <Loading show={loading} />
    </>
  );
}

export default AllAgencies;
