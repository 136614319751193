import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import api from "../../../config/api";
import { ListBody, ListBodyItemModal, ListBodyItemStatusEmojiModal } from "../../../components/List";
// import io from "socket.io-client";
import AddButton from "../../../components/AddButton";
import Loading from "../../../components/Load";
import { formatDate } from "../../../config/utils";
import ModalReunion from "../../../components/_modals/_agency/Reunion";
// import swal from "sweetalert";
import EmptyList from "../../../components/_empty/list";
import SidebarProject from "../../../components/SidebarProject";
import SidebarProjectCategory from "../../../components/SidebarProjectCategory";
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableHeaderItemDropdown,
  TableBody,
  TableBodyItemAction,
} from "../../../components/Table";
import { decodeToken } from "../../../config/auth";
import { Drawer } from "../../../components/Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { ConfirmDelete } from "../../../components/Alert";
import { toastsMsg } from "../../../components/ToasterMsg";
require("dotenv").config();

function Reunion(props) {
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reunions, setReunions] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [limit, setLimit] = useState(6);
  const [project, setProject] = useState("");
  const [client, setClient] = useState("");

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [subjectEdit, setSubjectEdit] = useState(false);
  const [scheduleEdit, setSheduleEdit] = useState(false);
  const [reunionIdDelete, setReunionIdDelete] = useState("");
  let [view, setView] = useState({});

  let [user, setUser] = useState({});
  const [reunion, setReunion] = useState({});

  // Filter
  const [filterDropdownName, setFilterDropdownName] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [responsibles, setResponsibles] = useState([]);
  const [responsibleFilter, setResponsibleFilter] = useState("");

  const [drawerOpenViewReunion, setDrawerOpenViewReunion] = useState(false);
  const [drawerOpenAddReunion, setDrawerOpenAddReunion] = useState(false);

  useEffect(() => {
    const { user = {} } = decodeToken();
    setUser(user);
    getReunions(page, limit, nameFilter, statusFilter, responsibleFilter);
    getProject();
    getResponsibles();
    // async function registerToSocket() {
    //     const socket = io(`${process.env.REACT_APP_API_URL}`);
    //     socket.on("reunion", () => {
    //         getReunions(page,nameFilter, statusFilter, responsibleFilter);
    //     });
    // }
    // registerToSocket();
  }, [page, props, user.role]);

  const getReunions = async (
    mypage = 1,
    limit = 6,
    filterName = "",
    filterStatus = "",
    filterResponsible = "",
    sortBy = "createdAt",
    order = "desc"
  ) => {
    setLoading(true);
    const response = await api.get(
      `/reunion/${props.match.params.id}?paginate=true&page=${mypage}&limit=${limit}&filterName=${filterName}&filterStatus=${filterStatus}&filterResponsible=${filterResponsible}&order=${order}&sortBy=${sortBy}`
    );
    const { ...arr } = response.data;
    setReunions(response.data);
    setPages(parseInt(arr.pages));
    setLoading(false);
  };

  const getProject = async () => {
    const response = await api.get(`/dashboard-project/${props.match.params.id}`);
    setProject(response.data);
    setClient(response.data.client);
  };

  const getResponsibles = async () => {
    const response = await api.get(`/user-in-reunion/${props.match.params.id}`);
    setResponsibles(response.data);
  };

  const nextPage = () => {
    setLoad(true);
    setPage(page === pages ? page : parseInt(page) + 1);
    getReunions(page === pages ? page : parseInt(page) + 1, limit, nameFilter, statusFilter, responsibleFilter);
    setLoad(false);
  };

  const backPage = () => {
    setLoad(true);
    setPage(page === 1 ? page : page - 1);
    getReunions(page === 1 ? page : page - 1, limit, nameFilter, statusFilter, responsibleFilter);
    setLoad(false);
  };

  const destroy = async () => {
    await api
      .delete(`/reunion/${reunionIdDelete}`)
      .then(() => {
        getReunions(page, limit, nameFilter, statusFilter, responsibleFilter);
        document.getElementById("cancelButtonDeleteModel").click();
        toastsMsg({
          icon: "success",
          title: "Sucesso!",
          text: "Reunião removida com sucesso.",
        });
      })
      .catch(() => {
        toastsMsg({
          icon: "error",
          title: "Erro!",
          text: "Erro ao remover a reunião, tente novamente mais tarde.",
        });
      });
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    const formData = new FormData();
    formData.append("file", reunion.file);
    (reunion.file !== undefined) & (reunion.file !== null)
      ? formData.append("haveFile", true)
      : formData.append("haveFile", false);
    (reunion.newSubject !== "") & (reunion.newSubject !== undefined)
      ? formData.append("subject", reunion.newSubject)
      : formData.append("subject", reunion.subject);
    formData.append("client", client._id);
    formData.append("project", reunion.project._id);
    formData.append("date", reunion.date);
    formData.append("rating", reunion.rating);
    formData.append("comments", reunion.comments);

    await api
      .put(`/reunion/${reunion._id}`, formData)
      .then(() => {
        setLoad(false);
        toastsMsg("Sucesso!", "Reunião editada com sucesso!", "success");
        closeDrawer();
        getReunions(page, limit, nameFilter, statusFilter, responsibleFilter);
      })
      .catch((error) => {
        setLoad(false);
        if (error.response.data.error === "Invalid File") {
          toastsMsg("Erro!", "Tipo de arquivo selecionado incorreto, por favor selecione um arquivo válido.", "error");
        } else {
          toastsMsg("Erro!", "Erro ao editar a reunião, tente novamente mais tarde.", "error");
        }
      });
  };

  const edit = (item) => {
    setDrawerOpen(true);
    setReunion(item);
  };

  const subjectNewEdit = (value) => {
    setSubjectEdit(!subjectEdit);
    setReunion((values) => ({ ...values, newSubject: value }));
  };

  const reunionHandleChange = (e) => {
    setReunion((values) => ({ ...values, [e.target.name]: e.target.value }));
  };

  const closeDrawer = () => {
    setSheduleEdit(false);
    setSubjectEdit(false);
    setDrawerOpen(false);
  };

  const reunionSheduleHandleChange = (e) => {
    setReunion((values) => ({ ...values, [e.target.name]: e.target.files[0] }));
  };

  const filterChangeHandlerName = (event) => {
    setNameFilter(event.target.value);
    getReunions(page, limit, event.target.value, statusFilter, responsibleFilter);
  };

  const filterChangeHandlerResponsible = (value) => {
    setResponsibleFilter(value);
    getReunions(page, limit, nameFilter, statusFilter, value);
  };

  const filterChangeHandlerStatus = (value) => {
    setStatusFilter(value);
    getReunions(page, limit, nameFilter, value, responsibleFilter);
  };

  const changeLimit = (event) => {
    setLimit(event.target.value);
    setPage(1);
    getReunions(1, event.target.value, nameFilter, statusFilter, responsibleFilter);
  };

  const changePage = (event) => {
    setLoad(true);
    setPage(event.target.id);
    getReunions(event.target.id, limit, nameFilter, statusFilter, responsibleFilter);
    setLoad(false);
  };

  const sortByNameChangeHandler = (sortBy, order) => {
    getReunions(page, limit, nameFilter, statusFilter, "", sortBy, order);
    setResponsibleFilter("");
  };

  const visually = (item) => {
    setView({
      _id: item._id,
      client: item.client,
      project: item.project,
      date: formatDate(item.date),
      subject: item.subject,
      rating: item.rating,
      comments: item.comments,
      user: item.user,
      schedule: item.schedule,
    });
    setDrawerOpenViewReunion(true);
  };

  const addReunion = () => {
    setReunion({});
    setDrawerOpenAddReunion(true);
  };

  return (
    <>
      <Layout
        defaultMenu={0}
        isSingleProject={true}
        clientImg={client.logo}
        clientName={client.name}
        projectName={project.name}
        projectCategory={project.category !== undefined ? project.category[0] : ""}
      >
        <div className="container-fluid singleProject">
          {user.role === "agency" ? <SidebarProject active={"reunion"} project={props.match.params.id} /> : <></>}
          {user.role === "category" ? (
            <div className="col-12">
              <SidebarProjectCategory seeReport={user.seeReport} active={"reunion"} project={props.match.params.id} />
            </div>
          ) : (
            <></>
          )}
          <div className="row">
            <div className="col-12 col-lg-4 mt-2 pe-0 me-auto">
              <div className="input-group input-search">
                <input
                  type="text"
                  className="form-control border-0 fz-14 box-shadow-none text-prop-grey-light font-rubik"
                  onChange={(event) => filterChangeHandlerName(event)}
                  placeholder="O que você está buscando?"
                />
                <span className="input-group-text bg-white border-0">
                  <i className="bi bi-search text-prop-grey-light"></i>
                </span>
              </div>
            </div>
            {user.role !== "category" ? (
              <AddButton className={"m-0"} sizeLg={"6"} onClick={() => addReunion()} text="Adicionar Reunião" />
            ) : (
              <></>
            )}
            <div className="col-12 mb-4">
              <Table
                title={"Reuniões"}
                totalItem={reunions.total}
                value={limit}
                onChangeLimit={(e) => changeLimit(e)}
                prevButton={() => backPage()}
                nextButton={() => nextPage()}
                page={page}
                changePage={(e) => changePage(e)}
              >
                <TableHeader>
                  <TableHeaderItem sizeLg={3} title="Assunto" />
                  <TableHeaderItem sizeLg={3} title="Colaborador">
                    <TableHeaderItemDropdown>
                      <li
                        className="dropdown-header d-flex justify-content-end flex-column pt-0 pb-1 px-2"
                        style={{ borderBottom: "1px solid #D9D9D9" }}
                      >
                        <div className="input-group input-search">
                          <input
                            onChange={({ target }) => setFilterDropdownName(target.value)}
                            type="text"
                            style={{ width: "173px" }}
                            className="input-dropdown form-control fz-14 border-0 box-shadow-none text-cl-auroMetal ff-roboto px-1"
                            placeholder={"Buscar"}
                          />
                          <span className="input-group-text bg-white border-0">
                            <i className="bi bi-search " style={{ color: "#000000" }}></i>
                          </span>
                        </div>
                        <div
                          className="c-pointer mt-1"
                          onClick={() => sortByNameChangeHandler("user.firstName", "asc")}
                        >
                          <span className="font-rubik fz-14 text-prop-dark-blue">Ordenar de A a Z</span>
                        </div>
                        <div
                          className="c-pointer mt-1"
                          onClick={() => sortByNameChangeHandler("user.firstName", "desc")}
                        >
                          <span className="font-rubik fz-14 text-prop-dark-blue">Ordenar de Z para A</span>
                        </div>
                      </li>
                      <li
                        onClick={() => filterChangeHandlerResponsible("")}
                        className="dropdown-header d-flex justify-content-start py-0 mt-2 px-2 c-pointer"
                      >
                        <span className="font-rubik fz-14 text-prop-dark-blue">Todos</span>
                      </li>
                      {responsibles
                        .filter(
                          (element) =>
                            filterDropdownName === "" ||
                            String(element.firstName + " " + element.lastName)
                              .toLowerCase()
                              .includes(filterDropdownName.toLocaleLowerCase())
                        )
                        .map((item, index) => (
                          <li
                            onClick={() => filterChangeHandlerResponsible(item._id)}
                            className="dropdown-header d-flex justify-content-start py-0 mt-2 px-2 c-pointer"
                          >
                            <span className="font-rubik fz-14 text-prop-dark-blue" key={index + 1}>
                              {item.firstName + " " + item.lastName}
                            </span>
                          </li>
                        ))}
                    </TableHeaderItemDropdown>
                  </TableHeaderItem>
                  <TableHeaderItem sizeLg={3} title="Data" />
                  <TableHeaderItem sizeLg={2} title="Status">
                    <TableHeaderItemDropdown>
                      <li
                        onClick={() => filterChangeHandlerStatus("")}
                        className="dropdown-header d-flex justify-content-start py-0 mt-2 px-2 c-pointer"
                      >
                        <span className="font-rubik fz-14 text-prop-dark-blue">Todos</span>
                      </li>
                      <li
                        onClick={() => filterChangeHandlerStatus("Bom")}
                        className="dropdown-header d-flex justify-content-start py-0 mt-2 px-2 c-pointer"
                      >
                        <span className="font-rubik fz-14 text-prop-dark-blue">Bom</span>
                      </li>
                      <li
                        onClick={() => filterChangeHandlerStatus("Regular")}
                        className="dropdown-header d-flex justify-content-start py-0 mt-2 px-2 c-pointer"
                      >
                        <span className="font-rubik fz-14 text-prop-dark-blue">Regular</span>
                      </li>
                      <li
                        onClick={() => filterChangeHandlerStatus("Ruim")}
                        className="dropdown-header d-flex justify-content-start py-0 mt-2 px-2 c-pointer"
                      >
                        <span className="font-rubik fz-14 text-prop-dark-blue">Ruim</span>
                      </li>
                    </TableHeaderItemDropdown>
                  </TableHeaderItem>
                </TableHeader>
                <TableBody>
                  {reunions.docs !== undefined ? (
                    reunions.docs.map((item, index) => (
                      <ListBody key={index} specialClass="card-with-hover d-flex flex-wrap">
                        <ListBodyItemModal
                          sizeLg={3}
                          title="Assunto"
                          value={item.subject}
                          modalId="#myModalView"
                          onClick={() => visually(item)}
                        />
                        <ListBodyItemModal
                          sizeLg={3}
                          title="Colaborador"
                          value={`${item.user.firstName} ${item.user.lastName}`}
                          modalId="#myModalView"
                          onClick={() => visually(item)}
                        />
                        <ListBodyItemModal
                          sizeLg={3}
                          title="Data"
                          value={formatDate(item.date)}
                          modalId="#myModalView"
                          onClick={() => visually(item)}
                        />
                        <ListBodyItemStatusEmojiModal
                          sizeLg={2}
                          title="Status"
                          value={item.rating}
                          modalId="#myModalView"
                          onClick={() => visually(item)}
                        />
                        {user.role !== "category" ? (
                          <TableBodyItemAction sizeLg={1}>
                            <li>
                              <span
                                modalId="#myModalView"
                                className="dropdown-item fz-14 font-rubik c-pointer"
                                onClick={() => edit(item)}
                              >
                                Editar
                              </span>
                            </li>
                            <li>
                              <span
                                onClick={() => setReunionIdDelete(item._id)}
                                className="dropdown-item fz-14 font-rubik text-prop-danger c-pointer"
                                data-bs-toggle="modal"
                                data-bs-target={"#confirmDeleteReunion"}
                              >
                                Excluir
                              </span>
                            </li>
                          </TableBodyItemAction>
                        ) : (
                          <></>
                        )}
                      </ListBody>
                    ))
                  ) : (
                    <></>
                  )}
                  {reunions.length === 0 ? (
                    <EmptyList icon="indiferente" message="Não foi encontrada nenhuma reunião" />
                  ) : reunions.docs.length === 0 ? (
                    <EmptyList icon="indiferente" message="Não foi encontrada nenhuma reunião" />
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </Layout>
      <Drawer
        show={drawerOpenViewReunion}
        onClose={() => setDrawerOpenViewReunion(false)}
        onOpen={() => setDrawerOpenViewReunion(true)}
      >
        <div className="p-3 notification-scroll modal-drawer">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                  Visualizar report
                </h5>
                <button
                  type="button"
                  id="btnCloseSingle"
                  className="btn-close"
                  onClick={() => setDrawerOpenViewReunion(false)}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  {view.user !== undefined ? (
                    <div className="col-12 d-flex align-items-center justify-content-start">
                      <div className="col-4 col-lg-2 d-flex align-items-center justify-content-start">
                        <img
                          src={view.user.avatar}
                          alt=""
                          width={100}
                          height={100}
                          style={{ border: "1px solid #626679" }}
                          className="rounded-circle"
                        />
                      </div>
                      <div className="col-8 col-lg-10 d-flex align-items-start justify-content-start flex-column">
                        <span className="fw-bold">{view.user.firstName + " " + view.user.lastName}</span>
                        <span>{view.date}</span>
                        <span className="fw-bold">
                          {"Status da reunião: "}
                          <span
                            className={`${
                              view.rating === "Bom"
                                ? "fw-normal text-prop-cyan"
                                : view.rating === "Regular"
                                ? "fw-normal text-prop-warning-light"
                                : "fw-normal text-prop-danger"
                            }`}
                          >
                            {view.rating}
                          </span>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="col-12 col-lg-12 mb-3 mt-2">
                    <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-2">Observações</label>
                    <div className="card border-0 shadow p-2" style={{ height: "198px", borderRadius: "10px" }}>
                      <div
                        className="d-flex justify-content-start text-break scroll h-100"
                        style={{ overflowY: "auto", overflowX: "hidden" }}
                      >
                        <span className="col-12 p-2">{view.comments}</span>
                      </div>
                    </div>
                  </div>
                  {(view.schedule !== undefined) & (view.schedule !== " ") ? (
                    <>
                      <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-2">
                        Arquivo para download
                      </label>
                      <div className="col-12 d-flex align-items-center justify-content-start flex-wrap ">
                        <span className="col-12 col-lg-6">Esse relatório possui um arquivo para download</span>
                        <a className="col-12 col-lg-3" target="_blank" rel="noopener noreferrer" href={view.schedule}>
                          <button className=" btn btn-prop-blue text-white font-rubik fz-16 px-3 " type="button">
                            Baixar arquivo
                          </button>
                        </a>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <Drawer
        show={drawerOpenAddReunion}
        onClose={() => setDrawerOpenAddReunion(false)}
        onOpen={() => setDrawerOpenAddReunion(true)}
      >
        <div className="p-3 notification-scroll modal-drawer">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              {reunion._id === undefined ? (
                <>
                  <div className="modal-header border-0">
                    <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalLabel">
                      Adicionar reunião
                    </h5>
                    <button
                      type="button"
                      id="btnClose"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <ModalReunion
                      getMethod={() => getReunions(page, limit, nameFilter, statusFilter, responsibleFilter)}
                      client={client}
                      project={project}
                      close={() => document.getElementById("btnClose").click()}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </Drawer>
      <div
        className="modal fade border-0"
        id="myModalSingle"
        tabIndex="-1"
        aria-labelledby="myModalSingleLabel"
        aria-hidden="true"
        style={{ borderRadius: "10px" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {reunion._id === undefined ? (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalLabel">
                    Adicionar reunião
                  </h5>
                  <button
                    type="button"
                    id="btnClose"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <ModalReunion
                    getMethod={() => getReunions(page, limit, nameFilter, statusFilter, responsibleFilter)}
                    client={client}
                    project={project}
                    close={() => document.getElementById("btnClose").click()}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <ConfirmDelete
        modalId="confirmDeleteReunion"
        title="Você tem certeza?"
        description="Essa reunião será apagada e não terá mais volta"
        deleteText="Excluir"
        confirmDeleteOnClick={() => destroy()}
      />
      <Drawer show={drawerOpen} onClose={() => closeDrawer()} onOpen={() => setDrawerOpen(true)}>
        <div className=" notification-scroll modal-drawer">
          <form onSubmit={save}>
            <button
              type="button"
              onClick={() => closeDrawer()}
              className="btn-close px-3 pt-3"
              aria-label="Close"
            ></button>
            <div className="py-2 px-3 bg-anti-flash-white-hover">
              {subjectEdit === false ? (
                <span className="font-rubik fz-20 fw-bold ">
                  {reunion.subject}{" "}
                  <span className="ms-2 c-pointer" onClick={() => subjectNewEdit(reunion.subject)}>
                    <FontAwesomeIcon color="#102A43" size="sm" icon={faPencilAlt} />
                  </span>{" "}
                </span>
              ) : (
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control"
                    name="newSubject"
                    style={{ width: "100%" }}
                    value={reunion.newSubject}
                    onChange={(e) => reunionHandleChange(e)}
                    required
                  />
                  <button
                    type="button"
                    onClick={() => subjectNewEdit("")}
                    className="btn-close px-3 pt-3"
                    aria-label="Close"
                  ></button>
                </div>
              )}
            </div>
            <div className="row px-3">
              <div className="col-6 col-lg-6 mb-3">
                <label className="form-label font-rubik fw-bold text-prop-black fz-14 mb-1">Data</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={String(reunion.date).substr(0, 10)}
                  onChange={(e) => reunionHandleChange(e)}
                  required
                />
              </div>
              <div className="col-6 col-lg-6 mb-3">
                <label className="form-label font-rubik fw-bold text-prop-black fz-14 mb-1">Status</label>
                <select
                  className="form-select"
                  name="rating"
                  value={reunion.rating}
                  onChange={(e) => reunionHandleChange(e)}
                  required
                >
                  <option></option>
                  <option value="Bom">Bom</option>
                  <option value="Regular">Regular</option>
                  <option value="Ruim">Ruim</option>
                </select>
              </div>
            </div>
            {(reunion.schedule !== undefined) & (scheduleEdit === false) ? (
              <div className="col-12 mb-3 px-3 d-flex flex-column">
                <label htmlFor="formFile" className="form-label font-rubik fw-bold text-prop-black fz-14 mb-1">
                  Pauta{" "}
                </label>
                <div className="d-flex">
                  <a href={reunion.schedule} target="_blank" className="" rel="noreferrer">
                    abrir pauta atual
                  </a>
                  <span className="ms-2 c-pointer" onClick={() => setSheduleEdit(!scheduleEdit)}>
                    <FontAwesomeIcon color="#102A43" size="sm" icon={faPencilAlt} />
                  </span>
                </div>
              </div>
            ) : (
              <div className="col-12 mb-3 px-3">
                <label htmlFor="formFile" className="form-label font-rubik fw-bold text-prop-black fz-14 mb-1">
                  Anexar pauta <span className="fz-10 text-prop-danger font-rubik">Tamanho máximo 2MB</span>
                </label>
                <div className="d-flex">
                  <input
                    className="form-control"
                    type="file"
                    name="file"
                    id="formFile"
                    onChange={(e) => reunionSheduleHandleChange(e)}
                  />
                  {scheduleEdit === true ? (
                    <button
                      type="button"
                      onClick={() => setSheduleEdit(!scheduleEdit)}
                      className="btn-close px-3 pt-3"
                      aria-label="Close"
                    ></button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )}

            <div className="col-12 mb-3 px-3">
              <label className="form-label font-rubik fw-bold text-prop-black fz-14 mb-1">Observações</label>
              <textarea
                className="form-control notification-scroll"
                name="comments"
                style={{ width: "100%" }}
                rows="3"
                value={reunion.comments}
                onChange={(e) => reunionHandleChange(e)}
                required
              ></textarea>
            </div>
            <div className="col-12 d-flex align-items-end justify-content-end px-3">
              {load ? (
                <button
                  type="button"
                  className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                  disabled
                >
                  <div className="spinner-border spinner-border-sm text-light" role="status">
                    <span className="visually-hidden">Carregando...</span>
                  </div>
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                >
                  Alterar
                </button>
              )}
            </div>
          </form>
        </div>
      </Drawer>
      {/* <div
        className="modal fade border-0"
        id="myModalView"
        tabIndex="-1"
        aria-labelledby="myModalView"
        aria-hidden="true"
        style={{ borderRadius: "10px" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5
                className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark"
                id="myModalSingleLabel"
              >
                Visualizar report
              </h5>
              <button
                type="button"
                id="btnCloseSingle"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                {view.user !== undefined ? (
                  <div className="col-12 d-flex align-items-center justify-content-start">
                    <div className="col-4 col-lg-2 d-flex align-items-center justify-content-start">
                      <img
                        src={view.user.avatar}
                        alt=""
                        width={100}
                        height={100}
                        style={{ border: "1px solid #626679" }}
                        className="rounded-circle"
                      />
                    </div>
                    <div className="col-8 col-lg-10 d-flex align-items-start justify-content-start flex-column">
                      <span className="fw-bold">
                        {view.user.firstName + " " + view.user.lastName}
                      </span>
                      <span>{view.date}</span>
                      <span className="fw-bold">
                        {"Status da reunião: "}
                        <span
                          className={`${
                            view.rating === "Bom"
                              ? "fw-normal text-prop-cyan"
                              : view.rating === "Regular"
                              ? "fw-normal text-prop-warning-light"
                              : "fw-normal text-prop-danger"
                          }`}
                        >
                          {view.rating}
                        </span>
                      </span>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="col-12 col-lg-12 mb-3 mt-2">
                  <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-2">
                    Observações
                  </label>
                  <div
                    className="card border-0 shadow p-2"
                    style={{ height: "198px", borderRadius: "10px" }}
                  >
                    <div
                      className="d-flex justify-content-start text-break scroll h-100"
                      style={{ overflowY: "auto", overflowX: "hidden" }}
                    >
                      <span className="col-12 p-2">{view.comments}</span>
                    </div>
                  </div>
                </div>
                {(view.schedule !== undefined) & (view.schedule !== " ") ? (
                  <>
                    <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-2">
                      Arquivo para download
                    </label>
                    <div className="col-12 d-flex align-items-center justify-content-start flex-wrap ">
                      <span className="col-12 col-lg-6">
                        Esse relatório possui um arquivo para download
                      </span>
                      <a
                        className="col-12 col-lg-3"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={view.schedule}
                      >
                        <button
                          className=" btn btn-prop-blue text-white font-rubik fz-16 px-3 "
                          type="button"
                        >
                          Baixar arquivo
                        </button>
                      </a>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Loading show={loading} />
    </>
  );
}

export default Reunion;
