import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import {
  List,
  ListBody,
  ListBodyItem,
  ListBodyItemAction,
  ListHeader,
  ListHeaderItemSortAble,
} from "../../../components/List";
import ModalPlatform from "../../../components/_modals/_agency/Departament";
import EmptyList from "../../../components/_empty/list";
import AddButton from "../../../components/AddButton";
import Loading from "../../../components/Load";
import api from "../../../config/api";
import { decodeToken } from "../../../config/auth";
// import io from "socket.io-client";
import swal from "sweetalert";
import { toastsMsg } from "../../../components/ToasterMsg";
require("dotenv").config();

function Departament() {
  const [departaments, setDepartaments] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(false);
  const [nameFilter, setNameFilter] = useState("");
  const [agencies, setAgencies] = useState([]);
  const [agency, setAgency] = useState("");
  const [user, setUser] = useState({});

  useEffect(() => {
    const { user = {} } = decodeToken();
    setUser(user);
    getDepartaments(page);
    getAgencies();
    // async function registerToSocket() {
    //     const socket = io(`${process.env.REACT_APP_API_URL}`);
    //     socket.on("departament", () => {
    //         console.log("SOCKET");
    //         getDepartaments(page);
    //     });
    // }
    // registerToSocket();
  }, [page]);

  const getDepartaments = async (mypage = 1, filter = "", sortBy = "createdAt", order = "desc") => {
    setLoading(true);
    const response = await api.get(
      `/departament?paginate=true&page=${mypage}&filter=${filter}&order=${order}&sortBy=${sortBy}`
    );
    const { docs = [], ...arr } = response.data;
    setDepartaments(docs);
    setPages(parseInt(arr.pages));
    setLoading(false);
  };

  const nextPage = () => {
    getDepartaments(page + 1);
    setPage(page === pages ? page : page + 1);
  };

  const backPage = () => {
    getDepartaments(page - 1);
    setPage(page === 1 ? page : page - 1);
  };

  const destroy = async (item) => {
    swal({
      title: "Tem certeza que deseja excluir esse departamento?",
      text: `Essa departamento será excluido e não terá mais volta`,
      // icon: "warning",
      buttons: ["Cancelar", "EXCLUIR"],
      dangerMode: true,
    }).then(async (res) => {
      if (res) {
        await api
          .delete(`/departament/${item._id}`)
          .then(async () => {
            await getDepartaments(page);
            toastsMsg({
              icon: "success",
              title: "Sucesso!",
              text: "Departamento removido com sucesso.",
            });
          })
          .catch(() => {
            toastsMsg({
              icon: "error",
              title: "Erro!",
              text: "Erro ao remover o departamento, tente novamente mais tarde.",
            });
          });
      }
    });
  };

  const edit = (item) => {
    setType("edit");
    setName(item.name);
    setId(item._id);
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    await api
      .put(`/departament/${id}`, { name })
      .then(() => {
        setLoad(false);
        toastsMsg("Sucesso!", "Departamento editado com sucesso!", "success");
        document.getElementById("btnCloseSingle").click();
        getDepartaments(page);
      })
      .catch(() => {
        setLoad(false);
        toastsMsg("Erro!", "Erro ao editar o departamento, tente novamente mais tarde.", "error");
      });
  };

  const sortByNameChangeHandler = () => {
    if (!order) {
      getDepartaments(page, nameFilter, "name", "asc");
      setOrder(order ? false : true);
    } else {
      getDepartaments(page, nameFilter);
      setOrder(order ? false : true);
    }
  };

  const getAgencies = async () => {
    try {
      const response = await api.get(`/agency/`);
      const dataArray = [];
      response.data.map((agency) => {
        dataArray.push({ key: agency._id, label: agency.name });
      });
      setAgencies(dataArray);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getValue = async (agencyId) => {
    setAgency(agencyId);
  };

  const filterChangeHandlerName = (event) => {
    setNameFilter(event.target.value);
    getDepartaments(page, event.target.value);
  };

  return (
    <>
      <Layout defaultMenu={21} defaultSubMenu={214} title={"Departamentos"}>
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-12 mb-4">
              <h1 className="mb-0 fz-24 fw-bold font-rubik">
                Departamentos
              </h1>
            </div> */}
            <div className="col-12 col-lg-4 mt-2 me-auto">
              <div className="input-group input-search">
                <input
                  type="text"
                  className="form-control border-0 fz-14 box-shadow-none text-prop-grey-light font-rubik"
                  onChange={(event) => filterChangeHandlerName(event)}
                  placeholder="O que você está buscando?"
                />
                <span className="input-group-text bg-white border-0">
                  <i className="bi bi-search text-prop-grey-light"></i>
                </span>
              </div>
            </div>
            <AddButton
              sizeLg={6}
              onClick={() => setType("add")}
              modalId={"#myModalSingle"}
              text={"Adicionar Departamento"}
            />
            <div className="col-12 mb-4 table-border-default">
              <List
                title={"departamentos"}
                paginate={true}
                page={page}
                pages={pages}
                backPage={() => backPage()}
                nextPage={() => nextPage()}
              >
                <ListHeader>
                  <ListHeaderItemSortAble name="Nome" onClick={() => sortByNameChangeHandler()} sizeLg={12} />
                </ListHeader>
                {departaments.map((item, index) => (
                  <ListBody key={index} specialClass="d-flex flex-wrap">
                    <ListBodyItem title="Nome" value={item.name} sizeLg={11} />
                    <ListBodyItemAction sizeLg={1}>
                      <li>
                        <span
                          className="dropdown-item fz-14 font-rubik c-pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#myModalSingle"
                          onClick={() => edit(item)}
                        >
                          Editar
                        </span>
                      </li>
                      <li>
                        <span
                          className="dropdown-item fz-14 font-rubik text-prop-danger c-pointer"
                          onClick={() => destroy(item)}
                        >
                          Excluir
                        </span>
                      </li>
                    </ListBodyItemAction>
                  </ListBody>
                ))}
                {departaments.length === 0 ? (
                  <EmptyList icon="indiferente" message="Não foi encontrada nenhuma departamento" />
                ) : (
                  <></>
                )}
              </List>
            </div>
          </div>
        </div>
      </Layout>
      <div
        className="modal fade border-0"
        id="myModalSingle"
        tabIndex="-1"
        aria-labelledby="myModalSingleLabel"
        aria-hidden="true"
        style={{ borderRadius: "10px" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {type === "add" ? (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalLabel">
                    Adicionar departamento
                  </h5>
                  <button
                    type="button"
                    id="btnCloseDepartament"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <ModalPlatform close={() => document.getElementById("btnCloseDepartament").click()} />
                </div>
              </>
            ) : (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                    Editar departamento
                  </h5>
                  <button
                    type="button"
                    id="btnCloseSingle"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={save}>
                    <div className="row">
                      <div className="col-12 col-lg-12 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Nome</label>
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          onChange={(event) => setName(event.target.value)}
                          required
                        />
                        {user.role !== "agency" ? (
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            onChange={(e) => getValue(e.target.value)}
                          >
                            <option value="">Selecione uma opção</option>
                            {agencies.map((each) => (
                              <option value={each.key}>{each.label}</option>
                            ))}
                          </select>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-12 d-flex align-items-center justify-content-end">
                        <button
                          type="button"
                          className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-28"
                          data-bs-dismiss="modal"
                        >
                          Cancelar
                        </button>
                        {load ? (
                          <button
                            type="button"
                            className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                            disabled
                          >
                            <div className="spinner-border spinner-border-sm text-light" role="status">
                              <span className="visually-hidden">Carregando...</span>
                            </div>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                          >
                            Salvar
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Loading show={loading} />
    </>
  );
}

export default Departament;
