import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import AddButton from "../../../components/AddButton";
// import ClearButton from "../../../components/ClearButton";
import { List, ListBody, ListBodyItem, ListBodyItemAction, ListBodyItemProgressBar, ListHeader, ListHeaderItemSortAble } from "../../../components/List";
import api from "../../../config/api";
import Loading from "../../../components/Load";
import ModalProject from "../../../components/_modals/_agency/Project";
import { formatDate } from "../../../config/utils";
import { decodeToken } from "../../../config/auth";
// import io from "socket.io-client";
// import swal from "sweetalert";
import Select from "react-select";
import { extendMoment } from "moment-range";
import EmptyList from "../../../components/_empty/list";
import Moment from "moment-timezone";
import Footer from "../../../components/Footer";
import { toastsMsg } from "../../../components/ToasterMsg";
const moment = extendMoment(Moment);
require("dotenv").config();

function CompanyProjectNotUpdated() {
  const [projects, setProjects] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [responsibles, setResponsibles] = useState([]);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState([]);
  const [platform, setPlatform] = useState("");
  const [responsible, setResponsible] = useState("");
  const [type, setType] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState(
    localStorage.getItem("PROJECT_NOT_UPDATED_FILTER_CATEGORY") === null ? "" : localStorage.getItem("PROJECT_NOT_UPDATED_FILTER_CATEGORY")
  );
  const [platformFilter, setPlatformFilter] = useState(
    localStorage.getItem("PROJECT_NOT_UPDATED_FILTER_PLATFORM") === null ? "" : localStorage.getItem("PROJECT_NOT_UPDATED_FILTER_PLATFORM")
  );
  const [responsibleFilter, setResponsibleFilter] = useState(
    localStorage.getItem("PROJECT_NOT_UPDATED_FILTER_RESPONSIBLE") === null ? "" : localStorage.getItem("PROJECT_NOT_UPDATED_FILTER_RESPONSIBLE")
  );
  const [startDate, setStartDate] = useState("");
  const [deadline, setDeadline] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deadlineChange, setDeadlineChange] = useState(false);
  const [comments, setComments] = useState("");
  const [client, setClient] = useState("");
  const [clients, setClients] = useState([]);
  const [sort, setSort] = useState("");
  const [user, setUser] = useState({});
  const [totalProjects, setTotalProjects] = useState(0);
  const [status, setStatus] = useState("");
  const [limitItensTable, setLimitItensTable] = useState(20);
  const [pageItensTable, setPageItensTable] = useState(1);

  useEffect(() => {
    const { user = {} } = decodeToken();
    setUser(user);
    getTotalProjects(nameFilter, categoryFilter, platformFilter, responsibleFilter);
    getProjects(page, nameFilter);
    if (user.role === "agency") {
      getCategories();
      getPlatforms();
      getClients();
      getResponsibles();
    }
  }, [nameFilter]);

  const getProjects = async (mypage = page, filterName = "", filterCategory = "", sortBy = "deadline", order = "desc", limit = limitItensTable) => {
    setLoading(true);
    const response = await api.get(
      `/dashboard-not-updated-project?paginate=true&limit=${limit}&page=${mypage}&filterName=${filterName}&filterCategory=${filterCategory}&order=${order}&sortBy=${sortBy}`
    );
    const { docs = [], ...arr } = response.data;
    setProjects(docs);
    setPages(parseInt(arr.pages));
    setLoading(false);
  };

  const changePage = (event) => {
    const changePage = event.target.id;
    setPage(changePage);
    setPageItensTable(changePage);
    getProjects(changePage);
  };

  const nextPage = () => {
    getProjects(page + 1);
    setPage(page === pages ? page : page + 1);
  };

  const backPage = () => {
    getProjects(page - 1);
    setPage(page === 1 ? page : page - 1);
  };

  const setLimitTable = (value) => {
    setLimitItensTable(value.target.value);
    getProjects(page, "", "", "deadline", "desc", value.target.value);
  };

  const getTotalProjects = async (filterName = "", filterCategory = "", filterPlaform = "", filterResponsible = "") => {
    const response = await api.get(
      `/dashboard-not-updated-project?limit=9999&filterName=${filterName}&filterCategory=${filterCategory}&filterPlaform=${filterPlaform}&filterResponsible=${filterResponsible}`
    );
    setTotalProjects(response.data.length);
  };

  const getCategories = async () => {
    const response = await api.get(`/category?limit=9999&sortBy=name&order=ASC`);
    const category_arr = [];
    let items = response.data;
    items.forEach((item) => category_arr.push({ value: item.name, label: `${item.name}` }));
    setCategories(category_arr);
  };

  const getPlatforms = async () => {
    const response = await api.get(`/platform?limit=9999&sortBy=name&order=ASC`);
    setPlatforms(response.data);
  };

  const getResponsibles = async () => {
    const response = await api.get(`/user/agency?limit=9999&sortBy=firstName&order=ASC`);
    setResponsibles(response.data);
  };

  const getClients = async () => {
    const response = await api.get(`/client?limit=9999&sortBy=name&order=ASC&filterStatus=active`);
    setClients(response.data);
  };

  const edit = (item) => {
    setType("edit");
    setName(item.name);
    setId(item._id);
    let category_arr = [];
    for (let count = 0; count < item.category.length; count++) {
      categories.forEach((x) => (x.label === item.category[count] ? category_arr.push(x) : ""));
    }
    setStatus(item.status);
    setDeadlineChange(false);
    setCategory(category_arr);
    setPlatform(item.platform);
    setClient(item.client._id);
    setResponsible(item.responsible._id);
    setStartDate(String(item.startDate).substr(0, 10));
    setDeadline(String(item.deadline).substr(0, 10));
    setDeliveryDate(item.deliveryDate === undefined ? String(item.deadline).substr(0, 10) : String(item.deliveryDate).substr(0, 10));
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    let category_arr = [];
    category.forEach((item) => category_arr.push(item.label));
    await api
      .put(`/project/${id}`, {
        name,
        category: category_arr,
        platform,
        responsible,
        client,
        deadline,
        startDate,
        comments,
        deliveryDate,
        status,
      })
      .then(() => {
        console.log({
          name,
          category: category_arr,
          platform,
          responsible,
          client,
          deadline,
          startDate,
          comments,
          deliveryDate,
          status,
        });
        setLoad(false);
        toastsMsg("Sucesso!", "Projeto editado com sucesso!", "success");
        document.getElementById("btnCloseSingle").click();
        getProjects(page);
      })
      .catch((error) => {
        setLoad(false);
        if (error.response.data.error === "Não foi encontrado stakeholder") {
          toastsMsg("Erro!", "Não é possivel alterar a data de entrega sem que exista um Stakeholder selecionado como gerente", "error");
        } else {
          toastsMsg("Erro!", "Erro ao editar o projeto, tente novamente mais tarde.", "error");
        }
      });
  };

  const filterChangeHandlerName = (event) => {
    setNameFilter(event.target.value);
    // getProjects(page, event.target.value, categoryFilter, platformFilter, responsibleFilter);
    // getTotalProjects(event.target.value, categoryFilter, platformFilter, responsibleFilter);
    getProjects(page, event.target.value, categoryFilter);
    getTotalProjects(event.target.value, categoryFilter);
  };

  const filterChangeHandlerCategory = (event) => {
    setCategoryFilter(event.target.value);
    getProjects(page, nameFilter, event.target.value, platformFilter, responsibleFilter);
    getTotalProjects(nameFilter, event.target.value, platformFilter, responsibleFilter);
    localStorage.setItem("PROJECT_NOT_UPDATED_FILTER_CATEGORY", event.target.value);
  };

  const filterChangeHandlerPlatform = (event) => {
    setPlatformFilter(event.target.value);
    getProjects(page, nameFilter, categoryFilter, event.target.value, responsibleFilter);
    getTotalProjects(nameFilter, categoryFilter, event.target.value, responsibleFilter);
    localStorage.setItem("PROJECT_NOT_UPDATED_FILTER_PLATFORM", event.target.value);
  };

  const filterChangeHandlerResponsible = (event) => {
    setResponsibleFilter(event.target.value);
    getProjects(page, nameFilter, categoryFilter, platformFilter, event.target.value);
    getTotalProjects(nameFilter, categoryFilter, platformFilter, event.target.value);
    localStorage.setItem("PROJECT_NOT_UPDATED_FILTER_RESPONSIBLE", event.target.value);
  };

  const sortByNameChangeHandler = (sortBy) => {
    if (sort !== sortBy) {
      getProjects(page, nameFilter, categoryFilter, platformFilter, responsibleFilter, sortBy, "asc");
      setSort(sortBy);
    } else {
      getProjects(page, nameFilter, categoryFilter, platformFilter, responsibleFilter);
      setSort("");
    }
  };

  const colorProgressBar = (prefix, item) => {
    let today = moment(new Date());
    let diference = moment.duration(today.diff(item.deadline));
    if (moment(new Date()).format("YYYY-MM-DD") > item.deadline) {
      return prefix + "-prop-danger";
    } else if (diference.asDays() <= 5 && diference.asDays() >= -4) {
      return prefix + "-prop-warning-dark";
    } else {
      return prefix + "-prop-success";
    }
  };

  const clearFilter = () => {
    localStorage.removeItem("PROJECT_NOT_UPDATED_FILTER_RESPONSIBLE");
    localStorage.removeItem("PROJECT_NOT_UPDATED_FILTER_CATEGORY");
    localStorage.removeItem("PROJECT_NOT_UPDATED_FILTER_PLATFORM");
    window.location.reload();
  };

  const changedDeadline = (event) => {
    setDeadline(event.target.value);
    setDeadlineChange(true);
  };

  return (
    <>
      <Layout defaultMenu={0} title={"Projetos desatualizados"} subTitle={`Existem ${totalProjects} projetos desatualizados`}>
        <div className="container-fluid">
          <div className="row">
            <div className={`mt-2 col-12 mb-4 col-lg-4 me-auto`}>
              <div className="input-group input-search">
                <input
                  type="text"
                  className="form-control border-0 fz-14 box-shadow-none text-prop-grey-light font-rubik"
                  onChange={(event) => filterChangeHandlerName(event)}
                  placeholder="O que você está buscando?"
                />
                <span className="input-group-text bg-white border-0">
                  <i className="bi bi-search text-prop-grey-light"></i>
                </span>
              </div>
            </div>

            <List
              title="projetos"
              paginate={true}
              page={parseInt(page)}
              pages={pages}
              backPage={() => backPage()}
              nextPage={() => nextPage()}
              totalItem={projects.length}
              changePage={(event) => {
                changePage(event);
              }}
              actualPage={pageItensTable}
              onChangeLimit={(event) => setLimitTable(event)}
            >
              <div className="col-12 table-border-default">
                <ListHeader>
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("name")} name="Nome" sizeLg={2} />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("client.name")} name="Cliente" sizeLg={2} />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("category")} name="Categoria" sizeLg={2} />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("responsible.firstName")} name="Responsável" sizeLg={2} />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("totalProgress")} name="Performance" sizeLg={2} />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("deadline")} name="Deadline" sizeLg={2} />
                </ListHeader>

                {projects.map((item, index) => (
                  <ListBody specialClass=" card-with-hover d-flex flex-wrap" key={index}>
                    <ListBodyItem title="Nome" value={item.name} sizeLg={2} link={`/projeto/${item._id}/overview`} />
                    <ListBodyItem title="Cliente" value={item.client.name} sizeLg={2} link={`/projeto/${item._id}/overview`} />
                    <ListBodyItem title="Categoria" value={`${item.category}`} sizeLg={2} link={`/projeto/${item._id}/overview`} />
                    <ListBodyItem
                      title="Responsável"
                      value={item.responsible.firstName + " " + item.responsible.lastName}
                      sizeLg={2}
                      link={`/projeto/${item._id}/overview`}
                    />
                    <ListBodyItemProgressBar
                      title="Performance"
                      sizeLg={2}
                      link={`/projeto/${item._id}/overview`}
                      projectProgress={parseInt(item.totalProgress)}
                      bgAlertClass={colorProgressBar("bg", item)}
                      textAlertClass={colorProgressBar("text", item)}
                    />
                    <ListBodyItem
                      title="Deadline"
                      value={formatDate(item.deadline)}
                      sizeLg={user.role === "agency" ? 1 : 2}
                      link={`/projeto/${item._id}/overview`}
                    />
                    {user.role === "agency" ? (
                      <ListBodyItemAction sizeLg={1}>
                        <li>
                          <span
                            className="dropdown-item fz-14 font-rubik c-pointer"
                            data-bs-toggle="modal"
                            data-bs-target="#myModalSingle"
                            onClick={() => edit(item)}
                          >
                            Editar
                          </span>
                        </li>
                        {/* <li><span className="dropdown-item fz-14 font-rubik text-prop-danger c-pointer" onClick={() => destroy(item)}>Excluir</span></li> */}
                      </ListBodyItemAction>
                    ) : (
                      <></>
                    )}
                  </ListBody>
                ))}
                {projects.length === 0 ? <EmptyList icon="piscando" message="Não foi encontrado nenhum projeto desatualizado" /> : <></>}
              </div>
            </List>
          </div>
          <Footer></Footer>
        </div>
      </Layout>
      <div
        className="modal fade border-0"
        id="myModalSingle"
        tabIndex="-1"
        aria-labelledby="myModalSingleLabel"
        aria-hidden="true"
        style={{ borderRadius: "10px" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {type === "add" ? (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                    Adicionar projeto
                  </h5>
                  <button type="button" id="btnCloseSingle" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <ModalProject close={() => document.getElementById("btnClose").click()} />
                </div>
              </>
            ) : (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                    Editar projeto
                  </h5>
                  <button
                    type="button"
                    onClick={() => setDeadlineChange(false)}
                    id="btnCloseSingle"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={save}>
                    <div className="row">
                      <div className="col-12 col-lg-9 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Nome do projeto</label>
                        <input type="text" className="form-control" value={name} onChange={(event) => setName(event.target.value)} required />
                      </div>
                      <div className="col-12 col-lg-3 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Status</label>
                        <select
                          className="form-select"
                          onChange={(event) => {
                            setStatus(event.target.value);
                          }}
                          value={status}
                          required
                        >
                          <option value={"open"} key={"open"}>
                            Em andamento
                          </option>
                          <option value={"close"} key={"close"}>
                            Concluído
                          </option>
                          <option value={"pause"} key={"pause"}>
                            Pausado
                          </option>
                        </select>
                      </div>
                      <div className="col-12 col-lg-12 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Categoria</label>
                        <Select placeholder="Selecione a categoria" value={category} onChange={(item) => setCategory(item)} options={categories} isMulti />
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Cliente</label>
                        <select className="form-select" value={client} onChange={(event) => setClient(event.target.value)} required>
                          <option className="text-prop-grey-light">Selecione o cliente</option>
                          {clients.map((item, index) => (
                            <option value={item._id} key={index}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Plataforma</label>
                        <select className="form-select" onChange={(event) => setPlatform(event.target.value)} value={platform} required>
                          <option></option>
                          {platforms.map((item, index) => (
                            <option value={item.name} key={index}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Início do projeto</label>
                        <input type="date" className="form-control" value={startDate} onChange={(event) => setStartDate(event.target.value)} required />
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Prévisão de entrega</label>
                        <input type="date" className="form-control" value={deliveryDate} onChange={(event) => setDeliveryDate(event.target.value)} />
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Previsão do prazo de contrato (Deadline)</label>
                        <input type="date" className="form-control" value={deadline} onChange={(event) => changedDeadline(event)} required />
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Responsável</label>
                        <select className="form-select" onChange={(event) => setResponsible(event.target.value)} value={responsible} required>
                          <option></option>
                          {responsibles.map((item, index) => (
                            <option value={item._id} key={index}>{`${item.firstName} ${item.lastName}`}</option>
                          ))}
                        </select>
                      </div>
                      {deadlineChange ? (
                        <div className="col-12 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Motivo:</label>
                          <textarea
                            rows="3"
                            className="form-control shadow-sm "
                            onChange={(event) => setComments(event.target.value)}
                            required={deadlineChange}
                          />
                          <label className="form-label font-rubik fw-bold text-prop-red fz-16 mb-1">
                            Atenção!!! A alteração da data de entrega está condicionada a aprovação do cliente que será notificado quando essa alteração for
                            salva.
                          </label>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="row my-3">
                      <div className="col-12 d-flex align-items-center justify-content-end">
                        <button
                          onClick={() => setDeadlineChange(false)}
                          type="button"
                          className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-25"
                          data-bs-dismiss="modal"
                        >
                          Cancelar
                        </button>
                        {load ? (
                          <button
                            type="button"
                            className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-20 d-flex justify-content-center"
                            disabled
                          >
                            <div className="spinner-border spinner-border-sm text-light" role="status">
                              <span className="visually-hidden">Carregando...</span>
                            </div>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                          >
                            Salvar
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Loading show={loading} />
    </>
  );
}

export default CompanyProjectNotUpdated;
