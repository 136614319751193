import React, { useState, useEffect } from "react";
import api from "../../../../config/api";
// import swal from "sweetalert";
import { decodeToken } from "../../../../config/auth";
import { toastsMsg } from "../../../ToasterMsg";

function ModalDepartament(props) {
  const [user, setUser] = useState({});
  const [name, setName] = useState("");
  const [agencies, setAgencies] = useState([]);
  const [agency, setAgency] = useState("");
  const [load, setLoad] = useState(false);
  const [saveType, setSaveType] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { user = {} } = decodeToken();
    setUser(user);

    getAgencies();
  }, []);

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    await api
      .post(`/departament`, { name, agency })
      .then(() => {
        setLoad(false);
        toastsMsg("Sucesso!", "Departamento cadastrado com sucesso!", "success");
        props.getDepartaments();
        props.close();
      })
      .catch(() => {
        setLoad(false);
        toastsMsg("Erro!", "Erro ao cadastrar o departamento, tente novamente mais tarde.", "error");
      });
  };

  const getAgencies = async () => {
    try {
      const response = await api.get(`/agency/`);
      const dataArray = [];
      response.data.map((agency) => {
        dataArray.push({ key: agency._id, label: agency.name });
      });
      setAgencies(dataArray);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getValue = async (agencyId) => {
    setAgency(agencyId);
  };

  if (loading !== true) {
    return (
      <>
        <form onSubmit={save}>
          <div className="row">
            <div className="col-12 col-lg-12 mb-3">
              <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Nome</label>
              <input
                type="text"
                className="form-control mb-2"
                onChange={(event) => setName(event.target.value)}
                required
              />
              {user.role !== "agency" ? (
                <>
                  <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Empresa</label>
                  <select class="form-select" onChange={(e) => getValue(e.target.value)}>
                    <option value="">Selecione uma opção</option>
                    {agencies.map((each) => (
                      <option value={each.key}>{each.label}</option>
                    ))}
                  </select>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12 d-flex align-items-center justify-content-end">
              <button type="reset" className="d-none" id="btnClearDepartament">
                Reset
              </button>
              <button
                type="button"
                onClick={() => props.close()}
                className="btn text-danger px-lg-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-25"
                data-bs-dismiss="modal"
              >
                Cancelar
              </button>
              {load ? (
                <button
                  type="button"
                  className="btn btn-prop-blue px-lg-5 py-2 text-white fz-13 text-uppercase font-rubik w-25"
                  disabled
                >
                  <div className="spinner-border spinner-border-sm text-light" role="status">
                    <span className="visually-hidden">Carregando...</span>
                  </div>
                </button>
              ) : (
                <>
                  <button
                    type="submit"
                    onClick={() => setSaveType("saveMore")}
                    className="px-lg-2 py-2 save-close-button fz-13 text-uppercase font-rubik w-28 mx-2"
                  >
                    SALVAR E CRIAR OUTRO
                  </button>
                  <button
                    type="submit"
                    onClick={() => setSaveType("save")}
                    style={{ borderRadius: "10px" }}
                    className="btn btn-prop-blue px-lg-2 py-2 text-white fz-13 text-uppercase font-rubik w-25"
                  >
                    SALVAR
                  </button>
                </>
              )}
            </div>
          </div>
        </form>
      </>
    );
  } else {
    return <></>;
  }
}

export default ModalDepartament;
