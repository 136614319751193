import React, { useState } from "react";
import api from "../../../../config/api";
// import swal from "sweetalert";
import { toastsMsg } from "../../../ToasterMsg";

function ModalClient(props) {
  const [name, setName] = useState("");
  const [file, setFile] = useState("");
  let [status, setStatus] = useState("");
  const [load, setLoad] = useState(false);

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);
    if (status === "") {
      status = "active";
    }
    formData.append("status", status);

    await api
      .post(`/client`, formData)
      .then(() => {
        setLoad(false);
        toastsMsg("Sucesso!", "Cliente cadastrado com sucesso!", "success");
        document.getElementById("btnClearClient").click();
        // props.close();
      })
      .catch(() => {
        setLoad(false);
        toastsMsg("Erro!", "Erro ao cadastrar o cliente, tente novamente mais tarde.", "error");
      });
  };

  return (
    <>
      <form onSubmit={save}>
        <div className="row">
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Nome</label>
            <input
              placeholder="Escolha um nome"
              type="text"
              className="form-control"
              onChange={(event) => setName(event.target.value)}
              required
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Status</label>
            <select className="form-select" onChange={(event) => setStatus(event.target.value)} required>
              <option value="active" defaultValue>
                Ativo
              </option>
              <option value="inactive">Inativo</option>
            </select>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label htmlFor="formFile" className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
              Logo <span className="fz-10 text-prop-danger font-rubik">Tamanho máximo 2MB</span>
            </label>
            <input
              className="form-control"
              type="file"
              id="formFile"
              onChange={(event) => setFile(event.target.files[0])}
              required
            />
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 d-flex align-items-center justify-content-end">
            <button type="reset" className="d-none" id="btnClearClient">
              Reset
            </button>
            <button
              type="button"
              className=" btn text-danger px-lg-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
            {load ? (
              <button
                type="button"
                className=" btn btn-prop-blue px-lg-5 py-2 text-white fz-13 text-uppercase font-rubik w-28 d-flex justify-content-center"
                disabled
              >
                <div className="spinner-border spinner-border-sm text-light" role="status">
                  <span className="visually-hidden">Carregando...</span>
                </div>
              </button>
            ) : (
              <button
                type="submit"
                className=" btn btn-prop-blue px-lg-5 py-2 text-white fz-13 text-uppercase font-rubik w-28 d-flex justify-content-center"
              >
                Cadastrar
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

export default ModalClient;
