import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
// import MaterialIcon from "material-icons-react";
import Select from "react-select";
import { List, ListBody, ListBodyItem, ListBodyItemAction, ListHeader, ListHeaderItem } from "../../../components/List";
import { CardBody, CardItemBody, CardHeaderItem, CardItem } from "../../../components/ColorCard";
import ModalProjectModel from "../../../components/_modals/_agency/ProjectModel";
import EmptyList from "../../../components/_empty/list";
import AddButton from "../../../components/AddButton";
import Loading from "../../../components/Load";
import api from "../../../config/api";
// import io from "socket.io-client";
import swal from "sweetalert";
import { toastsMsg } from "../../../components/ToasterMsg";
require("dotenv").config();

function ProjectModel() {
  const [projects, setProjects] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadTasksModel, setLoadTasksModel] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [tasksType, setTasksType] = useState([]);
  const [taskType, setTaskType] = useState("");
  const [tasksModel, setTasksModel] = useState([]);
  const [tasksModelSelected, setTasksModelSelected] = useState("");
  const [titleModelTask, setTitleModelTask] = useState("");
  const [taskId, setTaskId] = useState("");
  let [idProjectModel, setIdProjectModel] = useState("");
  const [saveType, setSaveType] = useState("");

  useEffect(() => {
    getProjects(page);
    getTasksType();
    getTasksModel();
    // async function registerToSocket() {
    //     const socket = io(`${process.env.REACT_APP_API_URL}`);
    //     socket.on("projectModel", () => {
    //         getProjects(page);
    //     });
    //     socket.on("taskModel", () => {
    //         getProjects(page);
    //     });
    // }
    // registerToSocket();
  }, [page]);

  const getProjects = async (mypage = 1, filter = "") => {
    setLoading(true);
    const response = await api.get(`/project-model-tasks?paginate=true&page=${mypage}&filter=${filter}`);
    const { docs = [], ...arr } = response.data;
    setProjects(docs);
    setPages(parseInt(arr.pages));
    setLoading(false);
  };

  const getTasksType = async () => {
    const response = await api.get(`/typeTask?limit=9999&sortBy=name&order=ASC`);
    setTasksType(response.data);
  };

  const getTasksModel = async () => {
    const response = await api.get(`/task-model?limit=9999&sortBy=name&order=ASC`);
    let taskModel_arr = [];
    let items = response.data;
    items.forEach((item) => taskModel_arr.push({ value: item._id, label: `${item.title}` }));
    setTasksModel(taskModel_arr);
  };

  const nextPage = () => {
    getProjects(page + 1);
    setPage(page === pages ? page : page + 1);
  };

  const backPage = () => {
    getProjects(page - 1);
    setPage(page === 1 ? page : page - 1);
  };

  const destroy = async (item) => {
    swal({
      title: "Tem certeza que deseja apagar esse modelo de projeto?",
      text: `Esse modelo de projeto será apagado e não terá mais volta`,
      // icon: "warning",
      buttons: ["Cancelar", "EXCLUIR"],
      dangerMode: true,
    }).then(async (res) => {
      if (res) {
        await api
          .delete(`/project-model/${item._id}`)
          .then(async () => {
            await getProjects(page);
            toastsMsg({
              icon: "success",
              title: "Sucesso!",
              text: "Modelo de projeto removido com sucesso.",
            });
          })
          .catch(() => {
            toastsMsg({
              icon: "error",
              title: "Erro!",
              text: "Erro ao remover o modelo de projeto, tente novamente mais tarde.",
            });
          });
      }
    });
  };

  const destroyModelTask = async (item) => {
    swal({
      title: "Tem certeza que deseja excluir essa tarefa?",
      text: `Essa tarefa será apagado e não terá mais volta`,
      // icon: "warning",
      buttons: ["Cancelar", "EXCLUIR"],
      dangerMode: true,
    }).then(async (res) => {
      if (res) {
        await api
          .delete(`/task-model/${item._id}`)
          .then(async () => {
            await getProjects(page);
            toastsMsg({
              icon: "success",
              title: "Sucesso!",
              text: "Modelo de tarefa removido com sucesso.",
            });
          })
          .catch(() => {
            toastsMsg({
              icon: "error",
              title: "Erro!",
              text: "Erro ao remover o modelo de tarefa, tente novamente mais tarde.",
            });
          });
      }
    });
  };

  const edit = (item) => {
    setType("edit");
    let taskModel_arr = [];
    item.tasks.forEach((item) => taskModel_arr.push({ value: item._id, label: `${item.title}` }));
    setTasksModelSelected(taskModel_arr);
    setName(item.name);
    setId(item._id);
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);

    let arrTasks = [];
    for (let count = 0; count < tasksModelSelected.length; count++) {
      arrTasks.push(tasksModelSelected[count].value);
    }

    let updateProjectModel = {
      name: name,
      tasks: arrTasks,
    };
    await api
      .put(`/project-model/${id}`, updateProjectModel)
      .then(() => {
        setLoad(false);
        toastsMsg("Sucesso!", "Modelo de projeto editado com sucesso!", "success");
        document.getElementById("btnCloseSingle").click();
        getProjects(page);
      })
      .catch(() => {
        setLoad(false);
        toastsMsg("Erro!", "Erro ao editar o modelo de projeto, tente novamente mais tarde.", "error");
      });
  };

  const editTaskModel = (task) => {
    setTitleModelTask(task.title);
    setTaskType(task.type._id);
    setTaskId(task._id);
  };

  const addTaskModel = (project) => {
    setIdProjectModel(project);
    setTitleModelTask("");
    setTaskType("");
    setTaskId("");
  };

  const saveModelTasks = async (event) => {
    event.preventDefault();
    setLoadTasksModel(true);
    if (idProjectModel === "") {
      idProjectModel = localStorage.getItem("projectModelId");
    }
    console.log("idProjectModel", idProjectModel);
    console.log("localStorage.getItem('projectModelId')", localStorage.getItem("projectModelId"));
    let object = {
      title: titleModelTask,
      taskType: taskType,
      projectModel: idProjectModel,
    };
    if (taskId === "") {
      await api
        .post(`/task-model`, object)
        .then((response) => {
          setLoadTasksModel(false);
          toastsMsg("Sucesso!", "Tarefa cadastrada com sucesso!", "success");
          setTitleModelTask("");
          setTaskType("");
          document.getElementById("btnClearTaskModel").click();

          if (saveType === "save") {
            document.getElementById("btnCloseTaskModel").click();
          }
          //props.close();
        })
        .catch(() => {
          setLoadTasksModel(false);
          toastsMsg("Erro!", "Erro ao cadastrar a tarefa, tente novamente mais tarde.", "error");
        });
    } else {
      await api
        .put(`/task-model/${taskId}`, object)
        .then(() => {
          setLoadTasksModel(false);
          toastsMsg("Sucesso!", "Tarefa editada com sucesso!", "success");
          setTitleModelTask("");
          setTaskType("");
          document.getElementById("btnCloseSingle").click();
        })
        .catch(() => {
          setLoadTasksModel(false);
          toastsMsg("Erro!", "Erro ao editar a tarefa, tente novamente mais tarde.", "error");
        });
    }
  };

  return (
    <>
      <Layout defaultMenu={6} title={"Modelos de projeto"}>
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-12 mb-4">
                            <h1 className="mb-0 fz-24 fw-bold font-rubik">Modelos de projeto</h1>
                        </div> */}
            <div className="col-12 col-lg-4 mt-2 me-auto mb-4">
              <div className="input-group input-search">
                <input
                  type="text"
                  className="form-control border-0 fz-14 box-shadow-none text-prop-grey-light font-rubik"
                  onChange={(event) => getProjects(page, event.target.value)}
                  placeholder="O que você está buscando?"
                />
                <span className="input-group-text bg-white border-0">
                  <i className="bi bi-search text-prop-grey-light"></i>
                </span>
              </div>
            </div>
            <AddButton sizeLg={6} onClick={() => setType("add")} text={"Adicionar Modelo"} modalId={"#myModalSingle"} />
            {projects.map((item, index) => (
              <div className="col-12 mb-4" key={index}>
                <List
                  paginate={false}
                  page={page}
                  pages={pages}
                  backPage={() => backPage()}
                  nextPage={() => nextPage()}
                >
                  <CardBody isSprint={false} edit={() => edit(item)} destroy={() => destroy(item)}>
                    <CardItemBody>
                      <CardHeaderItem sizeLg={12} className={"px-2"} text={"Nome"} />
                    </CardItemBody>
                    <CardItemBody className={"cardItemBody heigthCard"}>
                      <CardItem sizeLg={12} className={"px-lg-2"} title={"Nome"} text={item.name} />
                    </CardItemBody>
                  </CardBody>

                  {(item.tasks !== undefined) & (item.tasks.length > 0) ? (
                    <>
                      <ListHeader specialClass="mb-2">
                        <ListHeaderItem name="" sizeLg={1} />
                        <ListHeaderItem name="Nome da tarefa" sizeLg={2} />
                        <ListHeaderItem name="Tipo da tarefa" sizeLg={9} />
                      </ListHeader>
                      {item.tasks.map((tasks, index) => (
                        <ListBody key={index} specialClass="d-flex flex-wrap">
                          <ListBodyItem title="" value={""} sizeLg={1} />
                          <ListBodyItem title="Nome" value={tasks.title} sizeLg={2} />
                          <ListBodyItem title="Tipo" value={tasks.type.name} sizeLg={1} />
                          <ListBodyItemAction sizeLg={8}>
                            <li>
                              <span
                                className="dropdown-item fz-14 font-rubik c-pointer"
                                data-bs-toggle="modal"
                                data-bs-target="#myModalTask"
                                onClick={() => editTaskModel(tasks)}
                              >
                                Editar
                              </span>
                            </li>
                            <li>
                              <span
                                className="dropdown-item fz-14 font-rubik text-prop-danger c-pointer"
                                onClick={() => destroyModelTask(tasks)}
                              >
                                Excluir
                              </span>
                            </li>
                          </ListBodyItemAction>
                        </ListBody>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="col-12 d-flex justify-content-end align-items-center  mt-2">
                    <AddButton
                      sizeLg={6}
                      onClick={() => addTaskModel(item._id)}
                      text={"Adicionar Tarefa"}
                      modalId={"#myModalSingle"}
                    />
                  </div>
                </List>
              </div>
            ))}
            {projects.length === 0 ? (
              <EmptyList icon="indiferente" message="Não foi encontrado nenhum modelo de projeto" />
            ) : (
              <></>
            )}
          </div>
        </div>
      </Layout>
      <div
        className="modal fade border-0"
        id="myModalTask"
        tabIndex="-1"
        aria-labelledby="myModalTask"
        aria-hidden="true"
        style={{ borderRadius: "10px" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalLabel">
                {taskId === "" ? "Adicionar Modelo de tarefa" : "Alterar Modelo de tarefa"}
              </h5>
              <button
                type="button"
                id="btnCloseTaskModel"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={saveModelTasks}>
                <div className="row">
                  <div className="col-12 col-lg-12 mb-3">
                    <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Título</label>
                    <input
                      type="text"
                      className="form-control"
                      value={titleModelTask}
                      onChange={(event) => setTitleModelTask(event.target.value)}
                      required
                    />
                  </div>
                  <div className="col-12 col-lg-12 mb-3">
                    <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                      Tipo de tarefa
                    </label>
                    <select
                      className="form-select"
                      value={taskType}
                      onChange={(event) => setTaskType(event.target.value)}
                      required
                    >
                      <option></option>
                      {tasksType.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-12 d-flex align-items-center justify-content-end">
                    <button type="reset" className="d-none" id="btnClearTaskModel">
                      Reset
                    </button>
                    <button
                      type="button"
                      className="btn text-danger px-lg-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-25"
                      data-bs-dismiss="modal"
                    >
                      Cancelar
                    </button>
                    {loadTasksModel ? (
                      <button
                        type="button"
                        className="btn btn-prop-blue px-lg-5 py-2 text-white fz-13 text-uppercase font-rubik w-25"
                        disabled
                      >
                        <div className="spinner-border spinner-border-sm text-light" role="status">
                          <span className="visually-hidden">Carregando...</span>
                        </div>
                      </button>
                    ) : (
                      <>
                        <button
                          type="submit"
                          onClick={() => setSaveType("save")}
                          className="px-lg-2 py-2 save-close-button fz-13 text-uppercase font-rubik w-25 mx-2"
                        >
                          SALVAR
                        </button>
                        <button
                          type="submit"
                          onClick={() => setSaveType("saveMore")}
                          style={{ borderRadius: "10px" }}
                          className="btn btn-prop-blue px-lg-2 py-2 text-white fz-13 text-uppercase font-rubik w-28"
                        >
                          SALVAR E CRIAR OUTRA
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade border-0"
        id="myModalSingle"
        tabIndex="-1"
        aria-labelledby="myModalSingleLabel"
        aria-hidden="true"
        style={{ borderRadius: "10px" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {type === "add" ? (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalLabel">
                    Adicionar Modelo
                  </h5>
                  <button
                    type="button"
                    id="btnCloseProjectModel"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <ModalProjectModel
                    openTask={"myModalTask"}
                    close={() => document.getElementById("btnCloseProjectModel").click()}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                    Editar modelo de projeto
                  </h5>
                  <button
                    type="button"
                    id="btnCloseSingle"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={save}>
                    <div className="row">
                      <div className="col-12 col-lg-12 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Nome</label>
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          onChange={(event) => setName(event.target.value)}
                          required
                        />
                      </div>
                      <div className="col-12 col-lg-12 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Tarefas</label>
                        <Select
                          name="tasks"
                          placeholder=""
                          value={tasksModelSelected}
                          onChange={(item) => setTasksModelSelected(item)}
                          options={tasksModel}
                          isMulti
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-12 d-flex align-items-center justify-content-end">
                        <button
                          type="button"
                          className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-28"
                          data-bs-dismiss="modal"
                        >
                          Cancelar
                        </button>
                        {load ? (
                          <button
                            type="button"
                            className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                            disabled
                          >
                            <div className="spinner-border spinner-border-sm text-light" role="status">
                              <span className="visually-hidden">Carregando...</span>
                            </div>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                          >
                            Salvar
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Loading show={loading} />
    </>
  );
}

export default ProjectModel;
