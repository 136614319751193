import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import api from "../../../config/api";
import AddSprint from "../../../components/AddSprint";
import Loading from "../../../components/Load";
import ModalSprint from "../../../components/_modals/_agency/Sprint";
import swal from "sweetalert";
import SidebarProject from "../../../components/SidebarProject";
import SidebarProjectCategory from "../../../components/SidebarProjectCategory";
import { decodeToken } from "../../../config/auth";
import EmptyList from "../../../components/_empty/list";
import { convertTime } from "../../../config/utils";
import play from "../../../assets/img/icons/play.svg";
import pause from "../../../assets/img/icons/pause.svg";
import { ListEachSprint, ListEachSprintSkeleton } from "../../../components/List";
import Footer from "../../../components/Footer";
import { Drawer } from "../../../components/Drawer";

import Select from "react-select";
import { toastsMsg } from "../../../components/ToasterMsg";
require("dotenv").config();

function Sprint(props) {
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sprints, setSprints] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [estimatedDate, setEstimatedDate] = useState("");
  const [task, setTask] = useState([]);
  const [status, setStatus] = useState("");
  const [project, setProject] = useState("");
  const [client, setClient] = useState("");
  const [type, setType] = useState("");
  const [collapseArr, setCollapseArr] = useState([]);

  // Task
  const [users, setUsers] = useState(1);
  const [idTask, setIdTask] = useState("");
  const [titleTask, setTitleTask] = useState("");
  const [estimatedDateTask, setEstimatedDateTask] = useState("");
  const [sprintTitle, setSprintTitle] = useState("");
  const [priorityTask, setPriorityTask] = useState(false);
  const [responsibleTask, setResponsibleTask] = useState([]);
  const [taskShow, setTaskShow] = useState({});
  const [typeTasks, setTypeTasks] = useState([]);
  const [statusTask, setStatusTask] = useState("");
  const [categoryTask, setCategoryTask] = useState("");
  const [hoursTask, setHoursTask] = useState("");
  const [sprintTask, setSprintTask] = useState([]);
  const [saveType, setSaveType] = useState("");
  let [user, setUser] = useState({});
  let [timeOnPlay, setTimeOnPlay] = useState([]);
  const [taskHistory, setTaskHistory] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [drawerOpenShow, setDrawerOpenShow] = useState(false);
  const [viewTask, setViewTask] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerOpenAddSprint, setDrawerOpenAddSprint] = useState(false);
  const [drawerOpenAddTask, setDrawerOpenAddTask] = useState(false);
  const [loadSprints, setLoadSprints] = useState(false);
  const [loadTaskButton, setLoadTaskBurron] = useState(false);

  // const socket = io(`${process.env.REACT_APP_API_URL}`);
  const [taskOnPlay, setTaskOnPlay] = useState({});
  const [sort, setSort] = useState("");
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    const { user = {} } = decodeToken();
    setUser(user);
    getSprints(props.match.params.id);
    getTasks();
    getProject();
    getUsers();
    getTypeTasks();
    // getGantt();
    // async function registerToSocket() {
    //     try {
    //         const socket = io(`${process.env.REACT_APP_API_URL}`);
    //         socket.on("sprint", () => {
    //             getSprints();
    //         });
    //     } catch (error) {
    //         console.log("Error Sprint socket", error)
    //     }
    // }
    // registerToSocket();
    // async function registerToSocketTask() {
    //     const socket = io(`${process.env.REACT_APP_API_URL}`);
    //     socket.on("task", () => {
    //         getTasks();
    //     });
    // }
    // registerToSocketTask();
    // function teste() {
    //     getTaskOnPlay();
    //     const socket = io(`${process.env.REACT_APP_API_URL}`);
    //     socket.on("time", (response) => {
    //         let total = 0
    //         response.time.forEach(element => total += (element.hour === undefined) ? 0 : element.hour);
    //         let newArray = timeOnPlay;
    //         let index = timeOnPlay.findIndex(element => element.id === response.id);
    //         if (index !== -1) {
    //             newArray[index].total = total;
    //             newArray[index].hours = response.time;
    //             setTimeOnPlay(newArray);
    //         }
    //         getTaskOnPlay();
    //     });
    // }
    // teste();
  }, [props, user.role]);

  const getTaskOnPlay = async () => {
    try {
      const { user = {} } = decodeToken();
      const response = await api.get(`/profile/${user._id}`);
      setTaskOnPlay(response.data.taskOnPlay);
    } catch (error) {
      console.log(error);
    }
  };

  const getSprints = async (myprojectId = props.match.params.id) => {
    setLoadSprints(true);
    try {
      const response = await api.get(`/sprint/${myprojectId}?limit=99999&order=DESC`);
      setSprints(response.data);
      setLoadSprints(false);
    } catch (error) {
      console.log(error);
      setLoadSprints(false);
    }
  };

  const getProject = async () => {
    try {
      const response = await api.get(`/dashboard-project/${props.match.params.id}`);
      setProject(response.data);
      setClient(response.data.client);
    } catch (error) {
      console.log(error);
    }
  };

  const getTypeTasks = async (limit = 100) => {
    try {
      const response = await api.get(`/typeTask?paginate=true&limit=${limit}`);
      const { docs = [] } = response.data;
      setTypeTasks(docs);
    } catch (error) {
      console.log(error);
    }
  };

  // const getGantt = async () => {
  //     const response = await api.get(`/dashboard-project-schedule/${props.match.params.id}`);
  //     setSchedule(response.data);
  // };

  const getUsers = async () => {
    try {
      const response = await api.get(`/user/agency?limit=99999&sortBy=firstName&order=ASC`);
      const users_arr = [];
      let items = response.data;
      items = items.filter((item) => item.status === "active" && item.status !== "inactive");
      items.forEach((item) =>
        users_arr.push({
          value: item._id,
          label: `${item.firstName} ${item.lastName}`,
        })
      );
      setUsers(users_arr);
    } catch (error) {
      console.log(error);
    }
  };

  const getTaskHistory = async (id) => {
    try {
      const response = await api.get(`/task-history/${id}`);
      setTaskHistory(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const destroyTask = async (item) => {
    swal({
      title: "Tem certeza que deseja excluir a tarefa?",
      text: `Essa tarefa será apagada e não terá mais volta`,
      // icon: "warning",
      buttons: ["Cancelar", "EXCLUIR"],
      dangerMode: true,
    }).then(async (res) => {
      if (res) {
        await api
          .delete(`/task/${item._id}`)
          .then(async () => {
            await getSprints(props.match.params.id);
            getTasks();
            toastsMsg({
              icon: "success",
              title: "Sucesso!",
              text: "Tarefa removida com sucesso.",
            });
          })
          .catch(() => {
            toastsMsg({
              icon: "error",
              title: "Erro!",
              text: "Erro ao remover a tarefa, tente novamente mais tarde.",
            });
          });
      }
    });
  };

  const destroy = async (item) => {
    swal({
      title: "Tem certeza que deseja excluir a sprint",
      text: `Todas as tarefas irão ser apagadas e esta ação não tem mais volta`,
      // icon: "warning",
      buttons: ["Cancelar", "EXCLUIR"],
      dangerMode: true,
    }).then(async (res) => {
      if (res) {
        await api
          .delete(`/sprint/${item._id}`)
          .then(async () => {
            await getSprints(props.match.params.id);
            getTasks();
            toastsMsg({
              icon: "success",
              title: "Sucesso!",
              text: "Sprint removida com sucesso.",
            });
          })
          .catch(() => {
            toastsMsg({
              icon: "error",
              title: "Erro!",
              text: "Erro ao remover a sprint, tente novamente mais tarde.",
            });
          });
      }
    });
  };

  const edit = (item) => {
    setType("edit");
    setId(item._id);
    console.log(item._id);
    setTitle(item.title);
    setEstimatedDate(String(item.estimatedDate).substr(0, 10));
    setStatus(item.status);
    setDrawerOpenAddSprint(true);
    const tasks_arr = [];
    const { task: tasksSprint = [] } = item;
    if (tasksSprint) {
      tasksSprint.forEach((u) => tasks_arr.push({ value: u._id, label: u.title, sprint: u.sprint }));
    }
    setTask(tasks_arr);
  };

  const addType = (item) => {
    setType("add");
    setDrawerOpenAddSprint(true);
  };

  const editTask = (item) => {
    setTaskShow(item);
    setIdTask(item._id);
    setTitleTask(item.title);
    setEstimatedDateTask(String(item.estimatedDate).substr(0, 10));
    setStatusTask(item.status);
    setCategoryTask(item.category);
    setDrawerOpenAddTask(true);
    if (item.hours !== undefined) {
      if (Array.isArray(item.hours)) {
        let taskHours = item.hours.find((element) => element.user === user._id);
        if (taskHours !== undefined) {
          setHoursTask(convertTime(taskHours.hour));
        } else {
          setHoursTask(convertTime(0));
        }
      } else {
        setHoursTask(convertTime(0));
      }
    } else {
      setHoursTask(convertTime(0));
    }
    const users_arr = [];
    item.responsible.forEach((u) => users_arr.push({ value: u._id, label: `${u.firstName} ${u.lastName}` }));
    setResponsibleTask(users_arr);
  };

  const showTask = (item, sprint) => {
    getTaskHistory(item._id);
    setTaskShow(item);
    showTask.estimatedDate = String(item.estimatedDate).substr(0, 10);
    showTask.deliveryDate = String(item.deliveryDate).substr(0, 10);
    setSprintTitle(sprint.title);
    setDrawerOpenShow(true);
  };

  const addTask = (item) => {
    setSprintTask(item);
    setIdTask("");
    setTitleTask("");
    setEstimatedDateTask("");
    setStatusTask("open");
    setCategoryTask("");
    setHoursTask(0);
    setResponsibleTask([]);
    setDrawerOpenAddTask(true);
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);

    let task_arr = [];
    if (task !== null) {
      task_arr = task.length > 0 ? task.map((item) => item.value) : [];
    } else {
      task_arr = [];
    }
    try {
      await api
        .put(`/sprint/${id}`, { title, estimatedDate, task: task_arr, status })
        .then(() => {
          setLoad(false);
          toastsMsg("Sucesso!", "Sprint editada com sucesso!", "success");
          getSprints(props.match.params.id);
          getTasks();
          setDrawerOpenAddSprint(false);
        })
        .catch((error) => {
          console.log(error);
          setLoad(false);
          toastsMsg("Erro!", "Erro ao editar a sprint, tente novamente mais tarde.", "error");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const saveTask = async (event) => {
    event.preventDefault();
    setLoad(true);

    let user_arr = [];
    let hours_arr = [];
    if (responsibleTask !== null) {
      user_arr = responsibleTask.length > 0 ? responsibleTask.map((item) => item.value) : [];
      user_arr.forEach((item) => hours_arr.push({ user: item, hour: 0 }));
    } else {
      user_arr = [];
    }
    if (taskShow._id === undefined) {
      await api
        .post(`/task/`, {
          project: project,
          sprint: sprintTask,
          title: titleTask,
          estimatedDate: estimatedDateTask,
          priority: priorityTask,
          responsible: user_arr,
          status: statusTask,
          hours: hours_arr,
          category: categoryTask,
        })
        .then(() => {
          setLoad(false);
          // document.getElementById("btnCloseSingle").click();
          getSprints(props.match.params.id);
          getTasks();
          setIdTask("");
          setTitleTask("");
          setEstimatedDateTask("");
          setStatusTask("open");
          setCategoryTask("");
          setHoursTask(0);
          setResponsibleTask([]);
          setDrawerOpenAddTask(false);
          toastsMsg("Sucesso!", "Tarefa adicionada com sucesso!", "success");
        })
        .catch((error) => {
          console.log(error);
          setLoad(false);
          toastsMsg("Erro!", "Erro ao adicionada a tarefa, tente novamente mais tarde.", "error");
          setDrawerOpenAddTask(false);
        });
    } else {
      await api
        .put(`/task/${idTask}`, {
          title: titleTask,
          estimatedDate: estimatedDateTask,
          priority: priorityTask,
          responsible: user_arr,
          status: statusTask,
          hours: hoursTask,
          category: categoryTask,
        })
        .then(() => {
          setLoad(false);
          setDrawerOpenAddTask(false);
          toastsMsg("Sucesso!", "Tarefa editada com sucesso!", "success");
          getSprints(props.match.params.id);
          getTasks();
        })
        .catch(() => {
          setLoad(false);
          setDrawerOpenAddTask(false);
          toastsMsg("Erro!", "Erro ao editar a tarefa, tente novamente mais tarde.", "error");
        });
    }
  };

  const getTasks = async () => {
    const response = await api.get(`/task/${props.match.params.id}?limit=99999&order=DESC`);
    const tasks_arr = [];
    let items = response.data;
    let taskPlay = [];
    for (const item of items) {
      let total = 0;
      if (item.hours.length > 0) {
        item.hours.forEach((element) => (total += element.hour === undefined ? 0 : element.hour));
      }
      taskPlay.push({ id: item._id, total: total });
    }
    timeOnPlay = taskPlay;
    items.forEach((item) =>
      tasks_arr.push({
        value: item._id,
        label: item.title,
        sprint: item.sprint,
      })
    );
    setTasks(tasks_arr);
    setLoadTaskBurron(false);
  };

  const changeTask = async (item, status) => {
    setLoadTaskBurron(true);
    try {
      let deliveryDate = status === "closed" ? new Date() : null;
      await api.put(`/change-task/${item._id}`, { status, deliveryDate }).then(() => {
        getSprints(props.match.params.id);
        getTasks();
        setDrawerOpenShow(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const verifyTaskAction = async (item) => {
    let changedAction = item.action === "pause" ? "play" : "pause";
    if (changedAction === "play") {
      await api.get(`/profile/${user._id}`).then(async (res) => {
        // console.log(res);
        const userNew = res.data;
        if (userNew.activePlay === true) {
          swal({
            title: "Você tem certeza que deseja iniciar essa tarefa?",
            text: `Você está com outra tarefa em andamento no momento, selecionar essa tarefa irá parar a tarefa anterior`,
            buttons: ["Cancelar", "INICIAR TAREFA"],
            dangerMode: false,
          }).then(async (res) => {
            if (res) {
              changeActionTask(item);
              getSprints(props.match.params.id);
              getTasks();
            }
          });
        } else {
          changeActionTask(item);
          getSprints(props.match.params.id);
          getTasks();
        }
      });
    } else {
      changeActionTask(item);
      getSprints(props.match.params.id);
      getTasks();
    }
  };

  const changeActionTask = async (item) => {
    let changedAction = item.action === "pause" ? "play" : "pause";
    await api
      .put(`/change-task-action/${item._id}`, { action: changedAction })
      .then(() => {
        getSprints(props.match.params.id);
        getTasks();
      })
      .catch(() => {});
    await api.get(`/count-time/${item._id}`);
  };

  const changeSprint = async (item, status) => {
    if (status === "open") {
      let qtIniciada = 0;
      let sprintIniciada = "";
      for (let count = 0; count < sprints.length; count++) {
        if (sprints[count].status === "open") {
          qtIniciada++;
          sprintIniciada = sprints[count].title;
        }
      }
      if (qtIniciada === 0) {
        swal({
          title: "Você tem certeza que deseja iniciar a sprint?",
          text: `A sprint e as tarefas não poderão ser mais alteradas`,
          buttons: ["Cancelar", "INICIAR SPRINT"],
          dangerMode: false,
        }).then(async (res) => {
          if (res) {
            // console.log("item", item);
            if (item.task !== undefined) {
              if (item.task.length > 0) {
                let notResponsible = false;
                item.task.forEach((x) => (x.responsible === undefined || x.responsible.length === 0 ? (notResponsible = true) : ""));
                if (notResponsible) {
                  toastsMsg("Erro!", "Não é possivel iniciar a sprint até que todas as tarefas tenham responsáveis", "error");
                } else {
                  updateSprint(item, status);
                }
              } else {
                updateSprint(item, status);
              }
            } else {
              updateSprint(item, status);
            }
          }
        });
      } else {
        toastsMsg("Erro!", `Não é possivel iniciar essa sprint, pois a sprint ${sprintIniciada}, já foi iniciada`, "error");
      }
    } else if (status === "closed") {
      const taskOpen = item.task.find((t) => t.status !== "closed");
      if (taskOpen) {
        const nextSprint = sprints.find((s) => (s.status !== "closed") & (s.createdAt > item.createdAt));
        if (nextSprint) {
          swal({
            title: "Você tem certeza que deseja entregar a sprint?",
            text: `Essa ação não terá mais volta`,
            buttons: ["Cancelar", "ENTREGAR SPRINT"],
            dangerMode: false,
          }).then(async (res) => {
            if (res) {
              let deliveryDate = status === "closed" ? new Date() : null;
              await api
                .put(`/sprint-close/${item._id}`, {
                  title: item.title,
                  estimatedDate: item.estimatedDate,
                  status,
                  deliveryDate,
                  nextSprint,
                })
                .then(() => {
                  getSprints(props.match.params.id);
                  getTasks();
                })
                .catch(() => {
                  toastsMsg("Erro!", "Sprint não foi entregue, tente novamente mais tarde!", "error");
                });
            }
          });
        } else {
          toastsMsg("Erro!", `Não é possivel entregar essa sprint, pois a não existe uma proxima sprint ou a proxima sprint foi entregue`, "error");
        }
      } else {
        swal({
          title: "Você tem certeza que deseja entregar a sprint?",
          text: `Essa ação não terá mais volta`,
          buttons: ["Cancelar", "ENTREGAR SPRINT"],
          dangerMode: false,
        }).then(async (res) => {
          updateSprint(item, status);
        });
      }
    }
  };

  const updateSprint = async (item, status) => {
    const { task: taskArr = [] } = item;
    let task_arr = [];
    if (taskArr !== null) {
      task_arr = taskArr.length > 0 ? taskArr.map((item) => item._id) : [];
    } else {
      task_arr = [];
    }

    let sprintChanged = {
      title: item.title,
      estimatedDate: item.estimatedDate,
      status: status,
      task: task_arr,
    };

    await api
      .put(`/sprint/${item._id}`, sprintChanged)
      .then(() => {
        window.location.reload(false);
        getSprints(props.match.params.id);
        getTasks();
      })
      .catch(() => {});
  };

  const collapseSprint = (index) => {
    let arr = collapseArr;
    const sprint = collapseArr[index].show;
    arr[index].show = !sprint;
    setCollapseArr(arr);
  };

  const orderTask = async (item, sortBy) => {
    setLoading(true);
    let myprojectId = props.match.params.id;
    let response = [];
    if (sort !== sortBy) {
      response = await api.get(`/sprint-order/${myprojectId}?limit=99999&order=ASC&idSprint=${item._id}&sortBy=${sortBy}`);
      setSort(sortBy);
    } else {
      response = await api.get(`/sprint-order/${myprojectId}?limit=99999&order=ASC&idSprint=${item._id}`);
      setSort("");
    }
    setSprints(response.data);
    setLoading(false);
  };

  const filterChangeHandlerName = (event) => {
    setNameFilter(event.target.value);
    getTasks();
  };

  const filterChangeHandlerCategory = (event) => {};

  const changeSprintDate = async (sprint, startDate, estimatedDate) => {
    // if (sprint.status !== "pending" && sprint.startDate) {
    //   return toastsMsg("Erro!", `Sprint já iniciada ou concluída`, "error");
    // }
    const { task: taskArr = [] } = sprint;
    let task_arr = [];
    if (taskArr !== null) {
      task_arr = taskArr.length > 0 ? taskArr.map((item) => item._id) : [];
    } else {
      task_arr = [];
    }
    let sprintChanged = {
      title: sprint.title,
      estimatedDate: new Date(estimatedDate),
      startDate: new Date(startDate),
      status: sprint.status,
      task: task_arr,
    };
    try {
      await api.put(`/sprint/${sprint._id}`, sprintChanged).then(() => {
        getSprints(props.match.params.id);
        getTasks();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const changeSprintName = async (sprint, titulo) => {
    if (sprint.status !== "pending") {
      return toastsMsg("Erro!", `Sprint já iniciada ou concluída`, "error");
    }
    const { task: taskArr = [] } = sprint;
    let task_arr = [];
    if (taskArr !== null) {
      task_arr = taskArr.length > 0 ? taskArr.map((item) => item._id) : [];
    } else {
      task_arr = [];
    }
    let sprintChanged = {
      title: titulo,
      estimatedDate: sprint.estimatedDate,
      status: sprint.status,
      task: task_arr,
    };
    try {
      await api.put(`/sprint/${sprint._id}`, sprintChanged).then(() => {
        getSprints(props.match.params.id);
        getTasks();
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Layout
        defaultMenu={0}
        isSingleProject={true}
        clientImg={client.logo}
        clientName={client.name}
        projectName={project.name}
        projectCategory={project.category !== undefined ? project.category[0] : ""}
      >
        <div className="container-fluid singleProject">
          {/* <div className={`row col-12 d-flex justify-content-between align-items-end`}>
                        <WidgetClient
                            specialClass={"p-0 m-0"}
                            link={(user.role === 'company') ? "/empresa-projetos-em-andamento" : (user.role === 'agency') ? "/projetos-em-andamento" : (user.role === 'category') ? "/projetos-da-categoria" : "/meus-projetos"}
                            img={client.logo}
                            clientName={client.name}
                            projectName={project.name}
                        />
                        {(user.role === 'agency') ?
                            <AddButton
                                className={"p-0 m-0"}
                                sizeLg={'auto'}
                                onClick={() => setType("add")}
                                modalId={"#myModalSingle"}
                                text="Sprint"
                            />
                            :
                            <></>
                        }
                    </div> */}

          {user.role === "agency" ? <SidebarProject active={"sprint"} project={props.match.params.id} /> : <></>}
          {user.role === "category" ? (
            <div className="col-12">
              <SidebarProjectCategory seeReport={user.seeReport} active={"sprint"} project={props.match.params.id} />
            </div>
          ) : (
            <></>
          )}
          {user.role === "agency" && (
            <div className="row">
              <div className="col-12">
                <AddSprint addType={addType} />
              </div>
            </div>
          )}
          {sprints.length ? (
            <div className="row">
              <div className="col-12 col-lg-3 mt-2  mb-3">
                <div className="input-group input-search">
                  <input
                    type="text"
                    className="form-control border-0 fz-14 box-shadow-none text-prop-grey-light font-rubik"
                    onChange={(event) => filterChangeHandlerName(event)}
                    placeholder="Busque uma tarefa"
                  />
                  <span className="input-group-text bg-white border-0">
                    <i className="bi bi-search text-prop-grey-light"></i>
                  </span>
                </div>
              </div>
              <div className="col-12 col-lg-3 mt-2 mb-4">
                <select
                  className="filter-select fz-14 border-0 font-rubik fw-bold"
                  style={{ color: "#102A43" }}
                  onChange={(event) => filterChangeHandlerCategory(event)}
                  required
                >
                  <option value="" key={0}>
                    Todas as sprints
                  </option>
                </select>
              </div>
              <div className="col-12 mb-1">
                {sprints.map((sprint) => {
                  let { task: tasksSprint = [] } = sprint;
                  // console.log(sprint)
                  if (collapseArr.length === 0) {
                    let arr = [];
                    for (const sprint of sprints) {
                      if (sprint.status === "open") {
                        arr.push({ id: sprint._id, show: true });
                      } else {
                        arr.push({ id: sprint._id, show: false });
                      }
                    }
                    setCollapseArr(arr);
                  }
                  let index = -1;
                  index = collapseArr.findIndex((item) => item.id === sprint._id);

                  return (
                    <div key={index}>
                      <ListEachSprint
                        user={user}
                        openTask={() => collapseSprint(index)}
                        isTaskOpen={index !== -1 ? (collapseArr[index].show ? "open" : "close") : "close"}
                        isSprint={true}
                        status={sprint.status}
                        role={user.role}
                        task={tasksSprint}
                        openSprint={() => changeSprint(sprint, "open")}
                        closeSprint={() => changeSprint(sprint, "closed")}
                        edit={() => edit(sprint)}
                        destroy={() => destroy(sprint)}
                        collapseArr={collapseArr}
                        sprint={sprint}
                        index={index}
                        addTask={addTask}
                        collapseSprint={collapseSprint}
                        changeTask={changeTask}
                        destroyTask={destroyTask}
                        editTask={editTask}
                        showTask={showTask}
                        changeSprintDate={changeSprintDate}
                        changeSprintName={changeSprintName}
                        loadTaskButton={loadTaskButton}
                        setLoadTaskBurron={setLoadTaskBurron}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <>
              {loadSprints ? (
                <>
                  <ListEachSprintSkeleton />
                  <ListEachSprintSkeleton />
                  <ListEachSprintSkeleton />
                  <ListEachSprintSkeleton />
                  <ListEachSprintSkeleton />
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        <Footer></Footer>
      </Layout>
      <Drawer show={drawerOpenAddTask} onClose={() => setDrawerOpenAddTask(false)} onOpen={() => setDrawerOpenAddTask(true)}>
        <div className="p-3 modal-drawer">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalLabel">
                  {idTask === "" ? "Adicionar Tarefa" : "Editar tarefa"}
                </h5>
                <button type="button" onClick={() => setDrawerOpenAddTask(false)} className="btn-close" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <form onSubmit={saveTask}>
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-3">
                      <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Título</label>
                      <input type="text" className="form-control" value={titleTask} onChange={(event) => setTitleTask(event.target.value)} required />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Deadline</label>
                      <input
                        type="date"
                        className="form-control"
                        value={estimatedDateTask}
                        onChange={(event) => setEstimatedDateTask(event.target.value)}
                        required
                      />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Prioridade</label>
                      <select className="form-select" value={priorityTask} onChange={(event) => setPriorityTask(event.target.value)} required>
                        <option value=""></option>
                        <option value={true}>Sim</option>
                        <option value={false}>Não</option>
                      </select>
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Categoria</label>
                      <select className="form-select" onChange={(event) => setCategoryTask(event.target.value)} value={categoryTask} required>
                        <option></option>
                        {typeTasks.map((item, index) => (
                          <option value={item.name} key={index}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {responsibleTask.length > 0 ? (
                      responsibleTask.find((item) => item.value === user._id) !== undefined ? (
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Horas trabalhadas</label>
                          <input
                            type="time"
                            step="1"
                            value={hoursTask}
                            className="form-control"
                            placeholder="Time"
                            onChange={(event) => setHoursTask(event.target.value)}
                          />
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    <div className="col-12 col-lg-12 mb-3">
                      <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Responsável</label>
                      <Select name="responsible" placeholder="" value={responsibleTask} onChange={(item) => setResponsibleTask(item)} options={users} isMulti />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-12 d-flex align-items-end justify-content-end">
                      <button
                        type="button"
                        className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-25"
                        onClick={() => setDrawerOpenAddTask(false)}
                      >
                        Cancelar
                      </button>
                      {load ? (
                        <button type="button" className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25" disabled>
                          <div className="spinner-border spinner-border-sm text-light" role="status">
                            <span className="visually-hidden">Carregando...</span>
                          </div>
                        </button>
                      ) : (
                        <>
                          <button
                            type="submit"
                            onClick={() => setSaveType("save")}
                            className="px-lg-2 py-2 save-close-button fz-13 text-uppercase font-rubik w-25 mx-2"
                          >
                            SALVAR
                          </button>
                          {idTask === "" ? (
                            <button
                              type="submit"
                              onClick={() => setSaveType("saveMore")}
                              style={{ borderRadius: "10px" }}
                              className="btn btn-prop-blue px-lg-2 py-2 text-white fz-13 text-uppercase font-rubik w-25"
                            >
                              SALVAR E CRIAR OUTRA
                            </button>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer show={drawerOpenAddSprint} onClose={() => setDrawerOpenAddSprint(false)} onOpen={() => setDrawerOpenAddSprint(true)}>
        <div className="p-3" style={{ maxHeight: "100%", width: "737px", height: "100%" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              {type === "add" ? (
                <>
                  <div className="modal-header border-0">
                    <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalLabel">
                      Adicionar nova sprint
                    </h5>
                    <button type="button" onClick={() => setDrawerOpenAddSprint(false)} className="btn-close" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <ModalSprint
                      project={project}
                      close={() => setDrawerOpenAddSprint(false)}
                      setDrawerOpenAddSprint={setDrawerOpenAddSprint}
                      getSprints={getSprints}
                      getTasks={getTasks}
                      addTask={addTask}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="modal-header border-0">
                    <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                      Editar sprint
                    </h5>
                    <button type="button" onClick={() => setDrawerOpenAddSprint(false)} className="btn-close" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={save}>
                      <div className="row">
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Título</label>
                          <input type="text" className="form-control" value={title} onChange={(event) => setTitle(event.target.value)} required />
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Estimativa de entrega</label>
                          <input
                            type="date"
                            className="form-control"
                            value={estimatedDate.slice(0, 10)}
                            onChange={(event) => setEstimatedDate(event.target.value + "T03:00:00.000Z")}
                            required
                          />
                        </div>
                        <div className="col-12 col-lg-12 mb-3">
                          <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Tarefas</label>
                          <Select
                            name="task"
                            placeholder=""
                            value={task}
                            onChange={(item) => setTask(item)}
                            options={tasks.filter((t) => t.sprint === null || t.sprint === undefined || t.sprint === id)}
                            isMulti
                          />
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-12 d-flex align-items-end justify-content-end">
                          <button
                            type="button"
                            className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-20"
                            onClick={() => setDrawerOpenAddSprint(false)}
                          >
                            Cancelar
                          </button>
                          {load ? (
                            <button
                              type="button"
                              className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                              disabled
                            >
                              <div className="spinner-border spinner-border-sm text-light" role="status">
                                <span className="visually-hidden">Carregando...</span>
                              </div>
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                            >
                              Salvar
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Drawer>
      <Loading show={loading} />
      <Drawer show={drawerOpenShow} onClose={() => setDrawerOpenShow(false)} onOpen={() => setDrawerOpenShow(true)}>
        <div className="p-3 notification-scroll modal-drawer h-100">
          <div className="modal-dialog modal-lg h-100">
            <div className="modal-content ">
              <div className="modal-header border-0">
                {(taskShow.action !== undefined) & (taskShow.responsible !== undefined) & (taskShow._id !== undefined) ? (
                  taskShow.action === "play" && taskShow.responsible.find((item) => item._id === user._id) !== undefined && taskOnPlay === taskShow._id ? (
                    <img onClick={() => verifyTaskAction(taskShow)} src={pause} alt="" style={{ width: 30, height: 30, marginRight: "10px" }} />
                  ) : taskShow.responsible.find((item) => item._id === user._id) !== undefined ? (
                    <img onClick={() => verifyTaskAction(taskShow)} src={play} alt="" style={{ width: 30, height: 30, marginRight: "10px" }} />
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalLabel">{`${sprintTitle} - ${taskShow.title}`}</h5>
                <button type="button" onClick={() => setDrawerOpenShow(false)} className="btn-close" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="row m-0">
                  <div className="col-lg-9 px-0">
                    <div className="col-12 py-3">
                      {taskShow.responsible !== undefined ? (
                        <h2 className="form-h2 font-rubik fw-bold text-prop-grey-medium fz-16 mb-1 ">
                          {taskShow.responsible !== null ? (taskShow.responsible.length > 1 ? "Responsáveis:" : "Responsável:") : ""}
                        </h2>
                      ) : (
                        ""
                      )}
                      {taskShow.responsible !== undefined
                        ? taskShow.responsible.map((item) => {
                            return (
                              <h2 key={item._id} className="form-label font-rubik text-prop-grey-light fz-16 mb-1">{`${item.firstName} ${item.lastName}`}</h2>
                            );
                          })
                        : ""}
                    </div>
                    <div
                      className="col-12 px-2 mb-3"
                      id="scroll"
                      style={{
                        background: "rgb(249, 250, 252)",
                        overflowY: "scroll",
                        height: "75vh",
                        overscrollBehavior: "contain",
                      }}
                    >
                      {taskHistory.map((history) => {
                        return (
                          <div key={history._id} style={{ background: "rgb(255, 234, 201)" }} className="my-2 p-2">
                            <h2 className="mb-2 fz-10">{`${new Date(history.date).toLocaleDateString()} ${history.time}`}</h2>
                            <h2 className="m-0 fz-14">{`${history.user.firstName} ${history.user.lastName} ${
                              history.action === "edit"
                                ? " realizou a seguinte alterações: " + history.comment
                                : history.action === "play"
                                ? "iniciou o trabalho na tarefa"
                                : history.action === "pause"
                                ? "pausou o trabalho na tarefa"
                                : ""
                            } `}</h2>
                          </div>
                        );
                      })}
                      {taskHistory.length === 0 ? <EmptyList icon="indiferente" message="Não foi encontrada nenhum histórico da tarefa" /> : <></>}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    {(taskShow.status !== undefined) & (taskShow.category !== undefined) ? (
                      <>
                        <div className="col-12 pt-3">
                          <h2 className="form-h2 font-rubik fw-bold text-prop-grey-medium fz-16 mb-1 ">Deadline:</h2>
                          <h2 className="form-label font-rubik text-prop-grey-light fz-16 mb-1">
                            {(taskShow.estimatedDate !== undefined) & (taskShow.estimatedDate !== null)
                              ? new Date(taskShow.estimatedDate).toLocaleDateString("pt-br", { timeZone: "UTC" })
                              : "Não informado"}
                          </h2>
                        </div>
                        <div className="col-12 pt-3">
                          <h2 className="form-h2 font-rubik fw-bold text-prop-grey-medium fz-16 mb-1 ">Entrega:</h2>
                          <h2 className="form-label font-rubik text-prop-grey-light fz-16 mb-1">
                            {(taskShow.deliveryDate !== null) & (taskShow.deliveryDate !== undefined)
                              ? new Date(taskShow.deliveryDate).toLocaleDateString()
                              : "Não entregue"}
                          </h2>
                        </div>
                        <div className="col-12 pt-3">
                          <h2 className="form-h2 font-rubik fw-bold text-prop-grey-medium fz-16 mb-1 ">Status:</h2>
                          <h2 className="form-label font-rubik text-prop-grey-light fz-16 mb-1">
                            {taskShow.status === "closed" ? "Entregue" : taskShow.status === "open" ? "Em andamento" : "Pendente"}
                          </h2>
                        </div>
                        <div className="col-12 pt-3">
                          <h2 className="form-h2 font-rubik fw-bold text-prop-grey-medium fz-16 mb-1 ">Tipo:</h2>
                          <h2 className="form-label font-rubik text-prop-grey-light fz-16 mb-1">{taskShow.category}</h2>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-12 d-flex align-items-end justify-content-end mt-2">
                    <button
                      type="button"
                      className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-20"
                      onClick={() => setDrawerOpenShow(false)}
                    >
                      Cancelar
                    </button>
                    {taskShow.status === "open" ? (
                      <button
                        data-bs-dismiss="modal"
                        onClick={() => changeTask(taskShow, "closed")}
                        className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                      >
                        Entregar
                      </button>
                    ) : (
                      <button
                        data-bs-dismiss="modal"
                        onClick={() => changeTask(taskShow, "open")}
                        className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik d-flex justify-content-center"
                      >
                        Abrir Tarefa
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default Sprint;
