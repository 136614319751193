import React, { useEffect, useState } from "react";
import api from "../../../../config/api";
import Select from "react-select";
// import swal from "sweetalert";
import { toastsMsg } from "../../../ToasterMsg";

function ModalProject(props) {
  const [load, setLoad] = useState(false);
  const [clients, setClients] = useState([]);
  const [categories, setCategories] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [responsibles, setResponsibles] = useState([]);
  const [projectModels, setProjectModels] = useState([]);
  const [client, setClient] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState(1);
  const [platform, setPlatform] = useState("");
  const [startDate, setStartDate] = useState("");
  const [deadline, setDeadline] = useState("");
  const [responsible, setResponsible] = useState("");
  const [projectModel, setProjectModel] = useState("");

  useEffect(() => {
    getClients();
    getCategories();
    getPlatforms();
    getResponsibles();
    getProjectModels();
  }, []);

  const getClients = async () => {
    const response = await api.get(`/client?limit=9999&sortBy=name&order=ASC&filterStatus=active`);
    setClients(response.data);
  };

  const getCategories = async () => {
    const response = await api.get(`/category?limit=9999&sortBy=name&order=ASC`);
    const category_arr = [];
    let items = response.data;
    items.forEach((item) => category_arr.push({ value: item.name, label: `${item.name}` }));
    setCategories(category_arr);
  };

  const getPlatforms = async () => {
    const response = await api.get(`/platform?limit=9999&sortBy=name&order=ASC`);
    setPlatforms(response.data);
  };

  const getResponsibles = async () => {
    const response = await api.get(`/user/agency?limit=9999&sortBy=firstName&order=ASC`);
    setResponsibles(response.data);
  };

  const getProjectModels = async () => {
    const response = await api.get(`/project-model?limit=9999&sortBy=name&order=ASC`);
    setProjectModels(response.data);
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    let category_arr = [];
    category.forEach((item) => category_arr.push(item.label));
    await api
      .post(`/project`, {
        client,
        name,
        category: category_arr,
        platform,
        startDate,
        deadline,
        responsible,
        projectModel,
      })
      .then(() => {
        setLoad(false);
        toastsMsg("Sucesso!", "Projeto cadastrado com sucesso!", "success");
        document.getElementById("btnClearProject").click();
        props.close();
      })
      .catch(() => {
        setLoad(false);
        toastsMsg("Erro!", "Erro ao cadastrar o projeto, tente novamente mais tarde.", "error");
      });
  };

  return (
    <>
      <form onSubmit={save}>
        <div className="row">
          <div className="col-12 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Nome do projeto</label>
            <input
              placeholder="Adicione um título"
              type="text"
              className="form-control"
              onChange={(event) => setName(event.target.value)}
              required
            />
          </div>
          <div className="col-12 col-lg-12 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Categoria</label>
            <Select
              placeholder="Selecione a categoria"
              onChange={(item) => setCategory(item)}
              options={categories}
              isMulti
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Cliente</label>
            <select className="form-select" onChange={(event) => setClient(event.target.value)} required>
              <option className="text-prop-grey-light">Selecione o cliente</option>
              {clients.map((item, index) => (
                <option value={item._id} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Plataforma</label>
            <select className="form-select" onChange={(event) => setPlatform(event.target.value)} required>
              <option>Selecione a plataforma</option>
              {platforms.map((item, index) => (
                <option value={item.name} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Início do projeto</label>
            <input
              type="date"
              className="form-control"
              onChange={(event) => setStartDate(event.target.value)}
              required
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
              Estimativa de entrega
            </label>
            <input
              type="date"
              className="form-control"
              onChange={(event) => setDeadline(event.target.value)}
              required
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Responsável</label>
            <select className="form-select" onChange={(event) => setResponsible(event.target.value)} required>
              <option>Selecione o responsável</option>
              {responsibles.map((item, index) => (
                <option value={item._id} key={index}>{`${item.firstName} ${item.lastName}`}</option>
              ))}
            </select>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Modelo de projeto</label>
            <select className="form-select" onChange={(event) => setProjectModel(event.target.value)}>
              <option>Selecione o modelo do projeto</option>
              {projectModels.map((item, index) => (
                <option value={item._id} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 d-flex align-items-center justify-content-end">
            <button type="reset" className="d-none" id="btnClearProject">
              Reset
            </button>
            <button
              type="button"
              className="btn text-danger px-lg-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-25"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
            {load ? (
              <button
                type="button"
                className="btn btn-prop-blue px-lg-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                disabled
              >
                <div className="spinner-border spinner-border-sm text-light" role="status">
                  <span className="visually-hidden">Carregando...</span>
                </div>
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-prop-blue px-lg-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
              >
                Cadastrar
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

export default ModalProject;
