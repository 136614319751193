import React from "react";

function Footer() {

    return (
        <div className='col-12 mt-5'>
            <div className='bg-white w-100' style={{ height: "2px", border: "1px solid #EAEFF4" }}>
            </div>
            <div className='w-100 my-3 d-flex justify-content-center'>
                <span className='text-prop-dark-blue fz-16 font-rubik'>2022 © PROP - Todos os direitos reservados</span>
            </div>
        </div>
    );
}

export default Footer;