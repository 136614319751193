import React from 'react';
import Skeleton from 'react-loading-skeleton';


export default function WidgetLateSprintSkeleton(props) {

    return (
<div className='col-12 col-lg-4'>

        <div className={"card shadow-sm p-3 card-sprints font-rubik "} style={{ borderRadius: "5px", border: " 2px solid #D9E2EC", boxShadow: "0px 4px 8px #CBCBCB17" }}>
            <div className='col-12 mb-2'>
            <Skeleton height={16} width={100}/>
            <Skeleton height={12} width={40}/>
            </div>
            <div className='col-12 mb-3'>
            <Skeleton height={14}/>
            </div>
            <div className="col-12">
            <Skeleton height={18}/>
            </div>
        </div>
</div>
    );
}
