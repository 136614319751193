import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import {
  List,
  ListBody,
  ListBodyItem,
  ListBodyItemAction,
  ListHeaderItemSortAble,
  ListBodyItemImageAndName,
  ListBodyItemStatus,
  ListHeader,
} from "../../../components/List";
import ModalClientAccess from "../../../components/_modals/_agency/ClientAccess";
import api from "../../../config/api";
import AddButton from "../../../components/AddButton";
import EmptyList from "../../../components/_empty/list";
// import io from "socket.io-client";
import swal from "sweetalert";
import Loading from "../../../components/Load";
import { toastsMsg } from "../../../components/ToasterMsg";
require("dotenv").config();

function ClientAccess() {
  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [client, setClient] = useState("");
  let [status, setStatus] = useState("");
  const [id, setId] = useState("");
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [clientFilter, setClientFilter] = useState("");
  const [clientsFilter, setClientsFilter] = useState([]);
  const [sort, setSort] = useState("");

  useEffect(() => {
    getUsers(page);
    getClients();
    getClientsFilter();
  }, [page]);

  const getUsers = async (mypage = 1, filterName = "", filterClient = "", sortBy = "createdAt", order = "desc") => {
    setLoading(true);
    const response = await api.get(
      `/client-access?paginate=true&page=${mypage}&filterName=${filterName}&filterClient=${filterClient}&order=${order}&sortBy=${sortBy}`
    );
    const { docs = [], ...arr } = response.data;
    setUsers(docs);
    setPages(parseInt(arr.pages));
    setLoading(false);
  };

  const getClients = async () => {
    const response = await api.get(`/client?limit=9999&sortBy=name&order=ASC&filterStatus=active`);
    setClients(response.data);
  };

  const getClientsFilter = async () => {
    const response = await api.get(`/client?limit=9999&sortBy=name&order=ASC&filterStatus=active`);
    setClientsFilter(response.data);
  };

  const nextPage = () => {
    getUsers(page + 1);
    setPage(page === pages ? page : page + 1);
  };

  const backPage = () => {
    getUsers(page - 1);
    setPage(page === 1 ? page : page - 1);
  };

  const destroy = async (item) => {
    swal({
      title: "Tem certeza que deseja excluir esse acesso por cliente?",
      text: `O acesso por cliente será apagado, essa ação não tem volta`,
      // icon: "warning",
      buttons: ["Cancelar", "EXCLUIR"],
      dangerMode: true,
    }).then(async (res) => {
      if (res) {
        await api
          .delete(`/client-access/${item._id}`)
          .then(async () => {
            await getUsers(page);
            toastsMsg({
              icon: "success",
              title: "Sucesso!",
              text: "Acesso por Cliente removido com sucesso.",
            });
          })
          .catch(() => {
            toastsMsg({
              icon: "error",
              title: "Erro!",
              text: "Erro ao remover o  acesso por cliente, tente novamente mais tarde.",
            });
          });
      }
    });
  };

  const edit = (item) => {
    setStatus(item.status);
    setType("edit");
    setClient(item.client._id);
    setEmail(item.email);
    setId(item._id);
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    if (password === confirm_password) {
      let object = {
        email,
        password,
      };

      object.id_client = client;

      status === "" ? (object.status = "active") : (object.status = status);

      await api
        .put(`/client-access/${id}`, object)
        .then(() => {
          setLoad(false);
          toastsMsg("Sucesso!", "Acesso por Cliente editado com sucesso!", "success");
          // document.getElementById("btnCloseSingleAccess").click();
          setPassword("");
          setConfirmPassword("");
          getUsers(page);
        })
        .catch((error) => {
          setLoad(false);
          if (error.response.data.error === "User Already Exists") {
            toastsMsg("Erro!", "Esse e-mail já está sendo usado, por favor utilize outro", "error");
          } else {
            toastsMsg("Erro!", "Erro ao editar o acesso do cliente, tente novamente mais tarde.", "error");
            console.log(error.response.data.error);
          }
        });
    } else {
      setLoad(false);
      toastsMsg("Erro!", "Senha e Confirmar Senha não estão iguais, por favor insira informações corretamente.", "error");
    }
  };

  const filterChangeHandlerName = (event) => {
    setNameFilter(event.target.value);
    getUsers(page, event.target.value, clientFilter);
  };

  const filterChangeHandlerClient = (event) => {
    setClientFilter(event.target.value);
    getUsers(page, nameFilter, event.target.value);
  };

  const sortByNameChangeHandler = (sortBy) => {
    if (sort !== sortBy) {
      getUsers(page, nameFilter, clientFilter, sortBy, "asc");
      setSort(sortBy);
    } else {
      getUsers(page, nameFilter, clientFilter);
      setSort("");
    }
  };

  const changePage = (event) => {
    const changePage = event.target.id;
    setPage(parseInt(changePage));
    getUsers(changePage);
  };

  return (
    <>
      <Layout defaultMenu={21} defaultSubMenu={206} title={"Acesso por Clientes"}>
        <div className="container-fluid new-page">
          <div className="row">
            {/* <div className="col-12 mb-4 ">
                            <h1 className="mb-0 fz-24 fw-bold font-rubik">Acesso por Clientes</h1>
                        </div> */}
            <div className="col-12 col-lg-4 mt-2 me-auto">
              <div className="input-group input-search">
                <input
                  type="text"
                  className="form-control border-0 fz-14 box-shadow-none text-prop-grey-light font-rubik"
                  onChange={(event) => filterChangeHandlerName(event)}
                  placeholder="O que você está buscando?"
                />
                <span className="input-group-text bg-white border-0">
                  <i className="bi bi-search text-prop-grey-light"></i>
                </span>
              </div>
            </div>
            {/* <div className="col-12 col-lg-3 mt-2">
                            <select className="filter-select fz-12 text-prop-grey-light font-rubik" onChange={(event) => filterChangeHandlerClient(event)} required>
                                <option value="" key={0}>Selecione o cliente</option>
                                {clientsFilter.map((item, index) => (
                                    <option value={item._id} key={index}>{item.name}</option>
                                ))}
                            </select>
                        </div> */}
            <AddButton sizeLg={6} onClick={() => setType("add")} modalId={"#myModalSingle"} text={"Adicionar Acesso"} />
            <div className="col-12 mb-4 table-border-default">
              <List paginate={true} page={page} pages={pages} backPage={() => backPage()} nextPage={() => nextPage()} changePage={changePage}>
                <ListHeader>
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("firstName")} name="Cliente" sizeLg={5} />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("status")} name="Status" sizeLg={3} />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("createdAt")} name="Adicionado em" sizeLg={4} />
                </ListHeader>
                {users.map((item, index) => (
                  <ListBody key={index} specialClass="d-flex flex-wrap">
                    <ListBodyItemImageAndName value={`${item.firstName} ${item.lastName}`} sizeLg={5} img={item.avatar} />
                    <ListBodyItemStatus title="Status" value={item.status === "active" ? "Ativo" : "Inativo"} sizeLg={3} />
                    <ListBodyItem title="Adicionado em" value={new Date(item.createdAt).toLocaleDateString()} sizeLg={3} />
                    <ListBodyItemAction sizeLg={1}>
                      <li>
                        <span
                          className="dropdown-item fz-14 font-rubik c-pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#myModalSingle"
                          onClick={() => edit(item)}
                        >
                          Editar
                        </span>
                      </li>
                      <li>
                        <span className="dropdown-item fz-14 font-rubik text-prop-danger c-pointer" onClick={() => destroy(item)}>
                          Excluir
                        </span>
                      </li>
                    </ListBodyItemAction>
                  </ListBody>
                ))}
                {users.length === 0 ? <EmptyList icon="indiferente" message="Não foi encontrado nenhum acesso" /> : <></>}
              </List>
            </div>
          </div>
        </div>
      </Layout>
      <div
        className="modal fade border-0"
        id="myModalSingle"
        tabIndex="-1"
        aria-labelledby="myModalSingleLabel"
        aria-hidden="true"
        style={{ borderRadius: "10px" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {type === "add" ? (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalLabel">
                    Adicionar acesso por cliente
                  </h5>
                  <button type="button" id="btnClose" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <ModalClientAccess close={() => document.getElementById("btnClose").click()} />
                </div>
              </>
            ) : (
              <>
                <div className="modal-header border-0">
                  <h5 className="modal-title fz-25 fw-bold font-rubik text-prop-grey-dark" id="myModalSingleLabel">
                    Editar acesso do cliente
                  </h5>
                  <button type="button" id="btnCloseSingleAccess" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={save}>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Cliente</label>
                        <select className="form-select" value={client} onChange={(event) => setClient(event.target.value)} required>
                          <option className="text-prop-grey-light">Selecione o cliente</option>
                          {clients.map((item, index) => (
                            <option value={item._id} key={index}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Status</label>
                        <select className="form-select" value={status} onChange={(event) => setStatus(event.target.value)} required>
                          <option value="active" defaultValue>
                            Ativo
                          </option>
                          <option value="inactive">Inativo</option>
                        </select>
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">E-mail</label>
                        <input type="email" className="form-control" value={email} onChange={(event) => setEmail(event.target.value)} required />
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Senha</label>
                        <input type="password" className="form-control" value={password} onChange={(event) => setPassword(event.target.value)} />
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Confirmar Senha</label>
                        <input type="password" className="form-control" value={confirm_password} onChange={(event) => setConfirmPassword(event.target.value)} />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-12 d-flex align-items-center justify-content-end">
                        <button
                          type="button"
                          className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-28"
                          data-bs-dismiss="modal"
                        >
                          Cancelar
                        </button>
                        {load ? (
                          <button
                            type="button"
                            className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                            disabled
                          >
                            <div className="spinner-border spinner-border-sm text-light" role="status">
                              <span className="visually-hidden">Carregando...</span>
                            </div>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                          >
                            Salvar
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Loading show={loading} />
    </>
  );
}

export default ClientAccess;
