import React, { useEffect, useState } from "react";
import api from "../../../../config/api";
// import swal from "sweetalert";
import { toastsMsg } from "../../../ToasterMsg";

function ModalCategoryAccess(props) {
  const [category, setCategory] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let [status, setStatus] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [load, setLoad] = useState(false);
  const [categories, setCategories] = useState([]);
  const [seeReport, setSeeReport] = useState(false);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    const response = await api.get(`/category?limit=9999&sortBy=name&order=ASC`);
    setCategories(response.data);
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    if (password === confirm_password) {
      let object = {
        email,
        password,
        seeReport,
      };
      object.id_category = category;

      status === "" ? (object.status = "active") : (object.status = status);
      await api
        .post(`/category-access`, object)
        .then(() => {
          setLoad(false);
          toastsMsg("Sucesso!", "Acesso por categoria cadastrado com sucesso!", "success");
          document.getElementById("btnClearCategoryAccess").click();
          props.close();
        })
        .catch((error) => {
          setLoad(false);
          if (error.response.data.error === "Access Already Exists") {
            toastsMsg("Erro!", "Esse e-mail já está sendo usado, por favor utilize outro", "error");
          } else {
            toastsMsg("Erro!", "Erro ao cadastrar o acesso por categoria, tente novamente mais tarde.", "error");
          }
        });
    } else {
      setLoad(false);
      toastsMsg(
        "Erro!",
        "Senha e Confirmar Senha não estão iguais, por favor insira informações corretamente.",
        "error"
      );
    }
  };

  return (
    <>
      <form onSubmit={save}>
        <div className="row">
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Categoria</label>
            <select className="form-select" onChange={(event) => setCategory(event.target.value)} required>
              <option className="text-prop-grey-light">Selecione a categoria</option>
              {categories.map((item, index) => (
                <option value={item._id} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Status</label>
            <select className="form-select" onChange={(event) => setStatus(event.target.value)} required>
              <option value="active" defaultValue>
                Ativo
              </option>
              <option value="inactive">Inativo</option>
            </select>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">E-mail</label>
            <input type="email" className="form-control" onChange={(event) => setEmail(event.target.value)} required />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Senha</label>
            <input
              type="password"
              className="form-control"
              onChange={(event) => setPassword(event.target.value)}
              required
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Confirmar Senha</label>
            <input
              type="password"
              className="form-control"
              onChange={(event) => setConfirmPassword(event.target.value)}
              required
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Visualizar reports</label>
            <select className="form-select" value={seeReport} onChange={(event) => setSeeReport(event.target.value)}>
              <option value={true}>Sim</option>
              <option value={false}>Não</option>
            </select>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 d-flex align-items-center justify-content-end">
            <button type="reset" className="d-none" id="btnClearCategoryAccess">
              Reset
            </button>
            <button
              type="button"
              className=" btn text-danger px-lg-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-25"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
            {load ? (
              <button
                type="button"
                className=" btn btn-prop-blue px-lg-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
                disabled
              >
                <div className="spinner-border spinner-border-sm text-light" role="status">
                  <span className="visually-hidden">Carregando...</span>
                </div>
              </button>
            ) : (
              <button
                type="submit"
                className=" btn btn-prop-blue px-lg-5 py-2 text-white fz-13 text-uppercase font-rubik w-25 d-flex justify-content-center"
              >
                Cadastrar
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

export default ModalCategoryAccess;
