import React, { useState } from 'react';
import Logo from "../../../assets/img/logo.svg";
import PasswordResetImg from "../../../assets/img/password-reset.svg";
import api from "../../../config/api";

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [load, setLoad] = useState(false);
    const [message, setMessage] = useState("");

    const renewPassword = async (event) => {
        event.preventDefault();
        setLoad(false);
        setMessage("");
        await api.post(`/forgot-password`, { email })
            .then((response) => {
                setLoad(false);
                window.location.href = "/";
            })
            .catch((error) => {
                setMessage("Email inválido!");
                if (error.response !== undefined) {
                    const { error: message } = error.response.data;
                    if (message === "User Not Found") {
                        setMessage("Usuário não encontrado!");
                    }
                }
                setLoad(false);
            })
    }

    return (
        <div className="bg-white h-100 px-3 px-lg-0">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-12 col-lg-4 mb-auto">
                        <div className="row">
                            <div className="col-12 py-5 text-center text-lg-start">
                                <img src={Logo} alt="Project Progress" className="img-fluid" />
                            </div>
                            <div className="col-12 mb-5">
                                <h1 className="mb-0 font-rubik fz-30" style={{ fontWeight: 500, color: "#3F4254" }}>Esqueci minha senha</h1>
                            </div>
                            <div className="col-12 mb-5">
                                <p className="mb-0 font-rubik fz-18" style={{ color: "#3F4254" }}>Digite seu e-mail e enviaremos um link para que você possa redefinir</p>
                            </div>
                            <div className="col-12">
                                <form onSubmit={renewPassword}>
                                    <div className="mb-3">
                                        <label className="form-label text-uppercase font-rubik fz-16 mb-1 text-prop-grey-light">E-mail</label>
                                        <input type="email" className="form-control font-rubik rounded" style={{ borderColor: "#F2F2F2" }} onChange={(event) => setEmail(event.target.value)} required />
                                    </div>
                                    {(!load) ?
                                        <button type="submit" className="btn btn-prop-blue d-flex w-100 text-white justify-content-center text-uppercase font-rubik fw-bold py-3 fz-16 mb-2">Enviar</button>
                                        :
                                        <button type="submit" className="btn btn-prop-blue d-flex w-100 text-white justify-content-center text-uppercase font-rubik fw-bold py-3 fz-16 mb-2" disabled>
                                            <div className="spinner-border spinner-border-sm text-light" role="status">
                                                <span className="visually-hidden">Carregando...</span>
                                            </div>
                                        </button>
                                    }
                                    <button type="submit" className="btn btn-outline-prop-blue d-flex w-100 justify-content-center text-uppercase font-rubik fw-bold py-3 fz-16 bg-white text-prop-blue">Fazer Login</button>
                                    <div className="my-3 d-flex justify-content-center">
                                        <span className="font-rubik text-prop-danger fz-12">{message}</span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-7 d-none d-lg-flex">
                        <img src={PasswordResetImg} alt="Login" className="img-fluid" />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ForgotPassword;