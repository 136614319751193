import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { isAuthenticated, isCompany, isAgency, isClient, isShared, isCategory } from "./config/auth";

// Auth
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import RenewPassword from "./pages/auth/RenewPassword";

// Dashboard
import Dashboard from "./pages/dashboard/Dashboard";
import AllAgencies from "./pages/dashboard/AllAgencies";

// Profile
import Profile from './pages/Profile';

// Others
import NotFound from "./pages/others/NotFound";
import DeliveryDateAuthorized from "./pages/others/DeliveryDateAuthorized";

// Company
import Agency from "./pages/_company/Agency";
import UserAgency from "./pages/_company/UserAgency";
import UserCompany from "./pages/_company/UserCompany";
import ReunionCompany from "./pages/_company/Reunion";
import ReportCompany from "./pages/_company/Report";

// Agency
import Client from "./pages/_agency/Client";
import ClientAccess from "./pages/_agency/ClientAccess";
import AgencyUserAgency from "./pages/_agency/UserAgency";
import Category from "./pages/_agency/Category";
import CategoryAccess from "./pages/_agency/CategoryAccess";
import Platform from "./pages/_agency/Platform";
import Departament from "./pages/_agency/Departament";
import TypeTask from "./pages/_agency/TypeTask";
import Reunion from "./pages/_agency/Reunion";
import Report from "./pages/_agency/Report";

// Project
import Project from "./pages/_project/Project";
import ProjectClose from "./pages/_project/ProjectClose";
import ProjectPaused from "./pages/_project/ProjectPaused";
import ProjectOverview from "./pages/_project/Overview";
import ProjectReport from "./pages/_project/Report";
import ProjectReunion from "./pages/_project/Reunion";
import ProjectLate from "./pages/_project/ProjectLate";
import ProjectOutOfDate from "./pages/_project/ProjectNotUpdated";
import ProjectAttention from "./pages/_project/ProjectAttention";
import PossibleLateSprints from './pages/_project/PossibleSprintsLate';


import Stakeholder from "./pages/_project/Stakeholder";
import Task from "./pages/_project/Task";
import Sprint from "./pages/_project/Sprint";
import ProjectHistory from "./pages/_project/History";
import Settings from "./pages/_project/Settings";
import ProjectModel from "./pages/_projectModel/ProjectModel";
import CompanyProjectIndex from "./pages/_company/Project";
import CompanyProjectClose from "./pages/_company/ProjectClose";
import CompanyProjectPaused from "./pages/_company/ProjectPaused";

// Client
import ClientProjectIndex from "./pages/_client/Project";
import ClientReunion from "./pages/_client/Reunion";

// Category
import CategoryProjectIndex from "./pages/_category/Project";
import CategoryReunion from "./pages/_category/Reunion";
import CategoryReport from "./pages/_category/Report";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/", state: { from: props.location } }} />
            )
        }
    />
);

const CompanyRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isCompany() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/", state: { from: props.location } }} />
            )
        }
    />
);

const AgencyRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAgency() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/", state: { from: props.location } }} />
            )
        }
    />
);

const ClientRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isClient() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/", state: { from: props.location } }} />
            )
        }
    />
);

const CategoryRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isCategory() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/", state: { from: props.location } }} />
            )
        }
    />
);

const SharedRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isShared() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/", state: { from: props.location } }} />
            )
        }
    />
);

const Routes = () => (
    <BrowserRouter>
        <Switch>
            {/* Auth */}
            <Route exact path="/" component={Login} />
            <Route exact path="/esqueci-minha-senha" component={ForgotPassword} />
            <Route exact path="/nova-senha/:token" component={RenewPassword} />

            {/* System */}
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/sprints-podem-atrasar" component={PossibleLateSprints} />
            <PrivateRoute path="/perfil" component={Profile} />
            <PrivateRoute path="/projeto/:id/overview" component={ProjectOverview} />
            <PrivateRoute path="/projetos-atrasados" component={ProjectLate} />
            <PrivateRoute path="/projetos-na-uti" component={ProjectAttention} />
            <PrivateRoute path="/projetos-desatualizados" component={ProjectOutOfDate} />
            <PrivateRoute path="/:id/authorized" component={DeliveryDateAuthorized} />
            <PrivateRoute exact path="/departamentos" component={Departament} />

            {/* Company */}
            <CompanyRoute path="/todas-empresas" component={AllAgencies} />
            <CompanyRoute exact path="/parceiros-empresas" component={Agency} />
            <CompanyRoute exact path="/parceiros-colaboradores" component={UserAgency} />
            <CompanyRoute exact path="/empresa-colaboradores" component={UserCompany} />
            <CompanyRoute path="/empresa-projetos-em-andamento" component={CompanyProjectIndex} />
            <CompanyRoute path="/empresa-projetos-concluidos" component={CompanyProjectClose} />
            <CompanyRoute path="/empresa-projetos-pausados" component={CompanyProjectPaused} />
            <CompanyRoute path="/empresa-reunioes" component={ReunionCompany} />
            <CompanyRoute path="/empresa-reports" component={ReportCompany} />

            {/* Agency */}
            
            <AgencyRoute exact path="/clientes" component={Client} />
            <AgencyRoute exact path="/acessos-clientes" component={ClientAccess} />
            <AgencyRoute exact path="/colaboradores" component={AgencyUserAgency} />
            <AgencyRoute exact path="/categorias" component={Category} />
            <AgencyRoute exact path="/acessos-categoria" component={CategoryAccess} />
            <AgencyRoute exact path="/plataformas" component={Platform} />
            {/* <AgencyRoute exact path="/departamentos" component={Departament} /> */}
            <AgencyRoute exact path="/tipos-tarefas" component={TypeTask} />
            <AgencyRoute exact path="/report" component={Report} />
            <AgencyRoute exact path="/reunioes" component={Reunion} />
            <AgencyRoute exact path="/modelos-de-projeto" component={ProjectModel} />
            <AgencyRoute path="/projetos-em-andamento" component={Project} />
            <AgencyRoute path="/projetos-concluidos" component={ProjectClose} />
            <AgencyRoute path="/projetos-pausados" component={ProjectPaused} />
            <AgencyRoute path="/projeto/:id/reports" component={ProjectReport} />
            <AgencyRoute path="/projeto/:id/reunioes" component={ProjectReunion} />
            <AgencyRoute path="/projeto/:id/stakeholders" component={Stakeholder} />
            <AgencyRoute path="/projeto/:id/tarefas" component={Task} />
            <AgencyRoute path="/projeto/:id/sprints" component={Sprint} />
            <AgencyRoute path="/projeto/:id/historico-de-alteracoes" component={ProjectHistory} />
            <AgencyRoute path="/projeto/:id/configuracoes-do-projeto" component={Settings} />

            {/* Client */}
            <ClientRoute path="/meus-projetos" component={ClientProjectIndex} />
            <ClientRoute path="/minhas-reunioes" component={ClientReunion} />

            {/* Category */}
            <CategoryRoute path="/projetos-da-categoria" component={CategoryProjectIndex} />
            <CategoryRoute path="/reunioes-da-categoria" component={CategoryReunion} />
            <CategoryRoute path="/reports-da-categoria" component={CategoryReport} />
            <CategoryRoute path="/projeto-da-categoria/:id/reports" component={ProjectReport} />
            <CategoryRoute path="/projeto-da-categoria/:id/reunioes" component={ProjectReunion} />
            <CategoryRoute path="/projeto-da-categoria/:id/stakeholders" component={Stakeholder} />
            <CategoryRoute path="/projeto-da-categoria/:id/tarefas" component={Task} />
            <CategoryRoute path="/projeto-da-categoria/:id/sprints" component={Sprint} />
            <CategoryRoute path="/projeto-da-categoria/:id/historico-de-alteracoes" component={ProjectHistory} />

            <PrivateRoute path="/private" component={() => <h1>Private</h1>} />
            <CompanyRoute path="/company" component={() => <h1>Company</h1>} />
            <AgencyRoute path="/agency" component={() => <h1>Agency</h1>} />
            <ClientRoute path="/client" component={() => <h1>Client</h1>} />
            <SharedRoute path="/client" component={() => <h1>Shared</h1>} />
            
            <Route path="*" component={NotFound} />
        </Switch>
    </BrowserRouter>
);

export default Routes;