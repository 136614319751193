import React, { useEffect, useState } from "react";
import api from "../../../../config/api";
// import swal from "sweetalert";
import Select from "react-select";
import { toastsMsg } from "../../../ToasterMsg";

function ModalProjectModel(props) {
  const [tasksModel, setTasksModel] = useState([]);
  let [tasksModelSelected, setTasksModelSelected] = useState([]);
  const [load, setLoad] = useState(false);
  const [name, setName] = useState("");
  const [saveType, setSaveType] = useState("");

  useEffect(() => {
    getTasksModel();
  }, []);

  const getTasksModel = async () => {
    const response = await api.get(`/task-model?limit=9999&sortBy=name&order=ASC`);
    let taskModel_arr = [];
    let items = response.data;
    items.forEach((item) => taskModel_arr.push({ value: item._id, label: `${item.title}` }));
    setTasksModel(taskModel_arr);
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);

    let arrTasks = [];

    if (tasksModelSelected === null) {
      setTasksModelSelected([]);
    }

    for (let count = 0; count < tasksModelSelected.length; count++) {
      arrTasks.push(tasksModelSelected[count].value);
    }

    let newProjectModel = {
      name: name,
      tasks: arrTasks,
    };

    await api
      .post(`/project-model`, newProjectModel)
      .then((response) => {
        setLoad(false);
        toastsMsg("Sucesso!", "Modelo de projeto cadastrado com sucesso!", "success");
        document.getElementById("btnClearProjectModel").click();
        setTasksModelSelected(null);
        if (saveType === "save") {
          props.close();
          localStorage.setItem("projectModelId", response.data._id);
        }
      })
      .catch(() => {
        setLoad(false);
        toastsMsg("Erro!", "Erro ao cadastrar o modelo de projeto, tente novamente mais tarde.", "error");
      });
  };

  return (
    <>
      <form onSubmit={save}>
        <div className="row">
          <div className="col-12 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Nome</label>
            <input type="text" className="form-control" onChange={(event) => setName(event.target.value)} required />
          </div>
          <div className="col-12 col-lg-12 mb-3">
            <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Tarefas</label>
            <Select
              name="tasks"
              placeholder=""
              value={tasksModelSelected}
              onChange={(item) => setTasksModelSelected(item)}
              options={tasksModel}
              isMulti
            />
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 d-flex align-items-center justify-content-end">
            <button type="reset" className="d-none" id="btnClearProjectModel">
              Reset
            </button>
            <button
              type="button"
              className="btn text-danger px-lg-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik w-25"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
            {load ? (
              <button
                type="button"
                className="btn btn-prop-blue px-lg-5 py-2 text-white fz-13 text-uppercase font-rubik w-25"
                disabled
              >
                <div className="spinner-border spinner-border-sm text-light" role="status">
                  <span className="visually-hidden">Carregando...</span>
                </div>
              </button>
            ) : (
              <>
                <button
                  type="submit"
                  data-bs-toggle="modal"
                  data-bs-target={"#" + props.openTask}
                  onClick={() => setSaveType("save")}
                  className="px-lg-4 py-2 save-close-button fz-13 text-uppercase font-rubik w-30 mx-2"
                >
                  + SALVAR E CADASTRAR TAREFA
                </button>
                <button
                  type="submit"
                  onClick={() => setSaveType("saveMore")}
                  style={{ borderRadius: "10px" }}
                  className="btn btn-prop-blue px-lg-2 py-2 text-white fz-13 text-uppercase font-rubik w-28"
                >
                  SALVAR E CRIAR OUTRA
                </button>
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

export default ModalProjectModel;
