import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import {
  List,
  ListBody,
  ListBodyItem,
  ListHeader,
  ListBodyItemProgressBar,
  ListHeaderItemSortAble,
} from "../../../components/List";
import api from "../../../config/api";
// import io from "socket.io-client";
import Loading from "../../../components/Load";
import { formatDate } from "../../../config/utils";
import { extendMoment } from "moment-range";
import EmptyList from "../../../components/_empty/list";
import Moment from "moment-timezone";
// import { Projection } from "devextreme-react/vector-map";
import Footer from "../../../components/Footer";

const moment = extendMoment(Moment);
require("dotenv").config();

function CompanyProjectIndex() {
  const [projects, setProjects] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [categories, setCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [sort, setSort] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalProjects, setTotalProjects] = useState(0);
  const [limitItensTable, setLimitItensTable] = useState(20);
  const [pageItensTable, setPageItensTable] = useState(1);

  useEffect(() => {
    getProjects();
    getCategories();
    getTotalProjects();
    // async function registerToSocket() {
    //     const socket = io(`${process.env.REACT_APP_API_URL}`);
    //     socket.on("project", () => {
    //         getProjects(page);
    //     });
    // }
    // registerToSocket();
  }, [nameFilter]);

  const getProjects = async (
    mypage = page,
    filterName = "",
    filterCategory = "",
    sortBy = "deadline",
    order = "desc",
    limit = limitItensTable
  ) => {
    setLoading(true);
    const response = await api.get(
      `/project/open?paginate=true&limit=${limit}&page=${mypage}&filterName=${filterName}&filterCategory=${filterCategory}&order=${order}&sortBy=${sortBy}`
    );
    const { docs = [], ...arr } = response.data;
    setProjects(docs);
    setPages(parseInt(arr.pages));
    setLoading(false);
  };

  const changePage = (event) => {
    const changePage = event.target.id;
    setPage(changePage);
    setPageItensTable(changePage);
    getProjects(changePage);
  };

  const nextPage = () => {
    getProjects(page + 1);
    setPage(page === pages ? page : page + 1);
  };

  const backPage = () => {
    getProjects(page - 1);
    setPage(page === 1 ? page : page - 1);
  };

  const setLimitTable = (value) => {
    setLimitItensTable(value.target.value);
    getProjects(page, "", "", "deadline", "desc", value.target.value);
  };

  const getTotalProjects = async (filterName = "", filterCategory = "") => {
    const response = await api.get(
      `/project/open?limit=9999&filterName=${filterName}&filterCategory=${filterCategory}`
    );
    setTotalProjects(response.data.length);
  };

  const getCategories = async () => {
    const response = await api.get(`/category?limit=9999&sortBy=name&order=ASC`);
    setCategories(response.data);
  };

  const filterChangeHandlerName = (event) => {
    setNameFilter(event.target.value);
    getProjects(page, event.target.value, categoryFilter);
    getTotalProjects(event.target.value, categoryFilter);
  };

  const filterChangeHandlerCategory = (event) => {
    setCategoryFilter(event.target.value);
    getProjects(page, nameFilter, event.target.value);
    getTotalProjects(nameFilter, event.target.value);
  };

  const sortByNameChangeHandler = (sortBy, order) => {
    if (sort !== sortBy) {
      getProjects(page, nameFilter, categoryFilter, sortBy, order);
      setSort(sortBy);
    } else {
      getProjects(page, nameFilter, categoryFilter, sortBy, "desc");
      setSort("");
    }
  };

  const colorProgressBar = (prefix, item) => {
    let today = moment(new Date());
    let diference = moment.duration(today.diff(item.deadline));

    if (diference.asDays() <= 5 && diference.asDays() >= -4) {
      return prefix + "-prop-warning-dark";
    } else if (moment(new Date()).format("YYYY-MM-DD") > item.deadline) {
      return prefix + "-prop-danger";
    } else {
      return prefix + "-prop-success";
    }
  };

  return (
    <>
      <Layout
        defaultMenu={0}
        defaultSubMenu={211}
        title={"Projetos em andamento"}
        subTitle={`Existem ${totalProjects} projetos em andamento`}
      >
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-12 mb-1">
                            <h1 className="mb-0 fz-24 fw-bold font-rubik ">Projetos em andamento</h1>
                        </div>
                        <div className="col-12 mb-4">
                            <h1 className="mb-0 fz-14 text-prop-grey-light font-rubik ">{`Existem ${totalProjects} projetos em andamento`}</h1>
                        </div> */}
            <div className="col-12 col-lg-4 mt-2 mb-4">
              <div className="input-group input-search">
                <input
                  type="text"
                  className="form-control border-0 fz-14 box-shadow-none text-prop-grey-light font-rubik"
                  onChange={(event) => filterChangeHandlerName(event)}
                  placeholder="O que você está buscando?"
                />
                <span className="input-group-text bg-white border-0">
                  <i className="bi bi-search text-prop-grey-light"></i>
                </span>
              </div>
            </div>
            {/* <div className="col-12 col-lg-3 mt-2 mb-4">
                            <select className="filter-select fz-12 text-prop-grey-light font-rubik" onChange={(event) => filterChangeHandlerCategory(event)} required>
                                <option value="" key={0}>Selecione a categoria</option>
                                {categories
                                    .map((item, index) => (
                                        <option value={item.name} key={index + 1}>{item.name}</option>
                                    ))}
                            </select>
                        </div> */}
            <List
              title="projetos"
              paginate={true}
              page={parseInt(page)}
              pages={pages}
              backPage={() => backPage()}
              nextPage={() => nextPage()}
              totalItem={projects.length}
              changePage={(event) => {
                changePage(event);
              }}
              actualPage={pageItensTable}
              onChangeLimit={(event) => setLimitTable(event)}
            >
              <div className="col-12 table-border-default">
                <ListHeader>
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("name", "asc")}
                    name="Nome"
                    sizeLg={2}
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("client.name", "asc")}
                    name="Cliente"
                    sizeLg={2}
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("agency.name", "asc")}
                    name="Empresa"
                    sizeLg={2}
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("category", "asc")}
                    name="Categoria"
                    sizeLg={2}
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("totalProgress", "asc")}
                    name="Performance"
                    sizeLg={2}
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("deadline", "desc")}
                    name="Deadline"
                    sizeLg={2}
                  />
                </ListHeader>

                {projects.map((item, index) => (
                  <ListBody specialClass="card-with-hover d-flex flex-wrap" key={index}>
                    <ListBodyItem title="Nome" value={item.name} sizeLg={2} link={`/projeto/${item._id}/overview`} />
                    <ListBodyItem
                      title="Cliente"
                      value={item.client.name}
                      sizeLg={2}
                      link={`/projeto/${item._id}/overview`}
                    />
                    <ListBodyItem
                      title="Empresa"
                      value={item.agency.name}
                      sizeLg={2}
                      link={`/projeto/${item._id}/overview`}
                    />
                    <ListBodyItem
                      title="Categoria"
                      value={`${item.category}`}
                      sizeLg={2}
                      link={`/projeto/${item._id}/overview`}
                    />
                    <ListBodyItemProgressBar
                      title="Performance"
                      sizeLg={2}
                      link={`/projeto/${item._id}/overview`}
                      projectProgress={parseInt(item.totalProgress)}
                      bgAlertClass={colorProgressBar("bg", item)}
                      textAlertClass={colorProgressBar("text", item)}
                    />
                    <ListBodyItem
                      title="Deadline"
                      value={formatDate(item.deadline)}
                      sizeLg={2}
                      link={`/projeto/${item._id}/overview`}
                    />
                  </ListBody>
                ))}
                {projects.length === 0 ? (
                  <EmptyList icon="indiferente" message="Não foi encontrado nenhum projeto" />
                ) : (
                  <></>
                )}
              </div>
            </List>
          </div>
          <Footer></Footer>
        </div>
      </Layout>
      <Loading show={loading} />
    </>
  );
}

export default CompanyProjectIndex;
