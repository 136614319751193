export const formatDate = (date) =>
  new Date(
    new Date(date).setDate(new Date(date).getDate() + 1)
  ).toLocaleDateString();

export const formatDateNumber = (date) =>
  new Date(new Date(date).setDate(new Date(date).getDate() + 1));

export const convertTime = (value) => {
  const sec = parseInt(value, 10); // convert value to number if it's string
  let hours = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
};

export const daysDiferance = (dateStart, dateEnd) => {
  const diff = Math.abs(new Date(dateStart).getTime() - dateEnd.getTime());
  const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
  return days;
};
