import React from "react";
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

function BurndownChart(props) {

  return (

    <HighchartsReact
      highcharts={Highcharts}
      options={
        {
          title: {
            text: 'Sprint atual: ' + props.title,
            x: -20 //center
          },
          colors: ['blue', 'red'],
          xAxis: {
            categories: props.category
          },
          yAxis: {
            title: {
              text: 'Tarefas'
            },
            plotLines: [{
              value: 0,
              width: 1
            }]
          },
          tooltip: {
            valueDecimals: 2,
            crosshairs: true,
            shared: true
          },
          credits: {
            enabled: false
          },
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            borderWidth: 0
          },
          series: [{
            name: 'Andamento ideal',
            color: '#459ed8',
            lineWidth: 2,
            data: props.ideal
          }, {
            name: 'Andamento atual',
            color: '#f5564a',
            marker: {
              radius: 6
            },
            data: props.actual
          }]
        }
      }
    />
  );
}

export default BurndownChart;