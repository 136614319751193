import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import { List, ListBody, ListBodyItem, ListHeader, ListHeaderItemSortAble } from "../../../components/List";
import api from "../../../config/api";
// import io from "socket.io-client";
// import WidgetClient from "../../../components/_widgets/WidgetClient";
import SidebarProject from "../../../components/SidebarProject";
import SidebarProjectCategory from "../../../components/SidebarProjectCategory";
import Loading from "../../../components/Load";
import { decodeToken } from "../../../config/auth";
import EmptyList from "../../../components/_empty/list";
require("dotenv").config();

function History(props) {
  const [history_arr, setHistory] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [project, setProject] = useState("");
  const [client, setClient] = useState("");
  const [sort, setSort] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  let [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { user = {} } = decodeToken();
    setUser(user);
    getHistory(props.match.params.id);
    getProject();
    // async function registerToSocket() {
    //     const socket = io(`${process.env.REACT_APP_API_URL}`);
    //     socket.on("history", () => {
    //         getHistory();
    //     });
    // }
    // registerToSocket();
  }, [props, user.role]);

  const getHistory = async (
    mypage = 1,
    myprojectId = props.match.params.id,
    filterName = "",
    filterStart = "",
    filterEnd = "",
    sortBy = "createdAt",
    order = "desc"
  ) => {
    setLoading(true);
    const response = await api.get(
      `/history/${myprojectId}?paginate=true&page=${mypage}&filterName=${filterName}&filterStart=${filterStart}&filterEnd=${filterEnd}&order=${order}&sortBy=${sortBy}`
    );
    const { docs = [], ...arr } = response.data;
    setHistory(docs);
    setPages(parseInt(arr.pages));
    setLoading(false);
  };

  const getProject = async () => {
    const response = await api.get(`/dashboard-project/${props.match.params.id}`);
    setProject(response.data);
    setClient(response.data.client);
  };

  const nextPage = () => {
    getHistory(page + 1, props.match.params.id);
    setPage(page === pages ? page : page + 1);
  };

  const backPage = () => {
    getHistory(page - 1, props.match.params.id);
    setPage(page === 1 ? page : page - 1);
  };

  const filterChangeHandlerName = (event) => {
    setNameFilter(event.target.value);
    getHistory(page, props.match.params.id, event.target.value);
  };

  const sortByNameChangeHandler = (sortBy) => {
    if (sort !== sortBy) {
      getHistory(page, props.match.params.id, nameFilter, "", "", sortBy, "asc");
      setSort(sortBy);
    } else {
      getHistory(page, props.match.params.id, nameFilter, "", "");
      setSort("");
    }
  };

  return (
    <>
      <Layout
        defaultMenu={0}
        isSingleProject={true}
        clientImg={client.logo}
        clientName={client.name}
        projectName={project.name}
        projectCategory={project.category !== undefined ? project.category[0] : ""}
      >
        <div className="container-fluid">
          {/* <WidgetClient
                        link={(user.role === 'company') ? "/empresa-projetos-em-andamento" : (user.role === 'agency') ? "/projetos-em-andamento" : (user.role === 'category') ? "/projetos-da-categoria" : "/meus-projetos"}
                        img={client.logo}
                        clientName={client.name}
                        projectName={project.name}
                    /> */}
          {user.role === "agency" ? <SidebarProject active={"history"} project={props.match.params.id} /> : <></>}
          {user.role === "category" ? (
            <div className="col-12">
              <SidebarProjectCategory seeReport={user.seeReport} active={"history"} project={props.match.params.id} />
            </div>
          ) : (
            <></>
          )}

          <div className="row">
            {/* <div className="col-12 mb-4">
                            <h1 className="mb-0 fz-24 fw-bold font-rubik">Log</h1>
                        </div> */}
            <div className="col-12 col-lg-4 mb-4 mt-2">
              <div className="input-group input-search">
                <input
                  type="text"
                  className="form-control border-0 fz-14 box-shadow-none text-prop-grey-light font-rubik"
                  onChange={(event) => filterChangeHandlerName(event)}
                  placeholder="O que você está buscando?"
                />
                <span className="input-group-text bg-white border-0">
                  <i className="bi bi-search text-prop-grey-light"></i>
                </span>
              </div>
            </div>
            <List
              title={"logs"}
              paginate={true}
              page={page}
              pages={pages}
              backPage={() => backPage()}
              nextPage={() => nextPage()}
            >
              <div className="col-12 mb-4 table-border-default">
                <ListHeader>
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("responsible.firstName")}
                    name="Responsável"
                    sizeLg={3}
                  />
                  <ListHeaderItemSortAble onClick={() => sortByNameChangeHandler("createdAt")} name="Data" sizeLg={2} />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("description")}
                    name="Descrição"
                    sizeLg={3}
                  />
                  <ListHeaderItemSortAble
                    onClick={() => sortByNameChangeHandler("comments")}
                    name="Observações"
                    sizeLg={3}
                  />
                </ListHeader>
                {history_arr.map((item, index) => (
                  <ListBody key={index} specialClass="d-flex flex-wrap">
                    <ListBodyItem
                      title="Nome"
                      value={
                        item.responsible !== null
                          ? `${item.responsible.firstName} ${item.responsible.lastName}`
                          : "Não Alocado"
                      }
                      sizeLg={3}
                    />
                    <ListBodyItem title="Data" value={new Date(item.createdAt).toLocaleDateString()} sizeLg={2} />
                    <ListBodyItem title="Descrição" value={item.description} sizeLg={3} />
                    <ListBodyItem
                      title="Observações"
                      value={item.comments !== "" ? item.comments : "Nenhuma"}
                      sizeLg={4}
                    />
                  </ListBody>
                ))}
                {history_arr.length === 0 ? (
                  <EmptyList icon="piscando" message="Não foi encontrada nenhuma alteração neste projeto" />
                ) : (
                  <></>
                )}
              </div>
            </List>
          </div>
        </div>
      </Layout>
      <Loading show={loading} />
    </>
  );
}

export default History;
