import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import api from "../../config/api";
import Loading from "../../components/Load";
// import io from "socket.io-client";
// import swal from "sweetalert";
import { decodeToken } from "../../config/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import WidgetProfile from "../../components/_widgets/WidgetProfile";
import { toastsMsg } from "../../components/ToasterMsg";
require("dotenv").config();

function Profile(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [file, setFile] = useState("");
  const [fileUpload, setFileUpload] = useState("");
  const [newImage, setNewImage] = useState("");
  const [id, setId] = useState("");
  const [load, setLoad] = useState(false);
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [reunionGood, setReunionGood] = useState(true);
  const [reunionMedium, setReunionMedium] = useState(true);
  const [reunionBad, setReunionBad] = useState(true);
  const [reportGood, setReportGood] = useState(true);
  const [reportMedium, setReportMedium] = useState(true);
  const [reportBad, setReportBad] = useState(true);
  const [status, setStatus] = useState("");
  const [departament, setDepartament] = useState("");

  useEffect(() => {
    getUser();
    // async function registerToSocket() {
    //     const socket = io(`${process.env.REACT_APP_API_URL}`);
    //     socket.on("users", () => {
    //         getUser();
    //     });
    // }
    // registerToSocket();
  }, []);

  const getUser = async () => {
    setLoading(true);
    const { user: session = {} } = decodeToken();
    const response = await api.get(`/profile/${session._id}`);
    console.log(response);
    setFirstName(response.data.firstName);
    setLastName(response.data.lastName);
    setEmail(response.data.email);
    setFile(response.data.avatar);
    setId(response.data._id);
    setRole(response.data.role);
    setStatus(response.data.status);
    setDepartament(response.data.departament);

    setReunionGood(
      (response.data.notificationGood !== null) & (response.data.notificationGood !== undefined)
        ? response.data.notificationGood
        : true
    );
    setReunionMedium(
      (response.data.notificationMedium !== null) & (response.data.notificationMedium !== undefined)
        ? response.data.notificationMedium
        : true
    );
    setReunionBad(
      (response.data.notificationBad !== null) & (response.data.notificationBad !== undefined)
        ? response.data.notificationBad
        : true
    );
    setReportGood(
      (response.data.notificationReportGood !== null) & (response.data.notificationReportGood !== undefined)
        ? response.data.notificationReportGood
        : true
    );
    setReportMedium(
      (response.data.notificationReportMedium !== null) & (response.data.notificationReportMedium !== undefined)
        ? response.data.notificationReportMedium
        : true
    );
    setReportBad(
      (response.data.notificationReportBad !== null) & (response.data.notificationReportBad !== undefined)
        ? response.data.notificationReportBad
        : true
    );
    setLoading(false);
  };

  const handleImageChange = async (e) => {
    console.log(URL.createObjectURL(e.target.files[0]));
    setFileUpload(e.target.files[0]);
    setNewImage(URL.createObjectURL(e.target.files[0]));
    e.target.value = "";
  };

  const save = async (event) => {
    event.preventDefault();
    setLoad(true);
    const dataup = new FormData();
    if (fileUpload !== "") {
      dataup.append("file", fileUpload);
    }

    dataup.append("firstName", firstName);
    dataup.append("lastName", lastName);
    dataup.append("email", email);
    dataup.append("password", password);
    dataup.append("status", status);
    dataup.append("role", role);
    dataup.append("departament", departament);
    dataup.append("notificationGood", reunionGood);
    dataup.append("notificationMedium", reunionMedium);
    dataup.append("notificationBad", reunionBad);
    dataup.append("notificationReportGood", reportGood);
    dataup.append("notificationReportMedium", reportMedium);
    dataup.append("notificationReportBad", reportBad);

    await api
      .put(`/user/${id}`, dataup)
      .then(async (resp) => {
        setLoad(false);
        toastsMsg("Sucesso!", "Usuário editado com sucesso!", "success");
        console.log(resp);

        await api.post(`/newToken`, { id }).then((response) => {
          console.log(response);

          const { token } = response.data;
          localStorage.setItem("TOKEN_KEY", token);
          window.location.href = "/perfil";
        });
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
        toastsMsg("Erro!", "Erro ao editar o usuário, tente novamente mais tarde.", "error");
      });
  };

  const cancel = () => {
    const { user: session = {} } = decodeToken();
    setFirstName(session.firstName);
    setLastName(session.lastName);
    setEmail(session.email);
    setFileUpload("");
    setNewImage("");
    setFile(session.avatar);
    setId(session._id);
    setRole(session.role);
    setPassword("");
    setReunionGood(session.notificationGood === undefined ? true : session.notificationGood);
    setReunionMedium(session.notificationMedium === undefined ? true : session.notificationMedium);
    setReunionBad(session.notificationBad === undefined ? true : session.notificationBad);
    setReportGood(session.notificationReportGood === undefined ? true : session.notificationReportGood);
    setReportMedium(session.notificationReportMedium === undefined ? true : session.notificationReportMedium);
    setReportBad(session.notificationReportBad === undefined ? true : session.notificationReportBad);
    window.location.href = "/dashboard";
  };

  return (
    <>
      <Layout defaultMenu={500} title={"Meu perfil"}>
        <div className="container-fluid">
          <WidgetProfile
            link={
              role === "company"
                ? "/dashboard"
                : role === "agency"
                ? "/dashboard"
                : role === "category"
                ? "/projetos-da-categoria"
                : "meus-projetos"
            }
          />
          <div className="col-12 card border-0 py-2 rounded">
            <div className="card-body d-flex align-itens-center justify-content-center">
              <div className="col-12 col-lg-5">
                <form name="formmodal" novalidate>
                  <div className="d-flex flex-column">
                    <div className="col-12 d-flex align-items-center justify-content-center">
                      <div className="d-flex align-items-center justify-content-center position-relative rounded-circle">
                        <div
                          className="image-container d-flex align-items-center justify-content-center position-relative rounded-circle"
                          style={{
                            border: "1px solid #626679",
                          }}
                        >
                          <img
                            alt=""
                            src={fileUpload !== "" ? newImage : file}
                            className="img-fluid rounded-circle"
                            style={{
                              border: "1px solid #626679",
                            }}
                          />
                        </div>

                        <label
                          htmlFor="file"
                          className="p-2 rounded-circle d-flex align-items-center justify-content-center"
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            right: 0,
                            bottom: "10px",
                            backgroundColor: "#22A6AC",
                          }}
                        >
                          <FontAwesomeIcon color="white" icon={faPencilAlt} />
                          <input type="file" id="file" className="d-none" onChange={handleImageChange} required />
                        </label>
                      </div>
                    </div>
                    <div className="col-12 mb-3">
                      <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Nome</label>
                      <input
                        type="text"
                        className="form-control"
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                        required
                      />
                    </div>
                    <div className="col-12 mb-3">
                      <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Sobrenome</label>
                      <input
                        type="text"
                        className="form-control"
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                        required
                      />
                    </div>
                    <div className="col-12 mb-3 d-flex flex-column">
                      <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                        Nível de acesso
                      </label>
                      <label className="form-label font-rubik text-prop-grey-light fz-16 mb-1">
                        {role === "company"
                          ? "Administrador"
                          : role === "agency"
                          ? "Empresa"
                          : role === "category"
                          ? "Categoria"
                          : "Cliente"}
                      </label>
                    </div>
                    <div className="col-12 mb-3">
                      <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">E-mail</label>
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        required
                      />
                    </div>
                    <div className="col-12 mb-3">
                      <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">Senha</label>
                      <input
                        type="password"
                        className="form-control"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        required
                      />
                    </div>
                    <div className="col-12 mb-3">
                      <label className="form-label font-rubik fw-bold text-prop-grey-light fz-16 mb-1">
                        Notificação
                      </label>
                      <div className="col-12 d-lg-flex flex-row align-items-center justify-content-center">
                        <div className="d-flex flex-row align-items-center justify-content-start mb-2 col-12 col-lg-4">
                          <label className="font-rubik text-prop-grey-dark fz-16" style={{ marginRight: "5px" }}>
                            Reunião Boa
                          </label>
                          <label className="switch d-flex">
                            <input
                              type="checkbox"
                              name="notificationReunionGood"
                              value={reunionGood}
                              onClick={() => setReunionGood(!reunionGood)}
                              checked={reunionGood}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-start mb-2 col-12 col-lg-4">
                          <label className="font-rubik text-prop-grey-dark fz-16" style={{ marginRight: "5px" }}>
                            Reunião Regular
                          </label>
                          <label className="switch d-flex">
                            <input
                              type="checkbox"
                              name="notificationReunionGood"
                              value={reunionMedium}
                              onClick={() => setReunionMedium(!reunionMedium)}
                              checked={reunionMedium}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-center mb-2 col-12 col-lg-4">
                          <label className="font-rubik text-prop-grey-dark fz-16" style={{ marginRight: "5px" }}>
                            Reunião Ruim
                          </label>
                          <label className="switch d-flex">
                            <input
                              type="checkbox"
                              name="notificationReunionGood"
                              value={reunionBad}
                              onClick={() => setReunionBad(!reunionBad)}
                              checked={reunionBad}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-12 d-lg-flex flex-row align-items-center justify-content-start">
                        <div className="d-flex flex-row align-items-center justify-content-start mb-2 col-12 col-lg-4">
                          <label className="font-rubik text-prop-grey-dark fz-16" style={{ marginRight: "5px" }}>
                            Report Bom
                          </label>
                          <label className="switch d-flex">
                            <input
                              type="checkbox"
                              name="notificationReunionGood"
                              value={reportGood}
                              onClick={() => setReportGood(!reportGood)}
                              checked={reportGood}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-start mb-2 col-12 col-lg-4">
                          <label className="font-rubik text-prop-grey-dark fz-16" style={{ marginRight: "5px" }}>
                            Report Regular
                          </label>
                          <label className="switch d-flex">
                            <input
                              type="checkbox"
                              name="notificationReunionGood"
                              value={reportMedium}
                              onClick={() => setReportMedium(!reportMedium)}
                              checked={reportMedium}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-center mb-2 col-12 col-lg-4">
                          <label className="font-rubik text-prop-grey-dark fz-16" style={{ marginRight: "5px" }}>
                            Report Ruim
                          </label>
                          <label className="switch d-flex">
                            <input
                              type="checkbox"
                              name="notificationReunionGood"
                              value={reportBad}
                              onClick={() => setReportBad(!reportBad)}
                              checked={reportBad}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-12 d-flex align-items-center justify-content-end">
                      <button
                        type="button"
                        onClick={() => cancel()}
                        className="btn text-danger px-5 py-2 text-prop-danger fz-13 text-uppercase font-rubik "
                        data-bs-dismiss="modal"
                      >
                        Cancelar
                      </button>
                      {load ? (
                        <button
                          type="button"
                          className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik"
                          disabled
                        >
                          <div className="spinner-border spinner-border-sm text-light" role="status">
                            <span className="visually-hidden">Carregando...</span>
                          </div>
                        </button>
                      ) : (
                        <button
                          type="submit"
                          onClick={save}
                          className="btn btn-prop-blue px-5 py-2 text-white fz-13 text-uppercase font-rubik "
                        >
                          Salvar Alterações
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <Loading show={loading} />
    </>
  );
}

export default Profile;
