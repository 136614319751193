import React from "react";
import expand from "../../assets/img/icons/v.svg";
// import AddButton from '../AddButton';

export default function AddSprint(props) {
  return (
    <div className={` d-flex py-1 mb-3 flex-wrap `} style={{ borderRadius: "8px" }}>
      <div className="col-12">
        <div className="row m-0 py-3 align-items-center">
          <div className="col-12 col-lg-3 d-flex me-auto align-items-center click-sprint">
            <img src={expand} className={"m-2 rotate-icon"} />
            <span
              style={{ color: "#102A43", textTransform: "capitalize" }}
              className="font-rubik p-0 fw-bold fz-14 m-0"
            >
              New Sprint
            </span>
          </div>
          <div className="col-12 d-flex col-lg-3 me-5  justify-content-end">
            <div className={`col-12 col-lg-auto d-flex justify-content-end`}>
              <button
                onClick={() => props.addType()}
                className="btn btn-prop-blue text-white font-ruby fz-14 px-4 py-1 button-add "
                data-bs-toggle="modal"
                data-bs-target={"#myModalSingle"}
                type="button"
              >
                {"Adicionar Sprint"}
              </button>
            </div>
          </div>
        </div>
        <div className={"px-4 w-100 mb-5"}>
          <div className={`card-body-addsprint bg-white d-flex py-2 justify-content-center`}>
            <span className="font-rubik text-prop-grey-light fz-14 d-flex my-2">Sua tarefa aparecerá aqui</span>
          </div>
        </div>
      </div>
    </div>
  );
}
