import React, { useEffect } from 'react'; 
import Img404 from "../../../assets/img/checked.png";
import api from '../../../config/api';

function DeliveryDateAuthorized(props) {

    useEffect(() => {
        document.body.style.backgroundColor = "#fff";
        document.getElementById("root").style.backgroundColor = "#fff";

        save();
    }, [props]);

    const save = async () => {
        await api.put(`/project-authorized/${props.match.params.id}`);
    }

    return (
        <div className="container h-100">
            <div className="row justify-content-center h-100">
                <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center flex-column h-100">
                    <img src={Img404} className="img-fluid mb-5" width={"30%"} alt="404 - Not Found"/>
                    <h1 className="mb-0 fz-35 fw-bold font-rubik">Alteração autorizada</h1>
                </div>
            </div>
        </div>
    );
}

export default DeliveryDateAuthorized;